import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faTrashAlt, faEdit, faCloudUploadAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -230px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class SalesReturnsModule extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);


        this.state = {
            loading: false,
            salesReturnList: [],
            orgsalesReturnList: [],
            salesmanList: [],
            distributorList: [],
            salesreturnObj: '',
            salesReturnDetailsList: [],
            salesReturnNewList: [],
            refNum: "",
            currentDate: todaydate,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            quantity: '',
            salesReturnNo: '',
            salesReturnDate: '',
            distributorName: '',
            retailerName: '',
            retailerCode: '',
            srSalesmanCode: '',
            salesmanName: '',
            totAmount: '',
            productsList: [],
            productCode: "",
            reasonList: [],
            startDate: todaydate,
            endDate: todaydate,
            allChecked: false,
            salesReturnWriteAccess: this.props.userObj.salesReturnWriteAccess,
            loginSalesmanCode: this.props.userObj.salesmanCode,
        }

    }

    Tableedit_popCloseHandler = () => {
        document.querySelector('.salRetTableEdit_pop').style.display = 'none';
    }
    handleAllChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { salesReturnList, allChecked, orgsalesReturnList } = prevState;
            if (itemName === "checkAll") {
                console.log(checked)
                if (checked) {
                    allChecked = checked;
                    salesReturnList = salesReturnList.map(item => ({ ...item, isChecked: checked }));
                    orgsalesReturnList = orgsalesReturnList.map(item => ({ ...item, isChecked: checked }));
                } else {
                    allChecked = false;
                    salesReturnList = salesReturnList.map(item => ({ ...item, isChecked: false }));
                    orgsalesReturnList = orgsalesReturnList.map(item => ({ ...item, isChecked: false }));
                }

            }
            return { salesReturnList, allChecked, orgsalesReturnList };
        });
    };

    handleChange = (e, ind) => {
        let checked = e.target.checked;
        const { salesReturnList, allChecked, orgsalesReturnList } = this.state;
        salesReturnList[ind].isChecked = checked
        orgsalesReturnList[ind].isChecked = checked


        this.setState({ salesReturnList: salesReturnList, orgsalesReturnList: orgsalesReturnList, allChecked: false }, () => {
            ReactTooltip.rebuild();
        });
    };
    Tableedit_openHandler = (salesReturn) => {
        const params = {
            salesReturnId: salesReturn.id,
            salesmanCode: salesReturn.salesmanCode,
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/salesReturn/getSalesReturnDetailList", { params })
            .then(response => response.data)
            .then((data) => {
                //    console.log("data-->"+data.forOrderDetailsList)
                this.setState({
                    loading: false
                })
                this.setState({
                    salesReturnDetailsList: data.salesReturnDetailsList,
                    salesReturnObj: salesReturn,
                    refNum: salesReturn.refNum,
                    salesReturnNo: salesReturn.salesreturnNum,
                    salesReturnDate: salesReturn.salesReturnDTMStr,
                    distributorName: salesReturn.distributorName,
                    retailerName: salesReturn.retailerName,
                    retailerCode: salesReturn.retailerCode,
                    srSalesmanCode: salesReturn.salesmanCode,
                    salesmanName: salesReturn.salesmanName,
                    totAmount: salesReturn.salesReturnValue,
                    productsList: data.productList,
                }, () => {
                    ReactTooltip.rebuild();
                    document.querySelector('.salRetTableEdit_pop').style.display = 'flex';
                })
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    Salesret_newRowHandler = () => {
        let reason = '';
        if (this.state.reasonList.length !== 0 || this.state.salesReturnList === null || this.state.salesReturnList === undefined) {
            reason = this.state.reasonList[0].reasonCode
        } else {
            reason = ''
        }
        var appendRow = {
            productCode: "",
            distributorCode: '',
            productDistributorList: [],
            quantity: "0",
            quantityStr: "0",
            reasonCode: reason,
            isExported: "N",
            rate: "00.00",
            totRate: "00.00",
            salesReturnId: this.state.salesReturnObj.id,
            lastUpdatedBy: this.state.loginuserObj.userName,
            batchNum: 'AA',
            invoiceNum: 'AA',
            remarks: 'AA',
            status: 'Y',
            reasonName: this.state.reasonList[0].reasonDesc
        }

        let salesReturnDetailsList = this.state.salesReturnDetailsList
        salesReturnDetailsList.push(appendRow)

        this.setState({ salesReturnDetailsList: salesReturnDetailsList }, () => {
            ReactTooltip.rebuild();
        })
    }

    componentDidMount() {
        this.getAllSalesReturns();
    }

    getAllSalesReturns = () => {
        var dFlag = true;
        if (this.state.startDate !== '' && this.state.endDate !== '') {
            if (this.state.startDate > this.state.endDate) {
                alert("From Date Cannot Be Greater Than End Date");
                dFlag = false;
            }
        } else {
            alert("Please select Start and End Date...");
            dFlag = false;
        }
        if (dFlag) {
            const params = {
                companyCode: this.state.companyCode,
                distributorCode: "All",
                salesmanCode: this.state.salesmanCode,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                loginSalesmanCode: this.state.loginSalesmanCode,
            }
            this.setState({
                loading: true
            });
            axios.get(this.state.httpURL + "/salesReturn/getSalesReturnDetails/", { params })
                .then(response => response.data)
                .then((data) => {
                    this.setState({
                        loading: false
                    });
                    if (this.state.salesmanList.length === 0) {
                        this.setState({
                            salesmanList: data.salesmanList
                        })
                    }
                    this.setState({
                        salesReturnList: data.salesReturnDataList,
                        orgsalesReturnList: data.salesReturnDataList,
                        //   salesmanList: data.salesmanList,
                        distributorList: data.distributorList,
                        reasonList: data.reasonList
                    })
                    ReactTooltip.rebuild();
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    handleSalesmanChange = (event) => {
        this.setState({
            salesmanCode: event.target.value
        }, () => {
            this.getAllSalesReturns();
        });

    }

    changeqty = (e) => {
        let ivalue = e.target.value;
        const { salesReturnDetailsList } = this.state;
        const { id } = e.target;
        // const re = /^[0-9\b]+$/;
        const re = /^\d{1,}(\.\d{0,2})?$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            let rate = salesReturnDetailsList[id].rate;
            let newamt = ivalue * rate;
            salesReturnDetailsList[id].quantityStr = ivalue
            salesReturnDetailsList[id].totRate = newamt

            this.setState({ salesReturnDetailsList: salesReturnDetailsList }, () => {
                // console.log("calling")
                this.calculateTotalAmount();
            });
        }
    }

    handleDistributorChange = (event) => {
        this.setState({
            distributorCode: event.target.value
        }, () => {
            this.getAllSalesReturns();
        });

    }

    handleProductChange = (ind, event) => {
        // console.log("ind-->"+ind)
        // console.log("event-->"+event.value)
        // console.log("price-->"+event.price)
        const { salesReturnDetailsList } = this.state;
        salesReturnDetailsList[ind].productCode = event.value
        salesReturnDetailsList[ind].productName = event.label
        salesReturnDetailsList[ind].quantity = 0
        salesReturnDetailsList[ind].totRate = 0
        salesReturnDetailsList[ind].rate = event.price
        this.calculateTotalAmount();
        if (event.value !== undefined) {
            this.setState({ salesReturnDetailsList: salesReturnDetailsList }, () => {
                ReactTooltip.rebuild();
                this.getSRDistributor(ind, event);
            });
        }
    }

    handleSRProductDistributorChange = (ind, event) => {
        const { salesReturnDetailsList } = this.state;
        salesReturnDetailsList[ind].distributorCode = event.value
        salesReturnDetailsList[ind].distributorName = event.label
        this.setState({ salesReturnDetailsList: salesReturnDetailsList }, () => {
            ReactTooltip.rebuild();
        });
    }

    getSRDistributor = (ind, event) => {
        const params = {
            companyCode: this.state.loginuserObj.companyCode,
            productCode: event.value,
            retailerCode: this.state.retailerCode,
            salesmanCode: this.state.srSalesmanCode,
            productTypeCode: event.productTypeCode,
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/salesReturn/getDistributorList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    });
                    const { salesReturnDetailsList } = this.state;
                    if (data.productDistributorList.length > 0) {
                        salesReturnDetailsList[ind].productDistributorList = data.productDistributorList;
                        salesReturnDetailsList[ind].distributorCode = data.productDistributorList[0].value;
                        // salesReturnDetailsList[ind].isDisSelectDisabled = true ;
                    }

                    this.setState({ salesReturnDetailsList: salesReturnDetailsList });
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    changereasonDesc = (i, event) => {
        let ivalue = event.target.value;
        // console.log("ivalue--"+ivalue)
        const { salesReturnDetailsList } = this.state;
        // const { id } = e.target;
        let damageIndex = event.nativeEvent.target.selectedIndex;
        salesReturnDetailsList[i].reasonCode = ivalue
        salesReturnDetailsList[i].reasonName = event.nativeEvent.target[damageIndex].text
        this.setState({ salesReturnDetailsList: salesReturnDetailsList }, () => {
            ReactTooltip.rebuild();
        });
    }

    SRNewDetails_editHandler = (ind) => {
        // console.log("--cobjct-->"+ind);
        const { salesReturnDetailsList } = this.state;
        salesReturnDetailsList[ind].readOnly = false
        salesReturnDetailsList[ind].isSelectDisabled = false
        // salesReturnDetailsList[ind].isDisSelectDisabled = false
        this.setState({ salesReturnDetailsList: salesReturnDetailsList })

    }

    SRNewDetails_deleteHandler = (ind) => {
        // console.log("--cobjct-->"+ind);
        var salesReturnDetailsList = [...this.state.salesReturnDetailsList];
        salesReturnDetailsList.splice(ind, 1)
        this.setState({ salesReturnDetailsList: salesReturnDetailsList }, () => {
            // console.log("calling")
            this.calculateTotalAmount();
        });
    }


    SalesReturn_popupSaveHandler = () => {
        // console.log(this.state.salesReturnDetailsList)

        var uniqobj = this.getUnique(this.state.salesReturnDetailsList, 'productCode');
        // console.log('uniqobj:'+JSON.stringify(uniqobj))
        // console.log("uniqobj len--:"+uniqobj.length)

        //  console.log("Add data : "+this.state.salesReturnDetailsList.length)
        // console.log("no Product : "+this.checkProductisFilled())
        // console.log("no qty: "+this.checkQtyisFilled())

        if (this.state.salesReturnDetailsList.length === 0) {
            alert("Add Data");
            //console.log("no data")
            return false;
        }

        if (this.checkProductisFilled()) {
            alert("Enter All Product Details");
            // console.log("no Product")
            return false;
        }

        if (this.checkDistributorFilled()) {
            alert("Enter All Distributor");
            // console.log("no Product")
            return false;
        }

        if (this.checkQtyisFilled()) {
            alert("Enter All Quantity Details");
            //console.log("no qty");
            return false;
        }

        if (this.state.salesReturnDetailsList.length !== uniqobj.length) {
            alert("Remove Duplicate Products");
            //console.log("Duplicate product");
            return false;
        }

        this.setState({
            loading: true
        });
        axios.post(this.state.httpURL + "/salesReturn/updateSalesReturnDetails", { "salesReturnDetailsList": this.state.salesReturnDetailsList, "movedby": this.state.loginuserObj.userName })
            .then(res => {
                // console.log(res);
                //console.log(res.data);
                if (res.data.valid) {
                    this.setState({
                        loading: false
                    });
                    alert("Successfully Updated");
                    this.Tableedit_popCloseHandler();
                    this.getAllSalesReturns()
                }

            })
    }

    getUnique = (arr, index) => {
        const unique = arr
            .map(e => e[index])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }

    checkQtyisFilled = () => {
        // console.log("calling")
        var isZero = false;
        let srlist = this.state.salesReturnDetailsList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].quantityStr === "0" || parseInt(srlist[i].quantityStr, 10) === 0 || srlist[i].quantityStr === "") {
                isZero = true;
            }
        }
        //console.log("iszero:=="+isZero)
        return isZero;
    }

    checkProductisFilled = () => {
        // console.log("calling")
        var isNotFilled = false;
        let srlist = this.state.salesReturnDetailsList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].productCode === "") {
                isNotFilled = true;
            }
        }
        //console.log("iszero:=="+isZero)
        return isNotFilled;
    }

    checkDistributorFilled = () => {
        // console.log("calling")
        var isNotFilled = false;
        let srlist = this.state.salesReturnDetailsList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].distributorCode === "" || srlist[i].distributorCode === undefined) {
                isNotFilled = true;
            }
        }
        //console.log("iszero:=="+isZero)
        return isNotFilled;
    }

    calculateTotalAmount = () => {
        var totAmtt = 0;
        // console.log('totAmount--'+this.state.totAmount);
        //  console.log("--"+JSON.stringify(this.state.salesReturnDetailsList))
        this.state.salesReturnDetailsList.map((srd) => (
            totAmtt = totAmtt + parseFloat(srd.totRate)
        ));
        // console.log("totAmtt:111"+totAmtt)
        this.setState({ totAmount: totAmtt })
    }



    salesReturn_deleteHandler = (sorder) => {
        var answer = window.confirm("Are Sure To Delete This SalesReturn?")
        if (answer) {
            const params = {
                salesReturnId: sorder.id,
                userName: this.state.loginuserObj.userName
            }
            this.setState({
                loading: true
            });
            axios.get(this.state.httpURL + "/salesReturn/deleteSalesReturn", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.setState({
                            loading: false
                        });
                        alert("Successfully Deleted");
                        this.getAllSalesReturns();
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
    }

    filterList = (event) => {
        let filtered = this.state.orgsalesReturnList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            salesReturnList: filtered
        })
    }

    dateChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    salesReturnExcel = () => {
        var dFlag = true;
        if (this.state.salesReturnList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.salesReturnList;
            var fileName = "Sales Return Report_" + this.state.currentDate;
            var wscols = [
                { wch: 18 },
                { wch: 35 },
                { wch: 45 },
                { wch: 35 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Sales Return Number": dList[i].salesreturnNum,
                    "Sales Person": dList[i].salesmanName,
                    "Retailer": dList[i].retailerName,
                    "Sales Return Amount": dList[i].salesReturnValue.toFixed(2),
                    "Date Time": dList[i].salesReturnDTMStr
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'salesReturn': ws }, SheetNames: ['salesReturn'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>SALES RETURNS</span></div>
                    <div className="O2Table_Body">
                        <div className="O2Table_SubContainer SalesRetContainer">
                            <div className="O2Table_SelectSales O2Content_row">
                                <div className="O2Table_Label contentRow_Label">Sales Person</div>
                                <div className="O2Table_Value contentRow_Value">
                                    <select value={this.state.salesmanCode} onChange={this.handleSalesmanChange}>
                                        {
                                            this.state.loginuserObj.userType !== "salesman" && this.state.loginuserObj.userType !== "Admin" && this.state.loginuserObj.userType !== "Sys Admin" ?
                                                <option value="All" key="All">All</option>
                                                : null
                                        }
                                        {
                                            this.state.salesmanList.map((salesman) => (
                                                <option value={salesman.salesmanCode} key={salesman.salesmanCode}>{salesman.salesmanName}</option>

                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* <div className="O2Table_SelectDist">
                       <div className="O2Table_Label">Distributor</div>
                        <div className="O2Table_Value">
                            <select value={this.state.distributorCode} onChange={this.handleDistributorChange}>
                                   
                                {
                                    this.state.distributorList.map( (dist)=>(
                                        <option value={dist.distributorCode} key={dist.distributorCode}>{dist.distributorName}</option>
                                        
                                    ))
                                }
                            </select>
                        </div> 
                    </div> */}
                            {/* <div className="O2Table_Search">
                                <div className="O2Table_Label"> Search : </div>
                                <div className="O2Table_Value"><input type="text" onChange={this.filterList} /></div>
                            </div> */}


                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date From </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="startDate" value={this.state.startDate} onChange={this.dateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date To </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="endDate" value={this.state.endDate} onChange={this.dateChange} />
                                </div>
                            </div>

                            <div className="O2Table_Go" onClick={this.getAllSalesReturns}><button>Go</button></div>

                        </div>
                        {/* <div className="O2Table_SubContainer">
                    <div className="O2Table_Records">
                        <div className="O2Table_Value"><select><option>5</option></select></div>
                        <div className="O2Table_Label">Records per pages</div>
                    </div> 
                    <div className="O2Table_Search">
                        <div className="O2Table_Label"> Search : </div>
                        <div className="O2Table_Value"><input type="text"/></div>
                    </div>
                </div> */}
                        <div className="Table_wrapper">
                            <table className="customTable" id="SalesReturn_Table">
                                <thead>
                                    <tr>
                                        <th className="checkBox_Container">
                                            <input type="checkbox" name="checkAll" checked={this.state.allChecked} onChange={this.handleAllChange} />
                                        </th>
                                        <th className="Ref_Num">SalesReturn Number
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="salesreturnNum" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        {/* <th>Distributor</th> */}
                                        <th className="salesMan">Sales Person
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="salesmanName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Descript_width">Retailer
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        {/* <th>Product</th>        
                                <th>Quantity</th>
                                <th>Reason</th> */}
                                        {/* <th>Status</th> */}
                                        <th className="Value_width">SalesReturn Amount
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="salesReturnValue" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="DateTime_width">Date Time</th>
                                        {
                                            this.state.salesReturnWriteAccess ?
                                                <th className="o2Del_container"></th>
                                                : null
                                        }
                                        <th className="scroller"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.salesReturnList || this.state.salesReturnList === null || this.state.salesReturnList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="6">No SalesReturn Available</td>
                                            </tr> :
                                            this.state.salesReturnList.map((SalesReturn, i) => (
                                                <tr key={SalesReturn.id}>
                                                    <td className="checkBox_Container">
                                                        <input type="checkbox" name={SalesReturn.id} value={SalesReturn.id} checked={SalesReturn.isChecked} onChange={(e) => this.handleChange(e, i)} />
                                                    </td>
                                                    <td className="Ref_Num" onDoubleClick={() => this.Tableedit_openHandler(SalesReturn)} data-tip={SalesReturn.salesreturnNum}>{SalesReturn.salesreturnNum}</td>
                                                    {/* <td onClick={() => this.Tableedit_openHandler(SalesReturn)} >{SalesReturn.distributorName}</td> */}
                                                    <td className="salesMan" onDoubleClick={() => this.Tableedit_openHandler(SalesReturn)} data-tip={SalesReturn.salesmanName}>{SalesReturn.salesmanName}</td>
                                                    <td className="Descript_width" onDoubleClick={() => this.Tableedit_openHandler(SalesReturn)} data-tip={SalesReturn.retailerName}>{SalesReturn.retailerName}</td>
                                                    {/* <td>{SalesReturn.productName}</td>
                                        <td>{SalesReturn.quantity}</td>
                                        <td>{SalesReturn.reasonDescription}</td> */}
                                                    {/* <td>{SalesReturn.isExported}</td> */}
                                                    <td className="Value_width" onDoubleClick={() => this.Tableedit_openHandler(SalesReturn)} data-tip={parseFloat(SalesReturn.salesReturnValue).toFixed(2)}>{parseFloat(SalesReturn.salesReturnValue).toFixed(2)}</td>
                                                    <td className="DateTime_width" onDoubleClick={() => this.Tableedit_openHandler(SalesReturn)} data-tip={SalesReturn.salesReturnDTMStr}>{SalesReturn.salesReturnDTMStr}</td>
                                                    {
                                                        this.state.salesReturnWriteAccess ?
                                                            <td className="o2Del_container"><FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 8px' }} onClick={() => this.salesReturn_deleteHandler(SalesReturn)} /></td>
                                                            : null
                                                    }
                                                </tr>

                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.salesReturnList ? this.state.salesReturnList.length : 0}</span>entries</span></div>
                            {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{padding:'0px 8px'}}/></span></div>
                    <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{padding:'0px 8px'}}/> Previous</span></div> */}
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            {
                                this.state.salesReturnWriteAccess ?
                                    <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudUploadAlt} style={{ padding: '0px 8px' }} /> Upload to ERP</div>
                                    : null
                            }
                            {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="SalesReturn_Table" filename={"Sales Return_" + this.state.currentDate}
                                sheet="tablexls" buttonText="Excel Export" /></div> */}
                            <div className="O2Table_ExpCollExcel" onClick={() => this.salesReturnExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                        </div>
                    </div>
                </div>
                <div className="O2TableEdit_wrapper salRetTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">SalesReturn Ref No(#): {this.state.salesReturnNo}
                                <div className="O2Edit_New">
                                    {
                                        this.state.salesReturnWriteAccess ?
                                            <button onClick={() => this.Salesret_newRowHandler()}>New</button>
                                            : null
                                    }

                                </div>
                            </div>
                            <div className="O2TableEditBody">
                                <div className="row">
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">SalesReturn No : </div>
                                        <div className="FieldValue"><span>{this.state.salesReturnNo} </span></div>
                                    </div>
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">SalesReturn Date : </div>
                                        <div className="FieldValue"><span>{this.state.salesReturnDate} </span></div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="FieldComponent">
                                        <div className="FieldLabel">Distributor : </div>
                                        <div className="FieldValue"><span>{this.state.distributorName} </span></div>
                                    </div> */}
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Retailer : </div>
                                        <div className="FieldValue"><span>{this.state.retailerName} </span></div>
                                    </div>
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Sales Person : </div>
                                        <div className="FieldValue"><span>{this.state.salesmanName} </span></div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="FieldComponent">
                                        <div className="FieldLabel">Salesman : </div>
                                        <div className="FieldValue"><span>{this.state.salesmanName} </span></div>
                                    </div> */}
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Amount : </div>
                                        <div className="FieldValue"><span>{parseFloat(this.state.totAmount).toFixed(2)}  </span></div>
                                    </div>
                                    <div className="FieldComponent">
                                        {/* <div className="FieldLabel">Amount : </div>
                                        <div className="FieldValue"><span>{parseFloat(this.state.totAmount).toFixed(2)}  </span></div> */}
                                    </div>
                                </div>
                                <div className="Table_wrapper">
                                    <table className="customTable salesRetPopup">
                                        <thead>
                                            <tr>
                                                <th className="Sno_width">#</th>
                                                <th className="Descript_width prodDesc">Product</th>
                                                <th className="Descript_width">Distributor</th>
                                                <th className="Value_width">Quantity</th>
                                                <th className="Value_width">Rate</th>
                                                <th className="Value_width">Value</th>
                                                <th className="salesMan">Reason</th>
                                                {/* <th>Status</th> */}
                                                {
                                                    this.state.salesReturnWriteAccess ?
                                                        <th className="o2popDel_container"></th>
                                                        : null
                                                }

                                                <th className="scroller"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !this.state.salesReturnDetailsList || this.state.salesReturnDetailsList.length === 0 || this.state.salesReturnDetailsList == null ?
                                                    <tr align="center">
                                                        <td colSpan="6">No SalesReturn Details Available</td>
                                                    </tr> :
                                                    this.state.salesReturnDetailsList.map((sales, i) => (
                                                        <tr key={i}>
                                                            <td className="Sno" data-tip={i}>{i + 1}</td>
                                                            <td className="prodDesc Descript_width" data-tip={sales.productName}>
                                                                <Select options={this.state.productsList} value={this.state.productsList.filter(({ value }) => value === sales.productCode)} placeholder="Choose Product" isDisabled={sales.isSelectDisabled} onChange={(e) => this.handleProductChange(i, e)} />
                                                            </td>
                                                            <td className="Descript_width" data-tip={sales.distributorName}><Select options={sales.productDistributorList} value={sales.productDistributorList.filter(({ value }) => value === sales.distributorCode)} placeholder="Choose Distributor" isDisabled={sales.isDisSelectDisabled} onChange={(e) => this.handleSRProductDistributorChange(i, e)} />
                                                            </td>
                                                            <td className="Value_width" data-tip={sales.quantityStr}><input type="text" id={i} name={i} onChange={this.changeqty} value={sales.quantityStr} readOnly={sales.readOnly} /></td>
                                                            <td className="Value_width" data-tip={parseFloat(sales.rate).toFixed(2)}>{parseFloat(sales.rate).toFixed(2)}</td>
                                                            <td className="Value_width" data-tip={parseFloat(sales.totRate).toFixed(2)}>{parseFloat(sales.totRate).toFixed(2)}</td>
                                                            <td className="salesMan" data-tip={sales.reasonName}>
                                                                {/* <input type="text"  id={i} name={i} onChange={this.changereasonDesc} value={sales.reasonDescription} readOnly={sales.readOnly}/> */}
                                                                <select onChange={(e) => this.changereasonDesc(i, e)} value={sales.reasonCode} disabled={sales.isSelectDisabled}>
                                                                    {
                                                                        this.state.reasonList.map((reason) => (
                                                                            <option value={reason.reasonCode} key={reason.reasonCode}>{reason.reasonDesc}</option>

                                                                        ))
                                                                    }
                                                                </select>
                                                            </td>
                                                            {/* <td>{sales.isExported}</td> */}
                                                            {
                                                                this.state.salesReturnWriteAccess ?
                                                                    <td className="o2popDel_container">
                                                                        <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.SRNewDetails_editHandler(i)} />
                                                                        <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} onClick={() => this.SRNewDetails_deleteHandler(i)} />
                                                                    </td>
                                                                    : null
                                                            }

                                                        </tr>

                                                    ))
                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    {
                                        this.state.salesReturnWriteAccess ?
                                            <button onClick={this.SalesReturn_popupSaveHandler}>Save</button>
                                            : null
                                    }

                                    <button onClick={this.Tableedit_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                        </div>
                        <FadeLoader
                            css={override1}
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(SalesReturnsModule)

//export default SalesReturnsModule
