import React, { Component, useState } from 'react';
import './UserRoleModule.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt,faEdit,faSearch,faPlus} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import Select from 'react-select';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
class UserRoleModule extends Component {
    constructor(props) {
        super(props)
        this.state = {
            RoleAccessbtn: "Popup_tabButtons",
            UserRolebtn: "Popup_tabButtons",
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            urRoleFlag:true,
            urUserRoleList:[],
            urMenuList:[],
            urMenuOrgList:[],
            urUserRoleDetailList:[],
            urOrgUserRoleDetailList:[],
            companyCode: this.props.userObj.companyCode,
            urUserRole:'',
            urAllChecked:false,
            urMenuTypeList:[],
            urMenuType:'',
        }
    }
    poptabRoleAccessHandler = () => {
        this.setState({
            RoleAccessbtn: "Popup_tabButtonsActive",
            UserRolebtn: "Popup_tabButtons",
            urRoleFlag:true
        })
        document.querySelector('.RoleAccess_Container').style.display = 'block';
        document.querySelector('.UserRole_Container').style.display = 'none';
        this.getUserRole();
        this.clearUserRole();
    }
    poptabUserRoleHandler = () => {
        this.setState({
            RoleAccessbtn: "Popup_tabButtons ",
            UserRolebtn: "Popup_tabButtonsActive",
            urRoleFlag:false
        })
        document.querySelector('.UserRole_Container').style.display = 'block';
        document.querySelector('.RoleAccess_Container').style.display = 'none';
        this.getUserRoleDetailList();
    }
    componentDidMount() {
        this.poptabRoleAccessHandler();
    }
    getUserRole(){
        const params = {            
            companyCode: this.state.companyCode
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/userrole/getUserRole", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        urUserRoleList : data.userRoleList,
                        urMenuTypeList : data.menuTypeList                       
                    },()=>{                        
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getUserRoleList = () => {
        if(this.state.urUserRole === "" || this.state.urUserRole === null || this.state.urUserRole === undefined){
            alert("Please Select User Role");
        } else if(this.state.urMenuType === "" || this.state.urMenuType === null || this.state.urMenuType === undefined){
            alert("Please Select Menu Type");
        } else {
            this.getURMenuList();
        }        
    }

    getURMenuList(){        
        const params = {            
            rollId: this.state.urUserRole,
            menuType: this.state.urMenuType
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/userrole/getMenuList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        urMenuList : data.menuList,
                        urMenuOrgList : data.menuList                        
                    },()=>{                        
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }
    clearUserRole(){
        this.setState({                    
            urUserRole:'',
            urMenuList:[]
        });
    }
    handleURRoleChange = (event) => {        
        this.setState({                    
            urUserRole:event.value
        },()=>{   
            // if(this.state.urUserRole !== "" && this.state.urUserRole !== null && this.state.urUserRole !== undefined){
            //     this.getURMenuList();
            // }  
            this.setState({
                urMenuType:'',
                urMenuList:[],
                urMenuOrgList:[],
            });
        })
    }
    handleURMenuTypeChange = (event) => {        
        this.setState({                    
            urMenuType:event.value
        },()=>{    
            this.setState({
                urMenuList:[],
                urMenuOrgList:[],
            });                                                                        
        })
    }
    filterList = (event) => {
        let filtered =this.state.urMenuOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {
                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name
                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));                
            }
        }
        this.setState({
            urMenuList: filtered
        })
    }
    handleURAllChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { urMenuList, urAllChecked, urMenuOrgList } = prevState;
            if (itemName === "checkAll") {
               //console.log(checked)
                if(checked){
                    urAllChecked = checked;
                    urMenuList = urMenuList.map(item => item.readAccess === "N" ? { ...item, isChecked: checked, readAccess: "Y" } : item);
                    urMenuOrgList = urMenuOrgList.map(item => item.readAccess === "N" ? { ...item, isChecked: checked, readAccess: "Y" } : item);
                }else{
                    urAllChecked = false;
                    urMenuList = urMenuList.map(item => ({ ...item, isChecked: false, readAccess: "N" }));
                    urMenuOrgList = urMenuOrgList.map(item => ({ ...item, isChecked: false, readAccess: "N" }));
                }
               
            }
            return { urMenuList, urAllChecked, urMenuOrgList };
        });
    };
    editUR_openHandler = (ind) => {
        const { urMenuList } = this.state;        
        urMenuList[ind].readOnly = false
        this.setState({ urMenuList: urMenuList })
    }
    handleURRowClickChange = (e,ind) => {
        let checked = e.target.checked;
        const {  urMenuList, urMenuOrgList } = this.state;
        if(e.target.name === "rowCheckbox"){                   
            urMenuList[ind].isChecked = checked
            urMenuOrgList[ind].isChecked = checked
            if(checked){
                urMenuList[ind].readAccess = "Y"
                urMenuOrgList[ind].readAccess = "Y"
            } else {
                urMenuList[ind].readAccess = "N"
                urMenuOrgList[ind].readAccess = "N"
            }
            if(urMenuList[ind].parentMenu === "P"){
                this.childMenuSelect(e,ind);                
            }
        } else if(e.target.name === "status"){            
            urMenuList[ind].status = e.target.value;
            urMenuOrgList[ind].status = e.target.value;            
        }
        urMenuList[ind].changeFlag = "U";  
        urMenuOrgList[ind].changeFlag = "U";      
        this.setState({ urMenuList:urMenuList,urMenuOrgList:urMenuOrgList},()=>{
            ReactTooltip.rebuild();
        });                
    };
    childMenuSelect(e,ind){
        // let checked = e.target.checked;
        // const {  urMenuList, urMenuOrgList } = this.state;
        // console.log("<-1->"+urMenuList[ind].displayName);
        // console.log("<-2->"+checked);
        // this.setState(prevState => {
        //     let { urMenuList, urMenuOrgList } = prevState;
        //     var pName = urMenuList[ind].displayName;
        //     urMenuList = urMenuList.map(item => item.parentMenu === pName ? { ...item, isChecked: checked, readAccess: "Y" }  : item);
        //     urMenuOrgList = urMenuOrgList.map(item => item.parentMenu === pName ? { ...item, isChecked: checked, readAccess: "Y" } : item);
        //     return { urMenuList, urMenuOrgList };            
        // },()=>{
            
        // });      
        var dList = this.state.urMenuList;
        var pName = "";
        let checked = e.target.checked;
        dList.map(function (nc, i) {            
            if(i === ind){
                pName = nc.displayName;                
            }
            if(pName === nc.parentMenu){
                if(checked){
                    nc.isChecked = true;
                    nc.readAccess = "Y";
                } else {
                    nc.isChecked = false;
                    nc.readAccess = "N";
                }
                nc.readOnly = false;
                nc.changeFlag = "U";              
            }
        });  
    }

    /* For User Role Screen*/ 
    getUserRoleDetailList(){
        const params = {            
            companyCode: this.state.companyCode
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/userrole/getUserRoleDetailList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        urUserRoleDetailList : data.userRoleDetailList,
                        urOrgUserRoleDetailList : data.userRoleDetailList                        
                    },()=>{                        
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }
    urFilterList = (event) => {
        let filtered =this.state.urOrgUserRoleDetailList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {
                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name
                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));                
            }
        }
        this.setState({
            urUserRoleDetailList: filtered
        })
    }
    editURS_openHandler = (ind) => {
        const { urUserRoleDetailList } = this.state;        
        urUserRoleDetailList[ind].readOnly = false
        this.setState({ urUserRoleDetailList: urUserRoleDetailList })
    }
    handleURSRowClickChange = (e,ind) => {                
        const {  urUserRoleDetailList, urOrgUserRoleDetailList } = this.state;
        if(e.target.name === "roleName"){                   
            urUserRoleDetailList[ind].roleName = e.target.value;
            urOrgUserRoleDetailList[ind].roleName = e.target.value;
        } else if(e.target.name === "prefixCode"){ 
            urUserRoleDetailList[ind].prefixCode = e.target.value;
            urOrgUserRoleDetailList[ind].prefixCode = e.target.value;    
        } else if(e.target.name === "createSalesman"){            
            urUserRoleDetailList[ind].createSalesman = e.target.value;
            urOrgUserRoleDetailList[ind].createSalesman = e.target.value;
            if(e.target.value === "Y"){
                urUserRoleDetailList[ind].displayCreateSalesman = "Active"
                urOrgUserRoleDetailList[ind].displayCreateSalesman = "Active"
            } else {
                urUserRoleDetailList[ind].displayCreateSalesman = "In Active"
                urOrgUserRoleDetailList[ind].displayCreateSalesman = "In Active"
            }            
        } else if(e.target.name === "status"){            
            urUserRoleDetailList[ind].status = e.target.value;
            urOrgUserRoleDetailList[ind].status = e.target.value;
            if(e.target.value === "Y"){
                urUserRoleDetailList[ind].displayStatus = "Active"
                urOrgUserRoleDetailList[ind].displayStatus = "Active"
            } else {
                urUserRoleDetailList[ind].displayStatus = "In Active"
                urOrgUserRoleDetailList[ind].displayStatus = "In Active"
            }            
        }
        if(urUserRoleDetailList[ind].changeFlag === "N"){
            urUserRoleDetailList[ind].changeFlag = "U";  
            urOrgUserRoleDetailList[ind].changeFlag = "U";
        }              
        this.setState({ urUserRoleDetailList:urUserRoleDetailList,urOrgUserRoleDetailList:urOrgUserRoleDetailList},()=>{
            ReactTooltip.rebuild();
        });                
    };
    urs_newRowHandler = () => {                        
        var newind=this.state.urUserRoleDetailList.length+1;
        var emptyobj = {
            roleId: newind,
            roleName: '',
            createSalesman: 'Y',
            status: 'Y',
            displayCreateSalesman: 'Active',
            displayStatus: 'Active',                        
            changeFlag: 'A',                
        }
        let stockListtemp = this.state.urUserRoleDetailList
        stockListtemp.push(emptyobj)
        this.setState({ urUserRoleDetailList: stockListtemp })                         
    }
    deleteURS_openHandler = (ind) => {
        var urUserRoleDetailList = [...this.state.urUserRoleDetailList];
        urUserRoleDetailList.splice(ind, 1)
            this.setState({ urUserRoleDetailList: urUserRoleDetailList }, () => {                
                
            });            
    }
    urCancelfunction = () => {            
        if(this.state.urRoleFlag){
            this.getUserRoleList();
        } else {
            this.getUserRoleDetailList();
        }
    }
    urSavefunction = () => {
        if(this.state.urRoleFlag){
            if (this.state.urUserRole === "" && this.state.urUserRole === null && this.state.urUserRole === undefined) {
                alert("Please Choose User Role");
                return false;            
            } else if(!this.state.urMenuList || this.state.urMenuList === null || this.state.urMenuList.length === 0 ) {
                alert("No Screen Available To Save");
                return false;
            } else {
                let params = {
                    "rollId": this.state.urUserRole,
                    "menuType": this.state.urMenuType,
                    "lastModifiedBy":this.state.loginuserObj.userName,
                    "menuList": this.state.urMenuList
                }
                //console.log("<-1->"+JSON.stringify(params));
                this.setState({
                    loading: true
                });
                axios.post(this.state.httpURL + "/userrole/saveUserRoleAccess", params)
                    .then(response => {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            alert("User Role Access Saved Successfully.");
                            this.setState({
                                urMenuList : response.data.menuList,
                                urMenuOrgList : response.data.menuList
                            })
                            ReactTooltip.rebuild();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    })
                    .catch(error => {
                        //console.log("error" + error)
                        this.setState({
                            loading: false
                        });
                        alert(error);
                    })
            }
        } else {
            if (this.checkDataEnterURS()) {
                alert("Please Enter Roll Name");
                return false;
            } else if(this.checkURSUserRoleCde()) {
                alert("Please Enter User Role Code");
                return false;
            } else if (this.getURSUnique()) {
                alert("Remove Duplicate Roll Name");
                return false;
            } else {
                let params = {
                    "userRole": this.state.urUserRole,
                    "companyCode": this.state.companyCode,
                    "lastModifiedBy":this.state.loginuserObj.userName,
                    "userRoleDetailList": this.state.urUserRoleDetailList
                }
                //console.log("<-2->"+JSON.stringify(params));
                this.setState({
                    loading: true
                });
                axios.post(this.state.httpURL + "/userrole/saveUserRole", params)
                    .then(response => {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            alert("User Role Saved Successfully.");
                            this.setState({
                                urUserRoleDetailList : response.data.userRoleDetailList,
                                urOrgUserRoleDetailList : response.data.userRoleDetailList
                            })
                            ReactTooltip.rebuild();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    })
                    .catch(error => {
                        //console.log("error" + error)
                        this.setState({
                            loading: false
                        });
                        alert(error);
                    })
            }   
        }                         
    }
    checkDataEnterURS() {
        var notEntered = false;
        let srlist = this.state.urUserRoleDetailList;
        if(srlist.length > 0){
            for (var i = 0; i < srlist.length; i++) {                
                if (srlist[i].roleName === '' || srlist[i].roleName === undefined || srlist[i].roleName === null) {
                    notEntered = true;
                }
            }
        } else {
            notEntered = true;
        }        
        return notEntered;
    }

    checkURSUserRoleCde(){
        var notEntered = false;
        let srlist = this.state.urUserRoleDetailList;
        if(srlist.length > 0){
            for (var i = 0; i < srlist.length; i++) {                
                if (srlist[i].prefixCode === '' || srlist[i].prefixCode === undefined || srlist[i].prefixCode === null) {
                    notEntered = true;
                }
            }
        } else {
            notEntered = true;
        }        
        return notEntered;
    }

    getURSUnique() {       
        var isUnique = false;
        let srlist = this.state.urUserRoleDetailList;
        for (var i = 0; i < srlist.length; i++) {
            const roleName = srlist[i].roleName.split(' ').join('').toLowerCase();           
            for (var j = i + 1; j < srlist.length; j++) {
                const roleName1 = srlist[j].roleName.split(' ').join('').toLowerCase();                
                if (roleName === roleName1) {                   
                    isUnique = true;
                }                           
            }
        }
        return isUnique;
    }
    render() {
        return (

            <div className="O2Container_Wrapper">
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>RoleAccess</span></div>
                    
                        <div className="O2Table_SubContainer UserroleSub">
                            <div className="Popup_Tabwrapper">
                                <div className={this.state.RoleAccessbtn} onClick={this.poptabRoleAccessHandler}><span>User Role Access</span></div>
                                <div className={this.state.UserRolebtn} onClick={this.poptabUserRoleHandler}><span>User Role</span></div>
                            </div>
                        </div>
                        {
                            this.state.urRoleFlag ?
                            <div className="O2Table_SubContainer Report_subContainer urRoleGo">
                                <div className="O2Content_row">
                                    <div className="contentRow_Label">User Role</div>
                                    <div className="contentRow_Value">
                                        {/* <Select name="urUserRole" placeholder="Choose User Role"/>  */}
                                        <Select options={this.state.urUserRoleList} name="urUserRole" value={this.state.urUserRoleList.filter(({ value }) => value === this.state.urUserRole)} placeholder="Choose User Role" onChange={this.handleURRoleChange}/> 
                                    </div>
                                </div>
                                <div className="O2Content_row">
                                    <div className="contentRow_Label">Menu Type</div>
                                    <div className="contentRow_Value">
                                        <Select options={this.state.urMenuTypeList} name="urMenuType" value={this.state.urMenuTypeList.filter(({ value }) => value === this.state.urMenuType)} placeholder="Choose Menu Type" onChange={this.handleURMenuTypeChange}/> 
                                    </div>
                                </div>                                 
                                {/* <div className="O2Content_row">
                                    <div className="contentRow_Label"></div>
                                    <div className="contentRow_Value">
                                                                        
                                    </div>
                                </div>  */}
                                <div class="O2Table_Go"><button onClick={this.getUserRoleList}>Go</button></div>
                            </div>
                            : null
                        }                        
                        <div className="O2Content_Container">
                            <div className="RoleAccess_Container">
                                <div className="RAContent_wrapper">
                                <div className="Table_wrapper">
                                    <table className="customTable" >
                                    <thead>
                                        <tr> 
                                            <th className="">
                                                Screen  Name
                                                <div className="searchWrapper">
                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                        <input type="text" className="tableMultiFilterSearch_input maingrid" name="displayName" onChange={this.filterList} />
                                    </div>
                                            </th>
                                            <th className="RAcheckBox_Container">                                                
                                                {/* <input type="checkbox" name="checkAll" checked={this.state.urAllChecked} onChange={this.handleURAllChange}/> */}
                                                Enabled
                                            </th>
                                            <th className="">
                                                Status
                                                <div className="searchWrapper">
                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                        <input type="text" className="tableMultiFilterSearch_input maingrid" name="displayStatus" onChange={this.filterList} />
                                    </div>
                                            </th>
                                            <th className="o2Del_container">
                                                {/* <button onClick={this.getUserRoleList}>Go</button> */}
                                            </th>
                                            <th className="scroller"></th> 
                                        </tr>
                                    </thead>
                                    <tbody>                                                
                                        <tr>
                                            {/* <td className="">
                                                <input type="text"/>
                                            </td>
                                            <td className="RAcheckBox_Container">
                                                <input type="checkbox"/>   
                                            </td>
                                            <td className="">
                                                <select></select>
                                            </td>
                                            <td className="o2Del_container">
                                                <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}}/>
                                                <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}}/>
                                            </td> */}
                                            {
                                                !this.state.urMenuList || this.state.urMenuList === null || this.state.urMenuList.length === 0 ?
                                                    <tr align="center">
                                                        <td colSpan="5">No Screen Available</td>
                                                    </tr> :
                                                    this.state.urMenuList.map((menu,i) => (
                                                        <tr key={i}>
                                                            <td className="" data-tip={menu.displayName}>
                                                                <input type="text" id={i} name ="displayName" value={menu.displayName} disabled={menu.readOnly}/>
                                                            </td>
                                                            <td className="RAcheckBox_Container">                                                
                                                            <input type="checkbox" name ="rowCheckbox"  checked={menu.isChecked} disabled={menu.readOnly} onChange={ (e)=>this.handleURRowClickChange(e,i)}/>
                                                            </td>
                                                            <td className="" data-tip={menu.displayStatus}>
                                                            <select id={i} name ="status" value={menu.status} disabled={menu.readOnly} onChange={ (e)=>this.handleURRowClickChange(e,i)}>
                                                                    <option value="Y" >Active</option>
                                                                    <option value="N" >In Active</option>
                                                            </select>
                                                        </td>
                                                        <td className="o2Del_container">
                                                            <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}} onClick={() => this.editUR_openHandler(i)}/>                                                
                                                        </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tr>                                           
                                    </tbody>
                                </table>
                            </div>
                            </div>
                            </div>
                            <div className="UserRole_Container">
                                <div className="RAContent_wrapper">
                                <div className="Table_wrapper">
                                <table className="customTable" >
                                <thead>
                                    <tr>
                                        <th className=""> 
                                            Role Name
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="roleName" onChange={this.urFilterList} />
                                            </div>
                                        </th>
                                        <th className=""> 
                                            User Role Code
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="prefixCode" onChange={this.urFilterList} />
                                            </div>
                                        </th>
                                        <th className=""> 
                                            Create Salesman
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="displayCreateSalesman" onChange={this.urFilterList} />
                                            </div>
                                        </th>
                                        <th className=""> 
                                            Status
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="displayStatus" onChange={this.urFilterList} />
                                            </div>
                                        </th>
                                        <th className="o2Del_container"> 
                                            <button>
                                                <FontAwesomeIcon icon={faPlus} style={{padding:'0px 4px'}} onClick={() => this.urs_newRowHandler()}/>
                                            </button>
                                        </th>
                                        <th className="scroller"></th> 
                                    </tr>
                                </thead>
                                <tbody>
                                 {/* <tr>
                                    <td className="">
                                        <input type="text"/>
                                    </td>
                                    <td className="">
                                        <select></select>
                                    </td>
                                    <td className="">
                                        <select></select>
                                    </td>
                                    <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}}/>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}}/>
                                    </td>
                                </tr>  */}
                                <tr>
                                    {
                                        !this.state.urUserRoleDetailList || this.state.urUserRoleDetailList === null || this.state.urUserRoleDetailList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No User Role Available</td>
                                        </tr> :
                                        this.state.urUserRoleDetailList.map((user,i) => (
                                            <tr key={i}>
                                               <td className="" data-tip={user.roleName}>
                                                    <input type="text" id={i} name ="roleName" value={user.roleName} disabled={user.readOnly} onChange={ (e)=>this.handleURSRowClickChange(e,i)}/>
                                                </td>
                                                <td className="" data-tip={user.prefixCode}>
                                                    <input type="text" id={i} name ="prefixCode" value={user.prefixCode} disabled={user.readOnly} onChange={ (e)=>this.handleURSRowClickChange(e,i)}/>
                                                </td>
                                                <td className="" data-tip={user.displayCreateSalesman}>
                                                    <select id={i} name ="createSalesman" value={user.createSalesman} disabled={user.readOnly} onChange={ (e)=>this.handleURSRowClickChange(e,i)}>
                                                        <option value="Y" >Active</option>
                                                        <option value="N" >In Active</option>
                                                    </select>
                                                </td>
                                                <td className="" data-tip={user.displayStatus}>
                                                    <select id={i} name ="status" value={user.status} disabled={user.readOnly} onChange={ (e)=>this.handleURSRowClickChange(e,i)}>
                                                        <option value="Y" >Active</option>
                                                        <option value="N" >In Active</option>
                                                    </select>
                                                </td>
                                                <td className="o2Del_container">
                                                    <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}} onClick={() => this.editURS_openHandler(i)}/>
                                                    {
                                                        user.changeFlag === "A" ? 
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}} onClick={ ()=> this.deleteURS_openHandler(i)}/>
                                                        : null
                                                    }                                                    
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>    
                    </div>       
                </div>
                    <div className="O2Table_Footer">
                        <button onClick={() =>this.urSavefunction()}>Save</button>                       
                        <button onClick={() =>this.urCancelfunction()}>Cancel</button>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL        
    }
}
export default connect(mapStateToProps)(UserRoleModule)
//export default UserRoleModule

