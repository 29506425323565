import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from 'react-select';
import './ProductReportsModule.css';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
class ProductReportsModule extends Component {
    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            orderList: [],
            salesmanList: [],
            selectedsalesmanList: [],
            reportList: [],
            orgreportList: [],
            reSalesmanCode: '',
            orderObj: '',
            startDate: todaydate,
            endDate: todaydate,
            smanCode: '',
            todaydate: todaydate,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            userType: this.props.userObj.userType,
            loginSalesmanCode: this.props.userObj.salesmanCode,
            rmOrderTypeFilter: '',
            rmOrderTypeList: [],
            orFState: '',
            orFStateList: [],
            rmProductTypeFilter: '',
            orFProduct: '',
            orFProductList: [],
        }
    }

    componentDidMount() {
        this.getAllSalesmans();
    }

    loadReport() {
        let scode = this.state.salesmanCode;
        if (this.state.loginuserObj.userType === "salesman") {
            scode = scode;
        } else {
            scode = 'All';
        }
        this.setState({
            reSalesmanCode: scode,
            smanCode: scode
        }, () => {
            this.getAllSalesmans();
        });

    }

    getAllSalesmans() {

        let scode = this.state.salesmanCode;
        if (this.state.loginuserObj.userType === "salesman") {
            scode = scode;
        } else {
            // scode = 'All';
            scode = scode;
        }

        const params = {
            salesmanCode: scode,
            companyCode: this.state.companyCode,
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/report/getSalesmanList", { params })
            .then(response => response.data)
            .then((data) => {
                // console.log(data)
                this.setState({
                    loading: false
                })
                this.setState({ salesmanList: data.salesmansList }, () => {

                    if (data.salesmansList.length === 1) {
                        var temparry = []
                        temparry.push({
                            label: data.salesmansList[0].label,
                            value: data.salesmansList[0].value
                        });

                        this.setState({
                            selectedsalesmanList: temparry
                        })
                    }

                })
                this.setState({
                    orFStateList: data.stateList,
                    orFProductList: data.productTypeList,
                })


            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                })
                alert(err);
            })
    }

    loadSalesmanList() {
        var dList = this.state.salesmanList;
        var sman = this.state.reSalesmanCode;
        dList.map(function (sales, i) {

            if (sales.salesmanCode === sman) {
                //this.setState({ salesmanList: dList[i] })
            } else {
                dList.splice(i, 1)
            }

        });
        this.setState({ salesmanList: dList })
    }

    reportGo = () => {
        let stateVal = '';
        let salesmanCode = '';
        let productType = '';
        var dFlag = true;
        if (this.state.startDate !== '' && this.state.endDate !== '') {
            if (this.state.startDate > this.state.endDate) {
                alert("From Date Cannot Be Greater Than End Date");
                dFlag = false;
            }
        } else {
            alert("Please select From and To Date...");
            dFlag = false;
        }
        if (this.state.orFState === '' || this.state.orFState === undefined) {
            stateVal = "All";
        } else {
            stateVal = this.state.orFState;
        }

        if (this.state.reSalesmanCode === '' || this.state.reSalesmanCode === undefined) {
            salesmanCode = "All";
        } else {
            salesmanCode = this.state.reSalesmanCode;
        }

        if (this.state.rmProductTypeFilter === '' || this.state.rmProductTypeFilter === undefined) {
            productType = "All";
        } else {
            productType = this.state.rmProductTypeFilter;
        }

        if (dFlag) {
            const params = {
                // salesmanCode: salesmnallist,
                salesmanCode: salesmanCode,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                loginSalesmanCode: this.state.loginSalesmanCode,
                companyCode: this.state.companyCode,
                orderType: this.state.rmOrderTypeFilter,
                productType: productType,
                state: stateVal,
            }


            this.setState({
                loading: true
            })
            axios.get(this.state.httpURL + "/report/getProductWiseReport", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.setState({
                            loading: false
                        })
                        this.setState({
                            reportList: data.reportList,
                            orgreportList: data.reportList,
                        })
                        ReactTooltip.rebuild();
                    } else {
                        alert(data.responseMsg);
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    })
                    alert(err);
                })


        }
    }

    reportChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSalesmanChange = (event) => {
        // this.setState({
        //     salesmanCode: event.value
        // });
        this.setState({
            selectedsalesmanList: event,
            reSalesmanCode: event.value
        });
    }

    handleRMOrderTypeFilter = (event) => {
        this.setState({
            rmOrderTypeFilter: event.label
        }, () => {

        });
    }

    handleRMProductTypeFilter = (event) => {
        this.setState({
            rmProductTypeFilter: event.value
        }, () => {

        });
    }

    filterList = (event) => {
        let filtered = this.state.orgreportList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            reportList: filtered
        })
    }


    dsrExcel = () => {
        var dFlag = true;
        if (this.state.reportList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.reportList;
            var fileName = "Salesman Wise Report_" + this.state.todaydate;
            var wscols = [
                { wch: 30 },
                { wch: 40 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 15 },
                { wch: 15 },
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Product Code": dList[i].productCode,
                    "Product Name": dList[i].productName,
                    "Quantity": parseInt(dList[i].qty),
                    "Price": parseInt(dList[i].totPrice.toFixed(2)),
                    ///"Toatal Amount": dList[i].collectionAmt.toFixed(2),
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'DSR': ws }, SheetNames: ['DSR'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    handleORFStateChange = (event) => {
        this.setState({
            orFState: event.value,
        }, () => {

        });
    }
    render() {
        const salesmanSearch = [];

        for (const [index, value] of this.state.salesmanList.entries()) {
            salesmanSearch.push({
                label: value.salesmanName,
                value: value.salesmanCode
            });
        }

        return (

            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>PRODUCT WISE REPORT</span></div>
                    <div className="O2Table_Body">
                        <div className="O2Table_SubContainer Report_subContainer">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Sales Person</div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.salesmanList} name="reSalesmanCode" placeholder="Choose Sales Person" value={this.state.selectedsalesmanList} onChange={this.handleSalesmanChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">State</div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.orFStateList} name="orFState" placeholder="Choose State" value={this.state.orFStateList.filter(({ value }) => value === this.state.orFState)} onChange={this.handleORFStateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Product Type</div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.orFProductList} placeholder="Choose Product Type" value={this.state.orFProductList.filter(({ value }) => value === this.state.rmProductTypeFilter)} onChange={this.handleRMProductTypeFilter}></Select>
                                </div>
                            </div>
                        </div>
                        <div className="O2Table_SubContainer Report_subContainer">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date From </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="startDate" value={this.state.startDate} onChange={this.reportChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date To </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="endDate" value={this.state.endDate} onChange={this.reportChange} />
                                </div>
                            </div>
                            <div className="O2Table_Go" onClick={this.reportGo}><button>Go</button></div>
                        </div>
                        <div className="Table_wrapper">
                            <table className="customTable" id="salesmanWiseReport_Table">
                                <thead>
                                    <tr>
                                        <th className="Salemanno_width">Product Code
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="productCode" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Descript_width">Product Name
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="productName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        {/* <th className="Descript_width">Distributor Name
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="distributorName" onChange={this.filterList} />
                                            </div>
                                        </th> */}
                                        <th className="status_Width">Quantity
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="qty" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Value_width">Price
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="totPrice" onChange={this.filterList} />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.reportList || this.state.reportList === null || this.state.reportList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="4">No Records</td>
                                            </tr> :
                                            this.state.reportList.map((report) => (
                                                <tr>
                                                    <td className="Salemanno_width" data-tip={report.productCode}>{report.productCode}</td>
                                                    <td className="Descript_width align_salesPerson" data-tip={report.productName}>{report.productName}</td>
                                                    {/* <td className="Descript_width align_salesPerson" data-tip={report.distributorName}>{report.distributorName}</td> */}
                                                    <td className="status_Width align_noOfRetailers" data-tip={report.qty}>{report.qty.toFixed(2)}</td>
                                                    <td className="Value_width" data-tip={report.totPrice.toFixed(2)}>{report.totPrice.toFixed(2)}</td>
                                                </tr>
                                            ))
                                    }

                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.reportList ? this.state.reportList.length : 0}</span>entries</span></div>
                            {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                            <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Export</div> */}
                            {/* <div className="O2Table_ExpCollExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="salesmanDSRReport_Table" filename={"SalesmanDSRReport_" + this.state.todaydate}
                                sheet="tablexls" buttonText="Excel Export" /></div> */}
                            <div className="O2Table_ExpExcel" onClick={() => this.dsrExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}

export default connect(mapStateToProps)(ProductReportsModule)

//export default ProductReportsModule
