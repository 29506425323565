import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faPlus, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
import { useState } from 'react';
import * as XLSX from 'xlsx';
import fileSaver from 'file-saver';
import './UploadSalesData.css';
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class UploadSalesData extends Component {

    constructor(props) {

        var curr = new Date();
        curr.setDate(curr.getDate());
        var currentdate = curr.toISOString().substr(0, 10);

        super(props)
        this.state = {
            loading: false,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            toolNameList: [],
            tmName: 1,
            tmSelectedFile: '',
            todayDate: currentdate,
            errorList: [],
            orgErrorList: [],
            salesDataList:[],
            orgsalesDataList:[]
        }
    }

    componentDidMount() {
        this.getToolNameList();
    }

    getToolNameList() {
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/tool/getToolNameList")
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({
                        toolNameList: data.toolNameList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    handleImportFile = (e) => {

        if (this.state.tmName === undefined || this.state.tmName === "" || this.state.tmName === null) {
            alert("Please Select A Name");
        } else if (this.state.tmSelectedFile === undefined || this.state.tmSelectedFile === "" || this.state.tmSelectedFile === null) {
            alert("Please Choose File");
        } else {

            const formData = new FormData();

            formData.append('files', this.state.tmSelectedFile);

            const params = {
                lastUpdatedBy: this.state.loginuserObj.userName,
                nameValue: this.state.tmName
            }
            this.setState({
                loading: true
            });
            axios.post(this.state.httpURL + "/tool/uploadSalesData", formData, { params })
                .then(response => {
                    this.setState({
                        loading: false
                    });
                    if (response.data.valid) {
                        alert(response.data.responseMsg);
                        if(this.state.tmName==1){
                            this.setState({salesDataList: response.data.baSalesList,orgsalesDataList:response.data.baSalesList});
                        }else{
                            this.setState({salesDataList: response.data.outletSalesList,orgsalesDataList:response.data.outletSalesList});
                        }
                        ReactTooltip.rebuild();
                    } else {
                        alert(response.data.responseMsg);
                    }
                    //this.tool_clearfunction();
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                    alert(error);
                    this.tool_clearfunction();
                })
        }
    }

    handleTMNameChange = (event) => {
       // console.log("change vaue->"+event.target.value)
       
        this.setState({
            tmName: event.target.value,
            salesDataList:[],orgsalesDataList:[],
            tmSelectedFile: '',
        }, () => {

        });
    }

    generateReport = () => {
        
        this.setState({
            loading: true
        })
        const params = {
            salesType: this.state.tmName
        }
        axios.get(this.state.httpURL + "/tool/generateSalesReport", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                   

                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    laodData = () => {
        
        this.setState({
            loading: true
        })
        const params = {
            salesType: this.state.tmName
        }
        axios.get(this.state.httpURL + "/tool/loadSalesData", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    if(this.state.tmName==1){
                        this.setState({salesDataList: data.baSalesList});
                    }else{
                        this.setState({salesDataList: data.outletSalesList});
                    }
                    
                    
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    notmappedData  = () => {
        
        this.setState({
            loading: true
        })
        const params = {
            salesType: this.state.tmName
        }
        axios.get(this.state.httpURL + "/tool/notmappedSalesData", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    if(this.state.tmName==1){
                        this.setState({salesDataList: data.baSalesList});
                    }else{
                        this.setState({salesDataList: data.outletSalesList});
                    }
                    
                    
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }
    handleTMSelectFile = (event) => {
        this.setState({
            tmSelectedFile: event.target.files[0]
        }, () => {
            //console.log("<-123->"+this.state.tmSelectedFile);                                   
        });
    }

    tool_cancelfunction() {
        // if(this.state.urUserRole !== "" && this.state.urUserRole !== null && this.state.urUserRole !== undefined){
        //     this.getBaRetailerList();
        // }
        this.setState({
            tmName: '',
            tmSelectedFile: '',
            errorList: []
        }, () => {

        });
    }

    tool_clearfunction() {
        this.setState({
            tmName: '',
            tmSelectedFile: '',
        }, () => {

        });
    }

    filterList = (event) => {
        let filtered = this.state.orgsalesDataList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            salesDataList: filtered
        })
    }

    userExcel = () => {
        var dFlag = true;
        if (this.state.orgsalesDataList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.orgsalesDataList;
            var fileName = "SalesData_" + this.state.todayDate;
            var wscols = [
                { wch: 8 },
                { wch: 55 },
                { wch: 15 },
                { wch: 10 },
                { wch: 22 },
                { wch: 10 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                
                arr = {
                    "Line No": i++,
                    "State": dList[i].stateName,
                    "empId": dList[i].empId,
                    "empName": dList[i].empName,
                    "Outlet Name": dList[i].outletName,
                    "Outlet No": dList[i].outletNo,
                    "Outlet Code": dList[i].outletCode,
                    "Outlet Type": dList[i].outletType,
                    "1":dList[i].a1,
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'Sales Data': ws }, SheetNames: ['Error List'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>Import</span></div>
                    <div className="O2Table_SubContainer Report_subContainer">
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Name</div>
                            <div className="contentRow_Value">
                                {/* <Select name="urUserRole" placeholder="Choose User Role"/>  */}
                                {/* <Select options={this.state.toolNameList} name="tmName" value={this.state.toolNameList.filter(({ value }) => value === this.state.tmName)} placeholder="Choose Name" onChange={this.handleTMNameChange} /> */}
                                <select onChange={this.handleTMNameChange} value={this.state.tmName}>
                                    <option value='1' selected>Import BA wise</option>
                                    <option value='2'>Import Outlet wise</option>
                                </select>
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label">File Name</div>
                            <div className="contentRow_Value">
                                {/* <input type="text" type="file" name="tmSelectedFile" ref={(ref) => this.myInput = ref}  /> */}
                                <input type="file" name="tmSelectedFile" onClick={e => (e.target.value = null)} onChange={this.handleTMSelectFile} />
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label"></div>
                            <div className="contentRow_Value">

                            </div>
                        </div>
                        {/* <div className="O2Table_Go" onClick={(e) => this.myInput.click()}><button>Upload a file</button></div> */}
                        <div className="O2Table_Go" onClick={this.handleImportFile}><button>Import</button></div>
                        {/* <div className="O2Table_Go" onClick={this.generateReport}><button>generate Report</button></div>
                        <div className="O2Table_Go" onClick={this.laodData}><button>Load Data</button></div>
                        <div className="O2Table_Go" onClick={this.notmappedData}><button>Not Mapped List</button></div> */}
                        {/* <div className="O2Table_Go" onClick={this.notmappedData}><button>Not Mapped List</button></div>  */}
                    </div>
                    <div className="Table_wrapper ">

                    <div class="inner">
                        <table className="customTable" id="metaData_Table">
                            <thead>
                                <tr>
                                    <th className="width15">State
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="stateName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    {
                                        this.state.tmName==2 ?  <>
                                                    <th className="width15">Outlet Name
                                                    <div className="searchWrapper">
                                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                        <input type="text" className="tableMultiFilterSearch_input maingrid" name="outletName" onChange={this.filterList} />
                                                    </div>
                                                </th>
                                                <th className="width15">Outlet Code
                                                    <div className="searchWrapper">
                                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                        <input type="text" className="tableMultiFilterSearch_input maingrid" name="outletCode" onChange={this.filterList} />
                                                    </div>
                                                </th>
                                                <th className="width15">Outlet Type
                                                    <div className="searchWrapper">
                                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                        <input type="text" className="tableMultiFilterSearch_input maingrid" name="outletType" onChange={this.filterList} />
                                                    </div>
                                                </th></> : ''

                                    }
                                    

                                    
                                    <th className="width15">Emp ID
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="empId" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="width15">Emp Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="empName" onChange={this.filterList} />
                                        </div>
                                    </th>   
                                    <th className="width15">Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="status" onChange={this.filterList} />
                                        </div> 
                                    </th>
                                    <th className="width15">Action</th> 
                                    <th className="tableCell">1</th>
                                    <th className="tableCell">2</th>
                                    <th className="tableCell">3</th>
                                    <th className="tableCell">4</th>
                                    <th className="tableCell">5</th>
                                    <th className="tableCell">6</th>
                                    <th className="tableCell">7</th>
                                    <th className="tableCell">8</th>
                                    <th className="tableCell">9</th>
                                    <th className="tableCell">10</th>
                                    <th className="tableCell">11</th>
                                    <th className="tableCell">12</th>
                                    <th className="tableCell">13</th>
                                    <th className="tableCell">14</th>
                                    <th className="tableCell">15</th>
                                    <th className="tableCell">16</th>
                                    <th className="tableCell">17</th>
                                    <th className="tableCell">18</th>
                                    <th className="tableCell">19</th>
                                    <th className="tableCell">20</th>
                                    <th className="tableCell">21</th>
                                    <th className="tableCell">22</th>
                                    <th className="tableCell">23</th>
                                    <th className="tableCell">24</th>
                                    <th className="tableCell">25</th>
                                    <th className="tableCell">26</th>
                                    <th className="tableCell">27</th>
                                    <th className="tableCell">28</th>
                                    <th className="tableCell">29</th>
                                    <th className="tableCell">30</th>
                                    <th className="tableCell">31</th>
                                    
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                 {
                                    !this.state.salesDataList || this.state.salesDataList === null || this.state.salesDataList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No Records</td>
                                        </tr> :
                                        this.state.salesDataList.map((obj, i) => (

                                            <tr key={i} className={obj.sameDataExistYN == "Y" ? "" : "notmatchedrow"}>
                                                <td className="width15" data-tip={obj.stateName}>{obj.stateName}</td>

                                                {
                                                    this.state.tmName==2 ? <>
                                                        <td className="width15" data-tip={obj.outletName}>{obj.outletName}</td>
                                                        <td className="width15" data-tip={obj.outletNo}>{obj.outletNo}</td>
                                                        <td className="width15" data-tip={obj.outletType}>{obj.outletType}</td>
                                                    </>:''
                                                }
                                                <td className="width15" data-tip={obj.empId}>{obj.empId}</td>
                                                <td className="width15" data-tip={obj.empName}>{obj.empName}</td>
                                                {
                                                    this.state.tmName==2 ? <><td className="width15" data-tip={obj.status}>{obj.status == "Mapped" ? "Mapped" : "Retailer Not Created"}</td></>:
                                                    <td className="width15" data-tip={obj.status}>{obj.status == "Mapped" ? "Mapped" : "BA Not Mapped"}</td>
                                                }
                                                
                                                <td className="tableCell">{obj.sameDataExistYN == "Y" ? "Match" : "Not Matched"}</td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a1}</span> <br></br><span className='cellBottom'>{obj.o1}</span> </td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a2}</span> <br></br><span className='cellBottom'>{obj.o2}</span> </td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a3}</span> <br></br><span className='cellBottom'>{obj.o3}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a4}</span> <br></br><span className='cellBottom'>{obj.o4}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a5}</span> <br></br><span className='cellBottom'>{obj.o5}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a6}</span> <br></br><span className='cellBottom'>{obj.o6}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a7}</span> <br></br><span className='cellBottom'>{obj.o7}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a8}</span> <br></br><span className='cellBottom'>{obj.o8}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a9}</span> <br></br><span className='cellBottom'>{obj.o9}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a10}</span> <br></br><span className='cellBottom'>{obj.o10}</span> </td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a11}</span> <br></br><span className='cellBottom'>{obj.o11}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a12}</span> <br></br><span className='cellBottom'>{obj.o12}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a13}</span> <br></br><span className='cellBottom'>{obj.o13}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a14}</span> <br></br><span className='cellBottom'>{obj.o14}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a15}</span> <br></br><span className='cellBottom'>{obj.o15}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a16}</span> <br></br><span className='cellBottom'>{obj.o16}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a17}</span> <br></br><span className='cellBottom'>{obj.o17}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a18}</span> <br></br><span className='cellBottom'>{obj.o18}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a19}</span> <br></br><span className='cellBottom'>{obj.o19}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a20}</span> <br></br><span className='cellBottom'>{obj.o20}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a21}</span> <br></br><span className='cellBottom'>{obj.o21}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a22}</span> <br></br><span className='cellBottom'>{obj.o22}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a23}</span> <br></br><span className='cellBottom'>{obj.o23}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a24}</span> <br></br><span className='cellBottom'>{obj.o24}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a25}</span> <br></br><span className='cellBottom'>{obj.o25}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a26}</span> <br></br><span className='cellBottom'>{obj.o26}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a27}</span> <br></br><span className='cellBottom'>{obj.o27}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a28}</span> <br></br><span className='cellBottom'>{obj.o28}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a29}</span> <br></br><span className='cellBottom'>{obj.o29}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a30}</span> <br></br><span className='cellBottom'>{obj.o30}</span></td>
                                                <td className="tableCell"> <span className='cellTop'>{obj.a31}</span> <br></br><span className='cellBottom'>{obj.o31}</span></td>
                                               
                                                
                                            </tr>
                                        ))
                                } 
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.salesDataList ? this.state.salesDataList.length : 0}</span>entries</span></div>
                    </div>
                    <div className="O2Table_Footer">
                        {/* <button onClick={this.ba_savefunction}>Save</button>                        */}
                        {/* <button onClick={this.tool_cancelfunction}>Cancel</button> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_ExpExcel" onClick={() => this.userExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(UploadSalesData)
//export default BARetailerModule
