import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faTrashAlt, faEdit, faCloudUploadAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import './OrderModule.css';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

const override1 = css`
  display: block;
  top: -235px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;

class OrderModule extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            orderList: [],
            orgorderList: [],
            prevstateorderList: [],
            salesmanList: [],
            distributorList: [],
            orderObj: '',
            orderDetailsList: [],
            orgorderDetailsList: [],
            orderBADetailsList: [],
            orderDetailsNewList: [],
            refNum: "",
            ordSalesmanCode: "",
            salesmanName: "",
            retailerCode: "",
            retailerName: "",
            distributorName: '',
            orderDTM: "",
            currentDate: todaydate,
            productsList: [],
            productCode: "",
            totAmount: "0",
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            startDate: todaydate,
            endDate: todaydate,
            allChecked: false,
            checkExported: false,
            exportedOrder: false,
            erpOrderNo: '',
            writeAccess: this.props.userObj.orderWriteAccess,
            orderStatus: 'All',
            erpExported: 'Y',
            orderIdForPrint: '',
            loginSalesmanCode: this.props.userObj.salesmanCode,
            orderType: "",
            orderTypeFilter: '',
            orderTypeList: [],
            orFState: '',
            orFStateList: [],
            salesType: 'regular'
        }
    }
    Ordernew_popCloseHandler = () => {
        document.querySelector('.newOrder_popup').style.display = 'none';
    }

    Ordernew_popOpenHandler = () => {
        document.querySelector('.newOrder_popup').style.display = 'flex';
    }

    Order_popCloseHandler = () => {
        document.querySelector('.OrderTableEdit_pop').style.display = 'none';
    }

    Order_popPDFHandler = () => {
        // console.log("<-1->"+this.state.httpURL);
        // window.open("http:///orderReports/"+"ACHIE_9112020_1604912582403.pdf");       

        this.setState({
            loading: true
        });

        axios.post(this.state.httpURL + "/pdfreport/orderPdf", { "orderId": this.state.orderIdForPrint, "companyCode": this.props.userObj.companyCode, "reportType": "pdf" })
            .then(res => {
                if (res.data.valid) {
                    window.open("http://139.59.8.226/orderReports/" + res.data.fileName);
                    this.setState({
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    alert(res.data.responseMsg);
                }

            })
    }

    Order_popMailHandler = () => {

        this.setState({
            loading: true
        });

        axios.post(this.state.httpURL + "/pdfreport/orderPdf", { "orderId": this.state.orderIdForPrint, "companyCode": this.props.userObj.companyCode, "reportType": "email" })
            .then(res => {
                if (res.data.valid) {
                    alert("Mail Sent Successfully.");
                    this.setState({
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    alert("Mail Send Failed.");
                }

            })
    }

    handleAllChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { orderList, allChecked, orgorderList } = prevState;
            if (itemName === "checkAll") {
                //console.log(checked)
                if (checked) {
                    allChecked = checked;
                    orderList = orderList.map(item => item.isExported === "N" ? { ...item, isChecked: checked } : item);
                    orgorderList = orgorderList.map(item => item.isExported === "N" ? { ...item, isChecked: checked } : item);
                } else {
                    allChecked = false;
                    orderList = orderList.map(item => ({ ...item, isChecked: false }));
                    orgorderList = orgorderList.map(item => ({ ...item, isChecked: false }));
                }

            }
            return { orderList, allChecked, orgorderList };
        });
    };

    handleChangeItem = (e, ind) => {
        let checked = e.target.checked;
        const { orderDetailsList, allChecked, orgorderDetailsList } = this.state;
        orderDetailsList[ind].isChecked = checked
        orgorderDetailsList[ind].isChecked = checked
        this.setState({ orderDetailsList: orderDetailsList, orgorderDetailsList: orgorderDetailsList, allChecked: false }, () => {
            ReactTooltip.rebuild();
        });
    };

    handleAllChangeItem = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { orderDetailsList, allChecked, orgorderDetailsList } = prevState;
            if (itemName === "checkAllItem") {
                //console.log(checked)
                if (checked) {
                    allChecked = checked;
                    orderDetailsList = orderDetailsList.map(item => item.isChecked === false ? { ...item, isChecked: checked } : item);
                    orgorderDetailsList = orgorderDetailsList.map(item => item.isChecked === false ? { ...item, isChecked: checked } : item);
                } else {
                    allChecked = false;
                    orderDetailsList = orderDetailsList.map(item => ({ ...item, isChecked: false }));
                    orgorderDetailsList = orgorderDetailsList.map(item => ({ ...item, isChecked: false }));
                }

            }
            return { orderDetailsList, allChecked, orgorderDetailsList };
        });
    };

    handleExportedChange = e => {
        if (e.target.checked) {
            this.setState({
                [e.target.name]: true
            });
        } else {
            this.setState({
                [e.target.name]: false
            });
        }
    }

    handleChange = (e, ind) => {
        let checked = e.target.checked;
        const { orderList, allChecked, orgorderList } = this.state;
        if (orderList[ind].isExported === "N") {
            orderList[ind].isChecked = checked
            orgorderList[ind].isChecked = checked
            this.setState({ orderList: orderList, orgorderList: orgorderList, allChecked: false }, () => {
                ReactTooltip.rebuild();
            });
        }
    };
    Order_newRowHandler = () => {
        if (this.state.exportedOrder) {
            alert("This order already uploaded to erp, cannot modify.");
        } else {
            var newind = this.state.orderDetailsList.length + 1;
            var emptyobj = {
                ordind: newind,
                id: "9999" + newind,
                productCode: "",
                distributorCode: '',
                productDistributorList: [],
                quantity: "0",
                quantityStr: "0",
                numberOfCases: "00.00",
                numberOfBoxes: "00.00",
                numberOfPacks: "00.00",
                numberOfPieces: "00.00",
                rate: "00.00",
                totRate: "00.00",
                readOnly: false,
                isSelectDisabled: false,
                taxPercentage: "0",
                status: "Y",
                stock: "0",
                lastUpdatedBy: this.state.loginuserObj.userName,
                ordersId: this.state.orderObj.id,
                savedProduct: false
            }

            let orderDetailsListtemp = this.state.orderDetailsList
            orderDetailsListtemp.push(emptyobj)

            this.setState({ orderDetailsList: orderDetailsListtemp }, () => {
                ReactTooltip.rebuild();
            })

        }
    }
    Order_popup_openHandler = (order) => {
        this.setState({
            orderIdForPrint: order.id
        });
        const params = {
            orderId: order.id,
            salesmanCode: order.salesmanCode,
            salesType: this.state.salesType,
            loginSalesmanCode: this.state.loginSalesmanCode,
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/order/getOrderDetailList", { params })
            .then(response => response.data)
            .then((data) => {
                //    console.log("data-->"+data.forOrderDetailsList)
                this.setState({
                    loading: false
                })
                if (order.isExported === "Y") {
                    this.setState({
                        exportedOrder: true
                    });
                } else {
                    this.setState({
                        exportedOrder: false
                    });
                }
                var erpOrderNo = '';
                if (data.forOrderDetailsList.length > 0) {
                    erpOrderNo = data.forOrderDetailsList[0].erpOrderNo
                }

                this.setState({
                    orderDetailsList: data.forOrderDetailsList,
                    orgorderDetailsList: data.forOrderDetailsList,
                    orderObj: order,
                    refNum: order.refNum,
                    ordSalesmanCode: order.salesmanCode,
                    salesmanName: order.salesmanName,
                    retailerCode: order.retailerCode,
                    retailerName: order.retailerName,
                    orderDTM: order.orderDTMStr,
                    //distributorName:order.distributorName,
                    orderDetailsNewList: [],
                    totAmount: order.orderValue,
                    productsList: data.productList,
                    productDistributorList: data.productDistributorList,
                    erpOrderNo: erpOrderNo,
                    erpExported: order.isExported,
                    orderType: order.orderType,
                    writeAccess: data.writeAccess,
                }, () => {
                    ReactTooltip.rebuild();
                    document.querySelector('.OrderTableEdit_pop').style.display = 'flex';
                })
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                })
                alert(err);
            })
    }

    componentDidMount() {
        //    this.getAllOrders();
        this.getOrderType();

        var d = new Date();
        d.setMonth(d.getMonth() - 2);
        console.log("previous month ->" + d);
        console.log("previous month ->" + JSON.stringify());
        if (this.state.loginuserObj.multiUserRole == "Y") {

        }
    }

    getOrderType = () => {
        const params = {
            companyCode: this.props.userObj.companyCode,
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/order/getOrderType", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    orderTypeList: data.orderTypeList,
                    orderTypeFilter: data.orderTypeList[0].label,
                    orFStateList: data.stateList,
                }, () => {
                    this.setState({
                        loading: false
                    })
                    this.getAllOrders();
                })
            }).catch(err => {
                this.setState({
                    loading: false
                })
            })
    }

    getAllOrders = () => {
        let stateVal = '';
        var dFlag = true;
        if (this.state.startDate !== '' && this.state.endDate !== '') {
            if (this.state.startDate > this.state.endDate) {
                alert("From Date Cannot Be Greater Than End Date");
                dFlag = false;
            }
        } else {
            alert("Please select Start and End Date...");
            dFlag = false;
        }
        if (this.state.orFState === '' || this.state.orFState === undefined) {
            stateVal = "All";
        } else {
            stateVal = this.state.orFState;
        }

        if (dFlag) {
            const params = {
                companyCode: this.state.loginuserObj.companyCode,
                distributorCode: "All",
                salesmanCode: this.state.salesmanCode,
                retailerCode: "All",
                // startDate: '',
                // endDate: '',
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                orderStatus: this.state.orderStatus,
                loginSalesmanCode: this.state.loginSalesmanCode,
                orderType: this.state.orderTypeFilter,
                state: stateVal,
                salesType: this.state.salesType,
            }

            this.setState({
                loading: true
            })

            axios.get(this.state.httpURL + "/order", { params })
                .then(response => response.data)
                .then((data) => {
                    this.setState({
                        orderList: data.forOrderDataList,
                        orgorderList: data.forOrderDataList,
                        // salesmanList:data.salesmanList,
                        distributorList: data.distributorList,
                        allChecked: false,
                        salesmanList: data.salesmanList1
                        // productsList:data.productList
                    }, () => {
                        this.setState({
                            loading: false
                        })
                        ReactTooltip.rebuild();
                    })
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    })
                })
        }
    }

    // getAllOrders = () => {        
    //     var dFlag = true;
    //     if (this.state.startDate !== '' && this.state.endDate !== '') {
    //         if (this.state.startDate > this.state.endDate) {
    //             alert("From Date Cannot Be Greater Than End Date");
    //             dFlag = false;
    //         }
    //     } else {
    //         alert("Please select Start and End Date...");
    //         dFlag = false;
    //     }
    //     if (dFlag) {
    //         const params={
    //             companyCode:this.state.loginuserObj.companyCode,
    //             distributorCode:"All",
    //             salesmanCode:this.state.salesmanCode,
    //             retailerCode:"All",
    //             // startDate: '',
    //             // endDate: '',
    //             startDate: this.state.startDate,
    //             endDate: this.state.endDate,                
    //             orderStatus: this.state.orderStatus,
    //             loginSalesmanCode: this.state.loginSalesmanCode,
    //             orderType:this.state.orderTypeFilter
    //         }

    //         this.setState({
    //             loading:true
    //         })

    //         axios.get(this.state.httpURL+"/order",{params})
    //         .then(response=>response.data)
    //         .then((data) =>{
    //             if(this.state.salesmanList.length === 0){
    //                 this.setState({
    //                     salesmanList:data.salesmanList
    //                 })
    //             }
    //             this.setState({
    //                 orderList:data.forOrderDataList,
    //                 orgorderList:data.forOrderDataList,
    //                // salesmanList:data.salesmanList,
    //                 distributorList:data.distributorList,
    //                 allChecked: false
    //             // productsList:data.productList
    //             },()=>{
    //                 this.setState({
    //                     loading:false
    //                 }) 
    //                 ReactTooltip.rebuild();
    //             })
    //         }).catch(err=>{
    //             //console.log(err)
    //             this.setState({
    //                 loading:false
    //             })
    //         })
    //     }
    // }

    handleSalesmanChange = (event) => {
        //  console.log("event-->"+JSON.stringify(event))
        this.setState({
            salesmanCode: event.value
        }, () => {
            this.getAllOrders();
        });

    }

    handleOrderStatus = (event) => {
        this.setState({
            orderStatus: event.value
        }, () => {
            this.getAllOrders();
        });
    }

    handleOrderTypeFilter = (event) => {
        this.setState({
            orderTypeFilter: event.label
        }, () => {
            this.getAllOrders();
        });
    }

    handleDistributorChange = (event) => {
        this.setState({
            distributorCode: event.target.value
        }, () => {
            this.getAllOrders();
        });

    }

    handleSAlesChange = e => {
        const { name, value } = e.target;

        this.setState({
            [name]: value,
            orderList: []
        });
    };


    changeqty = (e) => {
        // alert(e.target.value);
        //let itemId = e.target.id;
        let ivalue = e.target.value
        //console.log(ivalue);
        const { orderDetailsList } = this.state;
        const { id } = e.target;
        //  console.log("index-->"+id)

        //const re = /^[0-9\b]+$/;
        const re = /^\d{1,}(\.\d{0,2})?$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            let rate = orderDetailsList[id].rate;
            let productPiecesPerBox = orderDetailsList[id].productPiecesPerBox;
            let newamt = ivalue * rate;
            let nb = parseInt(ivalue / productPiecesPerBox);
            let np = ivalue % productPiecesPerBox;
            orderDetailsList[id].quantityStr = ivalue;
            orderDetailsList[id].totRate = newamt;
            orderDetailsList[id].numberOfPacks = nb;
            orderDetailsList[id].numberOfPieces = np;

            this.setState({ orderDetailsList: orderDetailsList }, () => {
                // console.log("calling"+JSON.stringify(orderDetailsList));
                this.calculateTotalAmount();
            });
        }

    }

    Order_popupSaveHandler = () => {
        if (this.state.exportedOrder) {
            alert("This order already uploaded to erp, cannot modify.");
        } else {
            var uniqobj = this.getUnique(this.state.orderDetailsList, 'productCode');

            if (this.state.orderDetailsList.length === 0) {
                alert("Add Data");
                return false;
            }

            if (this.checkProductisFilled()) {
                alert("Enter All Product Details");
                return false;
            }

            if (this.checkDistributorFilled()) {
                alert("Enter All Distributor");
                return false;
            }

            if (this.checkQtyisFilled()) {
                alert("Enter All Quantity Details");
                return false;
            }

            if (this.state.orderDetailsList.length !== uniqobj.length) {
                alert("Remove Duplicate Products");
                return false;
            }

            this.setState({
                loading: true
            });
            var regularOrderDetailsList = [];
            var baOrderDetailsList = [];
            if (this.state.salesType == 'regular') {
                regularOrderDetailsList = this.state.orderDetailsList;
            } else {
                baOrderDetailsList = this.state.orderDetailsList;
            }

            axios.post(this.state.httpURL + "/order/updateOrderDetails", { "forOrderDetailsList": regularOrderDetailsList, "forBAOrderDetailsList": baOrderDetailsList, "movedby": this.state.loginuserObj.userName })
                .then(res => {
                    if (res.data.valid) {
                        this.setState({
                            loading: false
                        });
                        alert("Successfully Updated");
                        this.Order_popCloseHandler();
                        this.getAllOrders()
                    } else {
                        this.setState({
                            loading: false
                        });
                        alert(res.data.responseMsg);
                    }

                })
        }
    }


    Order_popupDeleteItemsHandler = () => {

        this.setState({
            loading: true
        });
        var regularOrderDetailsList = [];
        var baOrderDetailsList = [];
        if (this.state.salesType == 'regular') {
            regularOrderDetailsList = this.state.orgorderDetailsList;
        } else {
            baOrderDetailsList = this.state.orgorderDetailsList;
        }

        axios.post(this.state.httpURL + "/order/deleteOrderDetails", { "forOrderDetailsList": regularOrderDetailsList, "forBAOrderDetailsList": baOrderDetailsList, "movedby": this.state.loginuserObj.userName })
            .then(res => {
                if (res.data.valid) {
                    this.setState({
                        loading: false
                    });
                    alert("Successfully Updated");
                    this.Order_popCloseHandler();
                    this.getAllOrders()
                } else {
                    this.setState({
                        loading: false
                    });
                    alert(res.data.responseMsg);
                }

            })
    }

    getUnique = (arr, index) => {
        const unique = arr
            .map(e => e[index])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }

    checkQtyisFilled = () => {
        var isZero = false;
        let srlist = this.state.orderDetailsList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].quantityStr === "0" || parseInt(srlist[i].quantityStr, 10) === 0 || srlist[i].quantityStr === "") {
                isZero = true;
            }
        }
        return isZero;
    }

    checkProductisFilled = () => {
        var isNotFilled = false;
        let srlist = this.state.orderDetailsList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].productCode === "") {
                isNotFilled = true;
            }
        }
        return isNotFilled;
    }

    checkDistributorFilled = () => {
        var isNotFilled = false;
        let srlist = this.state.orderDetailsList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].distributorCode === "" || srlist[i].distributorCode === undefined) {
                isNotFilled = true;
            }
        }
        return isNotFilled;
    }

    OrderDetails_editHandler = (ind) => {
        const { orderDetailsList } = this.state;
        if (this.state.exportedOrder) {
            alert("This order already uploaded to erp, cannot modify.");
        } else {
            orderDetailsList[ind].readOnly = false
            orderDetailsList[ind].isDisSelectDisabled = false
            this.setState({ orderDetailsList: orderDetailsList })
        }
    }

    OrderDetails_deleteHandler = (ind) => {
        if (this.state.exportedOrder) {
            alert("This order already uploaded to erp, cannot delete.");
        } else {
            var orderDetailsList = [...this.state.orderDetailsList];
            orderDetailsList.splice(ind, 1)
            this.setState({ orderDetailsList: orderDetailsList }, () => {
                this.calculateTotalAmount();
            });
        }
    }

    OrderDetails_neweditHandler = (ind) => {
        const { orderDetailsNewList } = this.state;
        orderDetailsNewList[ind].readOnly = false
        this.setState({ orderDetailsNewList: orderDetailsNewList })

    }

    OrderDetails_newdeleteHandler = (ind) => {
        var orderDetailsNewList = [...this.state.orderDetailsNewList];
        orderDetailsNewList.splice(ind, 1)
        this.setState({ orderDetailsNewList: orderDetailsNewList })
    }

    handleProductChange = (ind, event) => {
        const { orderDetailsList } = this.state;
        orderDetailsList[ind].productCode = event.value
        orderDetailsList[ind].productName = event.label
        orderDetailsList[ind].distributorCode = ''
        orderDetailsList[ind].rate = event.price
        orderDetailsList[ind].quantity = 0
        orderDetailsList[ind].stock = event.stock
        orderDetailsList[ind].productPiecesPerBox = event.piecesPerBox


        if (event.value !== undefined) {
            this.setState({ orderDetailsList: orderDetailsList }, () => {
                ReactTooltip.rebuild();
                this.getDistributor(ind, event);
            });
        }
    }

    handleProductDistributorChange = (ind, event) => {
        const { orderDetailsList } = this.state;
        orderDetailsList[ind].distributorCode = event.value
        orderDetailsList[ind].distributorName = event.label
        this.setState({ orderDetailsList: orderDetailsList }, () => {
            ReactTooltip.rebuild();
        });
    }

    getDistributor = (ind, event) => {
        const params = {
            companyCode: this.state.loginuserObj.companyCode,
            productCode: event.value,
            retailerCode: this.state.retailerCode,
            salesmanCode: this.state.ordSalesmanCode,
            productTypeCode: event.productTypeCode,
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/order/getDistributorList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    });
                    const { orderDetailsList } = this.state;
                    orderDetailsList[ind].productDistributorList = data.productDistributorList;
                    this.setState({ orderDetailsList: orderDetailsList });
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    Order_deleteHandler = (order) => {
        if (order.isExported === "Y") {
            alert("This order already uploaded to erp, cannot delete.");
        } else {
            var answer = window.confirm("Are Sure To Delete The Order?")
            if (answer) {
                const params = {
                    orderId: order.id,
                    movedby: this.state.loginuserObj.userName
                }
                this.setState({
                    loading: true
                });
                axios.get(this.state.httpURL + "/order/deleteorder", { params })
                    .then(response => response.data)
                    .then((data) => {
                        if (data.valid) {
                            this.setState({
                                loading: false
                            });
                            alert("Successfully Deleted");
                            this.getAllOrders();
                        }

                    }).catch(err => {
                        //console.log(err)
                        this.setState({
                            loading: false
                        });
                        alert(err);
                    })
            }
        }
    }


    calculateTotalAmount = () => {
        var totAmtt = 0;
        // console.log('totAmount--'+this.state.totAmount);
        //  console.log("--"+JSON.stringify(this.state.salesReturnDetailsList))
        this.state.orderDetailsList.map((srd) => (
            totAmtt = totAmtt + parseFloat(srd.totRate)
        ));
        // console.log("totAmtt:111"+totAmtt)
        this.setState({ totAmount: totAmtt })
    }

    filterList = (event) => {
        // alert(event.target.value);
        // var tlist = this.state.orgorderList;
        // var searchString = event.target.value;
        //var fieldName = event.target.name;
        //console.log("--fieldName--"+fieldName)
        // searchString=searchString.toLocaleString();

        //  console.log("--ord list-->"+this.state.orderList.length)
        //   console.log("--org ord list-->"+this.state.orgorderList.length)
        /*   if(this.state.orderList.length === this.state.orgorderList.length){
               console.log("if condition==>")
               const filtered = tlist.filter(od => (
   
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                  // od.distributorName.toLowerCase().search(searchString.toLowerCase()) !== -1 ||
                  // od.salesmanName.toLowerCase().search(searchString.toLowerCase()) !== -1 ||
                  // od.retailerName.toLowerCase().search(searchString.toLowerCase()) !== -1 ||
                 //  od.orderValue.toLocaleString().search(searchString.toLowerCase()) !== -1 
               ));
               this.setState({
                   orderList: filtered
               })
           }else{
               console.log("else condition==>")
               var tlist = this.state.orderList;
   
               this.setState({
                   prevstateorderList: tlist
               })
               const filtered = tlist.filter(od => (
   
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                  // od.distributorName.toLowerCase().search(searchString.toLowerCase()) !== -1 ||
                 //  od.salesmanName.toLowerCase().search(searchString.toLowerCase()) !== -1 ||
                  // od.retailerName.toLowerCase().search(searchString.toLowerCase()) !== -1 ||
                  // od.orderValue.toLocaleString().search(searchString.toLowerCase()) !== -1 
               ));
               this.setState({
                   orderList: filtered
               })
   
           } */



        let filtered = this.state.orgorderList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }

        this.setState({
            orderList: filtered
        })

    }



    dateChange = (e) => {
        // console.log(e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    Order_upload = () => {
        var pList = this.state.orderList;
        var tFlag = false;
        pList.map(function (pl, i) {
            if (pl.isChecked) {
                tFlag = true;
            }
        });

        if (tFlag) {
            let params = {
                "forOrderDataList": this.state.orderList,
            }
            //console.log(params);
            this.setState({
                loading: true
            });
            axios.post(this.state.httpURL + "/adweb/exportOrder", params)
                .then(response => response.data)
                .then((data) => {
                    this.setState({
                        loading: false
                    });
                    if (data.valid) {
                        if (data.unExportedList !== null) {
                            alert(data.unExportedList);
                        } else {
                            alert(data.responseMsg);
                        }
                        this.getAllOrders();
                    } else {
                        alert(data.responseMsg);
                    }
                }).catch(err => {
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        } else {
            alert("Please select any order for upload.");
        }
    }

    orderExcel = () => {
        var dFlag = true;
        if (this.state.orderList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.orderList;
            var fileName = "Orders Report_" + this.state.currentDate;
            var wscols = [
                { wch: 13 },
                { wch: 35 },
                { wch: 45 },
                { wch: 35 },
                { wch: 20 },
                { wch: 20 },
                //{wch:20},
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Order Number": dList[i].refNum,
                    "Sales Person": dList[i].salesmanName,
                    "Retailer": dList[i].retailerName,
                    "State": dList[i].stateName,
                    "Order Value": parseInt(dList[i].orderValue.toFixed(2)),
                    "Date Time": dList[i].orderDTMStr,
                    "Exported To ERP": dList[i].isExported,
                    //"Order Type": dList[i].orderType,                    
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'order': ws }, SheetNames: ['order'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    handleORFStateChange = (event) => {
        this.setState({
            orFState: event.value,
        }, () => {
            this.getAllOrders();
        });
    }

    render() {
        const orderOption = [
            { value: 'All', label: 'All' },
            { value: 'Open Order', label: 'Open Order' },
            { value: 'Exported Order', label: 'Exported Order' }
        ]
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">

                    <div className="O2Table_Header"><span>ORDERS</span></div>
                    <div className="O2Table_Body">
                        <div className="O2Table_SubContainer OrderSubContainer">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Sales Person</div>
                                <div className="contentRow_Value">
                                    {/* <select value={this.state.salesmanCode} onChange={this.handleSalesmanChange}>
                                        {
                                            this.state.loginuserObj.userType !== "salesman" && this.state.loginuserObj.userType !== "Admin" && this.state.loginuserObj.userType !== "Sys Admin" ?
                                                <option value="All" key="All">All</option>
                                                : null
                                        }
                                        {
                                            this.state.salesmanList.map((salesman) => (
                                                <option value={salesman.salesmanCode} key={salesman.salesmanCode}>{salesman.salesmanName}</option>

                                            ))
                                        }
                                    </select> */}
                                    <Select options={this.state.salesmanList} name="smrSalesmanValue" value={this.state.salesmanList.filter(({ value }) => value === this.state.salesmanCode)} placeholder="Choose Sales Person" onChange={this.handleSalesmanChange} />

                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">State</div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.orFStateList} name="orFState" value={this.state.orFStateList.filter(({ value }) => value === this.state.orFState)} placeholder="Choose State" onChange={this.handleORFStateChange} />
                                </div>
                            </div>

                            {/* <div className="O2Content_row">
                            <div className="contentRow_Label O2TablecheckboxLabel">Display Exported Orders </div>
                            <div className="contentRow_Value O2TablecheckboxValue">
                                <input type="checkbox" name="checkExported" checked={this.state.checkExported} onChange={this.handleExportedChange}/>                                  
                            </div>
                        </div>  */}
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Order Status</div>
                                <div className="contentRow_Value">
                                    <Select options={orderOption} value={orderOption.filter(({ value }) => value === this.state.orderStatus)} onChange={this.handleOrderStatus}></Select>
                                </div>
                            </div>
                            {/* <div className="O2Table_Go" onClick={this.getAllOrders}><button>Go</button></div> */}

                        </div>
                        <div className="O2Table_SubContainer OrderSubContainer">

                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date From </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="startDate" value={this.state.startDate} onChange={this.dateChange} />
                                </div>
                            </div>

                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date To </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="endDate" value={this.state.endDate} onChange={this.dateChange} />
                                </div>
                            </div>

                            <div className="O2Content_orderTypeCon">
                                <div className="O2Content_orderTypeRadio">
                                    <span><input type="radio" value="regular" name="salesType" checked={this.state.salesType == 'regular'} onChange={this.handleSAlesChange} /> Secondary Orders</span>
                                    <span><input type="radio" value="ba" name="salesType" checked={this.state.salesType == 'ba'} onChange={this.handleSAlesChange} /> BA MRP Sales </span>
                                </div>
                            </div>
                            <div className="O2Table_Go" onClick={this.getAllOrders}><button>Go</button></div>

                        </div>
                        <div className="Table_wrapper">

                            <table className="customTable" id="orders_Table">
                                <thead>
                                    <tr>
                                        <th className="checkBox_Container">
                                            <input type="checkbox" name="checkAll" checked={this.state.allChecked} onChange={this.handleAllChange} />
                                        </th>
                                        <th className="Ref_Num orderRef_No" >Order Number
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="refNum" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        {/* <th>Distributor</th> */}
                                        <th className="salesMan orderSalesMan" >Sales Person
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="salesmanName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Descript_width" >Retailer
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="StateCode_width" >State Code
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="stateCode" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Value_width orderValuewidth">Order Value
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderValue" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="salesMan orderSalesMan">Date Time</th>
                                        <th className="DateTime_width orderERP">ERP
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="isExported" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        {/* <th className="DateTime_width">Order Type
                                    <div className="searchWrapper">
                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                        <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderType" onChange={this.filterList}  />
                                    </div>     
                                </th>  */}
                                        {/* <th>Status</th> */}
                                        {
                                            // this.state.writeAccess ?
                                            // <th className="o2Del_container"></th>
                                            // : null
                                        }

                                        <th className="scroller"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.orderList || this.state.orderList.length === 0 || this.state.orderList === null ?
                                            <tr align="center">
                                                <td colSpan="6">No Order Available</td>
                                            </tr> :
                                            this.state.orderList.map((order, i) => (
                                                <tr key={order.id}  >
                                                    <td className="checkBox_Container">
                                                        <input type="checkbox" name={order.id} value={order.id} checked={order.isChecked} onChange={(e) => this.handleChange(e, i)} />
                                                    </td>
                                                    <td className="Ref_Num orderRef_No" onDoubleClick={() => this.Order_popup_openHandler(order)} data-tip={order.refNum}>{order.refNum}</td>
                                                    {/* <td onClick={ ()=> this.Order_popup_openHandler(order)}>{order.distributorName}</td> */}
                                                    <td className="salesMan orderSalesMan" onDoubleClick={() => this.Order_popup_openHandler(order)} data-tip={order.salesmanName}>{order.salesmanName}</td>
                                                    <td className="Descript_width" onDoubleClick={() => this.Order_popup_openHandler(order)} data-tip={order.retailerName}>{order.retailerName}</td>
                                                    <td className="StateCode_width" onDoubleClick={() => this.Order_popup_openHandler(order)} data-tip={order.stateCode}>{order.stateCode}</td>
                                                    <td className="Value_width orderValuewidth" onDoubleClick={() => this.Order_popup_openHandler(order)} data-tip={order.orderValue.toFixed(2)}>{order.orderValue.toFixed(2)}</td>
                                                    <td className="salesMan orderSalesMan" onDoubleClick={() => this.Order_popup_openHandler(order)} data-tip={order.orderDTMStr}>{order.orderDTMStr}</td>
                                                    <td className="DateTime_width orderERP" onDoubleClick={() => this.Order_popup_openHandler(order)} data-tip={order.isExported}>{order.isExported}</td>
                                                    {/* <td className="DateTime_width" onDoubleClick={ ()=> this.Order_popup_openHandler(order)} data-tip={order.orderType}>{order.orderType}</td> */}
                                                    {/* <td>{order.status}</td> */}
                                                    {
                                                        // this.state.writeAccess ?
                                                        // <td className="o2Del_container">
                                                        //     {
                                                        //         order.isExported === 'N' ? 
                                                        //             <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 8px'}} onClick={ ()=> this.Order_deleteHandler(order)}/>
                                                        //         : null
                                                        //     }

                                                        //     </td>
                                                        // : null
                                                    }

                                                </tr>

                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.orderList ? this.state.orderList.length : 0} </span>entries</span></div>
                            {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{padding:'0px 8px'}}/></span></div>
                    <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{padding:'0px 8px'}}/> Previous</span></div> */}
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            {/* {
                        this.state.writeAccess ?
                        <div className="O2Table_Export" onClick={ ()=> this.Order_upload()}><FontAwesomeIcon icon={faCloudUploadAlt} style={{padding:'0px 8px'}}/> Upload to ERP</div>
                        : null
                    } */}
                            {
                                this.state.writeAccess ?
                                    <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudUploadAlt} style={{ padding: '0px 8px' }} /> Upload to ERP</div>
                                    : null
                            }
                            <div className="O2Table_ExpCollExcel" onClick={() => this.orderExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Excel Export</div>
                            {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{padding:'0px 8px'}}/><ReactHTMLTableToExcel className="download-table-xls-button" table="orders_Table" filename={"Orders_"+this.state.currentDate}
                        sheet="tablexls" buttonText="Excel Export"/></div>
                    <div className="O2Table_RecSelect">
                        <div className="O2Table_Label"> Select All Orders  : </div>
                        <div className="O2Table_Value"><input type="checkbox"/></div>
                    </div> */}
                        </div>
                    </div>
                </div>

                <div className="O2TableEdit_wrapper OrderTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Order Ref No(#): {this.state.refNum}
                                {/* <div className="O2Edit_New">
                                {
                                    this.state.erpExported === 'N' && this.state.writeAccess ?
                                    <button  onClick={ ()=> this.Order_newRowHandler()}>New</button>
                                    : null
                                }                                
                            </div> */}
                            </div>
                            <div className="O2TableEditBody">
                                {/* <div className="Table_wrapper">
                                <table className="customTable OrderTablepop">
                                    <thead>
                                        <tr>
                                            <th>Order Ref No</th>
                                            <th>Salesman</th>        
                                            <th>Reailer</th>
                                            <th>Date</th>
                                            <th className="scroller"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{this.state.refNum}</td>
                                        <td>{this.state.salesmanName}</td>
                                        <td>{this.state.retailerName}</td>
                                        <td>{this.state.orderDTM}</td>
                                        
                                    </tr>
                                    </tbody>
                                </table>
                            </div> */}
                                <div className="row">
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Order Number : </div>
                                        <div className="FieldValue"><span>{this.state.refNum} </span></div>
                                    </div>
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Order Date : </div>
                                        <div className="FieldValue"><span>{this.state.orderDTM} </span></div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="FieldComponent">
                                    <div className="FieldLabel">Distributor :  </div>
                                    <div className="FieldValue"><span>{this.state.distributorName}</span></div>
                                </div> */}
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Retailer : </div>
                                        <div className="FieldValue"><span> {this.state.retailerName}</span></div>
                                    </div>
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Sales Person : </div>
                                        <div className="FieldValue"><span> {this.state.salesmanName}</span></div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="FieldComponent">
                                    <div className="FieldLabel">Salesman : </div>
                                    <div className="FieldValue"><span> {this.state.salesmanName}</span></div>
                                </div> */}
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Amount : </div>
                                        <div className="FieldValue"><span>{parseFloat(this.state.totAmount).toFixed(2)} </span></div>
                                    </div>
                                    <div className="FieldComponent">
                                        {/* <div className="FieldLabel">Exported Order No : </div>
                                    <div className="FieldValue"><span>{this.state.erpOrderNo} </span></div> */}
                                    </div>
                                </div>
                                <div className="Table_wrapper orderpop_tablemargin">
                                    <table className="customTable OrderTablesecondpop">
                                        <thead>
                                            <tr>
                                                <th className="checkBox_Container">
                                                    <input type="checkbox" name="checkAllItem" checked={this.state.allChecked} onChange={this.handleAllChangeItem} />
                                                </th>
                                                <th className="Sno_width">S.No</th>
                                                <th className="Descript_width">Product</th>
                                                <th className="Descript_width">Distributor</th>
                                                {/* <th>VOM</th>         */}
                                                <th className="Value_width">Quantity</th>
                                                {/* <th>Scheme Qty</th> */}
                                                <th className="Value_width">Box</th>
                                                <th className="Value_width">Pack</th>
                                                <th className="Value_width">piece</th>
                                                <th className="Value_width">Rate</th>
                                                <th className="Value_width">Value</th>
                                                <th className="Value_width">Stock</th>
                                                <th className="ErpDoc_width">ERP Doc No</th>
                                                {/* {
                                                     this.state.writeAccess ?
                                                     <th className="o2popDel_container"></th>
                                                     : null
                                                } */}

                                                <th className="scroller"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.orderDetailsList.map((ord, i) => (
                                                    <tr key={i}>
                                                        <td className="checkBox_Container">
                                                            <input type="checkbox" name={ord.id} value={ord.id} checked={ord.isChecked} onChange={(e) => this.handleChangeItem(e, i)} />
                                                        </td>
                                                        <td className="Sno_width" data-tip={i}>{i + 1}</td>
                                                        {/* <td className="Descript_width" data-tip={ord.productName}><Select options={this.state.productsList} value={this.state.productsList.filter(({value}) => value === ord.productCode)} placeholder="Choose Product" isDisabled={ord.isSelectDisabled} onChange={ (e)=> this.handleProductChange(i,e)}/></td> */}
                                                        {
                                                            ord.savedProduct === true ?
                                                                <td className="Descript_width" data-tip={ord.productName}><input type="text" value={ord.productName} disabled={true} /></td>
                                                                :
                                                                <td className="Descript_width" data-tip={ord.productName}><Select options={this.state.productsList} value={this.state.productsList.filter(({ value }) => value === ord.productCode)} placeholder="Choose Product" isDisabled={ord.isSelectDisabled} onChange={(e) => this.handleProductChange(i, e)} /></td>
                                                        }
                                                        <td className="Descript_width" data-tip={ord.distributorName}><Select options={ord.productDistributorList} value={ord.productDistributorList.filter(({ value }) => value === ord.distributorCode)} placeholder="Choose Distributor" isDisabled={ord.isDisSelectDisabled} onChange={(e) => this.handleProductDistributorChange(i, e)} /></td>
                                                        {/* <td>{ord.productName}</td> */}
                                                        {/* <td className="Value_width" data-tip={ord.quantityStr}><input type="text" id={i} name={ord.id} onChange={this.changeqty} value={ord.quantityStr} readOnly={ord.readOnly} /></td> */}
                                                        <td className="Value_width" data-tip={ord.quantityStr}><input type="text" id={i} name={ord.id} onChange={this.changeqty} value={ord.quantityStr} /></td>
                                                        <td className="Value_width" data-tip={parseFloat(ord.numberOfBoxes).toFixed(1)}>{parseFloat(ord.numberOfBoxes).toFixed(1)}</td>
                                                        <td className="Value_width" data-tip={parseFloat(ord.numberOfPacks).toFixed(2)}>{parseFloat(ord.numberOfPacks).toFixed(2)}</td>
                                                        <td className="Value_width" data-tip={parseFloat(ord.numberOfPieces).toFixed(2)}>{parseFloat(ord.numberOfPieces).toFixed(2)}</td>
                                                        <td className="Value_width" data-tip={parseFloat(ord.rate).toFixed(2)}>{parseFloat(ord.rate).toFixed(2)}</td>
                                                        <td className="Value_width" data-tip={parseFloat(ord.totRate).toFixed(2)}>{parseFloat(ord.totRate).toFixed(2)}</td>
                                                        <td className="Value_width" data-tip={ord.stock}>{ord.stock}</td>
                                                        <td className="ErpDoc_width" data-tip={ord.documentNo}>{ord.documentNo}</td>
                                                        {/* {
                                                             this.state.writeAccess ?
                                                             <td className="o2popDel_container">
                                                                 {
                                                                     this.state.erpExported === 'N' ?
                                                                        <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}}  onClick={ ()=> this.OrderDetails_editHandler(i)}/>
                                                                     : null
                                                                 }
                                                                 {
                                                                    this.state.erpExported === 'N' ?
                                                                        <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}} onClick={ ()=> this.OrderDetails_deleteHandler(i)}/>
                                                                    : null
                                                                }                                                                                                                 
                                                                </td>
                                                             : null
                                                        } */}


                                                    </tr>

                                                ))


                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="O2TableEditFooter OrderModuleFooter">
                                {
                                    this.state.writeAccess ?
                                        // <button disabled onClick={this.Order_popupSaveHandler}>Save</button>
                                        <button onClick={this.Order_popupSaveHandler}>Save</button>
                                        : null
                                }
                                <button onClick={this.Order_popCloseHandler}>Cancel</button>
                                <div>
                                    <button onClick={this.Order_popPDFHandler}>Print</button>
                                    <button onClick={this.Order_popMailHandler}>E-Mail</button>
                                </div>

                                {
                                    this.state.writeAccess ?
                                        <button onClick={this.Order_popupDeleteItemsHandler}>Delete</button>
                                        : null
                                }
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="O2TableEdit_wrapper newOrder_popup">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">New Order</div>
                            <div className="O2Table_BodyContent">
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Distributor</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select/>                                        
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Salesman</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select/>      
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Retailer</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select/>      
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Product</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select/>      
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Quantity</div><span>:</span>
                                    <div className="legendBody_Value">
                                       <input type="text"></input>   
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    <button>Save</button>
                                    <button  onClick={this.Ordernew_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(OrderModule)
//export default OrderModule