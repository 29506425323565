import React, { Component, useState } from 'react'
import Select from 'react-select';
import './VisitPlanModule.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
class VisitPlanModule extends Component {
    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            vpDate: todaydate,
            todaydate: todaydate,
            userType: this.props.userObj.userType,
            companyCode: this.props.userObj.companyCode,
            vpSalesmanList: [],
            vpRetailerModeList: [],
            vpDayList: [],
            vpConstantDayList: [],
            vpStateList: [],
            vpCityList: [],
            vpRouteList: [],
            vpRetailerList: [],
            vpDayRetailerList: [],
            vpOrgSalesmanList: [],
            vpOrgDayList: [],
            vpOrgStateList: [],
            vpOrgCityList: [],
            vpOrgRouteList: [],
            vpOrgRetailerList: [],
            vpOrgDayRetailerList: [],
            vpSavedStateList: [],
            vpSavedCityList: [],
            vpSavedRouteList: [],
            vpSavedRetailerList: [],
            vpSalesPerson: '',
            vpVistPlan: 1,
            vpSelectedDay: '',
            vpSalesPersonName: '',
            vpAllStateChecked: false,
            vpAllCityChecked: false,
            vpAllRouteChecked: false,
            vpAllRetailerChecked: false,
            vpAllDayRetailerChecked: false,
            vpAllDayChecked: false,
            vpAllDayRetailerDisabled: false,
            vpAllRouteDisabled: false,
            vpAllRetailerDisabled: false,
            vpMaxRetailerAllowedCount: 0,
        }
    }


    componentDidMount() {
        this.getVisitPlan();
    }
    getVisitPlan() {
        //console.log("<-1->"+JSON.stringify(this.props.userObj.salesmanCode));
        const params = {
            salesmanCode: this.props.userObj.salesmanCode
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/visitplan/getVisitPlan", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        vpSalesmanList: data.salesmanList,
                        vpRetailerModeList: data.retailerModeList,
                        vpDayList: data.dayList,
                        vpConstantDayList: data.dayList,
                        vpOrgSalesmanList: data.salesmanList,
                        vpOrgDayList: data.dayList,
                        vpMaxRetailerAllowedCount: data.maxRetailerCount,
                    }, () => {
                        ReactTooltip.rebuild();
                    })
                    document.querySelector('.VisitPlanRoute_Container').style.display = 'block';
                    document.querySelector('.VisitPlandayRoute_Container').style.display = 'none';
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    handleOptionChange = (e) => {
        let itemName = e.value;
        let optionSelect = true;
        let todayDate = this.state.todaydate;
        // this.setState({
        //          optionSelect : true
        //      })
        if (itemName === 2) {
            // optionSelect = selected;
            optionSelect = true;
            // console.log(optionSelect)
            this.setState({
                optionSelect: true,
            })
            document.querySelector('.VisitPlandayRoute_Container').style.display = 'block';
            document.querySelector('.VisitPlanRoute_Container').style.display = 'none';
            this.vpDayRouteClear();
        } else {
            // console.log(optionSelect)
            this.setState({
                optionSelect: false,
            })
            document.querySelector('.VisitPlanRoute_Container').style.display = 'block';
            document.querySelector('.VisitPlandayRoute_Container').style.display = 'none';
            this.vpRouteClear();
        }
        this.setState({
            vpVistPlan: itemName,
            vpDate: todayDate
        }, () => {
            this.vpDayClear();
            this.vpRouteClear();
        });
    };

    vpDateChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    vpSalesPersonChange = (event) => {
        this.setState({
            vpSalesPerson: event.value,
            vpSalesPersonName: event.label
        }, () => {
            if (this.state.vpVistPlan === 2) {
                this.vpSavedList();
            } else {
                //this.vpDayClear();  
                this.vpSavedList();
            }
        });
    }

    vpALLStateCheckBoxChange = e => {
        if (this.state.vpSalesPerson !== '' && this.state.vpSalesPerson !== undefined) {
            let itemName = e.target.name;
            let checked = e.target.checked;
            this.setState(prevState => {
                let { vpStateList, vpAllStateChecked, vpOrgStateList } = prevState;
                if (itemName === "allStateCheckBox") {
                    if (checked) {
                        vpAllStateChecked = checked;
                        vpStateList = vpStateList.map(item => ({ ...item, stateChecked: true }));
                        vpOrgStateList = vpOrgStateList.map(item => ({ ...item, stateChecked: true }));
                    } else {
                        vpAllStateChecked = false;
                        vpStateList = vpStateList.map(item => ({ ...item, stateChecked: false }));
                        vpOrgStateList = vpOrgStateList.map(item => ({ ...item, stateChecked: false }));
                    }
                }
                return { vpStateList, vpAllStateChecked, vpOrgStateList };
            }, () => {
                this.getVPCityList();
            });
        } else {
            this.setState({
                vpAllStateChecked: false
            }, () => {
                alert("Please select sales person");
            });
        }
    };

    vpALLCityCheckBoxChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { vpCityList, vpAllCityChecked, vpAllRouteDisabled, vpAllRetailerDisabled, vpOrgCityList, vpRouteList, vpOrgRetailerList } = prevState;
            if (itemName === "allCityCheckBox") {
                if (checked) {
                    vpAllCityChecked = checked;
                    vpCityList = vpCityList.map(item => ({ ...item, cityChecked: true }));
                    vpOrgCityList = vpOrgCityList.map(item => ({ ...item, cityChecked: true }));
                    vpRouteList = vpRouteList.map(item => ({ ...item, isDisabled: true }));
                    vpOrgRetailerList = vpOrgRetailerList.map(item => ({ ...item, isDisabled: true }));
                } else {
                    vpAllCityChecked = false;
                    vpCityList = vpCityList.map(item => ({ ...item, cityChecked: false }));
                    vpOrgCityList = vpOrgCityList.map(item => ({ ...item, cityChecked: false }));
                    vpRouteList = vpRouteList.map(item => ({ ...item, isDisabled: true }));
                    vpOrgRetailerList = vpOrgRetailerList.map(item => ({ ...item, isDisabled: true }));
                }
                vpAllRouteDisabled = true;
                vpAllRetailerDisabled = true;
            }
            return { vpCityList, vpAllCityChecked, vpOrgCityList, vpRouteList, vpOrgRetailerList, vpAllRouteDisabled, vpAllRetailerDisabled };
        }, () => {
            //this.getVPRouteList();
        });
    };

    vpALLRouteCheckBoxChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { vpRouteList, vpAllRouteChecked, vpOrgRouteList, vpAllRetailerDisabled } = prevState;
            if (itemName === "allRouteCheckBox") {
                if (checked) {
                    vpAllRouteChecked = checked;
                    vpRouteList = vpRouteList.map(item => ({ ...item, routeChecked: true }));
                    vpOrgRouteList = vpOrgRouteList.map(item => ({ ...item, routeChecked: true }));
                } else {
                    vpAllRouteChecked = false;
                    vpRouteList = vpRouteList.map(item => ({ ...item, routeChecked: false }));
                    vpOrgRouteList = vpOrgRouteList.map(item => ({ ...item, routeChecked: false }));
                }
                vpAllRetailerDisabled = true;
            }
            return { vpRouteList, vpAllRouteChecked, vpOrgRouteList, vpAllRetailerDisabled };
        }, () => {
            //this.getVPRetailerList();
        });
    };

    vpALLRetailerCheckBoxChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { vpRetailerList, vpAllRetailerChecked, vpOrgRetailerList } = prevState;
            if (itemName === "allRetailerCheckBox") {
                if (checked) {
                    vpAllRetailerChecked = checked;
                    vpRetailerList = vpRetailerList.map(item => ({ ...item, retailerChecked: true }));
                    vpOrgRetailerList = vpOrgRetailerList.map(item => ({ ...item, retailerChecked: true }));
                } else {
                    vpAllRetailerChecked = false;
                    vpRetailerList = vpRetailerList.map(item => ({ ...item, retailerChecked: false }));
                    vpOrgRetailerList = vpOrgRetailerList.map(item => ({ ...item, retailerChecked: false }));
                }
            }
            return { vpRetailerList, vpAllRetailerChecked, vpOrgRetailerList };
        }, () => {
            //this.getVPRetailerList();
        });
    };

    vpALLDayCheckBoxChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { vpDayList, vpAllDayChecked, vpOrgDayList } = prevState;
            if (itemName === "allDayCheckBox") {
                if (checked) {
                    vpAllDayChecked = checked;
                    vpDayList = vpDayList.map(item => ({ ...item, dayChecked: true }));
                    vpOrgDayList = vpOrgDayList.map(item => ({ ...item, dayChecked: true }));
                } else {
                    vpAllDayChecked = false;
                    vpDayList = vpDayList.map(item => ({ ...item, dayChecked: false }));
                    vpOrgDayList = vpOrgDayList.map(item => ({ ...item, dayChecked: false }));
                }
            }
            return { vpDayList, vpAllDayChecked, vpOrgDayList };
        }, () => {
            // this.getVPRetailerList();
        });
    };

    vpALLDayRetailerCheckBoxChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { vpDayRetailerList, vpAllDayRetailerChecked, vpOrgDayRetailerList } = prevState;
            if (itemName === "allDayRetailerCheckBox") {
                if (checked) {
                    vpAllDayRetailerChecked = checked;
                    vpDayRetailerList = vpDayRetailerList.map(item => ({ ...item, retailerChecked: true }));
                    vpOrgDayRetailerList = vpOrgDayRetailerList.map(item => ({ ...item, retailerChecked: true }));
                } else {
                    vpAllDayRetailerChecked = false;
                    vpDayRetailerList = vpDayRetailerList.map(item => ({ ...item, retailerChecked: false }));
                    vpOrgDayRetailerList = vpOrgDayRetailerList.map(item => ({ ...item, retailerChecked: false }));
                }
            }
            return { vpDayRetailerList, vpAllDayRetailerChecked, vpOrgDayRetailerList };
        }, () => {
            //this.getVPRetailerList();
        });
    };


    vpStateCheckBoxChange = (e, ind) => {
        // if(this.state.vpSalesPerson !== '' && this.state.vpSalesPerson !== undefined){
        //     let checked = e.target.checked;
        //     const {  vpStateList,  vpOrgStateList } = this.state;        
        //     vpStateList[ind].stateChecked = checked
        //     vpOrgStateList[ind].stateChecked = checked            
        //     this.setState({ vpStateList:vpStateList,vpOrgStateList:vpOrgStateList},()=>{
        //         this.getVPCityList();
        //     });
        // } else {
        //     alert("Please select sales person");
        // }         
        if (this.state.vpSalesPerson !== '' && this.state.vpSalesPerson !== undefined) {
            let checked = e.target.checked;
            this.setState(prevState => {
                let { vpStateList, vpOrgStateList } = prevState;
                var sName = vpStateList[ind].state;
                vpStateList = vpStateList.map(item => item.state === sName ? { ...item, stateChecked: checked } : item);
                vpOrgStateList = vpOrgStateList.map(item => item.state === sName ? { ...item, stateChecked: checked } : item);
                return { vpStateList, vpOrgStateList };
            }, () => {
                this.getVPCityList();
            });
        } else {
            alert("Please select sales person");
        }
    };

    vpCityCheckBoxChange = (e, ind) => {
        let checked = e.target.checked;

        this.setState(prevState => {
            let { vpRouteList, vpOrgRouteList, vpRetailerList, vpOrgRetailerList } = prevState;
            vpRouteList = vpRouteList.map(item => ({ ...item, isDisabled: true }));
            vpOrgRouteList = vpOrgRouteList.map(item => ({ ...item, isDisabled: true }));
            vpRetailerList = vpRetailerList.map(item => ({ ...item, isDisabled: true }));
            vpOrgRetailerList = vpOrgRetailerList.map(item => ({ ...item, isDisabled: true }));
            return { vpRouteList, vpOrgRouteList, vpRetailerList, vpOrgRetailerList };
        }, () => {
            // const {  vpCityList,  vpOrgCityList } = this.state;        
            // vpCityList[ind].cityChecked = checked
            // vpOrgCityList[ind].cityChecked = checked            
            // this.setState({ vpCityList:vpCityList,vpOrgCityList:vpOrgCityList, vpAllRouteDisabled : true, vpAllRetailerDisabled : true},()=>{
            //    //this.getVPRouteList();
            // });                   
            this.setState(prevState => {
                let { vpCityList, vpOrgCityList } = prevState;
                var cName = vpCityList[ind].city;
                vpCityList = vpCityList.map(item => item.city === cName ? { ...item, cityChecked: checked } : item);
                vpOrgCityList = vpOrgCityList.map(item => item.city === cName ? { ...item, cityChecked: checked } : item);
                return { vpCityList, vpOrgCityList, vpAllRouteDisabled: true, vpAllRetailerDisabled: true };
            });
        });
    };

    vpRouteCheckBoxChange = (e, ind) => {
        let checked = e.target.checked;
        this.setState(prevState => {
            let { vpRetailerList, vpOrgRetailerList } = prevState;
            vpRetailerList = vpRetailerList.map(item => ({ ...item, isDisabled: true }));
            vpOrgRetailerList = vpOrgRetailerList.map(item => ({ ...item, isDisabled: true }));
            return { vpRetailerList, vpOrgRetailerList };
        }, () => {
            // const {  vpRouteList,  vpOrgRouteList } = this.state;        
            // vpRouteList[ind].routeChecked = checked
            // vpOrgRouteList[ind].routeChecked = checked            
            // this.setState({ vpRouteList:vpRouteList,vpOrgRouteList:vpOrgRouteList, vpAllRetailerDisabled : true},()=>{
            //    //this.getVPRetailerList();
            // });
            this.setState(prevState => {
                let { vpRouteList, vpOrgRouteList } = prevState;
                var rCode = vpRouteList[ind].routeCode;
                vpRouteList = vpRouteList.map(item => item.routeCode === rCode ? { ...item, routeChecked: checked } : item);
                vpOrgRouteList = vpOrgRouteList.map(item => item.routeCode === rCode ? { ...item, routeChecked: checked } : item);
                return { vpRouteList, vpOrgRouteList, vpAllRetailerDisabled: true };
            });
        });
    };

    vpRetailerCheckBoxChange = (e, ind) => {
        let checked = e.target.checked;
        // const {  vpRetailerList,  vpOrgRetailerList } = this.state;        
        //     vpRetailerList[ind].retailerChecked = checked
        //     vpOrgRetailerList[ind].retailerChecked = checked            
        //     this.setState({ vpRetailerList:vpRetailerList,vpOrgRetailerList:vpOrgRetailerList},()=>{
        //        //this.getVPRetailerList();
        //     });
        this.setState(prevState => {
            let { vpRetailerList, vpOrgRetailerList } = prevState;
            var rCode = vpRetailerList[ind].retailerCode;
            vpRetailerList = vpRetailerList.map(item => item.retailerCode === rCode ? { ...item, retailerChecked: checked } : item);
            vpOrgRetailerList = vpOrgRetailerList.map(item => item.retailerCode === rCode ? { ...item, retailerChecked: checked } : item);
            return { vpRetailerList, vpOrgRetailerList };
        });
    };

    vpDayCheckBoxChange = (e, ind) => {
        let checked = e.target.checked;
        this.setState(prevState => {
            let { vpDayList, vpOrgDayList, vpDayRetailerList, vpOrgDayRetailerList } = prevState;
            vpDayList = vpDayList.map(item => ({ ...item, dayChecked: false }));
            vpOrgDayList = vpOrgDayList.map(item => ({ ...item, dayChecked: false }));
            vpDayRetailerList = vpDayRetailerList.map(item => ({ ...item, isDisabled: true }));
            vpOrgDayRetailerList = vpOrgDayRetailerList.map(item => ({ ...item, isDisabled: true }));
            return { vpDayList, vpOrgDayList, vpDayRetailerList, vpOrgDayRetailerList };
        }, () => {

            // const {  vpDayList,  vpOrgDayList } = this.state;        
            //     vpDayList[ind].dayChecked = checked
            //     vpOrgDayList[ind].dayChecked = checked            
            //     this.setState({ vpDayList:vpDayList,vpOrgDayList:vpOrgDayList,vpAllDayRetailerDisabled:true},()=>{
            //        //this.getVPRetailerList();
            //     });
            this.setState(prevState => {
                let { vpDayList, vpOrgDayList } = prevState;
                var dayId = vpDayList[ind].dayId;
                vpDayList = vpDayList.map(item => item.dayId === dayId ? { ...item, dayChecked: checked } : item);
                vpOrgDayList = vpOrgDayList.map(item => item.dayId === dayId ? { ...item, dayChecked: checked } : item);
                return { vpDayList, vpOrgDayList, vpAllDayRetailerDisabled: true };
            });
        });


    };

    vpDayRetailerCheckBoxChange = (e, ind) => {
        let checked = e.target.checked;
        // const {  vpDayRetailerList,  vpOrgDayRetailerList } = this.state;        
        //     vpDayRetailerList[ind].retailerChecked = checked
        //     vpOrgDayRetailerList[ind].retailerChecked = checked            
        //     this.setState({ vpDayRetailerList:vpDayRetailerList,vpOrgRetailerList:vpOrgDayRetailerList},()=>{
        //        //this.getVPRetailerList();
        //     });  
        this.setState(prevState => {
            let { vpDayRetailerList, vpOrgDayRetailerList } = prevState;
            var rCode = vpDayRetailerList[ind].retailerCode;
            vpDayRetailerList = vpDayRetailerList.map(item => item.retailerCode === rCode ? { ...item, retailerChecked: checked } : item);
            vpOrgDayRetailerList = vpOrgDayRetailerList.map(item => item.retailerCode === rCode ? { ...item, retailerChecked: checked } : item);
            return { vpDayRetailerList, vpOrgDayRetailerList };
        });
    };

    vpState() {
        if (this.state.vpSalesPerson !== undefined && this.state.vpVistPlan !== undefined) {
            this.setState(
                {
                    vpStateList: [],
                    vpCityList: [],
                    vpRouteList: [],
                    vpRetailerList: [],
                    vpOrgStateList: [],
                    vpOrgCityList: [],
                    vpOrgRouteList: [],
                    vpOrgRetailerList: [],
                },
                () => {
                    this.getStateList();
                });
        }
    }

    getStateList() {
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/visitplan/getStateList")
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        vpStateList: data.stateList,
                        vpOrgStateList: data.stateList
                    }, () => {
                        this.vpSavedList();
                    });
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getSavedStateList() {
        this.setState({
            vpStateList: [],
            vpCityList: [],
            vpRouteList: [],
            vpRetailerList: [],
            vpOrgSalesmanList: [],
            vpOrgStateList: [],
            vpOrgCityList: [],
            vpOrgRouteList: [],
            vpOrgRetailerList: [],
            vpSavedStateList: [],
            vpSavedCityList: [],
            vpSavedRouteList: [],
            vpSavedRetailerList: [],
        });
        this.vpRouteCheckBoxClear();
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/visitplan/getStateList")
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        vpStateList: data.stateList,
                        vpOrgStateList: data.stateList
                    });
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getVPCityList() {

        this.setState({
            vpCityList: [],
            vpRouteList: [],
            vpRetailerList: [],
            vpOrgCityList: [],
            vpOrgRouteList: [],
            vpOrgRetailerList: [],
            vpAllCityChecked: false,
            vpAllRouteChecked: false,
            vpAllRetailerChecked: false,
            vpAllRouteDisabled: false,
            vpAllRetailerDisabled: false
        });

        var stateFlag = false;
        var stateList = this.state.vpStateList;
        stateList.map(function (nc, i) {
            if (nc.stateChecked === true) {
                stateFlag = true;
            }
        });
        if (stateFlag) {
            let params = {
                "salesmanCode": this.state.vpSalesPerson,
                "date": this.state.vpDate,
                "stateList": this.state.vpStateList,
                "companyCode": this.state.companyCode,
            }

            this.setState({
                loading: true,
            });
            axios.post(this.state.httpURL + "/visitplan/getCityList", params)
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.setState({
                            vpCityList: data.cityList,
                            vpOrgCityList: data.cityList,
                            vpAllCityChecked: data.cityAllChecked
                        }, () => {
                            // if(data.savedCityList !== null &&  data.savedCityList !== 0){
                            //     this.loadSavedCityList();
                            // }
                        });
                        ReactTooltip.rebuild();
                    } else {
                        alert(data.responseMsg);
                    }
                    this.setState({
                        loading: false
                    });
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    getVPRouteList() {

        this.setState({
            vpRouteList: [],
            vpRetailerList: [],
            vpOrgRouteList: [],
            vpOrgRetailerList: [],
            vpAllRetailerChecked: false,
            vpAllRouteDisabled: false,
            vpAllRetailerDisabled: false
        });

        let params = {
            "salesmanCode": this.state.vpSalesPerson,
            "date": this.state.vpDate,
            "companyCode": this.state.companyCode,
            "stateList": this.state.vpStateList,
            "cityList": this.state.vpCityList
        }

        this.setState({
            loading: true
        });

        axios.post(this.state.httpURL + "/visitplan/getRouteList", params)
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        vpRouteList: data.routeList,
                        vpOrgRouteList: data.routeList,
                        vpAllRouteChecked: data.routeAllChecked
                    }, () => {
                        // if(data.savedRouteList !== null &&  data.savedRouteList !== 0){
                        //     this.loadSavedRteList();
                        // }
                    });
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getVPRetailerList() {

        if (this.state.vpDate === undefined) {
            alert("Please Select Date");
            return false;
        }

        this.setState({
            vpRetailerList: [],
            vpOrgRetailerList: [],
            vpAllRetailerDisabled: false,
            vpAllDayRetailerDisabled: false
        });

        let params = {};

        if (this.state.vpVistPlan === 2) {
            params = {
                "companyCode": this.state.companyCode,
                "salesmanCode": this.state.vpSalesPerson,
                "vistPlan": this.state.vpVistPlan,
                "routeList": this.state.vpRouteList,
                "date": this.state.vpDate,
                "salesmanName": this.state.vpSalesPersonName
            }
        } else {
            params = {
                "companyCode": this.state.companyCode,
                "salesmanCode": this.state.vpSalesPerson,
                "vistPlan": this.state.vpVistPlan,
                "date": this.state.vpDate,
                "dayList": this.state.vpDayList,
                "salesmanName": this.state.vpSalesPersonName
            }
            this.setState({
                vpAllDayRetailerDisabled: false
            });

        }

        this.setState({
            loading: true
        });

        axios.post(this.state.httpURL + "/visitplan/getRetailerList", params)
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    if (this.state.vpVistPlan === 2) {
                        if (data.retailerResponceMsg !== null && data.retailerResponceMsg !== undefined && data.retailerResponceMsg !== "") {
                            alert(data.retailerResponceMsg);
                        } else {
                            this.setState({
                                vpRetailerList: data.retailerList,
                                vpOrgRetailerList: data.retailerList,
                                vpSavedRetailerList: data.savedRetailerList,
                                vpAllRetailerChecked: data.retailerAllChecked
                            })
                            // if(data.savedRetailerList !== null &&  data.savedRetailerList !== 0 && data.retailerList !== null &&  data.retailerList !== 0){
                            //     this.loadRetailerList();
                            // }
                        }
                    } else {
                        if (data.retailerResponceMsg !== null && data.retailerResponceMsg !== undefined && data.retailerResponceMsg !== "") {
                            alert(data.retailerResponceMsg);
                        } else {
                            this.setState({
                                vpDayRetailerList: data.retailerList,
                                vpOrgDayRetailerList: data.retailerList,
                                vpSavedRetailerList: data.savedRetailerList,
                                vpAllDayRetailerChecked: data.dayRetailerAllChecked
                            }, () => {
                                ReactTooltip.rebuild();
                            })
                            // if(data.savedRetailerList !== null &&  data.savedRetailerList !== 0 && data.retailerList !== null &&  data.retailerList !== 0){
                            //     this.loadRetailerList();
                            // }
                        }
                    }
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    vpSavedList() {

        if (this.state.vpVistPlan === 1) {
            // this.vpDayRouteCheckBoxClear();
            this.vpDayClear();
        } else {
            this.vpRouteCheckBoxClear();
        }

        let params = {
            "companyCode": this.state.companyCode,
            "salesmanCode": this.state.vpSalesPerson,
            "date": this.state.vpDate
        }

        this.setState({
            loading: true
        });

        axios.post(this.state.httpURL + "/visitplan/getSavedVPList", params)
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    if (data.routeMode === 0) {
                        if (this.state.vpVistPlan === 1) {
                            //this.vpDayClear();
                            //this.vpDayRouteClear();	
                        } else {
                            this.getSavedStateList();
                        }
                    } else {
                        if (data.routeMode === 1) {
                            this.setState({
                                vpDayList: data.dayList,
                                vpOrgDayList: data.dayList,
                                vpDayRetailerList: data.retailerList,
                                vpOrgDayRetailerList: data.retailerList,
                                vpVistPlan: data.routeMode,
                                vpAllDayRetailerChecked: data.dayRetailerAllChecked
                            }, () => {
                                ReactTooltip.rebuild();
                            })
                            document.querySelector('.VisitPlanRoute_Container').style.display = 'block';
                            document.querySelector('.VisitPlandayRoute_Container').style.display = 'none';
                        } else if (data.routeMode === 2) {
                            this.setState({
                                vpStateList: data.stateList,
                                vpCityList: data.cityList,
                                vpRouteList: data.routeList,
                                vpRetailerList: data.retailerList,
                                vpOrgStateList: data.stateList,
                                vpOrgCityList: data.cityList,
                                vpOrgRouteList: data.routeList,
                                vpOrgRetailerList: data.retailerList,
                                vpSavedStateList: data.savedStateList,
                                vpSavedCityList: data.savedCityList,
                                vpSavedRouteList: data.savedRouteList,
                                vpSavedRetailerList: data.savedRetailerList,
                                vpVistPlan: data.routeMode,
                                vpAllStateChecked: data.stateAllChecked,
                                vpAllCityChecked: data.cityAllChecked,
                                vpAllRouteChecked: data.routeAllChecked,
                                vpAllRetailerChecked: data.retailerAllChecked,
                            })
                            document.querySelector('.VisitPlandayRoute_Container').style.display = 'block';
                            document.querySelector('.VisitPlanRoute_Container').style.display = 'none';
                            // this.loadSavedRouteList();                      
                        }
                    }
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    // loadSavedCityList(){
    //     var cList = this.state.vpCityList;
    //     var scList = this.state.vpSavedCityList;
    //     cList.map(function (nc, i) {           
    //         scList.map(function (ncsd, i) {
    //             if (ncsd.city === nc.city) {
    //                 nc.cityChecked = true;
    //             }
    //         });
    //     });
    // }

    // loadSavedRteList(){
    //     var rList = this.state.vpRouteList;
    //     var srList = this.state.vpSavedRouteList;
    //     rList.map(function (rl, i) {           
    //         srList.map(function (srl, i) {
    //             if (rl.routeId === srl.routeId) {
    //                 rl.routeChecked = true;
    //             }
    //         });
    //     });
    // }

    // loadRetailerList(){
    //     if(this.state.vpVistPlan === 2){
    //         var rList = this.state.vpRetailerList;
    //         var srList = this.state.vpSavedRetailerList;
    //         rList.map(function (nc, i) {           
    //             srList.map(function (ncsd, i) {
    //                 if (ncsd.retailerCode === nc.retailerCode) {
    //                     nc.retailerChecked = true;
    //                 }
    //             });
    //         });
    //     } else {
    //         var rList = this.state.vpDayRetailerList;
    //         var srList = this.state.vpSavedRetailerList;
    //         rList.map(function (nc, i) {           
    //             srList.map(function (ncsd, i) {
    //                 if (ncsd.retailerCode === nc.retailerCode) {
    //                     nc.retailerChecked = true;
    //                 }
    //             });
    //         });
    //     }

    // }   

    // loadSavedRouteList(){
    //     var sList = this.state.vpStateList;
    //     var ssList = this.state.vpSavedStateList;
    //     sList.map(function (sl, i) {           
    //         ssList.map(function (ssl, i) {
    //             if (sl.state === ssl.state) {
    //                 sl.stateChecked = true;
    //             }
    //         });
    //     });
    //     var cList = this.state.vpCityList;
    //     var scList = this.state.vpSavedCityList;
    //     cList.map(function (cl, i) {           
    //         scList.map(function (scl, i) {
    //             if (cl.city === scl.city) {
    //                 cl.cityChecked = true;
    //             }
    //         });
    //     });
    //     var rList = this.state.vpRouteList;
    //     var srList = this.state.vpSavedRouteList;
    //     rList.map(function (rl, i) {           
    //         srList.map(function (srl, i) {
    //             if (rl.routeId === srl.routeId) {
    //                 rl.routeChecked = true;
    //             }
    //         });
    //     });
    //     var reList = this.state.vpRetailerList;
    //     var sreList = this.state.vpSavedRetailerList;
    //     reList.map(function (rel, i) {           
    //         sreList.map(function (srel, i) {
    //             if (rel.retailerCode === srel.retailerCode) {
    //                 rel.retailerChecked = true;
    //             }
    //         });
    //     });
    // }

    vpCityConfirm = () => {
        var cityFlag = false;
        var cityList = this.state.vpCityList;
        cityList.map(function (nc, i) {
            if (nc.cityChecked === true) {
                cityFlag = true;
            }
        });
        if (cityFlag) {
            this.getVPRouteList();
        } else {
            alert("Please select city");
            this.setState({
                vpAllRouteChecked: false,
                vpAllRetailerChecked: false,
                vpRouteList: [],
                vpOrgRouteList: [],
                vpRetailerList: [],
                vpOrgRetailerList: [],
            });
        }
    }

    vpRouteConfirm = () => {
        var routeFlag = false;
        var routeList = this.state.vpRouteList;
        routeList.map(function (nc, i) {
            if (nc.routeChecked === true) {
                routeFlag = true;
            }
        });
        if (routeFlag) {
            this.getVPRetailerList();
        } else {
            alert("Please select route");
            this.setState({
                vpAllRetailerChecked: false,
                vpRetailerList: [],
                vpOrgRetailerList: [],
            });
        }
    }

    vpDayConfirm = () => {
        var dayFlag = false;
        var dayList = this.state.vpDayList;
        dayList.map(function (nc, i) {
            if (nc.dayChecked === true) {
                dayFlag = true;
            }
        });
        if (this.state.vpSalesPerson !== '' && this.state.vpSalesPerson !== undefined) {
            if (dayFlag) {
                this.setState({
                    vpAllDayRetailerChecked: false,
                    vpDayRetailerList: [],
                    vpOrgDayRetailerList: [],
                }, () => {
                    this.getVPRetailerList();
                });
            } else {
                alert("Please select day");
                this.setState({
                    vpAllDayRetailerChecked: false,
                    vpDayRetailerList: [],
                    vpOrgDayRetailerList: [],
                });
            }
        } else {
            alert("Please select sales person");
        }
    }

    vpDelete = () => {
        this.vpDeleteValidation();
    }

    vpGo = () => {
        if (this.state.vpSalesPerson !== '' && this.state.vpSalesPerson !== undefined) {
            if (this.state.vpDate !== "" && this.state.vpDate !== undefined) {
                this.vpSavedList();
            } else {
                alert("Please select day");
            }
        } else {
            alert("Please select sales person");
        }
    }

    vpSaveValidation = () => {

        if (this.state.vpVistPlan === 1) {
            var dayFlag = false;
            var retailerFlag = false;
            var dayList = this.state.vpDayList;
            var retailerList = this.state.vpDayRetailerList;
            var day = "";
            var weekdayFlag = true;
            var dayId = "";
            let weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date(this.state.vpDate).getDay()];
            if (dayList !== undefined) {
                dayList.map(function (nc, i) {
                    if (nc.dayChecked === true) {
                        dayFlag = true;
                        day = nc.dayName;
                        dayId = nc.dayId;
                    }
                });
            }
            this.setState({
                vpSelectedDay: dayId
            }, () => {
                if (retailerList !== undefined) {
                    retailerList.map(function (nc, i) {
                        if (nc.retailerChecked === true) {
                            retailerFlag = true;
                        }
                    });
                }

                if (this.state.vpSalesPerson !== "" && this.state.vpSalesPerson !== undefined) {
                    if (this.state.vpVistPlan !== "" && this.state.vpVistPlan !== undefined) {
                        if (this.state.vpDate !== "" && this.state.vpDate !== undefined) {
                            if (dayFlag) {
                                if (retailerFlag && this.state.vpAllDayRetailerDisabled === false) {

                                    if (this.state.vpDate >= this.state.todaydate) {
                                        if (day !== weekday) {
                                            var answer = window.confirm("selected day does not match with date. Are you sure to save?");
                                            if (answer) {
                                                this.vpSave();
                                            }
                                        } else {
                                            this.vpSave();
                                        }

                                    } else {
                                        alert("Cannot Save Previous Date Records");
                                        return false;
                                    }

                                } else {
                                    alert("Please Select Retailer");
                                    return false;
                                }
                            } else {
                                alert("Please Select Day");
                                return false;
                            }
                        } else {
                            alert("Please Select Date");
                            return false;
                        }
                    } else {
                        alert("Please Select Visit Plan");
                        return false;
                    }
                } else {
                    alert("Please Select Sales Person");
                    return false;
                }
            });
        } else {
            var stateFlag = false;
            var cityFlag = false;
            var routeFlag = false;
            var retailerFlag = false;
            var sList = this.state.vpStateList;
            var cList = this.state.vpCityList;
            var routeList = this.state.vpRouteList;
            var retailerList = this.state.vpRetailerList;
            var tempRetailerCount = 0;
            if (sList !== undefined) {
                sList.map(function (nc, i) {
                    if (nc.stateChecked === true) {
                        stateFlag = true;
                    }
                });
            }
            if (cList !== undefined) {
                cList.map(function (nc, i) {
                    if (nc.cityChecked === true) {
                        cityFlag = true;
                    }
                });
            }
            if (routeList !== undefined) {
                routeList.map(function (nc, i) {
                    if (nc.routeChecked === true) {
                        routeFlag = true;
                    }
                });
            }
            if (retailerList !== undefined) {
                retailerList.map(function (nc, i) {
                    if (nc.retailerChecked === true) {
                        retailerFlag = true;
                        tempRetailerCount++;
                    }
                });
            }

            if (this.state.vpSalesPerson !== "" && this.state.vpSalesPerson !== undefined) {
                if (this.state.vpVistPlan !== "" && this.state.vpVistPlan !== undefined) {
                    if (this.state.vpDate !== "" && this.state.vpDate !== undefined) {
                        if (stateFlag) {
                            if (cityFlag) {
                                if (routeFlag && this.state.vpAllRouteDisabled === false) {
                                    if (retailerFlag && this.state.vpAllRetailerDisabled === false) {
                                        if (tempRetailerCount <= this.state.vpMaxRetailerAllowedCount) {
                                            if (this.state.vpDate >= this.state.todaydate) {
                                                this.vpSave();
                                            } else {
                                                alert("Cannot Save Previous Date Records");
                                                return false;
                                            }
                                        } else {
                                            alert("Selected Retailer Limit exceeds - Maximum (" + this.state.vpMaxRetailerAllowedCount + " Retailers)");
                                            return false;
                                        }
                                    } else {
                                        alert("Please Select Retailer");
                                        return false;
                                    }
                                } else {
                                    alert("Please Select Route");
                                    return false;
                                }
                            } else {
                                alert("Please Select City");
                                return false;
                            }
                        } else {
                            alert("Please Select State");
                            return false;
                        }
                    } else {
                        alert("Please Select Date");
                        return false;
                    }
                } else {
                    alert("Please Select Visit Plan");
                    return false;
                }
            } else {
                alert("Please Select Sales Person");
                return false;
            }
        }

    }

    vpSave() {

        let params = {};
        if (this.state.vpVistPlan === 1) {
            params = {
                "companyCode": this.state.companyCode,
                "salesmanCode": this.state.vpSalesPerson,
                "userName": this.props.userObj.userName,
                "selectedDay": this.state.vpSelectedDay,
                "vistPlan": this.state.vpVistPlan,
                "date": this.state.vpDate,
                "dayList": this.state.vpDayList,
                "retailerList": this.state.vpDayRetailerList
            }
        } else {
            params = {
                "companyCode": this.state.companyCode,
                "salesmanCode": this.state.vpSalesPerson,
                "userName": this.props.userObj.userName,
                "vistPlan": this.state.vpVistPlan,
                "date": this.state.vpDate,
                "stateList": this.state.vpStateList,
                "cityList": this.state.vpCityList,
                "routeList": this.state.vpRouteList,
                "retailerList": this.state.vpRetailerList
            }
        }

        this.setState({
            loading: true
        });

        axios.post(this.state.httpURL + "/visitplan/saveRouteList", params)
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    if (this.state.vpVistPlan === 1) {
                        if (data.responseMsg === null) {
                            alert("Route Saved Successfully.");
                        } else {
                            alert(data.responseMsg);
                        }
                        this.vpDayClear()
                        this.vpDayRouteClear();
                    } else {
                        if (data.responseMsg === null) {
                            alert("Route Saved Successfully.");
                        } else {
                            alert(data.responseMsg);
                        }
                        this.vpRouteClear();
                    }
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    vpDeleteValidation() {
        var deleteFlag = false;
        var deleteRetailerFlag = false;
        var deleteDayRetailerFlag = false;
        if (this.state.vpSalesPerson !== "" && this.state.vpSalesPerson !== undefined) {
            if (this.state.vpVistPlan !== "" && this.state.vpVistPlan !== undefined) {
                if (this.state.vpDate !== "" && this.state.vpDate !== undefined) {
                    if (this.state.vpVistPlan === 1) {
                        var retailerList = this.state.vpDayRetailerList;
                        retailerList.map(function (nc, i) {
                            if (nc.retailerChecked === true) {
                                deleteDayRetailerFlag = true;
                            }
                        });
                        if (deleteDayRetailerFlag) {
                            // if(this.state.vpDate > this.state.todaydate){                        
                            //     var answer =  window.confirm("Are Sure To Delete This Route?");
                            //     if(answer){
                            //         deleteFlag = true;
                            //     }
                            // } else {
                            //     alert("Can Delete Future Date Records Only");
                            //     return false;
                            // }
                            var answer = window.confirm("Are Sure To Delete This Route?");
                            if (answer) {
                                deleteFlag = true;
                            }
                        } else {
                            alert("Please select retailer");
                            return false;
                        }
                    } else {
                        var retailerList = this.state.vpRetailerList;
                        retailerList.map(function (nc, i) {
                            if (nc.retailerChecked === true) {
                                deleteRetailerFlag = true;
                            }
                        });
                        if (deleteRetailerFlag) {
                            // if(this.state.vpDate > this.state.todaydate){                        
                            //     var answer =  window.confirm("Are Sure To Delete This Route?");
                            //     if(answer){
                            //         deleteFlag = true;
                            //     }
                            // } else {
                            //     alert("Can Delete Future Date Records Only");
                            //     return false;
                            // }
                            var answer = window.confirm("Are Sure To Delete This Route?");
                            if (answer) {
                                deleteFlag = true;
                            }
                        } else {
                            alert("Please select retailer");
                            return false;
                        }
                    }
                } else {
                    alert("Please Select Date");
                    return false;
                }
            } else {
                alert("Please Select Visit Plan");
                return false;
            }
        } else {
            alert("Please Select Sales Person");
            return false;
        }
        //this.vpDelete();
        if (deleteFlag) {
            let params = {
                "companyCode": this.state.companyCode,
                "salesmanCode": this.state.vpSalesPerson,
                "userName": this.props.userObj.userName,
                "vistPlan": this.state.vpVistPlan,
                "date": this.state.vpDate,
            }

            this.setState({
                loading: true
            });

            axios.post(this.state.httpURL + "/visitplan/deleteVPList", params)
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        if (this.state.vpVistPlan === 1) {
                            if (data.responseMsg === null) {
                                alert("Route Delete Successfully.");
                            } else {
                                alert(data.responseMsg);
                            }
                            this.vpDayClear()
                            this.vpDayRouteClear();
                        } else {
                            if (data.responseMsg === null) {
                                alert("Route Delete Successfully.");
                            } else {
                                alert(data.responseMsg);
                            }
                            this.vpRouteClear();
                        }
                        ReactTooltip.rebuild();
                    } else {
                        alert(data.responseMsg);
                    }
                    this.setState({
                        loading: false
                    });
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    vpDelete() {
        let params = {
            "companyCode": this.state.companyCode,
            "salesmanCode": this.state.vpSalesPerson,
            "userName": this.props.userObj.userName,
            "vistPlan": this.state.vpVistPlan,
            "date": this.state.vpDate,
        }

        this.setState({
            loading: true
        });

        axios.post(this.state.httpURL + "/visitplan/deleteVPList", params)
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({

                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    vpDayRouteClear() {
        let vpCDayList = this.state.vpConstantDayList;
        this.setState({
            vpDayRetailerList: [],
            vpOrgDayRetailerList: [],
            vpSavedRetailerList: [],
            vpSalesPerson: '',
            vpDayList: vpCDayList,
            vpOrgDayList: vpCDayList,
        });
        this.vpDayRouteCheckBoxClear();
    }

    vpRouteClear() {
        this.setState({
            vpSalesPerson: '',
            vpStateList: [],
            vpCityList: [],
            vpRouteList: [],
            vpRetailerList: [],
            vpOrgSalesmanList: [],
            vpOrgStateList: [],
            vpOrgCityList: [],
            vpOrgRouteList: [],
            vpOrgRetailerList: [],
            vpSavedStateList: [],
            vpSavedCityList: [],
            vpSavedRouteList: [],
            vpSavedRetailerList: [],
        });
        this.vpRouteCheckBoxClear();
    }

    vpDayRouteCheckBoxClear() {
        this.setState({
            vpAllDayRetailerChecked: false,
            vpAllDayChecked: false,
            vpAllDayRetailerDisabled: false
        });
    }

    vpRouteCheckBoxClear() {
        this.setState({
            vpAllStateChecked: false,
            vpAllCityChecked: false,
            vpAllRouteChecked: false,
            vpAllRetailerChecked: false,
            vpAllRouteDisabled: false,
            vpAllRetailerDisabled: false
        });
    }

    vpDayClear() {
        let vpCDayList = this.state.vpConstantDayList;
        this.setState({
            vpDayRetailerList: [],
            vpOrgDayRetailerList: [],
            vpSavedRetailerList: [],
            vpAllDayRetailerDisabled: false,
            vpDayList: vpCDayList,
            vpOrgDayList: vpCDayList,
        });
        // this.setState(prevState => {
        //     let { vpDayList, vpOrgDayList } = prevState;
        //     vpDayList = vpDayList.map(item => ({ ...item, dayChecked: false }));
        //     vpOrgDayList = vpOrgDayList.map(item => ({ ...item, dayChecked: false }));
        //     return { vpDayList, vpOrgDayList };
        // },()=>{            
        //     this.setState({                                        
        //          vpDayRetailerList : [],                                                    
        //          vpOrgDayRetailerList : [],            
        //          vpSavedRetailerList : [],
        //          vpAllDayRetailerDisabled : false               
        //      });
        // });
    }

    vpStatefilterList = () => {
        let filtered = this.state.vpOrgStateList;
        let inputFields = document.getElementsByClassName('maingridState');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {
                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name
                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));
            }
        }
        this.setState({
            vpStateList: filtered
        })
    }

    vpCityfilterList = () => {
        let filtered = this.state.vpOrgCityList;
        let inputFields = document.getElementsByClassName('maingridCity');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {
                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name
                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));
            }
        }
        this.setState({
            vpCityList: filtered
        })
    }

    vpRoutefilterList = () => {
        let filtered = this.state.vpOrgRouteList;
        let inputFields = document.getElementsByClassName('maingridRoute');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {
                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name
                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));
            }
        }
        this.setState({
            vpRouteList: filtered
        })
    }

    vpRetailerfilterList = () => {
        let filtered = this.state.vpOrgRetailerList;
        let inputFields = document.getElementsByClassName('maingridRetailer');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {
                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name
                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));
            }
        }
        this.setState({
            vpRetailerList: filtered
        })
    }

    vpDayFilterList = () => {
        let filtered = this.state.vpOrgDayList;
        let inputFields = document.getElementsByClassName('maingridDay');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {
                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name
                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));
            }
        }
        this.setState({
            vpDayList: filtered
        })
    }

    vpDayRetailerFilterList = () => {
        let filtered = this.state.vpOrgDayRetailerList;
        let inputFields = document.getElementsByClassName('maingridDayRetailer');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {
                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name
                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));
            }
        }
        this.setState({
            vpDayRetailerList: filtered
        })
    }


    render() {
        return (

            <div className="O2Container_Wrapper">
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>VISIT PLAN</span></div>

                    <div className="O2Table_SubContainer VisitPlanSubContainer">
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Visit Plan</div>
                            <div className="contentRow_Value">
                                <Select options={this.state.vpRetailerModeList} value={this.state.vpRetailerModeList.filter(({ value }) => value === this.state.vpVistPlan)} placeholder="Choose Visit Plan" onChange={(e) => this.handleOptionChange(e)} />
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Sales Person</div>
                            <div className="contentRow_Value">
                                <Select options={this.state.vpSalesmanList} value={this.state.vpSalesmanList.filter(({ value }) => value === this.state.vpSalesPerson)} placeholder="Choose Sales Person" onChange={this.vpSalesPersonChange} />
                            </div>
                        </div>
                        {/* <div className="O2Content_row">
                                <div className="contentRow_Label O2TablecheckboxLabel">Day + Route</div>
                                <div className="contentRow_Value O2TablecheckboxValue">
                                <input type="radio"/>  
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label O2TablecheckboxLabel">Route</div>
                                <div className="contentRow_Value O2TablecheckboxValue">
                                <input type="radio"/>  
                                </div>
                            </div>  */}
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Date</div>
                            <div className="contentRow_Value">
                                <input type="date" name="vpDate" value={this.state.vpDate} onChange={this.vpDateChange} />
                            </div>
                        </div>
                        <div className="O2Table_Go" onClick={(e) => this.vpGo()}><button>GO</button></div>
                    </div>
                    <div className="O2Content_Container VisitPlanContainer">
                        <div className="VisitPlandayRoute_Container">
                            <div className="VPContent_wrapper">
                                <div className="Table_wrapper">
                                    <table className="customTable visitPlan_Statetable" >
                                        <thead>
                                            <tr>
                                                <th className="VPcheckBox_Container">
                                                    <input type="checkbox" name="allStateCheckBox" checked={this.state.vpAllStateChecked} onChange={this.vpALLStateCheckBoxChange} />
                                                </th>
                                                <th className="VisitStateWid">
                                                    State
                                                    <div className="searchWrapper">
                                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                        <input type="text" className="tableMultiFilterSearch_input maingridState" name="state" onChange={this.vpStatefilterList} />
                                                    </div>
                                                    {/* <div className="selectWrapper">
                                                            <Select   className="tableMultiFilterSearch_input"  placeholder="Select State" /> 
                                                        </div> */}
                                                </th>
                                                <th className="scroller"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !this.state.vpStateList || this.state.vpStateList === null || this.state.vpStateList.length === 0 ?
                                                    <tr align="center">
                                                        <td colSpan="5"></td>
                                                    </tr> :
                                                    this.state.vpStateList.map((state, i) => (
                                                        <tr key={state.state}>
                                                            <td className="VPcheckBox_Container">
                                                                <input type="checkbox" checked={state.stateChecked} onChange={(e) => this.vpStateCheckBoxChange(e, i)} />
                                                            </td>
                                                            <td className="">
                                                                {state.state}
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="Table_wrapper">
                                    <table className="customTable visitPlan_Citytable" >
                                        <thead>
                                            <tr>
                                                <th className="VPcheckBox_Container">
                                                    <input type="checkbox" name="allCityCheckBox" checked={this.state.vpAllCityChecked} onChange={this.vpALLCityCheckBoxChange} />
                                                </th>
                                                <th className="VisitStateWid">
                                                    City
                                                    <button className="O2Table_headBtn" onClick={this.vpCityConfirm}>Confirm</button>
                                                    <div className="searchWrapper">
                                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                        <input type="text" className="tableMultiFilterSearch_input maingridCity" name="city" onChange={this.vpCityfilterList} />
                                                    </div>
                                                    {/* <div className="selectWrapper">
                                                            <Select   className="tableMultiFilterSearch_input"  placeholder="Select City" /> 
                                                        </div> */}
                                                </th>
                                                <th className="scroller"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr>
                                                    <td className="checkBox_Container">
                                                        <input type="checkbox"/>   
                                                    </td> 
                                                    <td className="VisitStateWid">
                                                       Coimbatore
                                                    </td>
                                                </tr> */}
                                            {
                                                !this.state.vpCityList || this.state.vpCityList === null || this.state.vpCityList.length === 0 ?
                                                    <tr align="center">
                                                        <td colSpan="5"></td>
                                                    </tr> :
                                                    this.state.vpCityList.map((city, i) => (
                                                        <tr key={city.city}>
                                                            <td className="VPcheckBox_Container">
                                                                <input type="checkbox" checked={city.cityChecked} onChange={(e) => this.vpCityCheckBoxChange(e, i)} />
                                                            </td>
                                                            <td className="">
                                                                {city.city}
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="Table_wrapper">
                                    <table className="customTable visitPlan_Routtable" >
                                        <thead>
                                            <tr>
                                                <th className="VPcheckBox_Container">
                                                    <input type="checkbox" name="allRouteCheckBox" disabled={this.state.vpAllRouteDisabled} checked={this.state.vpAllRouteChecked} onChange={this.vpALLRouteCheckBoxChange} />
                                                </th>
                                                <th className="VisitStateWid">
                                                    Route
                                                    <button className="O2Table_headBtn" onClick={this.vpRouteConfirm}>Confirm</button>
                                                    <div className="searchWrapper">
                                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                        <input type="text" className="tableMultiFilterSearch_input maingridRoute" name="routeName" onChange={this.vpRoutefilterList} />
                                                    </div>
                                                    {/* <div className="selectWrapper">
                                                            <Select   className="tableMultiFilterSearch_input"  placeholder="Select Route" />
                                                        </div> */}
                                                </th>
                                                <th className="scroller"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr>
                                                    <td className="checkBox_Container">
                                                        <input type="checkbox"/>   
                                                    </td> 
                                                    <td className="VisitStateWid">
                                                       Saravanampatti
                                                    </td>
                                                </tr> */}
                                            {
                                                !this.state.vpRouteList || this.state.vpRouteList === null || this.state.vpRouteList.length === 0 ?
                                                    <tr align="center">
                                                        <td colSpan="5"></td>
                                                    </tr> :
                                                    this.state.vpRouteList.map((route, i) => (
                                                        <tr key={route.routeId}>
                                                            <td className="VPcheckBox_Container">
                                                                <input type="checkbox" disabled={route.isDisabled} checked={route.routeChecked} onChange={(e) => this.vpRouteCheckBoxChange(e, i)} />
                                                            </td>
                                                            <td className="">
                                                                {route.routeName}
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="Table_wrapper">
                                    <table className="customTable visitPlan_Rettable" >
                                        <thead>
                                            <tr>
                                                <th className="VPcheckBox_Container">
                                                    <input type="checkbox" name="allRetailerCheckBox" disabled={this.state.vpAllRetailerDisabled} checked={this.state.vpAllRetailerChecked} onChange={this.vpALLRetailerCheckBoxChange} />
                                                </th>
                                                <th className="VisitStateWid">
                                                    Retailer
                                                    <button className="O2Table_headBtn" onClick={this.vpDelete}>Delete All</button>
                                                    <div className="searchWrapper">
                                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                        <input type="text" className="tableMultiFilterSearch_input maingridRetailer" name="name" onChange={this.vpRetailerfilterList} />
                                                    </div>
                                                    {/* <div className="selectWrapper">
                                                            <Select   className="tableMultiFilterSearch_input"  placeholder="Select Retailer" />
                                                        </div>  */}
                                                </th>
                                                <th className="scroller"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr>
                                                    <td className="checkBox_Container">
                                                        <input type="checkbox"/>   
                                                    </td> 
                                                    <td className="VisitStateWid">
                                                        RP Traders
                                                    </td>
                                                </tr> */}
                                            {
                                                !this.state.vpRetailerList || this.state.vpRetailerList === null || this.state.vpRetailerList.length === 0 ?
                                                    <tr align="center">
                                                        <td colSpan="5"></td>
                                                    </tr> :
                                                    this.state.vpRetailerList.map((retailer, i) => (
                                                        <tr key={retailer.retailerCode}>
                                                            <td className="VPcheckBox_Container">
                                                                <input type="checkbox" disabled={retailer.isDisabled} checked={retailer.retailerChecked} onChange={(e) => this.vpRetailerCheckBoxChange(e, i)} />
                                                            </td>
                                                            <td className="">
                                                                {retailer.name}
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>
                                                    <span className="GridTotalRecords"> Total Retailers : <span> {this.state.vpRetailerList ? this.state.vpRetailerList.length : 0} </span></span>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div className="VisitPlanRoute_Container">
                            <div className="VPContent_wrapper">
                                <div className="Table_wrapper">
                                    <table className="customTable visitPlan_Daytable" >
                                        <thead>
                                            <tr>
                                                <th className="VPcheckBox_Container">
                                                    {/* <input type="checkbox" name="allDayCheckBox" onChange={this.vpALLDayCheckBoxChange}/>    */}
                                                </th>
                                                <th className="VisitStateWid">

                                                    Day
                                                    <button className="O2Table_headBtn" onClick={(e) => this.vpDayConfirm()}>Confirm</button>
                                                    <div className="searchWrapper">
                                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                        <input type="text" className="tableMultiFilterSearch_input maingridDay" name="dayName" onChange={this.vpDayFilterList} />
                                                    </div>
                                                    {/* <div className="selectWrapper">
                                                    <Select   className="tableMultiFilterSearch_input" placeholder="Select Day" /> 
                                                </div> */}
                                                </th>

                                                <th className="scroller"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {/* <td className="checkBox_Container">
                                                <input type="checkbox"/>   
                                            </td>
                                            <td className="VisitStateWid">
                                                Sunday 
                                            </td> */}
                                                {
                                                    !this.state.vpDayList || this.state.vpDayList === null || this.state.vpDayList.length === 0 ?
                                                        <tr align="center">
                                                            <td colSpan="5"></td>
                                                        </tr> :
                                                        this.state.vpDayList.map((day, i) => (
                                                            <tr key={day.dayId}>
                                                                <td className="VPcheckBox_Container">
                                                                    <input type="checkbox" checked={day.dayChecked} onChange={(e) => this.vpDayCheckBoxChange(e, i)} />
                                                                </td>
                                                                <td className="" data-tip={day.dayName}>
                                                                    {day.dayName}
                                                                </td>
                                                            </tr>
                                                        ))
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="Table_wrapper">
                                    <table className="customTable visitPlan_dayRettable" >
                                        <thead>
                                            <tr>
                                                <th className="VPcheckBox_Container">
                                                    <input type="checkbox" disabled={this.state.vpAllDayRetailerDisabled} name="allDayRetailerCheckBox" checked={this.state.vpAllDayRetailerChecked} onChange={this.vpALLDayRetailerCheckBoxChange} />
                                                </th>
                                                <th className="VisitStateWid">

                                                    Retailer
                                                    <button className="O2Table_headBtn" onClick={(e) => this.vpDelete()}>Delete All</button>
                                                    <div className="searchWrapper">
                                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                        <input type="text" className="tableMultiFilterSearch_input maingridDayRetailer" name="name" onChange={this.vpDayRetailerFilterList} />
                                                    </div>
                                                    {/* <div className="selectWrapper">
                                                   <Select   className="tableMultiFilterSearch_input" placeholder="Select Retailer" /> 
                                                </div> */}
                                                </th>
                                                <th className="scroller"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr>
                                            <td className="checkBox_Container">
                                                <input type="checkbox"/>   
                                            </td>
                                            <td className="VisitStateWid">
                                                RP Traders 
                                            </td>
                                        </tr> */}
                                            {
                                                !this.state.vpDayRetailerList || this.state.vpDayRetailerList === null || this.state.vpDayRetailerList.length === 0 ?
                                                    <tr align="center">
                                                        <td colSpan="5"></td>
                                                    </tr> :
                                                    this.state.vpDayRetailerList.map((retailer, i) => (
                                                        <tr key={retailer.retailerCode}>
                                                            <td className="VPcheckBox_Container">
                                                                <input type="checkbox" disabled={retailer.isDisabled} checked={retailer.retailerChecked} onChange={(e) => this.vpDayRetailerCheckBoxChange(e, i)} />
                                                            </td>
                                                            <td className="" data-tip={retailer.name}>
                                                                {retailer.name}
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>
                                                    <span className="GridTotalRecords"> Total Retailers : <span> {this.state.vpDayRetailerList ? this.state.vpDayRetailerList.length : 0} </span></span>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                        </div>


                    </div>

                    <div className="O2Table_Footer">
                        <button onClick={this.vpSaveValidation}>Save</button>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>

        )
    }
}
const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(VisitPlanModule)
//export default VisitPlanModule

