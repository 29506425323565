import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faTrashAlt, faEdit, faCloudUploadAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import './BaViewOrdermodule.css';
import Select from 'react-select';
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

const override1 = css`
  display: block;
  top: -235px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;

class BaViewOrdermodule extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            vbsSalesList: [],
            orgVBSSalesList: [],
            prevstateorderList: [],
            vbsSalesmanList: [],
            distributorList: [],
            vbsOrderObj: '',
            vbsSalesDetailsList: [],
            orderDetailsNewList: [],
            vbsRefNum: "",
            vbsOrdSalesmanCode: "",
            vbsSalesmanName: "",
            vbsRetailerCode: "",
            vbsRetailerName: "",
            distributorName: '',
            vbsOrderDTM: "",
            currentDate: todaydate,
            vbsProductsList: [],
            productCode: "",
            vbsTotAmount: "0",
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            vbsSalesmanCode: this.props.userObj.salesmanCode,
            vbsStartDate: todaydate,
            vbsEndDate: todaydate,
            allChecked: false,
            checkExported: false,
            exportedOrder: false,
            erpOrderNo: '',
            writeAccess: this.props.userObj.orderWriteAccess,
            orderStatus: 'All',
            erpExported: 'Y',
            orderIdForPrint: '',
            loginSalesmanCode: this.props.userObj.salesmanCode,
            vbsOrderType: "",
            orderTypeFilter: '',
            orderTypeList: [],
            vbsSalesDeleteList: [],
            vbsState: '',
            vbsStateList: [],
        }
    }

    componentDidMount() {
        this.getSalesmanAndStateList();
    }

    getSalesmanAndStateList = () => {
        const params = {
            companyCode: this.props.userObj.companyCode,
            salesmanCode: this.state.vbsSalesmanCode,
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/viewbasale/getSalesmanandStateList", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                            vbsSalesmanList: data.salesmanList,
                            vbsStateList: data.stateList,
                }, () => {
                    this.setState({
                        loading: false
                    })
                    this.getAllVBSOrders();
                })
            }).catch(err => {
                this.setState({
                    loading: false
                })
            })
    }

    getAllVBSOrders = () => {
        let stateVal = '';
        var dFlag = true;
        if (this.state.vbsStartDate !== '' && this.state.vbsEndDate !== '') {
            if (this.state.vbsStartDate > this.state.vbsEndDate) {
                alert("From Date Cannot Be Greater Than End Date");
                dFlag = false;
            }
        } else {
            alert("Please select Start and End Date...");
            dFlag = false;
        }
        if (this.state.vbsState === '' || this.state.vbsState === undefined) {
            stateVal = "All";
        } else {
            stateVal = this.state.vbsState;
        }
        if (dFlag) {
            const params = {
                companyCode: this.state.loginuserObj.companyCode,
                distributorCode: "All",
                salesmanCode: this.state.vbsSalesmanCode,
                retailerCode: "All",
                startDate: this.state.vbsStartDate,
                endDate: this.state.vbsEndDate,
                orderStatus: this.state.orderStatus,
                loginSalesmanCode: this.state.loginSalesmanCode,
                state: stateVal,
            }

            this.setState({
                loading: true
            })

            axios.get(this.state.httpURL + "/viewbasale/getBASalesList", { params })
                .then(response => response.data)
                .then((data) => {
                    // if (this.state.vbsSalesmanList.length === 0) {
                    //     this.setState({
                    //         vbsSalesmanList: data.salesmanList
                    //     })
                    // }
                    this.setState({
                        vbsSalesList: data.forBASalesDataList,
                        orgVBSSalesList: data.forBASalesDataList,
                    }, () => {
                        this.setState({
                            loading: false
                        })
                        ReactTooltip.rebuild();
                    })
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    })
                })
        }
    }

    handleVBSSalesmanChange = (event) => {
        this.setState({
            vbsSalesmanCode: event.target.value
        }, () => {
            this.getAllVBSOrders();
        });

    }

    VBSDateChange = (e) => {
        // console.log(e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    vbs_popup_openHandler = (order) => {
        this.setState({
            orderIdForPrint: order.id
        });
        const params = {
            orderId: order.id,
            salesmanCode: order.salesmanCode,
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/viewbasale/getBASalesDetailList", { params })
            .then(response => response.data)
            .then((data) => {
                //    console.log("data-->"+data.forOrderDetailsList)
                this.setState({
                    loading: false
                })
                if (order.isExported === "Y") {
                    this.setState({
                        exportedOrder: true
                    });
                } else {
                    this.setState({
                        exportedOrder: false
                    });
                }
                this.setState({
                    vbsSalesDetailsList: data.forBASalesDetailsList,
                    vbsOrderObj: order,
                    vbsRefNum: order.refNum,
                    vbsOrdSalesmanCode: order.salesmanCode,
                    vbsSalesmanName: order.salesmanName,
                    vbsRetailerCode: order.retailerCode,
                    vbsRetailerName: order.retailerName,
                    vbsOrderDTM: order.orderDTMStr,
                    //distributorName:order.distributorName,
                    //orderDetailsNewList:[],
                    vbsTotAmount: order.orderValue,
                    vbsProductsList: data.productList,
                    productDistributorList: data.productDistributorList,
                    // erpOrderNo:data.forOrderDetailsList[0].erpOrderNo,
                    // erpExported:order.isExported,
                    vbsOrderType: order.orderType,
                    vbsSalesDeleteList: [],
                }, () => {
                    ReactTooltip.rebuild();
                    document.querySelector('.ViewOrderTableEdit_pop').style.display = 'flex';
                })
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                })
                alert(err);
            })
    }

    vbsDetails_editHandler = (ind) => {
        const { vbsSalesDetailsList } = this.state;
        vbsSalesDetailsList[ind].readOnly = false
        vbsSalesDetailsList[ind].isDisSelectDisabled = false
        this.setState({ vbsSalesDetailsList: vbsSalesDetailsList })
    }

    vbsDetails_deleteHandler = (ind) => {
        var vbsSalesDetailsList = [...this.state.vbsSalesDetailsList];

        if (vbsSalesDetailsList[ind].savedProduct) {

            var emptyobj = {
                companyCode: vbsSalesDetailsList[ind].companyCode,
                retailerCode: vbsSalesDetailsList[ind].retailerCode,
                productCode: vbsSalesDetailsList[ind].productCode,
                quantity: vbsSalesDetailsList[ind].quantity,
                stock: vbsSalesDetailsList[ind].stock,
            }

            let vbsSalesDeleteListtemp = this.state.vbsSalesDeleteList
            vbsSalesDeleteListtemp.push(emptyobj)

            this.setState({ vbsSalesDeleteList: vbsSalesDeleteListtemp }, () => {
                // console.log("<-1->"+JSON.stringify(this.state.vbsSalesDeleteList));
            })

        }

        vbsSalesDetailsList.splice(ind, 1)
        this.setState({ vbsSalesDetailsList: vbsSalesDetailsList }, () => {
            this.calculateVBSTotalAmount();
        });
    }

    calculateVBSTotalAmount = () => {
        var totAmtt = 0;
        this.state.vbsSalesDetailsList.map((srd) => (
            totAmtt = totAmtt + parseFloat(srd.totRate)
        ));
        this.setState({ vbsTotAmount: totAmtt })
    }

    vbs_newRowHandler = () => {
        var newind = this.state.vbsSalesDetailsList.length + 1;
        var emptyobj = {
            ordind: newind,
            id: "9999" + newind,
            productCode: "",
            quantity: "0",
            quantityStr: "0",
            numberOfCases: "00.00",
            numberOfBoxes: "00.00",
            numberOfPacks: "00.00",
            numberOfPieces: "00.00",
            rate: "00.00",
            totRate: "00.00",
            readOnly: false,
            isSelectDisabled: false,
            taxPercentage: "0",
            status: "Y",
            stock: "0",
            lastUpdatedBy: this.state.loginuserObj.userName,
            ordersId: this.state.vbsOrderObj.id,
            retailerCode: this.state.vbsRetailerCode,
            savedProduct: false
        }

        let vbsSalesDetailsListtemp = this.state.vbsSalesDetailsList
        vbsSalesDetailsListtemp.push(emptyobj)

        this.setState({ vbsSalesDetailsList: vbsSalesDetailsListtemp }, () => {
            ReactTooltip.rebuild();
        })
    }

    handleVBSProductChange = (ind, event) => {
        const { vbsSalesDetailsList } = this.state;
        vbsSalesDetailsList[ind].productCode = event.value
        vbsSalesDetailsList[ind].productName = event.label
        vbsSalesDetailsList[ind].distributorCode = ''
        vbsSalesDetailsList[ind].rate = event.price
        vbsSalesDetailsList[ind].quantity = 0
        vbsSalesDetailsList[ind].stock = event.stock
        vbsSalesDetailsList[ind].productPiecesPerBox = event.piecesPerBox


        if (event.value !== undefined) {
            this.setState({ vbsSalesDetailsList: vbsSalesDetailsList }, () => {
                ReactTooltip.rebuild();
            });
        }
    }

    changeVBSQty = (e) => {
        let ivalue = e.target.value
        const { vbsSalesDetailsList } = this.state;
        const { id } = e.target;
        const re = /^\d{1,}(\.\d{0,2})?$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            let rate = vbsSalesDetailsList[id].rate;
            let productPiecesPerBox = vbsSalesDetailsList[id].productPiecesPerBox;
            let newamt = ivalue * rate;
            let nb = parseInt(ivalue / productPiecesPerBox);
            let np = ivalue % productPiecesPerBox;
            vbsSalesDetailsList[id].quantityStr = ivalue;
            vbsSalesDetailsList[id].totRate = newamt;
            vbsSalesDetailsList[id].numberOfPacks = nb;
            vbsSalesDetailsList[id].numberOfPieces = np;

            this.setState({ vbsSalesDetailsList: vbsSalesDetailsList }, () => {
                this.calculateVBSTotalAmount();
            });
        }
    }

    vbs_popupSaveHandler = () => {
        var uniqobj = this.getVBSUnique(this.state.vbsSalesDetailsList, 'productCode');

        if (this.state.vbsSalesDetailsList.length === 0) {
            alert("Please Add Product For Save");
            return false;
        }

        if (this.checkVBSProductisFilled()) {
            alert("Enter All Product Details");
            return false;
        }

        if (this.checkVBSQtyisFilled()) {
            alert("Enter All Quantity Details");
            return false;
        }

        if (this.state.vbsSalesDetailsList.length !== uniqobj.length) {
            alert("Remove Duplicate Products");
            return false;
        }

        //console.log("<-1->"+JSON.stringify(this.state.vbsSalesDetailsList));

        this.setState({
            loading: true
        });

        axios.post(this.state.httpURL + "/viewbasale/updateSalesDetails", { "forBASalesDetailsList": this.state.vbsSalesDetailsList, "forBASalesDeleteList": this.state.vbsSalesDeleteList, "movedby": this.state.loginuserObj.userName })
            .then(res => {
                if (res.data.valid) {
                    this.setState({
                        loading: false
                    });
                    alert("Successfully Updated");
                    this.ViewOrder_popCloseHandler();
                    this.getAllVBSOrders()
                } else {
                    this.setState({
                        loading: false
                    });
                    alert(res.data.responseMsg);
                }
            })
    }

    vbs_deleteHandler = (order) => {
        var answer = window.confirm("Are Sure To Delete The Order?")
        if (answer) {
            const params = {
                orderId: order.id,
                movedby: this.state.loginuserObj.userName
            }
            this.setState({
                loading: true
            });
            axios.get(this.state.httpURL + "/viewbasale/deleteBASales", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.setState({
                            loading: false
                        });
                        alert("Successfully Deleted");
                        this.getAllVBSOrders();
                    }

                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    getVBSUnique = (arr, index) => {
        const unique = arr
            .map(e => e[index])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }

    checkVBSProductisFilled = () => {
        var isNotFilled = false;
        let srlist = this.state.vbsSalesDetailsList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].productCode === "") {
                isNotFilled = true;
            }
        }
        return isNotFilled;
    }

    checkVBSQtyisFilled = () => {
        var isZero = false;
        let srlist = this.state.vbsSalesDetailsList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].quantityStr === "0" || parseInt(srlist[i].quantityStr, 10) === 0 || srlist[i].quantityStr === "") {
                isZero = true;
            }
        }
        return isZero;
    }

    vbs_popPDFHandler = () => {
        // console.log("<-1->"+this.state.httpURL);
        // window.open("http:///orderReports/"+"ACHIE_9112020_1604912582403.pdf");       

        this.setState({
            loading: true
        });

        axios.post(this.state.httpURL + "/pdfreport/baSalesPdf", { "orderId": this.state.orderIdForPrint, "companyCode": this.props.userObj.companyCode, "orderType": this.state.vbsOrderType, "reportType": "pdf" })
            .then(res => {
                if (res.data.valid) {
                    window.open("http://139.59.8.226/baSalesReports/" + res.data.fileName);
                    this.setState({
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    alert(res.data.responseMsg);
                }

            })
    }

    vbs_popMailHandler = () => {

        this.setState({
            loading: true
        });

        axios.post(this.state.httpURL + "/pdfreport/baSalesPdf", { "orderId": this.state.orderIdForPrint, "companyCode": this.props.userObj.companyCode, "reportType": "email", "managerEmail": "" })
            .then(res => {
                if (res.data.valid) {
                    alert("Mail Sent Successfully.");
                    this.setState({
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    alert("Mail Send Failed.");
                }

            })
    }

    filterList = (event) => {
        let filtered = this.state.orgVBSSalesList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            vbsSalesList: filtered
        })
    }

    ViewOrder_popup_openHandler = () => {
        document.querySelector('.ViewOrderTableEdit_pop').style.display = 'flex';
    }
    ViewOrder_popCloseHandler = () => {
        document.querySelector('.ViewOrderTableEdit_pop').style.display = 'none';
    }

    handleVBSStateChange = (event) => {
        this.setState({
            vbsState: event.value,
        }, () => {
            this.getAllVBSOrders();
        });
    }

    vbsExcel = () => {
        var dFlag = true;
        if (this.state.orgVBSSalesList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.orgVBSSalesList;
            var fileName = "View BA Sales Report_" + this.state.currentDate;
            var wscols = [
                { wch: 13 },
                { wch: 35 },
                { wch: 45 },
                { wch: 35 },
                { wch: 20 },
                //{wch:20},
                // {wch:20},
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Order Number": dList[i].refNum,
                    "Sales Person": dList[i].salesmanName,
                    "Retailer": dList[i].retailerName,
                    "Order Value": parseInt(dList[i].orderValue.toFixed(2)),
                    "State Code": dList[i].stateCode,
                    "State": dList[i].stateName,
                    "Date Time": dList[i].orderDTMStr,
                    "Version ID": dList[i].versionId,
                    //"Exported To ERP": dList[i].isExported,
                    // "Order Type": dList[i].orderType,                    
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'vbs': ws }, SheetNames: ['vbs'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    downloadReport =()=>{
        
        let stateVal = '';
        var dFlag = true;
        if (this.state.vbsStartDate !== '' && this.state.vbsEndDate !== '') {
            if (this.state.vbsStartDate > this.state.vbsEndDate) {
                alert("From Date Cannot Be Greater Than End Date");
                dFlag = false;
            }
        } else {
            alert("Please select Start and End Date...");
            dFlag = false;
        }
        if (this.state.vbsState === '' || this.state.vbsState === undefined) {
            stateVal = "All";
        } else {
            stateVal = this.state.vbsState;
        }
        if (dFlag) {
            const params = {
                companyCode: this.state.loginuserObj.companyCode,
                distributorCode: "All",
                salesmanCode: this.state.vbsSalesmanCode,
                retailerCode: "All",
                startDate: this.state.vbsStartDate,
                endDate: this.state.vbsEndDate,
                orderStatus: this.state.orderStatus,
                loginSalesmanCode: this.state.loginSalesmanCode,
                state: stateVal,
            }

            this.setState({
                loading: true
            })

            axios.get(this.state.httpURL + "/viewbasale/getBASalesListReport", { params })
                .then(response => response.data)
                .then((data) => {

                    if (data.valid) {
                        this.setState({loading: false});
                        const link = document.createElement('a');
                    link.href = data.fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    }else{
                        this.setState({loading: false});
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    })
                })
        }
    
    }
    render() {

        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">

                    <div className="O2Table_Header"><span>VIEW BA SALES</span></div>
                    <div className="O2Table_Body">
                        <div className="O2Table_SubContainer OrderSubContainer">
                            <div className="O2Table_SelectSales O2Content_row">
                                <div className="O2Table_Label">Sales Person</div>
                                <div className="O2Table_Value">
                                    <select value={this.state.vbsSalesmanCode} onChange={this.handleVBSSalesmanChange}>
                                        {
                                            this.state.loginuserObj.userType !== "salesman" && this.state.loginuserObj.userType !== "Admin" && this.state.loginuserObj.userType !== "Sys Admin" ?
                                                <option value="All" key="All">All</option>
                                                : null
                                        }
                                        {
                                            this.state.vbsSalesmanList.map((salesman) => (
                                                <option value={salesman.salesmanCode} key={salesman.salesmanCode}>{salesman.salesmanName}</option>

                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="O2Content_row">
                                <div className="contentRow_Label">State</div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.vbsStateList} name="vbsState" value={this.state.vbsStateList.filter(({ value }) => value === this.state.vbsState)} placeholder="Choose State" onChange={this.handleVBSStateChange} />
                                </div>
                            </div>

                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date From </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="vbsStartDate" value={this.state.vbsStartDate} onChange={this.VBSDateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date To </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="vbsEndDate" value={this.state.vbsEndDate} onChange={this.VBSDateChange} />
                                </div>
                            </div>
                            <div className="O2Table_Go" onClick={this.getAllVBSOrders}><button>Go</button></div>
                        </div>
                        {/* <div className="O2Table_SubContainer OrderSubContainer">
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Order Status</div>
                            <div className="contentRow_Value">
                                <Select></Select>                                                                                                     
                            </div>
                        </div> 
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Order Type</div>
                            <div className="contentRow_Value">
                                <Select></Select>
                            </div>
                        </div> 
                        <div className="O2Table_Go"><button>Go</button></div>

                </div> */}
                        <div className="Table_wrapper">

                            <table className="customTable">
                                <thead>
                                    <tr>
                                        {/* <th className="checkBox_Container">
                                    <input type="checkbox" name="checkAll" />   
                                </th>  */}
                                        <th className="Ref_Num" >Order Number
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="refNum" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="salesMan" >Sales Person
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="salesmanName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Descript_width" >Retailer
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Ref_Num" >State Code
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="stateCode" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        {/* <th className="Ref_Num" >Version ID
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="versionId" onChange={this.filterList} />
                                            </div>
                                        </th> */}
                                        <th className="Value_width">Order Value
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderValue" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="salesMan">Date Time</th>
                                        <th className="Ref_Num" >Version ID
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="versionId" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        {/* <th className="DateTime_width">Exported to ERP
                                    <div className="searchWrapper">
                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                        <input type="text" className="tableMultiFilterSearch_input maingrid" name="isExported" onChange={this.filterList}  />
                                    </div>     
                                </th>
                                <th className="DateTime_width">Order Type
                                    <div className="searchWrapper">
                                        <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                        <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderType" onChange={this.filterList}  />
                                    </div>     
                                </th>  */}
                                        {/* <th className="o2Del_container"></th> */}
                                        <th className="scroller"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.vbsSalesList || this.state.vbsSalesList.length === 0 || this.state.vbsSalesList === null ?
                                            <tr align="center">
                                                <td colSpan="6">No Sales Available</td>
                                            </tr> :
                                            this.state.vbsSalesList.map((order, i) => (
                                                <tr key={order.id}  >
                                                    <td className="Ref_Num" onDoubleClick={() => this.vbs_popup_openHandler(order)} data-tip={order.refNum}>{order.refNum}</td>
                                                    <td className="salesMan" onDoubleClick={() => this.vbs_popup_openHandler(order)} data-tip={order.salesmanName}>{order.salesmanName}</td>
                                                    <td className="Descript_width" onDoubleClick={() => this.vbs_popup_openHandler(order)} data-tip={order.retailerName}>{order.retailerName}</td>
                                                    <td className="Ref_Num refNum_AlignText" onDoubleClick={() => this.vbs_popup_openHandler(order)} data-tip={order.stateCode}>{order.stateCode}</td>
                                                    {/* <td className="Ref_Num refNum_AlignText" onDoubleClick={() => this.vbs_popup_openHandler(order)} data-tip={order.stateCode}>{order.versionId}</td> */}
                                                    <td className="Value_width" onDoubleClick={() => this.vbs_popup_openHandler(order)} data-tip={order.orderValue.toFixed(2)}>{order.orderValue.toFixed(2)}</td>
                                                    <td className="salesMan" onDoubleClick={() => this.vbs_popup_openHandler(order)} data-tip={order.orderDTMStr}>{order.orderDTMStr}</td>
                                                    <td className="Ref_Num refNum_AlignText" onDoubleClick={() => this.vbs_popup_openHandler(order)} data-tip={order.versionId}>{order.versionId}</td>
                                                    {/* {
                                                        this.state.writeAccess ?
                                                            <td className="o2Del_container">
                                                                {
                                                                    order.isExported === 'N' ?
                                                                        <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 8px' }} onClick={() => this.vbs_deleteHandler(order)} />
                                                                        : null
                                                                }

                                                            </td>
                                                            : null
                                                    } */}

                                                </tr>

                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.vbsSalesList ? this.state.vbsSalesList.length : 0}</span>entries</span></div>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_ExpCollExcel" onClick={() => this.vbsExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Excel Export</div>

                            <div className="O2Table_ExpCollExcel" onClick={() => this.downloadReport()}> Download BA Sales Report</div>
                        </div>
                    
                    </div>
                </div>

                <div className="O2TableEdit_wrapper ViewOrderTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Order Ref No(#): {this.state.vbsRefNum}
                                <div className="O2Edit_New">
                                    <button onClick={() => this.vbs_newRowHandler()}>New</button>
                                </div>
                            </div>
                            <div className="O2TableEditBody">
                                <div className="row">
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Order Number : </div>
                                        <div className="FieldValue"><span>{this.state.vbsRefNum}</span></div>
                                    </div>
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Order Date : </div>
                                        <div className="FieldValue"><span>{this.state.vbsOrderDTM} </span></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Retailer : </div>
                                        <div className="FieldValue"><span>{this.state.vbsRetailerName} </span></div>
                                    </div>
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Sales Person : </div>
                                        <div className="FieldValue"><span>{this.state.vbsSalesmanName} </span></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Amount : </div>
                                        <div className="FieldValue"><span>{parseFloat(this.state.vbsTotAmount).toFixed(2)} </span></div>
                                    </div>
                                    <div className="FieldComponent">
                                        {/* <div className="FieldLabel">Exported Order No : </div>
                                    <div className="FieldValue"><span>{this.state.erpOrderNo} </span></div> */}
                                    </div>
                                </div>
                                <div className="Table_wrapper orderpop_tablemargin">
                                    <table className="customTable OrderTablesecondpop">
                                        <thead>
                                            <tr>
                                                <th className="Sno_width">S.No</th>
                                                <th className="Descript_width">Product</th>
                                                {/* <th className="Descript_width">Distributor</th>                                        */}
                                                <th className="Value_width">Quantity</th>
                                                <th className="Value_width">Box</th>
                                                <th className="Value_width">Pack</th>
                                                <th className="Value_width">piece</th>
                                                <th className="Value_width">Rate</th>
                                                <th className="Value_width">Value</th>
                                                <th className="Value_width">Stock</th>
                                                {/* <th className="ErpDoc_width">ERP Doc No</th> */}
                                                <th className="o2popDel_container"></th>
                                                <th className="scroller"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr >
                                            <td className="Sno_width">1</td>
                                            <td className="Descript_width"><input type="text"/></td>
                                            <td className="Descript_width"><Select/></td>
                                            <td className="Value_width"><input type="text"/></td>
                                            <td className="Value_width"></td>
                                            <td className="Value_width"></td>
                                            <td className="Value_width"></td>
                                            <td className="Value_width"></td>
                                            <td className="Value_width"></td>
                                            <td className="Value_width"></td>                                                    
                                            <td className="ErpDoc_width"></td>
                                            <td className="o2popDel_container">
                                                <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}} />
                                                <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}}/>                                                                                                      
                                            </td>
                                        </tr> */}
                                            {
                                                this.state.vbsSalesDetailsList.map((ord, i) => (
                                                    <tr key={i}>
                                                        <td className="Sno_width" data-tip={i}>{i + 1}</td>

                                                        {
                                                            ord.savedProduct === true ?
                                                                <td className="Descript_width" data-tip={ord.productName}><input type="text" value={ord.productName} disabled={true} /></td>
                                                                :
                                                                <td className="Descript_width" data-tip={ord.productName}><Select options={this.state.vbsProductsList} value={this.state.vbsProductsList.filter(({ value }) => value === ord.productCode)} placeholder="Choose Product" isDisabled={ord.isSelectDisabled} onChange={(e) => this.handleVBSProductChange(i, e)} /></td>
                                                        }

                                                        {/* <td>{ord.productName}</td> */}
                                                        <td className="Value_width" data-tip={ord.quantityStr}><input type="text" id={i} name={ord.id} onChange={this.changeVBSQty} value={ord.quantityStr} readOnly={ord.readOnly} /></td>
                                                        <td className="Value_width" data-tip={parseFloat(ord.numberOfBoxes).toFixed(1)}>{parseFloat(ord.numberOfBoxes).toFixed(1)}</td>
                                                        <td className="Value_width" data-tip={parseFloat(ord.numberOfPacks).toFixed(2)}>{parseFloat(ord.numberOfPacks).toFixed(2)}</td>
                                                        <td className="Value_width" data-tip={parseFloat(ord.numberOfPieces).toFixed(2)}>{parseFloat(ord.numberOfPieces).toFixed(2)}</td>
                                                        <td className="Value_width" data-tip={parseFloat(ord.rate).toFixed(2)}>{parseFloat(ord.rate).toFixed(2)}</td>
                                                        <td className="Value_width" data-tip={parseFloat(ord.totRate).toFixed(2)}>{parseFloat(ord.totRate).toFixed(2)}</td>
                                                        <td className="Value_width" data-tip={ord.stock}>{ord.stock}</td>
                                                        {/* <td className="ErpDoc_width" data-tip={ord.documentNo}>{ord.documentNo}</td> */}
                                                        {
                                                            this.state.writeAccess ?
                                                                <td className="o2popDel_container">
                                                                    <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.vbsDetails_editHandler(i)} />
                                                                    <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} onClick={() => this.vbsDetails_deleteHandler(i)} />
                                                                </td>
                                                                : null
                                                        }


                                                    </tr>

                                                ))


                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="O2TableEditFooter OrderModuleFooter">
                                <button onClick={this.vbs_popupSaveHandler}>Save</button>
                                <button onClick={this.ViewOrder_popCloseHandler}>Cancel</button>
                                <div>
                                    <button onClick={this.vbs_popPDFHandler}>Print</button>
                                    <button onClick={this.vbs_popMailHandler}>E-Mail</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(BaViewOrdermodule)

// export default BaViewOrdermodule