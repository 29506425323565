import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import './PriceTypeModule.css';
import axios from 'axios'
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class PriceTypeModule extends Component {

    constructor(props) {

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        super(props)
        this.state = {
            loading: false,
            ptmPriceTypeList: [],
            ptmPriceTypeOrgList: [],
            ptmPriceType: '',
            ptmPriceTypeId: 0,
            ptmCompanyCode: '',
            ptmPriceStatus: 'Y',
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            priceTypeWriteAccess:this.props.userObj.priceTypeWriteAccess,
            currentDate:todaydate
        }
    }

    componentDidMount() {
        this.getPriceTypeList();
    }

    getPriceTypeList() {
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/pricetype/getPriceType")
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({
                        ptmPriceTypeList: data.priceTypeList,
                        ptmPriceTypeOrgList: data.priceTypeList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    newPT_popCloseHandler = () => {
        document.querySelector('.priceTypeTableEdit_pop').style.display = 'none';
    }

    newPT_popSaveHandler = () => {
        if (this.state.ptmPriceType === undefined || this.state.ptmPriceType === "" || this.state.ptmPriceType === null) {
            alert("Please Enter Price Type");
        } 
        // else if (this.state.ptmCompanyCode === undefined || this.state.ptmCompanyCode === "" || this.state.ptmCompanyCode === null) {
        //     alert("Please Enter Company Code");
        // } 
        else {

            const newPTM = [{
                productPriceId: this.state.ptmPriceTypeId,
                priceTypeName: this.state.ptmPriceType,
                companyCode: this.state.loginuserObj.companyCode,
                status: this.state.ptmPriceStatus,
                lastUpdatedBy: this.state.loginuserObj.userName
            }];

            let params = {
                "priceTypeList": newPTM
            }
            //console.log(newDM);
            this.setState({
                loading: true
            });
            axios.post(this.state.httpURL+"/pricetype/", params)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading:false
                        });
                        if (response.data.valid) {
                            if (response.data.existPriceType) {
                                alert("This Price Type Is Already Exist.");
                            } else {
                                alert("Price Type Saved Successfully.");
                                this.clearPTM();
                                document.querySelector('.priceTypeTableEdit_pop').style.display = 'none';
                                this.getPriceTypeList();
                            }
                        } else {
                            alert(response.data.responseMsg);
                        }                           
                    }
                })
                .catch(error => {
                   // console.log("error" + error)
                   this.setState({
                    loading:false
                });
                alert(error);
                })
        }
    }

    newPT_openHandler = () => {
        this.setState({
            ptmPriceType: '',
            ptmPriceTypeId: 0,
            ptmCompanyCode: '',
            ptmPriceStatus: 'Y',
        });
        document.querySelector('.priceTypeTableEdit_pop').style.display = 'flex';
    }

    editPT_openHandler = (price) => {
        this.setState({
            ptmPriceTypeId: price.productPriceId,
            ptmPriceType: price.priceTypeName,
            ptmCompanyCode: price.companyCode,
            ptmPriceStatus: price.status,
        });
        document.querySelector('.priceTypeTableEdit_pop').style.display = 'flex';
    }

    deletePT_Handler = (price) => {
        if (window.confirm("Delete The Price Type?")) {
            this.setState({
                loading: true
            });
            axios.delete(this.state.httpURL + "/pricetype/" + price.productPriceId)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            alert("Price Type Deleted Successfully ...");
                            this.getPriceTypeList();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    ptmChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    ptmStatusChange = (event) => {
        this.setState({
            ptmPriceStatus: event.target.value
        });

    }

    filterList = (event) => {
        let filtered =this.state.ptmPriceTypeOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
                
            }
        }
        this.setState({
            ptmPriceTypeList: filtered
        })
    }

    clearPTM() {
        this.setState({
            ptmPriceTypeList: [],
            ptmPriceType: '',
            ptmPriceTypeId: 0,
            ptmCompanyCode: '',
            ptmPriceStatus: 'Y',
        });
    }

    priceTypeExcel = () =>{
        console.log("<--1-->");
        var dFlag = true;
        if(this.state.ptmPriceTypeList.length === 0){
            alert("No Records");
            dFlag = false;
        } 
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.ptmPriceTypeList;
            var fileName = "Price Type Report_"+this.state.currentDate;
            var wscols = [
                {wch:35},
                {wch:10}
            ];
            for(var i=0;i<dList.length;i++){
                arr = {};
                arr = {
                    "Product Price Type": dList[i].priceTypeName,                    
                    "Status": dList[i].status                                      
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'PriceType': ws }, SheetNames: ['PriceType'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            fileSaver.saveAs(data, fileName + fileExtension);
        }        
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>PRICE TYPE</span></div>
                    <div className="O2Table_SubContainer">
                        {/* <div className="O2Table_Search">
                            <div className="O2Table_Label"> Search : </div>
                            <div className="O2Table_Value"><input type="text" onChange={this.filterList} /></div>
                        </div> */}
                        <div className="O2Table_New">
                            <button onClick={this.newPT_openHandler}>New</button>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="priceType_Table">
                            <thead>
                                <tr>
                                    <th>Product Price Type
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="priceTypeName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    {/* <th>Company Code
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="companyCode" onChange={this.filterList} />
                                        </div>
                                    </th> */}
                                    <th className="Status_Route">Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="status" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container"></th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td className="checkBox_Container">                                                    
                                    </td>                                                
                                    <td>Ice Cream</td>
                                    <td>hkis52645</td>
                                    <td className="Status_Route">Y</td>
                                    <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}} onClick={ ()=> this.newPT_openHandler()}/>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}}/>
                                    </td>
                                </tr> */}
                                {
                                    !this.state.ptmPriceTypeList || this.state.ptmPriceTypeList === null || this.state.ptmPriceTypeList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No PriceType Available</td>
                                        </tr> :
                                        this.state.ptmPriceTypeList.map((price) => (
                                            <tr>
                                                <td data-tip={price.priceTypeName}>{price.priceTypeName}</td>
                                                {/* <td>{price.companyCode}</td> */}
                                                <td className="Status_Route" data-tip={price.status}>{price.status}</td>
                                                <td className="o2Del_container">
                                                    <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.editPT_openHandler(price)} />
                                                    {
                                                        this.state.priceTypeWriteAccess ?
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 8px' }} onClick={() => this.deletePT_Handler(price)} />
                                                        : null
                                                    }                                                    
                                                </td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.ptmPriceTypeList ? this.state.ptmPriceTypeList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Export</div> */}
                        {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="priceType_Table" filename={"Product Price Type"}
                            sheet="tablexls" buttonText="Excel Export" /></div> */}
                        <div className="O2Table_ExpExcel" onClick={ ()=> this.priceTypeExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }}/>Excel Export</div>
                    </div>
                </div>
                <div className="O2TableEdit_wrapper priceTypeTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Price Type</div>
                            <div className="RM_BodyContent">
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Product Price Type </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name="ptmPriceType" value={this.state.ptmPriceType} onChange={this.ptmChange} />
                                    </div>
                                </div>
                                {/* <div className="legentContent_div">
                                    <div className="legendBody_Label">Company Code </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name="ptmCompanyCode" value={this.state.ptmCompanyCode} onChange={this.ptmChange} />
                                    </div>
                                </div> */}
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Status </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select value={this.state.ptmPriceStatus} onChange={this.ptmStatusChange}>
                                            <option value="Y" >Active</option>
                                            <option value="N" >In Active</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    {
                                        this.state.priceTypeWriteAccess ?
                                            <button onClick={this.newPT_popSaveHandler}>Save</button>
                                        : null
                                    }                                    
                                    <button onClick={this.newPT_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(PriceTypeModule)
//export default PriceTypeModule
