import React, { Component } from 'react'
import './MenuBodyModule.css'
import LoadingPageModule from '../Menu-BodyModule/Loading-ItemsModule/LoadingPageModule';
import MenuModule from '../Menu-BodyModule/Menu-Module/MenuModule';
import {BrowserRouter as Router} from 'react-router-dom';
class MenuBodyModule extends Component {
    render() {
        //console.log("-----"+this.props.toggleActive)
        return (
            <Router>
                <div className='MenuBodyContainer'>
                    <MenuModule toggleActive={this.props.toggleActive}></MenuModule>
                    <LoadingPageModule></LoadingPageModule>
                </div>
            </Router>
        )
    }
}

export default MenuBodyModule
