import React, { Component } from 'react'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faTrashAlt, faEdit, faCloudUploadAlt,faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

const override1 = css`
  display: block;
  top: -235px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class CollectionModule extends Component {


    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            collectionList: [],
            orgcollectionList: [],
            salesmanList: [],
            distributorList: [],
            collectionObj: '',
            collectionDetailsList: [],
            collectionNewList: [],
            paymentList: [],
            productDistributorList:[],
            selectcollectionDate: todaydate,
            refNum: "",
            collSalesmanCode:"",
            salesman: '',
            retailerName: "",
            distributorName: "",
            collectionDate: '',
            CashAmt: '',
            ChequeAmt: '',
            ChequeNum: '',
            ChequeDate: '',
            ChequeBank: '',
            collId: '',
            collInvoiceNumber: '',
            collInvoiceAmt: '',
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            editId: "",
            editRefNo: "",
            editMode: "",
            editDate: "",
            editAmount: "",
            paymentId: "",
            paymentName: "",
            collectionAmt: "",
            userName: "",
            startDate: todaydate,
            endDate: todaydate,
            allChecked:false,
            collectionWriteAccess:this.props.userObj.collectionWriteAccess,
            loginSalesmanCode:this.props.userObj.salesmanCode,
            currentDate:todaydate
        }
    }

    // Collectionnew_popCloseHandler = () => {
    //     document.querySelector('.newCollection_popup').style.display = 'none';
    // }

    // Collectionnew_popOpenHandler = () => {
    //     document.querySelector('.newCollection_popup').style.display = 'flex';
    // }

    Collection_popCloseHandler = () => {
        document.querySelector('.collectionTableEdit_pop').style.display = 'none';
    }
    handleAllChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { collectionList, allChecked, orgcollectionList } = prevState;
            if (itemName === "checkAll") {
                console.log(checked)
                if(checked){
                    allChecked = checked;
                    collectionList = collectionList.map(item => ({ ...item, isChecked: checked }));
                    orgcollectionList = orgcollectionList.map(item => ({ ...item, isChecked: checked }));
                }else{
                    allChecked = false;
                    collectionList = collectionList.map(item => ({ ...item, isChecked: false }));
                    orgcollectionList = orgcollectionList.map(item => ({ ...item, isChecked: false }));
                }
               
            } 
            return { collectionList, allChecked, orgcollectionList };
        });
    };
    handleChange = (e,ind) => {
        let checked = e.target.checked;
        const {  collectionList, allChecked, orgcollectionList } = this.state;
        collectionList[ind].isChecked = checked
        orgcollectionList[ind].isChecked = checked
        

        this.setState({ collectionList:collectionList,orgcollectionList:orgcollectionList,allChecked:false},()=>{
            ReactTooltip.rebuild();
        });
    };   
    // Collection_newRowHandler = () => {
    //     console.log('hi');
    //     var appendSales = {
    //         collectionAmt: 0,
    //         paymentMode: "",
    //         documentNumber: "",
    //         documentDate: "",
    //         bankName: ""
    //     }

    //     // this.state.orderDetailsList.push(emptyobj)
    //     let salesRetDetailsListtemp = this.state.collectionNewList
    //     salesRetDetailsListtemp.push(appendSales)

    //     this.setState({ collectionNewList: salesRetDetailsListtemp })
    // }

    Collection_newRowHandler = () => {
        //var newind=this.state.collectionDetailsList.length+1;
        var cNo = this.state.collId;
        var emptyobj = {
            // id:newind,
            distributorCode:'',
            productDistributorList:this.state.productDistributorList,
            collectionNumber: cNo,
            collectionAmt: 0,
            collectionAmtStr: 0,
            paymentId: 1,
            documentNumber: "",
            documentDate: "",
            bankName: "",
            readOnly: false,
            paymentMode:this.state.paymentList[0].paymentName
        }
        let collDetailListtemp = this.state.collectionDetailsList
        collDetailListtemp.push(emptyobj)
        this.setState({ collectionDetailsList: collDetailListtemp },()=>{
            ReactTooltip.rebuild();
            //console.log("new object-->"+JSON.stringify(this.state.collectionDetailsList))
        })
        // console.log(this.state.collectionDetailsList);  
    }

    Collection_popup_openHandler = (collection) => {
        const params={
            collectionId:collection.id,           
            salesmanCode:collection.salesmanCode,
        }
        this.setState({
            loading: true
        });
        this.setState({
            CashAmt: '',
            ChequeAmt: '',
            ChequeNum: '',
            ChequeDate: '',
            ChequeBank: ''
        }, () => {
            this.setState({ collId: collection.id });
            axios.get(this.state.httpURL + "/collection/getCollectionDetailList",{params})
                .then(response => response.data)
                .then((data) => {
                    // console.log("data-->"+data.collectionList)
                    this.setState({
                        loading: false
                    })
                    this.setState({
                        collectionDetailsList: data.collectionDetailList,
                        collectionObj: collection,
                        refNum: collection.id,
                        collSalesmanCode:collection.salesmanCode,
                        salesman: collection.salesmanName,
                        retailerName: collection.retailerName,
                        collectionDate: collection.collectionDate,
                        CashAmt: collection.collectionTotalAmount,
                        distributorName: collection.distributorName,
                        productDistributorList: data.collectionDetailList[0].productDistributorList
                        // collInvoiceNumber: data.invoiceNumber,
                        // collInvoiceAmt: data.invoiceAmount,
                        // editId: data.id,
                        // editRefNo: data.refNo,
                        // editMode: data.mode,
                        // editDate: data.date,
                        // editAmount: data.amount,
                    }, () => {  // callback functions
                        //console.log("salesman"+this.state.salesman)
                        ReactTooltip.rebuild();
                        document.querySelector('.collectionTableEdit_pop').style.display = 'flex';
                    })
                   
                }).catch(err => {
                    // console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        })
    }

    componentDidMount() {
        this.getAllCollections();
        //this.loadCollection();
    }

    loadCollection() {



        let dcode = this.state.loginuserObj.distributorCode;
        let scode = this.state.loginuserObj.salesmanCode;
        let cCode = this.state.loginuserObj.companyCode;
        let uCode = this.state.loginuserObj.userName;


        // if (localStorage.getItem("userType") === "salesman") {
        //     dcode = localStorage.getItem("userDistributorCode");
        //     scode = localStorage.getItem("userSalesmanCode");
        // } else {
        //     dcode = "All";
        //     scode = "All";
        // }

        this.setState({
            companyCode: cCode,
            distributorCode: dcode,
            salesmanCode: scode,
            userName: uCode,
        }, () => {
            //  console.log(this.state.topic)
            this.getAllCollections();
        });
    }

    getAllCollections = () => {
        var dFlag = true;
        if (this.state.startDate !== '' && this.state.endDate !== '') {
            if (this.state.startDate > this.state.endDate) {
                alert("From Date Cannot Be Greater Than End Date");
                dFlag = false;
            }
        } else {
            alert("Please select Start and End Date...");
            dFlag = false;
        }
        if (dFlag) {
            const params = {
                companyCode: this.state.companyCode,
                distributorCode: "All",
                salesmanCode: this.state.salesmanCode,
                collectionDate: this.state.selectcollectionDate,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                loginSalesmanCode: this.state.loginSalesmanCode,
            }

            this.setState({
                loading: true
            });
            axios.get(this.state.httpURL + "/collection", { params })
                .then(response => response.data)
                .then((data) => {
                    this.setState({
                        loading: false
                    });
                    // console.log("data-->"+data.collectionList)
                    if(this.state.salesmanList.length === 0){
                        this.setState({
                            salesmanList:data.salesmanList
                        })
                    }
                    this.setState({
                        collectionList: data.collectionList,
                        orgcollectionList: data.collectionList,
                       // salesmanList: data.salesmanList,
                        distributorList: data.distributorList,
                        paymentList: data.paymentList,
                    })
                    ReactTooltip.rebuild();
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    handleSalesmanChange = (event) => {
        this.setState({
            salesmanCode: event.target.value
        }, () => {
            //  console.log(this.state.topic)
            this.getAllCollections();
        });

    }

    handleDistributorChange = (event) => {
        this.setState({
            distributorCode: event.target.value
        }, () => {
            //  console.log(this.state.topic)
            this.getAllCollections();
        });

    }

    collectionEditChange = (e) => {
        // var row = event.target.attributes.getNamedItem('row').value;
        // console.log(row);
        // console.log(e.target.name);
        // console.log(e.target.value);
        // let itemId = e.target.id;       
        let ivalue = e.target.value;        
        const { collectionDetailsList } = this.state;
        const { id } = e.target;        
        if (e.target.name === 'collectionAmt') {
            const re = /^\d{1,}(\.\d{0,2})?$/;
            if (ivalue === '' || re.test(ivalue)) {
                collectionDetailsList[id].collectionAmtStr = ivalue
            }            
        } else if (e.target.name === 'paymentName') {
            let paymentIndex = e.nativeEvent.target.selectedIndex;
            let paymentTypeName = e.nativeEvent.target[paymentIndex].text;
            collectionDetailsList[id].paymentId = ivalue;
            collectionDetailsList[id].paymentMode = paymentTypeName;
            collectionDetailsList[id].documentNumber = "";
            collectionDetailsList[id].documentDate = "";
            collectionDetailsList[id].bankName = "";
        } else if (e.target.name === 'documentNumber') {
            collectionDetailsList[id].documentNumber = ivalue
        } else if (e.target.name === 'documentDate') {
            collectionDetailsList[id].documentDate = ivalue
        } else if (e.target.name === 'bankName') {
            collectionDetailsList[id].bankName = ivalue
        }

        this.setState({ collectionDetailsList: collectionDetailsList },()=>{ 
            this.calculateTotalAmount();
            ReactTooltip.rebuild();   
       });

        //console.log(this.state.collectionDetailsList);
    }

    collection_deleteHandler = (ind) => {
        // console.log("--cobjct-->"+ind);
        var collectionDetailsList = [...this.state.collectionDetailsList];
        collectionDetailsList.splice(ind, 1)
        this.setState({ collectionDetailsList: collectionDetailsList })

    }

    collection_EnableEdit = (ind) => {

        const { collectionDetailsList } = this.state;
        collectionDetailsList[ind].readOnly = false
        collectionDetailsList[ind].disabled = ""
        collectionDetailsList[ind].isSelectDisabled = false
        this.setState({ collectionDetailsList: collectionDetailsList })

    }

    collection_GridDelete = (ind) => {
        // console.log(this.state.collectionList[ind].collectionNumber);
        if (window.confirm("Delete the collection?")) {
            // var collectionList = [...this.state.collectionList];
            // collectionList.splice(ind, 1)
            // this.setState({ collectionList: collectionList }, () => {
            //     console.log(this.state.collectionList);
            // });
            //this.setState({ collId: this.state.collectionList[ind].collectionNumber });
            const params = {
                "userName": this.state.loginuserObj.userName,
                "collectionNumber": this.state.collectionList[ind].id,
            }


            // axios.get(this.state.httpURL + "/collection", { params })
            // let params = {
            //     "userName":this.state.userName,
            //     "collectionNumber": this.state.collectionList[ind].collectionNumber,
            // }   
            this.setState({
                loading: true
            });
            axios.delete(this.state.httpURL + "/collection/", { params })
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            alert("Collection Deleted Successfull ...");
                            this.getAllCollections();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    handlecollectiondateChange = (event) => {
        this.setState({
            selectcollectionDate: event.target.value
        }, () => {
            //  console.log(this.state.selectcollectionDate)
            this.getAllCollections();
        });

    }

    filterList = (event) => {
        let filtered =this.state.orgcollectionList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
                
            }
        }
        this.setState({
            collectionList: filtered
        })

    }

    collectionChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    Collection_savefunction = (e) => {
        // e.preventDefault();

        // const newcoll = [{
        //     id: this.state.collId,
        //     collectionAmtCash: this.state.CashAmt,
        //     collectionAmtCheque: this.state.ChequeAmt,
        //     chequeNumber: this.state.ChequeNum,
        //     chequeDate: this.state.ChequeDate,
        //     banknameLocation: this.state.ChequeBank
        // }];

        // const newcollDetailList = [];

        // if (this.state.CashAmt != 0 || this.state.CashAmt != "") {
        //     newcollDetailList.push({
        //         collectionsId: this.state.collId,                
        //         adjustedAmount: this.state.CashAmt,
        //         adjustedMode: 'Cash'
        //     })
        // }

        // if (this.state.ChequeAmt != 0 || this.state.ChequeAmt != "") {
        //     newcollDetailList.push({
        //         collectionsId: this.state.collId,                
        //         adjustedAmount: this.state.ChequeAmt,
        //         adjustedMode: 'Cheque'
        //     })
        // }

        // let params = {
        //     "collectionList": newcoll,
        //     "collectionDetailList": newcollDetailList
        // }
        // axios.post(this.state.httpURL + "/collection/", params)
        //     .then(response => {
        //         if (response.data !== null) {
        //             // this.setState(this.initialState)
        //             alert("Collection Save Successfully ...");

        //             this.setState({
        //                 collId: '',
        //                 CashAmt: '',
        //                 ChequeAmt: '',
        //                 ChequeNum: '',
        //                 ChequeDate: '',
        //                 ChequeBank: '',
        //             })
        //             this.Collection_popCloseHandler();
        //             this.getAllCollections();
        //         }
        //     })
        //     .catch(error => {
        //         console.log("error" + error)
        //     })

        var validationFlag = true;
        var validationMsg = "";
        if (this.state.collectionDetailsList.length > 0) {
            this.state.collectionDetailsList.map(function (coll, i) {
                if (validationFlag) {
                    if (coll.collectionAmtStr === "" || parseInt(coll.collectionAmtStr, 10) === 0) {
                        validationFlag = false;
                        validationMsg = "Please Enter Collection Amount";
                    }
                    if (parseInt(coll.paymentId) === 2 || parseInt(coll.paymentId) === 3) {
                        if (coll.documentNumber === "") {
                            validationFlag = false;
                            validationMsg = "Please Enter Document Number";
                        } else if (coll.documentDate === "") {
                            validationFlag = false;
                            validationMsg = "Please Enter Document Date";
                        } else if (coll.bankName === "") {
                            validationFlag = false;
                            validationMsg = "Please Enter Bank Name And Location";
                        }
                    }                    
                    if (coll.distributorCode === "" || coll.distributorCode === undefined) {
                        validationFlag = false;
                        validationMsg = "Please Enter Distributor Code";
                    }
                }
            });
        } else {
            validationFlag = false;
            validationMsg = "No Records.";
        }


        if (!validationFlag) {
            alert(validationMsg);
        } else {
            let params = {
                "userName": this.state.loginuserObj.userName,
                "collectionDetailList": this.state.collectionDetailsList,
            }
            this.setState({
                loading: true
            });
            axios.post(this.state.httpURL + "/collection/", params)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            alert("Collection Saved Successfully ...");
                            this.setState({
                                collId: '',
                                CashAmt: '',
                                ChequeAmt: '',
                                ChequeNum: '',
                                ChequeDate: '',
                                ChequeBank: '',
                            })
                            this.Collection_popCloseHandler();
                            this.getAllCollections();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                })
                .catch(error => {
                    //console.log("error" + error)
                    this.setState({
                        loading: false
                    });
                    alert(error);
                })
        }
    }

    handleCOLLProductDistributorChange = (ind,event) => {            
        const { collectionDetailsList } = this.state;
        collectionDetailsList[ind].distributorCode = event.value
        collectionDetailsList[ind].distributorName = event.label                
        this.setState({ collectionDetailsList:collectionDetailsList },()=>{
            ReactTooltip.rebuild();
        });
    }

    dateChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    calculateTotalAmount = () => {
        var totAmtt=0;
        var collAmt="00.00";
        var cList = this.state.collectionDetailsList;
       // console.log('totAmount--'+this.state.totAmount);
      //  console.log("--"+JSON.stringify(this.state.salesReturnDetailsList))
    //   cList.map( (srd)=>(
    //         totAmtt=totAmtt+parseFloat(srd.collectionAmtStr)
    //     ));
    cList.map(function (srd, i) {
            console.log(srd);
            if(srd.collectionAmtStr === ""){
                totAmtt=totAmtt+parseFloat(collAmt) 
            } else {
                totAmtt=totAmtt+parseFloat(srd.collectionAmtStr)
            }
        });
       // console.log("totAmtt:111"+totAmtt)
        this.setState({ CashAmt:totAmtt })
    }

    collectionExcel = () =>{
        var dFlag = true;
        if(this.state.collectionList.length === 0){
            alert("No Records");
            dFlag = false;
        } 
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.collectionList;
            var fileName = "Collection Report_"+this.state.currentDate;
            var wscols = [
                {wch:16},
                {wch:35},
                {wch:45},
                {wch:35}
            ];
            for(var i=0;i<dList.length;i++){
                arr = {};
                arr = {
                    "Collection Number": dList[i].collectionNumber,
                    "Sales Person": dList[i].salesmanName,
                    "Retailer": dList[i].retailerName,
                    "Amount": dList[i].collectionTotalAmount.toFixed(2),
                    "Date Time": dList[i].collectionDate                                       
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'Collection': ws }, SheetNames: ['Collection'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            fileSaver.saveAs(data, fileName + fileExtension);
        }        
    }

    render() {

        const { selectcollectionDate } = this.state


        return (
            <div className="O2Container_Wrapper">
                 <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>COLLECTIONS</span></div>
                    <div className="O2Table_Body">
                        <div className="O2Table_SubContainer collect_subContainer">
                            {/* <div className="O2Table_SelectDist">
                                <div className="O2Table_Label">Distributor</div>
                                <div className="O2Table_Value">
                                    <select value={this.state.distributorCode} onChange={this.handleDistributorChange}>
                                        
                                        {
                                            this.state.distributorList.map((dist) => (
                                                <option value={dist.distributorCode} key={dist.distributorCode}>{dist.distributorName}</option>

                                            ))
                                        }
                                    </select>
                                </div>
                            </div> */}
                            <div className="O2Table_SelectSales O2Content_row">
                                <div className="O2Table_Label">Sales Person</div>
                                <div className="O2Table_Value">
                                    <select value={this.state.salesmanCode} onChange={this.handleSalesmanChange}>
                                        {
                                        this.state.loginuserObj.userType !== "salesman" && this.state.loginuserObj.userType !== "Admin" && this.state.loginuserObj.userType !== "Sys Admin" ?
                                            <option value="All" key="All">All</option>
                                        : null
                                        }                                        
                                        {
                                            // localStorage.getItem("userType") === "salesman" ? null : <option value="All" key="All">All</option>

                                            this.state.salesmanList.map((salesman) => (
                                                <option value={salesman.salesmanCode} key={salesman.salesmanCode}>{salesman.salesmanName}</option>

                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* <div className="O2Table_SelectDate">
                                <div className="O2Table_Label"> Select Date  </div>
                                <div className="O2Table_Value"><input type="date" name="selectcollectionDate" value={selectcollectionDate} onChange={this.handlecollectiondateChange} /></div>
                            </div> */}
                            {/* <div className="O2Table_Search">
                                <div className="O2Table_Label"> Search </div>
                                <div className="O2Table_Value"><input type="text" onChange={this.filterList} /></div>
                            </div> */}

                            
                        <div className="O2Content_row">
                                <div className="contentRow_Label">Date From </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="startDate" value={this.state.startDate} onChange={this.dateChange} />                                   
                                </div>
                        </div> 
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Date To </div>
                            <div className="contentRow_Value">
                                <input type="date" name="endDate" value={this.state.endDate} onChange={this.dateChange} />                                  
                            </div>
                        </div> 

                        <div className="O2Table_Go" onClick={this.getAllCollections}><button>Go</button></div>
                        
                            {/* <div class="O2Table_New"><button onClick={this.Collectionnew_popOpenHandler}>New</button></div> */}
                        </div>
                        {/* <div className="O2Table_SubContainer">
                            <div className="O2Table_Records">
                                <div className="O2Table_Value"><select><option>5</option></select></div>
                                <div className="O2Table_Label">Records per pages</div>
                            </div> 
                            <div className="O2Table_SelectDate">
                                <div className="O2Table_Label"> Select Date  </div>
                                <div className="O2Table_Value"><input type="date" name="selectcollectionDate" value={selectcollectionDate} onChange={this.handlecollectiondateChange} /></div>
                            </div>
                            <div className="O2Table_Search">
                                <div className="O2Table_Label"> Search </div>
                                <div className="O2Table_Value"><input type="text" onChange={this.filterList} /></div>
                            </div>
                            
                        </div> */}
                        <div className="Table_wrapper">
                            <table className="customTable" id="collections_Table">
                                <thead>
                                    <tr>
                                        <th className="checkBox_Container">
                                            <input type="checkbox" name="checkAll" checked={this.state.allChecked} onChange={this.handleAllChange}/>
                                        </th> 
                                        <th className="Ref_Num">Collection Number
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="id" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        {/* <th>Distributor</th> */}
                                        <th className="salesMan">Sales Person
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="salesmanName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Descript_width">Retailer
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Value_width">Amount
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="collectionTotalAmount" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="DateTime_width">Date Time</th>
                                        {
                                            this.state.collectionWriteAccess ?
                                            <th className="o2Del_container"></th>
                                            : null
                                        }                                        
                                        <th className="scroller"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.collectionList || this.state.collectionList == null || this.state.collectionList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="6">No Collection Available</td>
                                            </tr> :
                                            this.state.collectionList.map((collection, i) => (
                                                <tr key={collection.id + "_" + collection.refNum + "_" + collection.retailerCode}>
                                                    <td className="checkBox_Container">
                                                        <input type="checkbox" name={collection.id}  value={collection.id} checked={collection.isChecked} onChange={(e)=>this.handleChange(e,i)}/>
                                                    </td> 
                                                    <td className="Ref_Num" onDoubleClick={() => this.Collection_popup_openHandler(collection)} data-tip={collection.id}>{collection.id}</td>
                                                    {/* <td onClick={() => this.Collection_popup_openHandler(collection)}>{collection.distributorName}</td> */}
                                                    <td className="salesMan" onDoubleClick={() => this.Collection_popup_openHandler(collection)} data-tip={collection.salesmanName}>{collection.salesmanName}</td>
                                                    <td className="Descript_width"onDoubleClick={() => this.Collection_popup_openHandler(collection)} data-tip={collection.retailerName}>{collection.retailerName}</td>
                                                    <td className="Value_width" onDoubleClick={() => this.Collection_popup_openHandler(collection)} data-tip={collection.collectionTotalAmount}>{collection.collectionTotalAmount.toFixed(2)}</td>
                                                    <td className="DateTime_width" onDoubleClick={() => this.Collection_popup_openHandler(collection)} data-tip={collection.collectionDate}>{collection.collectionDate}</td>
                                                    {
                                                        this.state.collectionWriteAccess ?
                                                        <td className="o2Del_container"><FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 8px' }} onClick={() => this.collection_GridDelete(i)} /></td>
                                                        :
                                                        null
                                                    }                                                    
                                                </tr>

                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.collectionList ? this.state.collectionList.length : 0} </span>entries</span></div>
                            {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                            <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            {
                                this.state.collectionWriteAccess ?
                                <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudUploadAlt} style={{ padding: '0px 8px' }} /> Upload to ERP</div>
                                : null
                            }
                            
                            {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> SAP Export</div> */}
                            {/* <div className="O2Table_ExpCollExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="collections_Table" filename={"Collections_" + this.state.selectcollectionDate}
                                sheet="tablexls" buttonText="Excel Export" /></div> */}
                            <div className="O2Table_ExpCollExcel" onClick={ ()=> this.collectionExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>    
                            <div className="O2Table_RecSelect">
                                {/* <div className="O2Table_Label"> Select All Orders  : </div>
                                <div className="O2Table_Value"><input type="checkbox" /></div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="O2TableEdit_wrapper collectionTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Collection Ref No(#): <span>{this.state.collId}</span>
                                <div className="O2Edit_New">
                                    {
                                        this.state.collectionWriteAccess ?
                                        <button onClick={() => this.Collection_newRowHandler()}>New</button>
                                        : null
                                    }                                    
                                </div>
                            </div>
                            <div className="O2TableEditBody">
                                <div className="row">
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Collection Number : </div>
                                        <div className="FieldValue"><span>{this.state.collId}</span></div>
                                    </div>
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Collection Date : </div>
                                        <div className="FieldValue"><span>{this.state.collectionDate}</span></div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="FieldComponent">
                                        <div className="FieldLabel">Distributor : </div>
                                        <div className="FieldValue"><span>{this.state.distributorName}</span></div>
                                    </div> */}
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Retailer : </div>
                                        <div className="FieldValue"><span>{this.state.retailerName}</span></div>
                                    </div>
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Sales Person : </div>
                                        <div className="FieldValue"><span>{this.state.salesman}</span></div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="FieldComponent">
                                        <div className="FieldLabel">Salesman : </div>
                                        <div className="FieldValue"><span>{this.state.salesman}</span></div>
                                    </div> */}
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Amount : </div>
                                        <div className="FieldValue"><span>{parseFloat(this.state.CashAmt).toFixed(2)}</span></div>
                                    </div>
                                    <div className="FieldComponent">
                                        {/* <div className="FieldLabel">Amount : </div>
                                        <div className="FieldValue"><span>{this.state.CashAmt}</span></div> */}
                                    </div>
                                </div>
                                <div className="Table_wrapper">
                                    <table className="customTable">
                                        <thead>
                                            <tr>
                                                <th className="Descript_width">Distributor</th>
                                                <th className="Value_width">Amount</th>
                                                <th className="Value_width">Payment Mode</th>
                                                <th className="Value_width">Document No</th>
                                                <th className="">Document Date</th>
                                                <th className="">BankName and Location</th>
                                                {
                                                    this.state.collectionWriteAccess ?
                                                    <th className="o2popDel_container"></th>
                                                    : null
                                                }                                                
                                                <th className="scroller"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !this.state.collectionDetailsList || this.state.collectionDetailsList.length === 0 || this.state.collectionDetailsList == null ?
                                                    <tr align="center">
                                                        <td colSpan="6">{this.state.collectionDetailsList.length} Collection Available</td>
                                                    </tr> :
                                                    this.state.collectionDetailsList.map((collection, i) => (
                                                        <tr key={i}>
                                                            <td className="Descript_width" data-tip={collection.distributorName}><Select options={collection.productDistributorList} value={collection.productDistributorList.filter(({ value }) => value === collection.distributorCode)} placeholder="Choose Distributor" isDisabled={collection.isSelectDisabled} onChange={(e) => this.handleCOLLProductDistributorChange(i, e)} /></td>
                                                            <td className="Value_width" data-tip={collection.collectionAmtStr}><input type="text" id={i} name="collectionAmt" value={collection.collectionAmtStr} onChange={this.collectionEditChange} readOnly={collection.readOnly} /></td>
                                                            <td className="Value_width" data-tip={collection.paymentMode}>
                                                                <select id={i} name="paymentName" value={collection.paymentId} onChange={this.collectionEditChange} disabled={collection.disabled}>
                                                                    {
                                                                        this.state.paymentList.map((payment) => (
                                                                            <option value={payment.paymentId} key={payment.paymentId}>{payment.paymentName}</option>
                                                                        ))
                                                                    }
                                                                </select></td>
                                                            <td className="Value_width" data-tip={collection.documentNumber}><input type="text" id={i} name="documentNumber" value={collection.documentNumber} onChange={this.collectionEditChange} readOnly={collection.readOnly} /></td>
                                                            <td className="" data-tip={collection.documentDate}><input type="date" id={i} name="documentDate" value={collection.documentDate} onChange={this.collectionEditChange} readOnly={collection.readOnly} /></td>
                                                            <td className="" data-tip={collection.bankName}><input type="text" id={i} name="bankName" value={collection.bankName} onChange={this.collectionEditChange} readOnly={collection.readOnly} /></td>
                                                            {
                                                                this.state.collectionWriteAccess ?
                                                                <td className="o2popDel_container">
                                                                    <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.collection_EnableEdit(i)} />
                                                                    <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} onClick={() => this.collection_deleteHandler(i)} />
                                                                </td>
                                                                : null
                                                            }                                                            
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    {
                                        this.state.collectionWriteAccess ?
                                        <button onClick={this.Collection_savefunction}>Save</button>
                                        : null
                                    }                                    
                                    <button onClick={this.Collection_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="O2TableEdit_wrapper newCollection_popup">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">New Collection</div>
                            <div className="O2Table_BodyContent">
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Distributor</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Salesman</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Retailer</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Amount</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text"></input>
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Payment Mode</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select></select>
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Ref No</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text"></input>
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    <button>Save</button>
                                    <button onClick={this.Collectionnew_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(CollectionModule)

//export default CollectionModule
