import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faPlus, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
import { useState } from 'react';
import * as XLSX from 'xlsx';
import fileSaver from 'file-saver';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class ToolModule extends Component {

    constructor(props) {

        var curr = new Date();
        curr.setDate(curr.getDate());
        var currentdate = curr.toISOString().substr(0, 10);

        super(props)
        this.state = {
            loading: false,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            toolNameList: [],
            tmName: '',
            tmSelectedFile: '',
            todayDate: currentdate,
            errorList: [],
            orgErrorList: [],
        }
    }

    componentDidMount() {
        this.getToolNameList();
    }

    getToolNameList() {
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/tool/getToolNameList")
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({
                        toolNameList: data.toolNameList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    handleImportFile = (e) => {

        if (this.state.tmName === undefined || this.state.tmName === "" || this.state.tmName === null) {
            alert("Please Select A Name");
        } else if (this.state.tmSelectedFile === undefined || this.state.tmSelectedFile === "" || this.state.tmSelectedFile === null) {
            alert("Please Choose File");
        } else {

            const formData = new FormData();

            formData.append('files', this.state.tmSelectedFile);

            const params = {
                lastUpdatedBy: this.state.loginuserObj.userName,
                nameValue: this.state.tmName
            }
            this.setState({
                loading: true
            });
            axios.post(this.state.httpURL + "/tool/uploadData", formData, { params })
                .then(response => {
                    this.setState({
                        loading: false
                    });
                    if (response.data.valid) {
                        alert(response.data.responseMsg);
                        this.setState({
                            errorList: response.data.errorList,
                            orgErrorList: response.data.errorList,
                        })
                        ReactTooltip.rebuild();
                    } else {
                        alert(response.data.responseMsg);
                    }
                    this.tool_clearfunction();
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                    alert(error);
                    this.tool_clearfunction();
                })
        }
    }

    handleTMNameChange = (event) => {
        this.setState({
            tmName: event.value
        }, () => {

        });
    }

    handleTMSelectFile = (event) => {
        this.setState({
            tmSelectedFile: event.target.files[0]
        }, () => {
            //console.log("<-123->"+this.state.tmSelectedFile);                                   
        });
    }

    tool_cancelfunction() {
        // if(this.state.urUserRole !== "" && this.state.urUserRole !== null && this.state.urUserRole !== undefined){
        //     this.getBaRetailerList();
        // }
        this.setState({
            tmName: '',
            tmSelectedFile: '',
            errorList: []
        }, () => {

        });
    }

    tool_clearfunction() {
        this.setState({
            tmName: '',
            tmSelectedFile: '',
        }, () => {

        });
    }

    filterList = (event) => {
        let filtered = this.state.orgErrorList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            errorList: filtered
        })
    }

    userExcel = () => {
        var dFlag = true;
        if (this.state.errorList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.errorList;
            var fileName = "Error Report_" + this.state.todayDate;
            var wscols = [
                { wch: 8 },
                { wch: 55 },
                { wch: 15 },
                { wch: 10 },
                { wch: 22 },
                { wch: 10 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Line No": dList[i].lineNo,
                    "Error Message": dList[i].errorMessage,
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'Error List': ws }, SheetNames: ['Error List'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>Import</span></div>
                    <div className="O2Table_SubContainer Report_subContainer">
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Name</div>
                            <div className="contentRow_Value">
                                {/* <Select name="urUserRole" placeholder="Choose User Role"/>  */}
                                <Select options={this.state.toolNameList} name="tmName" value={this.state.toolNameList.filter(({ value }) => value === this.state.tmName)} placeholder="Choose Name" onChange={this.handleTMNameChange} />
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label">File Name</div>
                            <div className="contentRow_Value">
                                {/* <input type="text" type="file" name="tmSelectedFile" ref={(ref) => this.myInput = ref}  /> */}
                                <input type="file" name="tmSelectedFile" onClick={e => (e.target.value = null)} onChange={this.handleTMSelectFile} />
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label"></div>
                            <div className="contentRow_Value">

                            </div>
                        </div>
                        {/* <div className="O2Table_Go" onClick={(e) => this.myInput.click()}><button>Upload a file</button></div> */}
                        <div className="O2Table_Go" onClick={this.handleImportFile}><button>Import</button></div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="metaData_Table">
                            <thead>
                                <tr>
                                    <th className="Ref_Num">Line No
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="lineNo" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th>Message
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="errorMessage" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !this.state.errorList || this.state.errorList === null || this.state.errorList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No Records</td>
                                        </tr> :
                                        this.state.errorList.map((error, i) => (
                                            <tr key={i}>
                                                <td className="Ref_Num" data-tip={error.lineNo}>{error.lineNo}</td>
                                                <td data-tip={error.errorMessage}>{error.errorMessage}</td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.errorList ? this.state.errorList.length : 0}</span>entries</span></div>
                    </div>
                    <div className="O2Table_Footer">
                        {/* <button onClick={this.ba_savefunction}>Save</button>                        */}
                        <button onClick={this.tool_cancelfunction}>Cancel</button>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_ExpExcel" onClick={() => this.userExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(ToolModule)
//export default BARetailerModule
