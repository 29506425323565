import React, { Component } from 'react'
import TopModule from '../App-Body/Top-Module/TopModule';
import MenuBodyModule from '../App-Body/Menu-BodyModule/MenuBodyModule';
import './AppBody.css'
import AppLogin from '../App_Login/AppLogin'


import {connect} from 'react-redux'
import {addUserObj,userLoggedOut} from '../redux/actions'


class AppBody extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
           //isUserLogged:false,
             toggleActive:false,
             isUserLogged:this.props.isUserLogged,
        }
        
        
    }
    
    callbackFunction = (childData,userobj,appPrefrenece) => {
       // console.log("login:"+JSON.stringify(this.props))
        this.setState({isUserLogged: childData,userObj:userobj})
        this.props.addUserObj(userobj,appPrefrenece);
       // console.log("login:"+JSON.stringify(this.props))
    }

    togglecallbackFunction = (childData) => {
        this.setState({toggleActive: childData})
      //  console.log('toggleActive-->'+childData);
    }

    logoutcallbackFunction = () => {
       // console.log("called logoutcallbackFunction")
        this.setState({isUserLogged: false})
        this.props.userLoggedOut();
        
    }
    render() {
        const {toggleActive} =this.state;
       // console.log("toggleActive--"+toggleActive)
        return (
            
            <div className="menuModules">
                {
                    this.state.isUserLogged?
                        <div>
                            <TopModule parentCallback = {this.togglecallbackFunction} logoutflag = {this.logoutcallbackFunction}></TopModule>
                            <MenuBodyModule toggleActive={this.state.toggleActive}></MenuBodyModule>
                        </div>
                      : 
                        <AppLogin parentCallback = {this.callbackFunction}></AppLogin>
                }
            </div>
        )
    }
}


const mapStateToProps=state=>{
    return {
        httpURL:state.httpURL,
        userObj:state.userObj,
        isUserLogged:state.isUserLogged,
    
    }
}



const mapDispatchToProps = dispatch => ({
    addUserObj: (msg,appPrefrenece) => {
        dispatch(addUserObj(msg,appPrefrenece));
    },
    userLoggedOut: msg => {
        dispatch(userLoggedOut());
    }
   });

   
 export default connect(mapStateToProps,mapDispatchToProps)(AppBody)


//export default AppBody
