import React, { Component } from 'react'
import './RetailerApproval.css';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faCloudDownloadAlt, faPencilAlt, faTrashAlt, faFileAlt, faLeaf, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -385px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;


 class RetailerApproval extends Component {

    constructor(props) {
        super(props)
       
        this.state = {
            loading: false,
           
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            allChecked: false,
            salesmanWriteAccess: this.props.userObj.salesmanWriteAccess,
            retailerList: [],
            orgretailerList: [],
        }

    }

    componentDidMount() {
        this.getRetailerList();
    }
   
    getRetailerList() {

        const params = { companyCode: this.state.companyCode , salesmanCode: this.state.salesmanCode}

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/retailerApprovalList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        orgretailerList: data.retailerList,
                        retailerList: data.retailerList,
                      
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    gridFilterList = (event) => {
        let filtered = this.state.orgretailerList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            retailerList: filtered
        })
    }

    handleAllChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        // console.log("else -->"+itemName)
        this.setState(prevState => {
            let { retailerList, allChecked, orgretailerList } = prevState;
            if (itemName === "checkAll") {
                // console.log(checked)
                if (checked) {
                    allChecked = checked;
                    retailerList = retailerList.map(item => ({ ...item, isChecked: checked }));
                    orgretailerList = orgretailerList.map(item => ({ ...item, isChecked: checked }));
                } else {
                    allChecked = false;
                    retailerList = retailerList.map(item => ({ ...item, isChecked: false }));
                    orgretailerList = orgretailerList.map(item => ({ ...item, isChecked: false }));
                }

            }
            return { retailerList, allChecked, orgretailerList };
        });
    };

    handleChange = (e, ind) => {
        //console.log(ind)
        let checked = e.target.checked;
        // console.log(checked)
        const { retailerList, allChecked, orgretailerList } = this.state;
        retailerList[ind].isChecked = checked
        orgretailerList[ind].isChecked = checked

        this.setState({ orgretailerList: orgretailerList, retailerList: retailerList, allChecked: false }, () => {
            //console.log("0 index-->"+JSON.stringify(retailerList[ind]))
            ReactTooltip.rebuild();
        });


    };

    newCust_Savefns = (e) => {
     
           let selectList = this.state.retailerList.filter( i => i.isChecked);
            console.log("selectList->"+JSON.stringify(selectList.length));
            
            if(selectList.length > 0){

                    this.setState({loading: true})


            let params = {
                "retailerList": selectList,
                "companyCode": this.state.loginuserObj.companyCode,
                "lastUpdtedBy": this.state.loginuserObj.userName
            }

            axios.post(this.state.httpURL + "/newcustomer/saveretailerApprovalList", params)
                .then(response => {
                    if (response.data !== null) {
                        if (response.data.valid) {
                            this.setState({
                                loading: false
                            })
                                alert("Retailer Approve Saved Successfully.");
                                this.setState({
                                    retailerList: [],
                                    orgretailerList: [],
                                },()=>{
                                    this.getRetailerList();
                                });
                                
                        } else {
                            this.setState({
                                loading: false
                            });
                            alert(response.data.responseMsg);
                        }
                    }
                })
                .catch(error => {
                    //console.log("error" + error)                    
                    this.setState({
                        loading: false
                    })
                    alert(error);
                })
            }else{
                alert("Select Any one Retailer")
            }
        
    }
    newCust_Rejectfns= (e) => {
     
        let selectList = this.state.retailerList.filter( i => i.isChecked);
         console.log("selectList->"+JSON.stringify(selectList.length));
         
         if(selectList.length > 0){

                 this.setState({loading: true})


         let params = {
             "retailerList": selectList,
             "companyCode": this.state.loginuserObj.companyCode,
             "lastUpdtedBy": this.state.loginuserObj.userName
         }

         axios.post(this.state.httpURL + "/newcustomer/rejectretailerApprovalList", params)
             .then(response => {
                 if (response.data !== null) {
                     if (response.data.valid) {
                         this.setState({
                             loading: false
                         })
                             alert("Retailer Approve Rejected Successfully.");
                             this.setState({
                                 retailerList: [],
                                 orgretailerList: [],
                             },()=>{
                                 this.getRetailerList();
                             });
                             
                     } else {
                         this.setState({
                             loading: false
                         });
                         alert(response.data.responseMsg);
                     }
                 }
             })
             .catch(error => {
                 //console.log("error" + error)                    
                 this.setState({
                     loading: false
                 })
                 alert(error);
             })
         }else{
             alert("Select Any one Retailer")
         }
           
             
             
     
 }


  render() {
    return (
        <div className="O2Container_Wrapper">
        <ReactTooltip />
            <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>Retailer Approval</span></div>
                    <div className="O2Table_Body"></div>
                    <div className="Table_wrapper">
                    <table className="customTable" id="NewCustomer_table">
                                <thead>
                                    <tr>
                                        <th className="checkBox_Container">
                                            <input type="checkbox" name="checkAll" checked={this.state.allChecked} onChange={this.handleAllChange} />
                                        </th>
                                        <th className="Ref_Num">Retailer Code
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerCode" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="retailer_Edit Descript_width">Retailer
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="name" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="Ref_Num">Route
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="routeName" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="Ref_Num">City
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="city" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="retailer_Edit DistAd_width">Retailer Type
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerType" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        {/* <th className="retailer_Edit DistAd_width">Retailer Group
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="routeName" onChange={this.gridFilterList} />
                                            </div>
                                        </th> */}
                                        {/* <th>Distributor</th> */}
                                        {/* <th>Salesman</th>
                                        <th>Description</th> */}
                                        <th className="status_Width">State
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="state" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="status_Width">Created By
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="lastUpdtedBy" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                     
                                        <th className="scroller"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                        !this.state.retailerList || this.state.retailerList === null || this.state.retailerList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="5">No Retailer Available</td>
                                            </tr> :
                                            this.state.retailerList.map((retailer, i) => (
                                                <tr key={retailer.id}>
                                                    <td className="checkBox_Container">
                                                        <input type="checkbox" checked={retailer.isChecked} onChange={(e) => this.handleChange(e, i)} />
                                                    </td>
                                                    <td className="Ref_Num" data-tip={retailer.retailerCode}>{retailer.retailerCode}</td>
                                                    <td className="retailer_Edit Descript_width" data-tip={retailer.name + '_' + retailer.address1 + '_' + retailer.address2}>{retailer.name}</td>
                                                    <td className="Ref_Num" data-tip={retailer.routeName}>{retailer.routeName}</td>
                                                    <td className="Ref_Num" data-tip={retailer.city}>{retailer.city}</td>
                                                    <td className="DistAd_width" data-tip={retailer.retailerType}>{retailer.retailerType}</td>
                                                    <td className="status_Width" data-tip={retailer.status}>{retailer.state}</td>
                                                    <td className="status_Width" data-tip={retailer.status}>{retailer.lastUpdtedBy}</td>
                                              
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.retailerList ? this.state.retailerList.length : 0}</span>entries</span></div>
                            <div className="O2Table_ExpExcel" onClick={() => this.newCust_Savefns()}>Approve</div>
                            <div className="O2Table_ExpExcel" onClick={() => this.newCust_Rejectfns()}>Reject</div>
                        </div>
                        
            </div>

            <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
        </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(RetailerApproval)



