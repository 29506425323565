import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit,faSearch,faPlus } from "@fortawesome/free-solid-svg-icons";
import './RetailerCategoryModule.css';
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux';
import Select from 'react-select';
import ReactTooltip from "react-tooltip";
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class RetailerCategoryModule extends Component {


    filterList = (event) => {
        let filtered =this.state.ptmPriceTypeOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
                
            }
        }
        this.setState({
            ptmPriceTypeList: filtered
        })
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>RETAILER CATEGORY</span></div>
                    {/* <div className="O2Table_SubContainer">
                         <div className="O2Table_Search">
                            <div className="O2Table_Label"> Search : </div>
                            <div className="O2Table_Value"><input type="text" onChange={this.filterList} /></div>
                        </div> 
                        <div className="O2Table_New">
                            <button onClick={this.newRetCategory_openHandler}>New</button>
                        </div>
                    </div> */}
                    <div className="Table_wrapper">
                        <table className="customTable masterTable">
                            <thead>
                                <tr>
                                    <th>Retailer Category
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="priceTypeName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Status_Route">Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="status" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container">
                                        <button><FontAwesomeIcon icon={faPlus} style={{ padding: '0px 4px' }}  /></button>
                                    </th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>                                              
                                    <td>
                                        <Select></Select>
                                    </td>
                                    <td className="Status_Route">
                                        <select></select>
                                    </td>
                                    <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}}/>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}}/>
                                    </td>
                                </tr> 
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> 0 </span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer  pricemasterSub">
                        {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Export</div> */}
                        <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="priceType_Table" filename={"Product Price Type"}
                            sheet="tablexls" buttonText="Excel Export" /></div>
                        <div class="PricemasterFooter">
                            <button>Save</button>
                            <button>Cancel</button>
                        </div>
                    </div>
                    {/* <div class="O2Table_Footer">
                        <button>Save</button>
                        <button>Cancel</button>
                    </div> */}
                </div>
                {/* <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                /> */}
            </div>
        )
    }
}

export default RetailerCategoryModule
