import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import './DistributorPriceMarginModule.css';
import Select from 'react-select';
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -255px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class DistributorPriceMarginModule extends Component {




    constructor(props) {

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);


        super(props)

        this.state = {
            loading: false,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            prodList: [],
            priceTypeList: [],
            productPriceList: [],
            orgproductPriceList: [],
            productTypeList: [],
            productCode: '',
            productKey: '',
            priceType: '',
            purchasePrice: '',
            mrp: '',
            sellPrice: '',
            effectiveDate: todaydate,
            status: 'Y',
            //newCompanyCode: '',
            productPriceObj: '',
            productPriceId: '0',
            productPriceWriteAccess: this.props.userObj.productPriceWriteAccess,
            currentDate: todaydate,
            productType: '',
            distributorType: '',
        }
    }


    componentDidMount() {
        this.getAllProductPriceDetails();
    }

    getAllProductPriceDetails() {
        const params = {
            companyCode: this.state.companyCode,
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/productprice/getAllProductPriceDetails/", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                });
                console.log(data.priceTypeList);
                this.setState({
                    prodList: data.prodList,
                    priceTypeList: data.priceTypeList,
                    productPriceList: data.productPriceList,
                    orgproductPriceList: data.productPriceList,
                    productTypeList: data.productTypeList
                })
                ReactTooltip.rebuild();
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    smChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    ppChange = (e) => {
        const re = /^\d{1,}(\.\d{0,2})?$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    handleProductChange = (event) => {
        // console.log("--"+event.value)
        // console.log(eventproductKey);
        this.setState({
            productCode: event.productCode,
            productKey: event.value,
        })
    }

    handleProductTypeChange = (event) => {
        this.setState({
            productType: event.value
        })
    }

    newProductprice_popCloseHandler = () => {
        document.querySelector('.ProductPriceTableEdit_pop').style.display = 'none';
    }
    newProductprice_openHandler = () => {
        document.querySelector('.ProductPriceTableEdit_pop').style.display = 'flex';
        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);
        this.setState({
            productCode: "",
            productKey: "",
            purchasePrice: "",
            mrp: "",
            sellPrice: "",
            effectiveDate: todaydate,
            status: "Y",
            // newCompanyCode: "",
            productPriceId: '0'
        })
        if (this.state.priceTypeList.length > 0) {

            if (this.state.priceType === "") {
                this.setState({ priceType: this.state.priceTypeList[0].productPriceId })
            }
        }
    }


    newProductprice_editHandler = (prod) => {

        // console.log("----"+prod.productPriceId)
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/productprice/" + prod.productPriceId)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                this.setState({ productPriceObj: data.productPriceList[0] }, () => {
                    // console.log(this.state.productPriceObj.productKey);
                    var curr = new Date();
                    // curr.setDate(this.state.productPriceObj.priceEffectiveDate);
                    var todaydate = curr.toISOString().substr(0, 10);

                    this.setState({
                        priceType: this.state.productPriceObj.priceTypeId,
                        productCode: this.state.productPriceObj.productCode,
                        productKey: this.state.productPriceObj.productKey,
                        purchasePrice: this.state.productPriceObj.purchasePriceStr,
                        mrp: this.state.productPriceObj.mrpStr,
                        sellPrice: this.state.productPriceObj.sellPriceStr,
                        effectiveDate: this.state.productPriceObj.priceEffectiveDateStr,
                        status: this.state.productPriceObj.status,
                        //newCompanyCode: this.state.productPriceObj.companyCode,
                        productPriceId: prod.productPriceId,
                        productType: prod.productTypeCode
                    })
                    // console.log("date-->"+this.state.productPriceObj.priceEffectiveDate)
                    document.querySelector('.ProductPriceTableEdit_pop').style.display = 'flex';
                })
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })

    }

    newProductprice_SaveHandler = () => {

        const params = {
            companyCode: this.state.loginuserObj.companyCode,
            priceTypeId: this.state.priceType,
            productCode: this.state.productCode,
            productKey: this.state.productKey,
            purchasePrice: this.state.purchasePrice,
            mrp: this.state.mrp,
            sellPrice: this.state.sellPrice,
            priceEffectiveDateStr: this.state.effectiveDate,
            status: this.state.status,
            lastUpdatedBy: this.state.loginuserObj.userName,
            productPriceId: this.state.productPriceId,
            productTypeCode: this.state.productType
        }

        // if (this.state.productCode == "") {
        if (this.state.productKey == "") {
            alert("Select Product")
            return false;
        }

        // if (this.state.newCompanyCode == "") {
        //     alert("Enter Company Code")
        //     return false;
        // }


        if (this.state.purchasePrice == "") {
            alert("Enter Purchase Price")
            return false;
        }


        if (this.state.mrp == "") {
            alert("Enter Mrp")
            return false;
        }


        if (this.state.sellPrice == "") {
            alert("Enter Sell Price")
            return false;
        }


        if (this.state.effectiveDate == "") {
            alert("Select Date")
            return false;
        }
        this.setState({
            loading: true
        });
        axios.post(this.state.httpURL + "/productprice", { "productPriceObj": params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                });
                if (data.valid) {
                    alert("Successfully Saved");
                    this.getAllProductPriceDetails();
                    this.newProductprice_popCloseHandler();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    newProductprice_deleteHandler = (delId) => {
        var answer = window.confirm("Are Sure To Delete This Product Price?")
        if (answer) {
            this.setState({
                loading: true
            });
            axios.delete(this.state.httpURL + "/productprice/" + delId)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            alert("Product Price Deleted Successfully ...");
                            this.getAllProductPriceDetails();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    filterList = (event) => {
        let filtered = this.state.orgproductPriceList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            productPriceList: filtered
        })
    }

    productPriceExcel = () => {
        var dFlag = true;
        if (this.state.productPriceList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.productPriceList;
            var fileName = "Product Price Report_" + this.state.currentDate;
            var wscols = [
                { wch: 60 },
                { wch: 40 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 15 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Product Name": dList[i].productName,
                    "Price Type": dList[i].productPriceType,
                    "Purchase Price": dList[i].purchasePrice.toFixed(2),
                    "MRP": dList[i].mrp.toFixed(2),
                    "Sell Price": dList[i].sellPrice.toFixed(2),
                    "Effective Date": dList[i].priceEffectiveDateStr,
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'ProductPrice': ws }, SheetNames: ['ProductPrice'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    handleDistributorTypeChange = (event) => {
        //  console.log("event-->"+JSON.stringify(event))
        this.setState({
            distributorType: event.value
        }, () => {
            this.getDistributorPriceMargin();
        });

    }

    getDistributorPriceMargin = () => {
        console.log('get')
        // let stateVal = '';
        // var dFlag = true;
        // if (this.state.startDate !== '' && this.state.endDate !== '') {
        //     if (this.state.startDate > this.state.endDate) {
        //         alert("From Date Cannot Be Greater Than End Date");
        //         dFlag = false;
        //     }
        // } else {
        //     alert("Please select Start and End Date...");
        //     dFlag = false;
        // }
        // if (this.state.orFState === '' || this.state.orFState === undefined) {
        //     stateVal = "All";
        // } else {
        //     stateVal = this.state.orFState;
        // }

        // if (dFlag) {
        //     const params = {
        //         companyCode: this.state.loginuserObj.companyCode,
        //         distributorCode: "All",
        //         salesmanCode: this.state.salesmanCode,
        //         retailerCode: "All",
        //         // startDate: '',
        //         // endDate: '',
        //         startDate: this.state.startDate,
        //         endDate: this.state.endDate,
        //         orderStatus: this.state.orderStatus,
        //         loginSalesmanCode: this.state.loginSalesmanCode,
        //         orderType: this.state.orderTypeFilter,
        //         state: stateVal,
        //         salesType: this.state.salesType,
        //     }

        //     this.setState({
        //         loading: true
        //     })

        //     axios.get(this.state.httpURL + "/order", { params })
        //         .then(response => response.data)
        //         .then((data) => {
        //             this.setState({
        //                 orderList: data.forOrderDataList,
        //                 orgorderList: data.forOrderDataList,
        //                 // salesmanList:data.salesmanList,
        //                 distributorList: data.distributorList,
        //                 allChecked: false,
        //                 salesmanList: data.salesmanList1
        //                 // productsList:data.productList
        //             }, () => {
        //                 this.setState({
        //                     loading: false
        //                 })
        //                 ReactTooltip.rebuild();
        //             })
        //         }).catch(err => {
        //             //console.log(err)
        //             this.setState({
        //                 loading: false
        //             })
        //         })
        // }
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>DISTRIBUTOR PRICE MARGIN</span></div>
                    <div className="O2Table_SubContainer">
                    <Select options={this.state.priceTypeList} name='distributorPriceMargin' value={this.state.priceTypeList.filter(({ value }) => value === this.state.distributorType)} onChange={this.handleDistributorTypeChange} />
                        {/* <div className="O2Table_New">
                            <button onClick={this.newProductprice_openHandler}>New</button>
                        </div> */}
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="productPrice_Table">
                            <thead>
                                <tr>
                                    {/* <th className="checkBox_Container"></th>                                     */}
                                    {/* <th>Company Code
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="companyCode" onChange={this.filterList} />
                                        </div>
                                    </th> */}
                                    <th >Product Code
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productKey" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Descript_width">Product Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    {/* <th>Product Code</th> */}
                                    <th className="status_Width">Price Type
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productPriceType" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Value_width">Purchase Price
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="purchasePrice" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Value_width">MRP
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="mrp" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Value_width">Sell Price
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="sellPrice" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th>EffectiveDate</th>
                                    {/* <th className="Status_Route">Status</th> */}
                                    <th className="o2Del_container"></th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    !this.state.productPriceList || this.state.productPriceList == null || this.state.productPriceList.length == 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No Product Price Available</td>
                                        </tr>
                                        :
                                        this.state.productPriceList.map((prod, i) => (
                                            <tr key={i}>
                                                {/* <td className="checkBox_Container"></td>                                                 */}
                                                {/* <td>{prod.companyCode}</td> */}
                                                <td data-tip={prod.productKey}>{prod.productKey}</td>
                                                <td className="Descript_width" data-tip={prod.productName}>{prod.productName}</td>
                                                {/* <td>{prod.productCode}</td> */}
                                                <td className="status_Width" data-tip={prod.productPriceType}>{prod.productPriceType}</td>
                                                <td className="Value_width productPriceTabletd" data-tip={prod.purchasePrice.toFixed(2)}>{prod.purchasePrice.toFixed(2)}</td>
                                                <td className="Value_width productPriceTabletd" data-tip={prod.mrp.toFixed(2)}>{prod.mrp.toFixed(2)}</td>
                                                <td className="Value_width productPriceTabletd" data-tip={prod.sellPrice.toFixed(2)}>{prod.sellPrice.toFixed(2)}</td>
                                                <td data-tip={prod.priceEffectiveDateStr}>{prod.priceEffectiveDateStr}</td>
                                                {/* <td className="Status_Route">{prod.status}</td> */}
                                                <td className="o2Del_container">
                                                    <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.newProductprice_editHandler(prod)} />
                                                    {
                                                        this.state.productPriceWriteAccess ?
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} onClick={() => this.newProductprice_deleteHandler(prod.productPriceId)} />
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                        ))

                                }


                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.productPriceList ? this.state.productPriceList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Export</div> */}

                        {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="productPrice_Table" filename={"Product Price"}
                            sheet="tablexls" buttonText="Excel Export" /></div> */}
                        <div className="O2Table_ExpExcel" onClick={() => this.productPriceExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                    </div>
                </div>
                <div className="O2TableEdit_wrapper ProductPriceTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Product Price</div>
                            <div className="RM_BodyContent">
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Price Type </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select name="priceType" value={this.state.priceType} onChange={this.smChange} >
                                            {
                                                this.state.priceTypeList.map((pt) => (
                                                    <option value={pt.productPriceId} key={pt.productPriceId}> {pt.priceTypeName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Product Name </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.prodList} value={this.state.prodList.filter(({ value }) => value === this.state.productKey)} placeholder="Choose Product" onChange={(e) => this.handleProductChange(e)} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Product Type </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.productTypeList} value={this.state.productTypeList.filter(({ value }) => value === this.state.productType)} placeholder="Choose Product Type" onChange={(e) => this.handleProductTypeChange(e)} />
                                    </div>
                                </div>
                                {/* <div className="legentContent_div">
                                    <div className="legendBody_Label">Company Code </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name="newCompanyCode" value={this.state.newCompanyCode} onChange={this.smChange} />
                                    </div>
                                </div> */}

                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Purchace Price </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name="purchasePrice" value={this.state.purchasePrice} onChange={this.ppChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">MRP </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name="mrp" value={this.state.mrp} onChange={this.ppChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Sell Price </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name="sellPrice" value={this.state.sellPrice} onChange={this.ppChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">EffectiveDate </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="date" name="effectiveDate" value={this.state.effectiveDate} onChange={this.smChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Status </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select name="status" value={this.state.status} onChange={this.smChange}>
                                            <option value="Y" >Active</option>
                                            <option value="N" >In Active</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    {
                                        this.state.productPriceWriteAccess ?
                                            <button onClick={this.newProductprice_SaveHandler}>Save</button>
                                            : null
                                    }
                                    <button onClick={this.newProductprice_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(DistributorPriceMarginModule)


//export default DistributorPriceMarginModule
