import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import './MenuContainerModule.css';
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class MenuContainerModule extends Component {

   

    newmenuCreate_popCloseHandler = () => {
        document.querySelector('.MenumaintenanceTableEdit_pop').style.display = 'none';
    }
    newmenuCreate_openHandler = () => {
        document.querySelector('.MenumaintenanceTableEdit_pop').style.display = 'flex';
    }

    filterList = (event) => {
        let filtered =this.state.pgProductGroupOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
            }
        }
        this.setState({
            pgProductGroupList: filtered
        })
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>MENU MAINTENANCE</span></div>
                    <div className="O2Table_SubContainer">
                        <div className="O2Table_New">
                            <button onClick={this.newmenuCreate_openHandler}>New</button>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable">
                            <thead>
                                <tr>
                                    <th>Menu Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productGroupName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th>Sub Menu Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productGroupName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container"></th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>                                               
                                    <td>Order</td>
                                    <td>subOrder</td>
                                    <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}} onClick={ ()=> this.newmenuCreate_openHandler()}/>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}}/>
                                    </td>
                                </tr> 
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> 0</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="productGroup_Table" filename={"Product Group"}
                            sheet="tablexls" buttonText="Excel Export" /></div> */}
                    </div>
                </div>
                <div className="O2TableEdit_wrapper MenumaintenanceTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Menu Maintenance</div>
                            <div className="RM_BodyContent">
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Menu Name </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='' />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">SubMenu Name </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='' />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Status</div><span>:</span>
                                    <div className="legendBody_Value">
                                       <select></select>
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    <button>Save</button>
                                    <button onClick={this.newmenuCreate_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            {/* <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            /> */}
                        </div>
                    </div>
                </div>
                {/* <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                /> */}
            </div>
        )
    }
}
export default MenuContainerModule

