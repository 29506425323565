import React, { Component } from 'react'
import './RouteMaster.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faEdit, faTrashAlt,faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Select from 'react-select';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -95px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class RouteMaster extends Component {

    constructor(props) {

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        super(props)
        this.state = {
            loading: false,
            rmRouteMasterList: [],
            rmOrgRouteMasterList: [],
            orgRouteMasterList: [],
            rmDistributorList: [],
            rmIndianCitiesList: [],
            rmRouteId: '',
            rmRouterName: '',
            rmStatus: '',
            rmDistributorCodeValue: '',
            rmStatusCodeValue: '',
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            rmCityList: [],
            rmOrgCityList: [],
            rmStateList: [],
            rmCity: '',
            rmState: '',
            rmStateName:'',
            routeMasterWriteAccess:this.props.userObj.routeMasterWriteAccess,
            currentDate:todaydate,
            selectedState: '',
        }

    }

    componentDidMount() {
        this.getRouteMasterList();
    }

    getRouteMasterList() {
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/route")
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        //rmRouteMasterList: data.routeList,
                        orgRouteMasterList: data.routeList,
                        rmDistributorList: data.distributorList,
                        rmIndianCitiesList: data.indianCitiesList,
                        rmCityList:data.cityList,
                        rmStateList:data.stateList,
                        rmOrgCityList:data.cityList,
                    })
                    //console.log("routeList->"+JSON.stringify(data.routeList));
                    //console.log("city->"+JSON.stringify(data.cityList));
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
                ReactTooltip.rebuild();
            }).catch(err => {
                //console.log(err)
                alert(err);
                this.setState({
                    loading: false
                })
            })
    }

    handleRMDistributorChange = (event) => {
        this.setState({
            rmDistributorCodeValue: event.value
        });

    }

    rmStatusChange = (event) => {
        this.setState({
            rmStatusCodeValue: event.target.value
        });

    }

    rmChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleNCStateChange = (e) => {
        this.setState({
            rmState: e.value,
            rmStateName: e.label
        });
        
        let params = {
            "value": e.value,
            "id": 1,
        }
        this.setState({
            loading: true
        })
    axios.get(this.state.httpURL + "/route/getCityStateList",{params})
        .then(response => response.data)
        .then((data) => {
            if (data.valid) {
                this.setState({                    
                    rmCityList:data.cityList,
                    rmCity: '',
                })
            } else {
                alert(data.responseMsg);
            }
            this.setState({
                loading: false
            })
            ReactTooltip.rebuild();
        }).catch(err => {
            //console.log(err)
            alert(err);
            this.setState({
                loading: false
            })
        })
    }

    handleNCCityChange = (e) => {
        this.setState({
            rmCity: e.value
        });

    //     let params = {
    //         "value": e.value,
    //         "id": 2,
    //     }
    //     this.setState({
    //         loading: true
    //     })
    // axios.get(this.state.httpURL + "/route/getCityStateList",{params})
    //     .then(response => response.data)
    //     .then((data) => {
    //         if (data.valid) {
    //             this.setState({                    
    //                 rmStateList:data.stateList
    //             })
    //         } else {
    //             alert(data.responseMsg);
    //         }
    //         this.setState({
    //             loading: false
    //         })
    //         ReactTooltip.rebuild();
    //     }).catch(err => {
    //         //console.log(err)
    //         alert(err);
    //         this.setState({
    //             loading: false
    //         })
    //     })
    }

    rmSaveFns = (e) => {

        // if (this.state.rmDistributorCodeValue == "") {
        //     alert("Please Select Distributor");
        // } 

        if (this.state.rmRouterName === "" || this.state.rmRouterName === null || this.state.rmRouterName === undefined) {
            alert("Please Enter RouterName");
        } else if (this.state.rmStatusCodeValue === "" || this.state.rmStatusCodeValue === null || this.state.rmStatusCodeValue === undefined) {
            alert("Please Select Status");
        } else {

            let uCode = this.state.loginuserObj.userName;
            const newRM = [{
                routeId: this.state.rmRouteId,
                distributorCode: this.state.rmDistributorCodeValue,
                routeName: this.state.rmRouterName,
                status: this.state.rmStatusCodeValue,
                modifiedBy: uCode,
                state:this.state.rmStateName,
                city:this.state.rmCity
            }];

            let params = {
                "routeList": newRM
            }
            this.setState({
                loading: true
            })
            axios.post(this.state.httpURL + "/route/", params)
                .then(response => {
                    if (response.data !== null) {
                        if (response.data.valid) {
                            this.setState({
                                loading: false
                            })
                            if (response.data.existRoute) {
                                alert("This Route Is Already Exist.");
                            } else {
                                alert("Route Saved Successfully.");
                                this.clearRouteMaster();
                                document.querySelector('.routeMasterTableEdit_pop').style.display = 'none';
                                this.getRouteMasterList();
                            }
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                })
                .catch(error => {
                    //console.log("error" + error)
                    this.setState({
                        loading: false
                    })
                    alert(error);
                })
        }
    }


    newRM_popCloseHandler = () => {
        document.querySelector('.routeMasterTableEdit_pop').style.display = 'none';
    }
    newRM_openHandler = () => {
        this.clearRouteMaster();
        document.querySelector('.routeMasterTableEdit_pop').style.display = 'flex';
    }

    editRM_openHandler = (route) => {        
        let params = {
            "value": route.stateCode,
            "id": 1,
        }
        this.setState({
            loading: true
        })
    axios.get(this.state.httpURL + "/route/getCityStateList",{params})
        .then(response => response.data)
        .then((data) => {
            if (data.valid) {
                this.setState({                    
                    rmCityList:data.cityList,                    
                },()=>{  
                    this.setState({
                        rmRouteId: route.routeId,
                        rmDistributorCodeValue: route.distributorCode,
                        rmRouterName: route.routeName,
                        rmStatusCodeValue: route.status,
                        rmCity: route.city,
                        rmState: route.stateCode,
                        rmStateName: route.state
                    });
                })
            } else {
                alert(data.responseMsg);
            }
            this.setState({
                loading: false
            })
            ReactTooltip.rebuild();
        }).catch(err => {
            //console.log(err)
            alert(err);
            this.setState({
                loading: false
            })
        })
        document.querySelector('.routeMasterTableEdit_pop').style.display = 'flex';
    }

    clearRouteMaster() {
        this.setState({
            rmRouteId: '0',
            rmDistributorCodeValue: 'All',
            rmRouterName: '',
            rmStatusCodeValue: 'Y',
            rmCity: '',
            rmState: '',
        });
    }

    filterList = (event) => {
        let filtered =this.state.rmOrgRouteMasterList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
                
            }
        }
        this.setState({
            rmRouteMasterList: filtered
        })
    }

    deleteRM_openHandler = (route) => {
        if (window.confirm("Delete The Route?")) {
            this.setState({
                loading: true
            })
            axios.delete(this.state.httpURL + "/route/" + route.routeId)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        })
                        if (response.data.valid) {
                            alert("Route Deleted Successfull ...");
                            this.getRouteMasterList();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    })
                    alert(err);
                })
        }
    }

    routeExcel = () =>{
        var dFlag = true;
        if(this.state.rmRouteMasterList.length === 0){
            alert("No Records");
            dFlag = false;
        } 
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.rmRouteMasterList;
            var fileName = "Route Report_"+this.state.currentDate;
            var wscols = [  
                {wch:35},              
                {wch:35},
                {wch:35},
                {wch:35},
                {wch:10}
            ];
            for(var i=0;i<dList.length;i++){
                arr = {};
                arr = {
                    "Route Code": dList[i].routeCode,
                    "Route Name": dList[i].routeName,
                    "State": dList[i].state,  
                    "City": dList[i].city,                  
                    "Status": dList[i].status                                       
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'Route': ws }, SheetNames: ['Route'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            fileSaver.saveAs(data, fileName + fileExtension);
        }        
    }

    stateSelectChange = (event) => {
        let data = this.state.rmOrgCityList.filter(function(item){
            return item.stateCode == event.value;
         })

        this.setState({
            selectedState: event.value,
            rmCityList:data
        }, () => {          });
    }
    citySelectChange = (event) => {
        this.setState({
            selectedCity: event.value
        }, () => {     });
    }

    loadRouterDetails = () => {

        let a=this.state.selectedState;
        let b=this.state.selectedCity;

        if(this.state.selectedState.length == 0){
            alert("Select State");
        }else{

            if(this.state.selectedCity !=undefined && this.state.selectedState !=""){

                let data = this.state.orgRouteMasterList.filter(function(item){
                    return item.stateCode == a && item.city == b ;
                 })
                this.setState({
                    rmRouteMasterList: data,
                    rmOrgRouteMasterList:data,
                }, () => {          });

            }else if(this.state.selectedState !="" && this.state.selectedCity ==undefined){

                let data = this.state.orgRouteMasterList.filter(function(item){
                    return item.stateCode == a ;
                 })
                this.setState({
                    rmRouteMasterList: data,
                    rmOrgRouteMasterList:data,
                }, () => {          });

            }

            

        }
       


    }
    render() {

        const { rmRouterName } = this.state
        const distributorCodeSearch = [];    
        for (const [index, value] of this.state.rmDistributorList.entries()) {
            distributorCodeSearch.push({
                label: value.distributorName,
                value: value.distributorCode
            });
        }   
       
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>ROUTE MASTER</span></div>

                    {/* <div className="O2Table_SubContainer">
                        <div className="O2Table_SelectSales">Salesman<select><option>All</option></select></div>
                        <div className="O2Table_SelectDist">Distributor<select><option>All</option></select></div>
                    </div> */}
                    <div className="O2Table_SubContainer OrderSubContainer">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">State</div>
                                <div className="contentRow_Value">
                                <Select  name="rstate" value={this.state.rmStateList.filter(({ value }) => value === this.state.selectedState)} placeholder="Select State" onChange={this.stateSelectChange} options={this.state.rmStateList} />
                                </div>
                            </div>

                            <div className="O2Content_row">
                                <div className="contentRow_Label">City</div>
                                <div className="contentRow_Value">
                                <Select  name="rcity"  placeholder="Select City" onChange={this.citySelectChange} options={this.state.rmCityList} value={this.state.rmCityList.filter(({ value }) => value === this.state.selectedCity)}/>
                                </div>
                            </div>
                            <div className="O2Table_New">
                                <button onClick={this.loadRouterDetails}>Go</button>
                            </div>
                       
                        <div className="O2Table_New">
                            <button onClick={this.newRM_openHandler}>New</button>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="routeMaster_Table">
                            <thead>
                                <tr>
                                    <th>Route Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="routeName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Status_Route">Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="status" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container"></th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !this.state.rmRouteMasterList || this.state.rmRouteMasterList === null || this.state.rmRouteMasterList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No Routes Available</td>
                                        </tr> :
                                        this.state.rmRouteMasterList.map((route) => (
                                            <tr key={route.routeId}>
                                                <td data-tip={route.routeName}>{route.routeName}
                                                    {/* <button className="editBtn" onClick={ ()=> this.editRM_openHandler(route)}><FontAwesomeIcon icon={faPencilAlt} style={{ padding: '0px 8px' }} /></button> */}
                                                </td>
                                                <td className="Status_Route" data-tip={route.status}>{route.status}</td>
                                                <td className="o2Del_container">
                                                    <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.editRM_openHandler(route)} />
                                                    {
                                                        this.state.routeMasterWriteAccess ?
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} onClick={() => this.deleteRM_openHandler(route)} />
                                                        : null
                                                    }                                                    
                                                </td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.rmRouteMasterList ? this.state.rmRouteMasterList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Export</div> */}
                        {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="routeMaster_Table" filename={"RouteMaster"}
                            sheet="tablexls" buttonText="Excel Export" /></div> */}
                        <div className="O2Table_ExpExcel" onClick={ ()=> this.routeExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }}/>Excel Export</div>
                    </div>
                </div>
                <div className="O2TableEdit_wrapper routeMasterTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Route Master</div>
                            <div className="RM_BodyContent">
                                {/* <div className="legentContent_div">
                                    <div className="legendBody_Label">Distributor  </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={distributorCodeSearch} name="distributorCode"  value={distributorCodeSearch.filter(({value}) => value === this.state.rmDistributorCodeValue)} placeholder="Choose Distributor Code" onChange={this.handleRMDistributorChange}/>                                        
                                    </div>
                                </div> */}
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Route Name </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input name='rmRouterName' type="text" value={rmRouterName} onChange={this.rmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">State</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.rmStateList} name="rmState" value={this.state.rmStateList.filter(({ value }) => value === this.state.rmState)} placeholder="Choose State" onChange={this.handleNCStateChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">City</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.rmCityList} name="rmCity" value={this.state.rmCityList.filter(({ value }) => value === this.state.rmCity)} placeholder="Choose City" onChange={this.handleNCCityChange} />
                                    </div>
                                </div>                                
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Status </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select value={this.state.rmStatusCodeValue} onChange={this.rmStatusChange}>
                                            <option value="Y" >Active</option>
                                            <option value="N" >In Active</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    {
                                        this.state.routeMasterWriteAccess ?
                                            <button onClick={this.rmSaveFns}>Save</button>
                                        : null
                                    }                                    
                                    <button onClick={this.newRM_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(RouteMaster)


//export default RouteMaster
