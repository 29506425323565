import React, { Component } from 'react'
import './LoadingPageModule.css'
import OrderModule from './OrderModule/OrderModule';
import CollectionModule from './CollectionModule/CollectionModule';
import SalesReturnsModule from './SalesReturnsModule/SalesReturnsModule';
import NewCustomer from './NewCustomer/NewCustomer';
import Competitors from './Competitors/Competitors';
import Inventory from './Inventory/Inventory';
import SalesmanRoute from './SalesmanRoute/SalesmanRoute';
import RouteMaster from './RouteMaster/RouteMaster';
import Salesman from './Salesman/Salesman';
import ReportsModule from './ReportsModule/ReportsModule';
import DayMaster from './DayMasterModule/DayMasterModule';
import { Switch, Route } from 'react-router-dom';
import DayMasterModule from './DayMasterModule/DayMasterModule';
import PriceTypeModule from './PriceTypeModule/PriceTypeModule';
import ProductModule from './ProductModule/ProductModule';
import ProductPriceModule from './ProductPriceModule/ProductPriceModule';
import RetailerPriceModule from './RetailerPriceModule/RetailerPriceModule';
import ProductGroupModule from './ProductGroupModule/ProductGroupModule';
import DistributorStockModule from './DistributorStockModule/DistributorStockModule';
import DataSync from './DataSync/DataSync';
import ProductTypeModule from './ProductType/ProductTypeModule';
import DistributorModule from './DistributorModule/DistributorModule';
import ClientModule from './ViewSyncData/ClientModule/ClientModule';
import OrganizationModule from './ViewSyncData/OrganizationModule/OrganizationModule';
import UserModule from './ViewSyncData/UserModule/UserModule';
import BankModule from './ViewSyncData/BankModule/BankModule';
import InvoiceModule from './ViewSyncData/InvoiceModule/InvoiceModule';
import UOM_Module from './ViewSyncData/UOM_Module/UOM_Module';
import ProductModuleView from './ViewSyncData/ProductModuleView/ProductModuleView';
import PriceListModule from './ViewSyncData/PriceListModule/PriceListModule';
import BusinessPartnerModule from './ViewSyncData/BusinessPartnerModule/BusinessPartnerModule';
import PartnerLocation from './ViewSyncData/PartnerLocation/PartnerLocation';
import DocumentType from './ViewSyncData/DocumentType/DocumentType';
import LocationModule from './ViewSyncData/LocationModule/LocationModule';
import PaymentTermModule from './ViewSyncData/PaymentTermModule/PaymentTermModule';
import WarehouseModule from './ViewSyncData/WarehouseModule/WarehouseModule';
import ErpUploadedOrderModule from './ErpUploadedOrderModule/ErpUploadedOrder';
import MenuContainerModule from './Admin/MenuContainerModule/MenuContainerModule';
import RoleAccessModule from './Admin/RoleAccessModule/RoleAccessModule';
import VisitPlanModule from './VisitPlanModule/VisitPlanModule';
import RetailerPricingModule from './RetailerPricingModule/RetailerPricingModule';
import DistributorPricingModule from './DistributorPricingModule/DistributorPricingModule';
import RetailerProductPriceModule from './RetailerProductPriceModule/RetailerProductPriceModule';
import RetailerCategoryModule from './RetailerCategoryModule/RetailerCategoryModule';
import RetailerGroupModule from './RetailerGroupModule/RetailerGroupModule';
import DistributorCategoryModule from './DistributorCategoryModule/DistributorCategoryModule';
import DistributorGroupModule from './DistributorGroupModule/DistributorGroupModule';
import DistributorProductPriceModule from './DistributorProductPriceModule/DistributorProductPriceModule';
import EmployeeTaskModule from './EmployeeTaskModule/EmployeeTaskModule';
import VisitDetailModule from './VisitDetailModule/VisitDetailModule';
import UserMainModule from './UserMainModule/UserMainModule';
import RetailerSchemeProductMaintainModule from './RetailerSchemeProductsMaintainModule/RetailerSchemeProductMaintainModule';
import GenerateRetailerSchemeProductModule from './GenerateRetailerSchemeProductModule/GenerateRetailerSchemeProductModule';
import MetaDataModule from './MetaDataModule/MetaDataModule';
import UserRoleModule from './UserRoleModule/UserRoleModule';
import UserTeamModule from './UserTeamModule/UserTeamModule';
import BARetailerModule from './BARetailerModule/BARetailerModule';
import BARetailerOutLetModule from './BARetailerOutLetModule/BARetailerOutLetModule';
import ViewPhotoModule from './ViewPhotoModule/ViewPhotoModule';
import ExpensesViewModule from './ExpensesViewModule/ExpensesViewModule';
import StateCityModule from './StateCityModule/StateCityModule';
import BaViewOrdermodule from './BAViewOrderModule/BaViewOrdermodule';
import ToolModule from './ToolModule/ToolModule';
import RetailerDistributor_Report from './RetailerDistributor_Report/RetailerDistributor_Report';
import RetailerRoute_Report from './RetailerRoute_Report/RetailerRoute_Report';
import SalesmanReportsModule from './SalesmanReportsModule/SalesmanReportsModule';
import ProductReportsModule from './ProductReportsModule/ProductReportsModule';
import SaleOrderDetailReport from './SaleOrderDetailReport/SaleOrderDetailReport';
import UsageDetailReport from './UsageDetailReport/UsageDetailReport';
import UsageDetailSummaryReport from './UsageDetailSummaryReport/UsageDetailSummaryReport';
import BAUsageDetailSummaryReport from './BAUsageDetailSummaryReport/BAUsageDetailSummaryReport';
import BASalesByRetailerReport from './BASalesByRetailerReport/BASalesByRetailerReport';
import UserAppVersionReport from './UserAppVersionReport/UserAppVersionReport';
import SalesReportOnDemandReport from './SalesReportOnDemand/SalesReportOnDemand';
import ActivityReportsModule from './ActivityReportsModule/ActivityReportsModule';
import RetailerReport from './RetailerReports/RetailerReport';
import RetailerApproval from './RetailerApproval/RetailerApproval';
import RetailerTeamAllocation from './RetailerTeamAllocation/RetailerTeamAllocation';
import UploadSalesData from './UploadSalesData/UploadSalesData';
import UserAttendance from './UserAttendance/UserAttendance';
import BAAttendance from './BAAttendance/BAAttendance';
import DistributorPriceMarginModule from './DistributorPriceMarginModule/DistributorPriceMarginModule';


class LoadingPageModule extends Component {
    render() {
        return (
            <div className='LoadingContainer'>
                <Switch>
                    <Route exact path="/" component={OrderModule} />
                    <Route path="/order" exact component={OrderModule} />
                    <Route path="/collection" exact component={CollectionModule} />
                    <Route path="/salesReturn" exact component={SalesReturnsModule} />
                    <Route path="/newCustomer" exact component={NewCustomer} />
                    {/* <Route path="/competitors" exact component={Competitors}/> */}
                    {/* <Route path="/inventory" exact component={Inventory}/> */}
                    <Route path="/distributorStock" exact component={DistributorStockModule} />
                    <Route path="/salesmanRoute" exact component={SalesmanRoute} />
                    <Route path="/dayMaster" exact component={DayMasterModule} />
                    <Route path="/priceType" exact component={PriceTypeModule} />
                    <Route path="/product" exact component={ProductModule} />
                    <Route path="/productPrice" exact component={ProductPriceModule} />
                    <Route path="/retailerPrice" exact component={RetailerPriceModule} />
                    <Route path="/productGroup" exact component={ProductGroupModule} />
                    <Route path="/routeMaster" exact component={RouteMaster} />
                    <Route path="/salesman" exact component={Salesman} />
                    <Route path="/reports" exact component={ReportsModule} />
                    <Route path="/dataSync" exact component={DataSync} />
                    <Route path="/productType" exact component={ProductTypeModule} />
                    <Route path="/distributor" exact component={DistributorModule} />
                    <Route path="/client" exact component={ClientModule} />
                    <Route path="/organization" exact component={OrganizationModule} />
                    <Route path="/user" exact component={UserModule} />
                    <Route path="/bank" exact component={BankModule} />
                    <Route path="/invoice" exact component={InvoiceModule} />
                    <Route path="/uom" exact component={UOM_Module} />
                    <Route path="/productView" exact component={ProductModuleView} />
                    <Route path="/priceListView" exact component={PriceListModule} />
                    <Route path="/businessPartner" exact component={BusinessPartnerModule} />
                    <Route path="/businessPartnerLocation" exact component={PartnerLocation} />
                    <Route path="/documentType" exact component={DocumentType} />
                    <Route path="/location" exact component={LocationModule} />
                    <Route path="/paymentTerm" exact component={PaymentTermModule} />
                    <Route path="/wareHouse" exact component={WarehouseModule} />
                    <Route path="/erpUploadedOrder" exact component={ErpUploadedOrderModule} />
                    <Route path="/menuMaintenance" exact component={MenuContainerModule} />
                    <Route path="/roleAccess" exact component={RoleAccessModule} />
                    <Route path="/visitPlan" exact component={VisitPlanModule} />
                    <Route path="/retailerPricing" exact component={RetailerPricingModule} />
                    <Route path="/distributorPricing" exact component={DistributorPricingModule} />
                    <Route path="/retailerProductPrice" exact component={RetailerProductPriceModule} />
                    <Route path="/distributorProductPrice" exact component={DistributorProductPriceModule} />
                    <Route path="/retailerCategory" exact component={RetailerCategoryModule} />
                    <Route path="/retailerGroup" exact component={RetailerGroupModule} />
                    <Route path="/distributorCategory" exact component={DistributorCategoryModule} />
                    <Route path="/distributorGroup" exact component={DistributorGroupModule} />
                    <Route path="/employeeTask" exact component={EmployeeTaskModule} />
                    <Route path="/visitDetail" exact component={VisitDetailModule} />
                    <Route path="/userAccess" exact component={UserMainModule} />
                    <Route path="/schemeMaintain" exact component={RetailerSchemeProductMaintainModule} />
                    <Route path="/schemeGenerate" exact component={GenerateRetailerSchemeProductModule} />
                    <Route path="/metaDataModule" exact component={MetaDataModule} />
                    <Route path="/userRoleModule" exact component={UserRoleModule} />
                    <Route path="/userTeamModule" exact component={UserTeamModule} />
                    <Route path="/baRetailer" exact component={BARetailerModule} />
                    <Route path="/baRetailerOutLet" exact component={BARetailerOutLetModule} />
                    <Route path="/viewPhoto" exact component={ViewPhotoModule} />
                    <Route path="/expensesView" exact component={ExpensesViewModule} />
                    <Route path="/cityState" exact component={StateCityModule} />
                    <Route path="/viewBASales" exact component={BaViewOrdermodule} />
                    <Route path="/toolModule" exact component={ToolModule} />
                    <Route path="/retailerDistributorReport" exact component={RetailerDistributor_Report} />
                    <Route path="/retailerRouteReport" exact component={RetailerRoute_Report} />
                    <Route path="/salesmanReport" exact component={SalesmanReportsModule} />
                    <Route path="/productReport" exact component={ProductReportsModule} />
                    <Route path="/SaleOrderDetailReport" exact component={SaleOrderDetailReport} />
                    <Route path="/UsageDetailReport" exact component={UsageDetailReport} />
                    <Route path="/UsageDetailSummaryReport" exact component={UsageDetailSummaryReport} />
                    <Route path="/BA-USummaryReport" exact component={BAUsageDetailSummaryReport} />
                    <Route path="/BASalesByRetailerReport" exact component={BASalesByRetailerReport} />
                    <Route path="/UserAppVersionReport" exact component={UserAppVersionReport} />
                    <Route path="/SalesReportOnDemandReport" exact component={SalesReportOnDemandReport} />
                    <Route path="/ActivityReports" exact component={ActivityReportsModule} />
                    <Route path="/RetailerReport" exact component={RetailerReport} />
                    <Route path="/retailerApproval" exact component={RetailerApproval} />
                    <Route path="/retailerTeamAllocation" exact component={RetailerTeamAllocation} />
                    <Route path="/uploadSalesData" exact component={UploadSalesData} />
                    <Route path="/userAttendance" exact component={UserAttendance} />
                    <Route path="/baAttendance" exact component={BAAttendance} />
                    <Route path="/distributorPriceMargin" exact component={DistributorPriceMarginModule} />
                </Switch>
            </div>
        )
    }
}

export default LoadingPageModule
