import React, { Component } from 'react'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faSearch, faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import './DistributorModule.css';
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux'
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

const override1 = css`
  display: block;
  top: -400px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class DistributorModule extends Component {


    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            Distributorbtn: "Popup_tabButtons",
            DistServbtn: "Popup_tabButtons",
            dmDistributorList: [],
            dmOrgDistributorList: [],
            dmErpList: [],
            dmErpOrgList: [],
            dmDistributorTypeList: [],
            dmProductGroupList: [],
            dmProductTypeList: [],
            dmDistributorProductList: [],
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            httpPostalURL: this.props.httpPostalURL,
            allChecked: false,
            dmId: 0,
            dmName: '',
            dmDistributorCode: '',
            dmErpCode: '',
            dmGstinnum: '',
            dmPanNum: '',
            dmRefNum: '',
            dmDistributorType: '',
            dmAddressLine1: '',
            dmAddressLine2: '',
            dmAddressLine3: '',
            dmAddressLine4: '',
            dmPinCode: '',
            dmCity: '',
            dmState: '',
            dmCountry: '',
            dmLandMark: '',
            dmLatitude: '',
            dmLongtitude: '',
            dmContactName: '',
            dmContactPhone: '',
            dmContactEmail: '',
            dmPendingAmount: '',
            dmCreditLimit: '',
            dmCreditDays: '',
            dmTINNumber: '',
            dmStatus: 'Y',
            dmErpEnabled: 'N',
            distributorWriteAccess: this.props.userObj.distributorWriteAccess,
            currentDate: todaydate,
            dmStateList: [],
            dmCityList: [],
            dmFStateList: [],
            dmFCityList: [],
            dmFState: '',
            dmFCity: '',
            searchDistributorCode: '',
            searchDistributorName: '',
        }

    }

    componentDidMount() {
        //this.getDMDistributor();
        this.getDMFilterStateList();
    }

    getDMFilterStateList() {
        const params = {
            companyCode: this.props.userObj.companyCode,
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/distributor/getStateList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        dmFStateList: data.dStateList,
                        dmErpList: data.erpCodeList,
                        dmErpOrgList: data.erpCodeList,
                        dmDistributorTypeList: data.distributorTypeList,
                        dmProductGroupList: data.productGroupList,
                        dmProductTypeList: data.productTypeList,
                        dmStateList: data.indianStateList,
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getDMFilterCityList() {
        const params = {
            companyCode: this.props.userObj.companyCode,
            state: this.state.dmFState
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/distributor/getCityList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        dmFCityList: data.dCityList,
                        dmFCity: data.dCityList[0].value,
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getDMDistributor() {

        const params = {
            companyCode: this.props.userObj.companyCode,
            state: this.state.dmFState,
            city: this.state.dmFCity,
            distributorCode: this.state.searchDistributorCode,
            distributorName: this.state.searchDistributorName
        }

        this.setState({
            loading: true
        });
        // const params = {
        //     companyCode: this.state.loginuserObj.companyCode,            
        // }        
        //axios.get(this.state.httpURL + "/distributor/getDistributorList", { params })
        axios.get(this.state.httpURL + "/distributor/getDistributor", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        dmDistributorList: data.distributorDetails,
                        dmOrgDistributorList: data.distributorDetails,
                        // dmErpList: data.erpCodeList,
                        // dmErpOrgList: data.erpCodeList,
                        // dmDistributorTypeList: data.distributorTypeList,
                        // dmProductGroupList: data.productGroupList,
                        // dmProductTypeList: data.productTypeList,
                        // dmStateList:data.indianStateList,
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    poptabDistributorHandler = () => {
        this.setState({
            Distributorbtn: "Popup_tabButtonsActive",
            DistServbtn: "Popup_tabButtons",
        })
        document.querySelector('.retailerTab').style.display = 'block';
        document.querySelector('.distributorTab').style.display = 'none';

    }
    poptabDistributorServHandler = () => {
        this.setState({
            Distributorbtn: "Popup_tabButtons ",
            DistServbtn: "Popup_tabButtonsActive",
        })
        document.querySelector('.distributorTab').style.display = 'block';
        document.querySelector('.retailerTab').style.display = 'none';
    }

    dmNewpopup_openHandler = () => {
        this.clearDM();
        var emptyobj = {
            companyCode: this.state.loginuserObj.companyCode,
            distributorCode: '',
            productGroupId: 0,
            value: '',
            status: "Y",
            lastUpdtedBy: this.state.loginuserObj.userName
        }

        if (!this.state.dmDistributorProductList || this.state.dmDistributorProductList.length === 0 || this.state.dmDistributorProductList.length >= 0) {
            let emptylist = []
            emptylist.push(emptyobj)
            this.setState({
                dmDistributorProductList: emptylist
            })
        }
        this.setState({
            Distributorbtn: "Popup_tabButtonsActive",
            DistServbtn: "Popup_tabButtons",
        })
        document.querySelector('.retailerTab').style.display = 'block';
        document.querySelector('.distributorTab').style.display = 'none';

        document.querySelector('.newCustEdit_wrapper').style.display = 'flex';
    }

    dmEditOpenHandler = (dtr) => {
        this.clearEditDM(dtr);
        //console.log("<--1-->",dtr);       
    }

    dmCloseHandler = () => {
        document.querySelector('.newCustEdit_wrapper').style.display = 'none';
    }

    dmCheckHandle = (e, ind) => {
        //console.log(ind)
        let checked = e.target.checked;
        // console.log(checked)
        const { dmDistributorList, allChecked, dmOrgDistributorList } = this.state;
        dmDistributorList[ind].isChecked = checked
        dmOrgDistributorList[ind].isChecked = checked

        this.setState({ dmDistributorList: dmDistributorList, dmOrgDistributorList: dmOrgDistributorList, allChecked: false }, () => {
            ReactTooltip.rebuild();
        });
    };

    handleAllChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        // console.log("else -->"+itemName)
        this.setState(prevState => {
            let { dmDistributorList, allChecked, dmOrgDistributorList } = prevState;
            if (itemName === "checkAll") {
                // console.log(checked)
                if (checked) {
                    allChecked = checked;
                    dmDistributorList = dmDistributorList.map(item => ({ ...item, isChecked: checked }));
                    dmOrgDistributorList = dmOrgDistributorList.map(item => ({ ...item, isChecked: checked }));
                } else {
                    allChecked = false;
                    dmDistributorList = dmDistributorList.map(item => ({ ...item, isChecked: false }));
                    dmOrgDistributorList = dmOrgDistributorList.map(item => ({ ...item, isChecked: false }));
                }

            }
            return { dmDistributorList, allChecked, dmOrgDistributorList };
        });
    };

    clearDM() {
        this.setState({
            dmId: 0,
            dmName: '',
            dmDistributorCode: '',
            dmErpCode: '',
            dmGstinnum: '',
            dmPanNum: '',
            dmRefNum: '',
            dmDistributorType: '',
            dmAddressLine1: '',
            dmAddressLine2: '',
            dmAddressLine3: '',
            dmAddressLine4: '',
            dmPinCode: '',
            dmCity: '',
            dmState: '',
            dmCountry: '',
            dmLandMark: '',
            dmLatitude: '',
            dmLongtitude: '',
            dmContactName: '',
            dmContactPhone: '',
            dmContactEmail: '',
            dmPendingAmount: '',
            dmCreditLimit: '',
            dmCreditDays: '',
            dmTINNumber: '',
            dmStatus: 'Y',
            dmErpEnabled: 'N'
        });
    }

    clearEditDM(dtr) {
        this.setState({
            dmName: '',
            dmDistributorCode: '',
            dmErpCode: '',
            dmGstinnum: '',
            dmPanNum: '',
            dmRefNum: '',
            dmDistributorType: '',
            dmAddressLine1: '',
            dmAddressLine2: '',
            dmAddressLine3: '',
            dmAddressLine4: '',
            dmPinCode: '',
            dmCity: '',
            dmState: '',
            dmCountry: '',
            dmLandMark: '',
            dmLatitude: '',
            dmLongtitude: '',
            dmContactName: '',
            dmContactPhone: '',
            dmContactEmail: '',
            dmPendingAmount: '',
            dmCreditLimit: '',
            dmCreditDays: '',
            dmTINNumber: '',
            dmStatus: 'Y',
            dmErpEnabled: 'N'
        }, () => {
            this.setState({
                dmId: dtr.id,
                dmName: dtr.distributorName,
                dmDistributorCode: dtr.distributorCode,
                dmErpCode: dtr.erpCode,
                dmGstinnum: dtr.gstinNumber,
                dmPanNum: dtr.panNumber,
                dmRefNum: dtr.refNum,
                dmDistributorType: dtr.distributorType,
                dmAddressLine1: dtr.address1,
                dmAddressLine2: dtr.address2,
                dmAddressLine3: dtr.address3,
                dmAddressLine4: dtr.address4,
                dmPinCode: dtr.pincode,
                dmCity: dtr.city,
                dmState: dtr.state,
                dmCountry: dtr.country,
                dmLandMark: dtr.landmark,
                dmLatitude: dtr.latitude,
                dmLongtitude: dtr.longitude,
                dmContactName: dtr.contactName,
                dmContactPhone: dtr.contactPhone,
                dmContactEmail: dtr.contactEmail,
                dmPendingAmount: dtr.pendingAmt,
                dmCreditLimit: dtr.creditLimit,
                dmCreditDays: dtr.creditDays,
                dmTINNumber: dtr.tinNumber,
                dmStatus: dtr.status,
                dmErpEnabled: dtr.erpEnabled
            });

            this.setState({
                loading: true
            });
            const params = {
                companyCode: this.state.loginuserObj.companyCode,
                distributorCode: dtr.distributorCode
            }
            axios.get(this.state.httpURL + "/distributor/getDistributorProduct", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.setState({
                            dmDistributorProductList: data.distributorProductList
                        })
                        ReactTooltip.rebuild();
                    } else {
                        alert(data.responseMsg);
                    }
                    this.setState({
                        loading: false
                    });
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })


            this.setState({
                Distributorbtn: "Popup_tabButtonsActive",
                DistServbtn: "Popup_tabButtons",
            }, () => {  // callback functions
                this.getDMCity();
            })

            document.querySelector('.retailerTab').style.display = 'block';
            document.querySelector('.distributorTab').style.display = 'none';

            document.querySelector('.newCustEdit_wrapper').style.display = 'flex';
        })
    }

    clearNewDM() {
        this.setState({

        });
    }

    filterList = (event) => {
        let filtered = this.state.dmOrgDistributorList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));
            }
        }
        this.setState({
            dmDistributorList: filtered
        })
    }

    distributorChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if (e.target.name === 'dmName') {
            // console.log("<--1-->");
            var tlist = this.state.dmErpOrgList;
            var searchString = e.target.value;
            const filtered = tlist.filter(c => (
                c.name.toLowerCase().search(searchString.toLowerCase()) !== -1
            ));
            this.setState({
                dmErpList: filtered
            })
        }
    }
    distributorPinCodeChange = (e) => {
        if (e.target.value.length <= 6) {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
        // if(e.target.value.length === 6){
        //     this.setState({
        //         loading: true
        //     });
        //     axios.get(this.state.httpPostalURL+e.target.value)
        //     .then(response => response.data)            
        //     .then((data) => {     
        //         if(data[0].Status === "Success"){
        //             this.setState({
        //                 dmCity: data[0].PostOffice[0].Block,
        //                 dmState: data[0].PostOffice[0].State,
        //                 dmCountry: data[0].PostOffice[0].Country,
        //             })
        //         }                          
        //         this.setState({
        //             loading: false
        //         });
        //     }).catch(err => {                               
        //         this.setState({
        //             loading: false
        //         });
        //         alert(err);
        //     })
        // }        
    }

    dmErpCodeChange = (event) => {
        this.setState({
            dmErpCode: event.value
        });
    }

    dmDistributorTypeChange = (event) => {
        this.setState({
            dmDistributorType: event.value
        }, () => {
            ReactTooltip.rebuild();
        });

    }

    dmSavefns = (e) => {
        var uniqobj = this.getUnique();
        if (this.state.dmName === "" || this.state.dmName === null || this.state.dmName === undefined) {
            alert("Please Enter Name");
            return false;
        } else if (this.state.dmDistributorCode === "" || this.state.dmDistributorCode === null || this.state.dmDistributorCode === undefined) {
            alert("Please Enter Distributor Code");
            return false;
        } else if (this.state.dmDistributorType === "" || this.state.dmDistributorType === null || this.state.dmDistributorType === undefined) {
            alert("Please Enter Distributor Type");
            return false;
        } else if (this.state.dmAddressLine1 === "" || this.state.dmAddressLine1 === null || this.state.dmAddressLine1 === undefined) {
            alert("Please Enter Address Line1");
            return false;
        } else if (this.state.dmAddressLine2 === "" || this.state.dmAddressLine2 === null || this.state.dmAddressLine2 === undefined) {
            alert("Please Enter Address Line2");
            return false;
        } else if (this.state.dmPinCode === "" || this.state.dmPinCode === null || this.state.dmPinCode === undefined) {
            alert("Please Enter PinCode");
            return false;
        } else if (this.state.dmCity === "" || this.state.dmCity === null || this.state.dmCity === undefined) {
            alert("Please Enter City");
            return false;
        } else if (this.state.dmState === "" || this.state.dmState === null || this.state.dmState === undefined) {
            alert("Please Enter State");
            return false;
        } else if (this.state.dmCountry === "" || this.state.dmCountry === null || this.state.dmCountry === undefined) {
            alert("Please Enter Country");
            return false;
        } else if (this.state.dmContactName === "" || this.state.dmContactName === null || this.state.dmContactName === undefined) {
            alert("Please Enter Contact Name");
            return false;
        } else if (this.state.dmContactPhone === "" || this.state.dmContactPhone === null || this.state.dmContactPhone === undefined) {
            alert("Please Enter Contact Phone");
            return false;
        } else if (this.state.dmContactEmail === "" || this.state.dmContactEmail === null || this.state.dmContactEmail === undefined) {
            alert("Please Enter Contact Email");
            return false;
        } else if (this.state.dmPendingAmount === "" || this.state.dmPendingAmount === null || this.state.dmPendingAmount === undefined) {
            alert("Please Enter Pending Amount");
            return false;
        } else if (this.state.dmCreditLimit === "" || this.state.dmCreditLimit === null || this.state.dmCreditLimit === undefined) {
            alert("Please Enter Credit Limit");
            return false;
        } else if (this.checkDataEnterDP()) {
            alert("Please Enter Distribuor Products");
            return false;
        } else if (!uniqobj) {
            alert("Remove Duplicate Proudct Type");
            return false;
        } else {
            const dtr = [{
                id: this.state.dmId,
                companyCode: this.state.loginuserObj.companyCode,
                // distributorCode: this.state.ncDistributorCode,
                distributorName: this.state.dmName,
                distributorCode: this.state.dmDistributorCode,
                erpCode: this.state.dmErpCode,
                gstinNumber: this.state.dmGstinnum,
                panNumber: this.state.dmPanNum,
                refNum: this.state.dmRefNum,
                distributorType: this.state.dmDistributorType,
                address1: this.state.dmAddressLine1,
                address2: this.state.dmAddressLine2,
                address3: this.state.dmAddressLine3,
                address4: this.state.dmAddressLine4,
                pincode: this.state.dmPinCode,
                city: this.state.dmCity,
                state: this.state.dmState,
                country: this.state.dmCountry,
                landmark: this.state.dmLandMark,
                latitude: this.state.dmLatitude,
                longitude: this.state.dmLongtitude,
                contactName: this.state.dmContactName,
                contactPhone: this.state.dmContactPhone,
                contactEmail: this.state.dmContactEmail,
                pendingAmt: this.state.dmPendingAmount,
                creditLimit: this.state.dmCreditLimit,
                creditDays: this.state.dmCreditDays,
                tinNumber: this.state.dmTINNumber,
                status: this.state.dmStatus,
                erpEnabled: this.state.dmErpEnabled,
                lastUpdtedBy: this.state.loginuserObj.userName
            }];

            let params = {
                "distributorDetails": dtr,
                "distributorProductList": this.state.dmDistributorProductList
            }
            //console.log(params);
            this.setState({
                loading: true
            })
            axios.post(this.state.httpURL + "/distributor/saveDistributor/", params)
                .then(response => {
                    if (response.data !== null) {
                        if (response.data.valid) {
                            if (response.data.existDistributor) {
                                alert("This Distributor Name Is Already Exist.");
                            } else if (response.data.existDistributorCode) {
                                alert("This Distributor Code Is Already Exist.");
                            } else {
                                alert("Distributor Saved Successfully.");
                                this.clearDM();
                                this.dmCloseHandler();
                                if ((this.state.dmFState !== "" && this.state.dmFState !== null && this.state.dmFState !== undefined) && (this.state.dmFCity !== "" && this.state.dmFCity !== null && this.state.dmFCity !== undefined)) {
                                    this.getDMDistributor();
                                }
                            }
                            this.setState({
                                loading: false
                            })
                        } else {
                            this.setState({
                                loading: false
                            });
                            alert(response.data.responseMsg);
                        }
                    }
                })
                .catch(error => {
                    //console.log("error" + error)                    
                    this.setState({
                        loading: false
                    })
                    alert(error);
                })
        }
    }

    getUnique = () => {
        var isUnique = true;
        let srlist = this.state.dmDistributorProductList;
        for (var i = 0; i < srlist.length; i++) {
            for (var j = i + 1; j < srlist.length; j++) {
                if (srlist[i].value === srlist[j].value) {
                    isUnique = false;
                }
            }
        }
        return isUnique;
    }

    checkDataEnterDP() {
        var notEntered = false;
        let srlist = this.state.dmDistributorProductList;
        if (srlist.length > 0) {
            for (var i = 0; i < srlist.length; i++) {
                if (srlist[i].value === '' || srlist[i].value === undefined) {
                    notEntered = true;
                }
            }
        } else {
            notEntered = true;
        }

        return notEntered;

    }

    dmProductGroupChange = (ind, event) => {
        const { dmDistributorProductList } = this.state;
        dmDistributorProductList[ind].productGroupId = event.value
        this.setState({ dmDistributorProductList: dmDistributorProductList }, () => {
            ReactTooltip.rebuild();
        });
        if (event.value !== undefined) {
            this.setState({ dmDistributorProductList: dmDistributorProductList }, () => {
                ReactTooltip.rebuild();
            });
        }
    }

    dmProductTypeChange = (ind, event) => {
        const { dmDistributorProductList } = this.state;
        dmDistributorProductList[ind].value = event.value
        //dmDistributorProductList[ind].label = event.label        
        this.setState({ dmDistributorProductList: dmDistributorProductList }, () => {
            ReactTooltip.rebuild();
        });
        if (event.value !== undefined) {
            this.setState({ dmDistributorProductList: dmDistributorProductList }, () => {
                ReactTooltip.rebuild();
            });
        }
    }

    dmNewDistributorProduct = () => {
        let srlist = this.state.dmDistributorProductList;
        for (var i = 0; i < srlist.length; i++) {
            // if(srlist[i].productGroupId === 0 || srlist[i].productGroupId === undefined){
            //     alert("Please Enter Product Group");
            //     return false;
            // } else if(srlist[i].value === '' || srlist[i].value === undefined){
            //     alert("Please Enter Product Value");
            //     return false;
            // }
            if (srlist[i].value === '' || srlist[i].value === undefined) {
                alert("Please Enter Product Type");
                return false;
            }
        }
        var emptyobj = {
            companyCode: this.state.loginuserObj.companyCode,
            distributorCode: '',
            productGroupId: 0,
            value: '',
            status: "Y",
            lastUpdtedBy: this.state.loginuserObj.userName
        }
        let emptylist = this.state.dmDistributorProductList
        emptylist.push(emptyobj)
        this.setState({
            dmDistributorProductList: emptylist
        })
    }

    dmDistributorProductDelete = (ind) => {

        var dmDistributorProductList = [...this.state.dmDistributorProductList];
        if (dmDistributorProductList.length === 1) {
            alert("Can't Delete All Rows");
        } else {
            dmDistributorProductList.splice(ind, 1)
            this.setState({ dmDistributorProductList: dmDistributorProductList }, () => {
            });
        }
    }

    dmDPStatusChange = (ind, event) => {
        const { dmDistributorProductList } = this.state;
        dmDistributorProductList[ind].status = event.target.value
        this.setState({ dmDistributorProductList: dmDistributorProductList }, () => {
            // console.log(this.state.retailerdistributorList);
        });
    }

    dmDeleteHandler = (dtr) => {
        var answer = window.confirm("Are Sure Delete The Distributor?")
        if (answer) {
            this.setState({
                loading: true
            })
            axios.delete(this.state.httpURL + "/distributor/" + dtr.distributorCode)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        })
                        alert("Distributor Deleted Successfully.");
                        this.setState({
                            dmDistributorList: []
                        })
                        if ((this.state.dmFState !== "" && this.state.dmFState !== null && this.state.dmFState !== undefined) && (this.state.dmFCity !== "" && this.state.dmFCity !== null && this.state.dmFCity !== undefined)) {
                            this.getDMDistributor();
                        }
                    }
                }).catch(err => {
                    // console.log(err)                    
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    distributorExcel = () => {
        if (this.state.dmFState === "" || this.state.dmFState === null || this.state.dmFState === undefined) {
            alert("Please Select State");
        } else if (this.state.dmFCity === "" || this.state.dmFCity === null || this.state.dmFCity === undefined) {
            alert("Please Select City");
        }
        else {
            const params = {
                companyCode: this.props.userObj.companyCode,
                state: this.state.dmFState,
                city: this.state.dmFCity,
            }

            this.setState({
                loading: true
            });
            axios.get(this.state.httpURL + "/distributor/getDistributorReport", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.genarateDistributorXL(data.distributorReportList);
                    } else {
                        alert(data.responseMsg);
                    }
                    this.setState({
                        loading: false
                    });
                }).catch(err => {
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    genarateDistributorXL = (distributorReportList) => {
        var dFlag = true;
        if (distributorReportList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = distributorReportList;
            var fileName = "Distributor Report_" + this.state.currentDate;
            var wscols = [
                { wch: 16 },
                { wch: 45 },
                { wch: 20 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 50 },
                { wch: 10 },
                { wch: 10 },
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Distributor Number": dList[i].distributorCode,
                    "Distributor": dList[i].distributorName,
                    "Distributor Type": dList[i].distributorType,
                    "State": dList[i].state,
                    "City": dList[i].city,
                    "Contact Phone": dList[i].contactPhone,
                    "Contact Email": dList[i].contactEmail,
                    "Erp Enabled": dList[i].erpEnabled,
                    "Status": dList[i].status
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'Distributor': ws }, SheetNames: ['Distributor'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    handleDMStateChange = (event) => {
        console.log("calling***********");
        this.setState({
            dmState: event.value,
        }, () => {
            console.log(this.state.dmState)
            if (this.state.dmState !== "" && this.state.dmState !== null && this.state.dmState !== undefined) {
                this.getDMCity();
            }
        })
    }
    getDMCity() {
        const params = {
            state: this.state.dmState
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/distributor/getIndianCityList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        dmCityList: data.indianCitiesList
                    })
                    console.log(this.state.dmCityList);
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    handleDMCityChange = (event) => {
        this.setState({
            dmCity: event.value,
        }, () => {
        })
    }

    handleDMFStateChange = (event) => {
        this.setState({
            dmFState: event.value,
            dmFCity: '',
            dmFCityList: [],
            dmDistributorList: [],
            dmOrgDistributorList: [],
        }, () => {
            if (this.state.dmFState !== "" && this.state.dmFState !== null && this.state.dmFState !== undefined) {
                this.getDMFilterCityList();
            }
        })
    }

    handleDMFCityChange = (event) => {
        this.setState({
            dmFCity: event.value,
            dmDistributorList: [],
            dmOrgDistributorList: [],
        }, () => {

        })
    }

    setSearchDMDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    getDMFDistributor = () => {
        if (this.state.dmFState === "" || this.state.dmFState === null || this.state.dmFState === undefined) {
            alert("Please Select State");
        } else if (this.state.dmFCity === "" || this.state.dmFCity === null || this.state.dmFCity === undefined) {
            alert("Please Select City");
        }
        else {
            this.getDMDistributor();
        }
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>DISTRIBUTOR</span></div>
                    <div className="O2Table_Body">
                        {/* <div className="O2Table_SubContainer distriStockSub">
                            <div class="O2Content_row">
                                <div class="contentRow_Label"></div>
                                <div class="contentRow_Value">
                                   
                                </div>
                            </div>
                            <div class="O2Content_row">
                                <div class="contentRow_Label"></div>
                                <div class="contentRow_Value">
                                    
                                </div>
                            </div>
                        
                            <div className="O2Table_New">
                                <button onClick={this.dmNewpopup_openHandler}>New</button>
                            </div>
                        </div> */}
                        <div className="O2Table_SubContainer Report_subContainer">
                            <div className="O2Content_row">
                                <div className="contentRow_Label mandatoryClass">State <span>*</span></div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.dmFStateList} name="dmFState" value={this.state.dmFStateList.filter(({ value }) => value === this.state.dmFState)} placeholder="Choose State" onChange={this.handleDMFStateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label mandatoryClass">City <span>*</span></div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.dmFCityList} name="dmFCity" value={this.state.dmFCityList.filter(({ value }) => value === this.state.dmFCity)} placeholder="Choose City" onChange={this.handleDMFCityChange} />
                                </div>
                            </div>
                            {/* <div className="O2Content_row">
                                    <div className="contentRow_Label">Route </div>
                                    <div className="contentRow_Value">
                                        <Select options={this.state.ncFRouteList} name="ncFRoute" value={this.state.ncFRouteList.filter(({ value }) => value === this.state.ncFRoute)} placeholder="Choose Route" onChange={this.handleNCFRouteChange}/>                                                                         
                                    </div>
                                </div>                                                         */}
                        </div>
                        <div className="O2Table_SubContainer Report_subContainer">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Distributor Code </div>
                                <div className="contentRow_Value">
                                    <input type="text" name="searchDistributorCode" onChange={this.setSearchDMDetails}></input>
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Distributor Name </div>
                                <div className="contentRow_Value">
                                    <input type="text" name="searchDistributorName" onChange={this.setSearchDMDetails}></input>
                                </div>
                            </div>
                            <div className="O2Table_Go"><button onClick={this.getDMFDistributor}>Go</button></div>
                        </div>
                        <div className="Table_wrapper">
                            <table className="customTable" id="distributor_Table">
                                <thead>
                                    <tr>
                                        <th className="checkBox_Container">
                                            <input type="checkbox" name="checkAll" checked={this.state.allChecked} onChange={this.handleAllChange} />
                                        </th>
                                        <th className="Ref_Num">Distributor Number
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="distributorCode" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="">Distributor
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="distributorName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="retailer_Edit DistAd_width">Distributor Type
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="distributorType" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="retailer_Edit DistAd_width">ERP Enabled
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="erpEnabled" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="status_Width">Status
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="status" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="o2Del_container">
                                            <button onClick={this.dmNewpopup_openHandler}>New</button>
                                        </th>
                                        <th className="scroller"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {
                                            !this.state.dmDistributorList || this.state.dmDistributorList === null || this.state.dmDistributorList.length === 0 ?
                                                <tr align="center">
                                                    <td colSpan="5">No Distributor Available</td>
                                                </tr> :
                                                this.state.dmDistributorList.map((dtr, i) => (
                                                    <tr key={dtr.id}>
                                                        <td className="checkBox_Container">
                                                            <input type="checkbox" checked={dtr.isChecked} onChange={(e) => this.dmCheckHandle(e, i)} />
                                                        </td>
                                                        <td className="Ref_Num" data-tip={dtr.distributorCode}>{dtr.distributorCode}</td>
                                                        <td className="" data-tip={dtr.distributorName}>{dtr.distributorName}</td>
                                                        <td className="DistAd_width" data-tip={dtr.distributorType}>{dtr.distributorType}</td>
                                                        <td className="DistAd_width" data-tip={dtr.erpEnabled}>{dtr.erpEnabled}</td>
                                                        <td className="status_Width" data-tip={dtr.status}>{dtr.status}</td>
                                                        <td className="o2Del_container">
                                                            <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.dmEditOpenHandler(dtr)} />
                                                            {
                                                                this.state.distributorWriteAccess ?
                                                                    <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} onClick={() => this.dmDeleteHandler(dtr)} />
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                        }
                                        {/* <td className="checkBox_Container">
                                            <input type="checkbox" />
                                        </td>
                                        <td className="Ref_Num"></td>
                                        <td className="retailer_Edit Descript_width"></td>
                                        <td className="status_Width"></td>
                                        <td className="o2Del_container">
                                            <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.newCust_newpopup_openHandler()} />
                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 8px' }} />
                                        </td> */}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.dmDistributorList ? this.state.dmDistributorList.length : 0}</span>entries</span></div>
                            {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                            <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="distributor_Table" filename={"Distributor"}
                            sheet="tablexls" buttonText="Excel Export" /></div> */}
                            <div className="O2Table_ExpExcel" onClick={() => this.distributorExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                        </div>
                    </div>
                </div>
                <div className="newCustEdit_wrapper">
                    <div className="newCustEditBoxWrap">
                        <div className="newCustEditBox">
                            <div className="Popup_Tabwrapper">
                                <div className={this.state.Distributorbtn} onClick={this.poptabDistributorHandler}><span>Distributor</span></div>
                                <div className={this.state.DistServbtn} onClick={this.poptabDistributorServHandler}><span>Distributor Product Serviced</span></div>
                            </div>
                            <div className="Popup_tabContentwrapper">
                                <div className="popTabBody retailerTab">
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Name <span>*</span></div>
                                            <div className="FieldValue"><input labeler="First Name" name='dmName' type="text" value={this.state.dmName} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        {/* <div className="FieldComponent">
                                            <div className="FieldLabel">ERP Code</div>
                                            <div className="FieldValue">
                                                <Select options={this.state.dmErpList} name="dmErpCode" value={this.state.dmErpList.filter(({ value }) => value === this.state.dmErpCode)} placeholder="Choose Erp Code" onChange={this.dmErpCodeChange}/>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Distributor Code <span>*</span></div>
                                            <div className="FieldValue"><input labeler="First Name" name='dmDistributorCode' type="text" value={this.state.dmDistributorCode} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">ERP Code</div>
                                            <div className="FieldValue">
                                                <Select options={this.state.dmErpList} name="dmErpCode" value={this.state.dmErpList.filter(({ value }) => value === this.state.dmErpCode)} placeholder="Choose Erp Code" onChange={this.dmErpCodeChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">GSTIN Number <span></span></div>
                                            <div className="FieldValue"><input name='dmGstinnum' type="text" value={this.state.dmGstinnum} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">PAN Number<span></span></div>
                                            <div className="FieldValue"><input name='dmPanNum' type="text" value={this.state.dmPanNum} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Customer <span></span></div>
                                            <div className="FieldValue"><input type="checkbox" name='ncCustomer' /></div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Vendor <span></span></div>
                                            <div className="FieldValue"><input type="checkbox" name='ncVendor' /></div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Ref  Num <span></span></div>
                                            <div className="FieldValue"><input name='dmRefNum' type="text" value={this.state.dmRefNum} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Distributor Type <span>*</span></div>
                                            <div className="FieldValue">
                                                <Select options={this.state.dmDistributorTypeList} name="dmDistributorType" value={this.state.dmDistributorTypeList.filter(({ value }) => value === this.state.dmDistributorType)} placeholder="Choose Distributor Type" onChange={this.dmDistributorTypeChange} />
                                            </div>
                                        </div>
                                        {/* <div className="FieldComponent">
                                            <div className="FieldLabel">AddressLine 1 <span>*</span></div>
                                            <div className="FieldValue"><input name='dmAddressLine1' type="text" value={this.state.dmAddressLine1} onChange={this.distributorChange}/>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row">
                                        {/* <div className="FieldComponent">
                                            <div className="FieldLabel">AddressLine 2 <span>*</span></div>
                                            <div className="FieldValue"><input name='dmAddressLine2' type="text" value={this.state.dmAddressLine2} onChange={this.distributorChange}/>
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">AddressLine 3 <span></span></div>
                                            <div className="FieldValue"><input name='dmAddressLine3' type="text" value={this.state.dmAddressLine3} onChange={this.distributorChange}/>
                                            </div>
                                        </div> */}
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">AddressLine 1 <span>*</span></div>
                                            <div className="FieldValue"><input name='dmAddressLine1' type="text" value={this.state.dmAddressLine1} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">AddressLine 2 <span>*</span></div>
                                            <div className="FieldValue"><input name='dmAddressLine2' type="text" value={this.state.dmAddressLine2} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="FieldComponent">
                                            <div className="FieldLabel">AddressLine 4 <span></span></div>
                                            <div className="FieldValue"><input name='dmAddressLine4' type="text" value={this.state.dmAddressLine4} onChange={this.distributorChange}/>
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">PinCode <span>*</span></div>
                                            <div className="FieldValue"><input name='dmPinCode' type="text" value={this.state.dmPinCode} onChange={this.distributorPinCodeChange}/>
                                            </div>
                                        </div> */}
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">AddressLine 3 <span></span></div>
                                            <div className="FieldValue"><input name='dmAddressLine3' type="text" value={this.state.dmAddressLine3} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">AddressLine 4 <span></span></div>
                                            <div className="FieldValue"><input name='dmAddressLine4' type="text" value={this.state.dmAddressLine4} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="FieldComponent">
                                            <div className="FieldLabel">City <span>*</span></div>
                                            <div className="FieldValue"><input name='dmCity' type="text" value={this.state.dmCity} onChange={this.distributorChange}/>
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">State <span>*</span></div>
                                            <div className="FieldValue"><input name='dmState' type="text" value={this.state.dmState} onChange={this.distributorChange}/>
                                            </div>
                                        </div> */}
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">State <span>*</span></div>
                                            {/* <div className="FieldValue"><input name='dmState' type="text" value={this.state.dmState} onChange={this.distributorChange}/>
                                            </div> */}
                                            <div className="FieldValue">
                                                <Select options={this.state.dmStateList} name="dmState" value={this.state.dmStateList.filter(({ value }) => value === this.state.dmState)} placeholder="Choose State" onChange={this.handleDMStateChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">City <span>*</span></div>
                                            <div className="FieldValue">
                                                {/* <input name='dmCity' type="text" value={this.state.dmCity} onChange={this.distributorChange}/> */}
                                                <Select options={this.state.dmCityList} name="dmCity" value={this.state.dmCityList.filter(({ value }) => value === this.state.dmCity)} placeholder="Choose City" onChange={this.handleDMCityChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="FieldComponent">
                                            <div className="FieldLabel">Country <span>*</span></div>
                                            <div className="FieldValue"><input name='dmCountry' type="text" value={this.state.dmCountry} onChange={this.distributorChange}/>
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Land Mark <span></span></div>
                                            <div className="FieldValue"><input name='dmLandMark' type="text" value={this.state.dmLandMark} onChange={this.distributorChange}/>
                                            </div>
                                        </div> */}
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Country <span>*</span></div>
                                            <div className="FieldValue"><input name='dmCountry' type="text" value={this.state.dmCountry} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">PinCode <span>*</span></div>
                                            <div className="FieldValue"><input name='dmPinCode' type="text" value={this.state.dmPinCode} onChange={this.distributorPinCodeChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="FieldComponent">
                                            <div className="FieldLabel">Latitude <span></span></div>
                                            <div className="FieldValue"><input name='dmLatitude' type="text" value={this.state.dmLatitude} onChange={this.distributorChange}/>
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Longtitude <span></span></div>
                                            <div className="FieldValue"><input name='dmLongtitude' type="text" value={this.state.dmLongtitude} onChange={this.distributorChange}/>
                                            </div>
                                        </div> */}
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Land Mark <span></span></div>
                                            <div className="FieldValue"><input name='dmLandMark' type="text" value={this.state.dmLandMark} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Latitude <span></span></div>
                                            <div className="FieldValue"><input name='dmLatitude' type="text" value={this.state.dmLatitude} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Longtitude <span></span></div>
                                            <div className="FieldValue"><input name='dmLongtitude' type="text" value={this.state.dmLongtitude} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Contact Name <span>*</span></div>
                                            <div className="FieldValue"><input name='dmContactName' type="text" value={this.state.dmContactName} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Contact Phone <span>*</span></div>
                                            <div className="FieldValue"><input name='dmContactPhone' type="text" value={this.state.dmContactPhone} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Contact E-mail <span>*</span></div>
                                            <div className="FieldValue"><input name='dmContactEmail' type="text" value={this.state.dmContactEmail} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Pending Amount <span>*</span></div>
                                            <div className="FieldValue"><input name='dmPendingAmount' type="text" value={this.state.dmPendingAmount} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Credit Limit <span>*</span></div>
                                            <div className="FieldValue"><input name='dmCreditLimit' type="text" value={this.state.dmCreditLimit} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Credit Days <span></span></div>
                                            <div className="FieldValue"><input name='dmCreditDays' type="text" value={this.state.dmCreditDays} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">TIN Number <span></span></div>
                                            <div className="FieldValue"><input name='dmTINNumber' type="text" value={this.state.dmTINNumber} onChange={this.distributorChange} />
                                            </div>
                                        </div>
                                        {/* <div className="FieldComponent">
                                            <div className="FieldLabel">Confirmation <span></span></div>
                                            <div className="FieldValue">
                                                <select name='ncConfirmation' >
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Distributor Category <span>*</span></div>
                                            <div className="FieldValue">
                                                <select></select>
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Distributor Group <span>*</span></div>
                                            <div className="FieldValue">
                                                <select></select>
                                            </div>
                                        </div>                                        
                                    </div> */}
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">ERP Enabled <span></span></div>
                                            <div className="FieldValue">
                                                <select name='dmErpEnabled' value={this.state.dmErpEnabled} onChange={this.distributorChange}>
                                                    <option value="Y">Active</option>
                                                    <option value="N">In Active</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Status <span></span></div>
                                            <div className="FieldValue">
                                                <select name='dmStatus' value={this.state.dmStatus} onChange={this.distributorChange}>
                                                    <option value="Y">Active</option>
                                                    <option value="N">In Active</option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>
                                <div className="popTabBody distributorTab">
                                    <div className="tabBodyContent">
                                        <div className="Table_wrapper">
                                            <table className="customTable">
                                                <thead>
                                                    <tr>
                                                        {/* <th>Product Group</th> */}
                                                        <th>Product Type</th>
                                                        <th>Status</th>
                                                        <th className="o2popDel_container">
                                                            <button><FontAwesomeIcon icon={faPlus} style={{ padding: '0px 4px' }} onClick={() => this.dmNewDistributorProduct()} /></button>
                                                        </th>
                                                        <th className="scroller"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        {/* <td>
                                                            <Select options={this.state.dmProductGroupList} name="dmErpCode" value={this.state.dmProductGroupList.filter(({ value }) => value === this.state.value)} placeholder="Choose Erp Code" onChange={this.dmErpCodeChange}/>
                                                        </td>
                                                        <td>
                                                            <Select/>
                                                        </td>
                                                        <td>
                                                            <select>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                        </td>
                                                        <td className="o2popDel_container">
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} />
                                                        </td> */}
                                                        {
                                                            !this.state.dmDistributorProductList || this.state.dmDistributorProductList == null || this.state.dmDistributorProductList.length === 0 ?
                                                                <tr align="center">
                                                                    <td colSpan="4"></td>
                                                                </tr> :
                                                                this.state.dmDistributorProductList.map((rd, i) => (
                                                                    <tr key={i}>
                                                                        {/* <td>
                                                                        <Select options={this.state.dmProductGroupList} name="popupDistributor" value={this.state.dmProductGroupList.filter(({ value }) => value === rd.productGroupId)} placeholder="Choose Product Group" onChange={(e) => this.dmProductGroupChange(i, e)} />
                                                                    </td>                                                                     */}
                                                                        <td data-tip={rd.productTypeCode}>
                                                                            <Select options={this.state.dmProductTypeList} name="productType" value={this.state.dmProductTypeList.filter(({ value }) => value === rd.value)} placeholder="Choose Product Type" isDisabled={rd.isPTYpeSelectDisabled} onChange={(e) => this.dmProductTypeChange(i, e)} />
                                                                        </td>
                                                                        <td>
                                                                            <select value={rd.status} onChange={(e) => this.dmDPStatusChange(i, e)}>
                                                                                <option value="Y">Active</option>
                                                                                <option value="N">InActive</option>
                                                                            </select>
                                                                        </td>
                                                                        <td className="o2popDel_container">
                                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} onClick={() => this.dmDistributorProductDelete(i)} />
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                        }
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="newCustEditFooter">
                                <div className="row editCustBtnWrap">
                                    {
                                        this.state.distributorWriteAccess ?
                                            <button onClick={this.dmSavefns}>Save</button>
                                            : null
                                    }
                                    <button onClick={this.dmCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL,
        httpPostalURL: state.httpPostalURL
    }
}
export default connect(mapStateToProps)(DistributorModule)
//export default DistributorModule
