import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from 'react-select';

import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
class BAUsageDetailSummaryReport extends Component {
    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            salesmanList: [],
            selectedsalesmanList: [],
            reportList: [],
            orgreportList: [],
            vdSalesmanCode: '',
            orderObj: '',
            startDate: todaydate,
            endDate: todaydate,
            smanCode: '',
            todaydate: todaydate,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            userType: this.props.userObj.userType,
            loginSalesmanCode: this.props.userObj.salesmanCode,
            vdFState: '',
            vdFStateList: [],
        }
    }

    componentDidMount() {
        this.getAllSalesmans();
    }

    loadReport() {
        let scode = this.state.salesmanCode;
        if (this.state.loginuserObj.userType === "salesman") {
            scode = scode;
        } else {
            scode = 'All';
        }
        this.setState({
            vdSalesmanCode: scode,
            smanCode: scode
        }, () => {
            this.getAllSalesmans();
        });

    }

    getAllSalesmans() {
        let scode = this.state.salesmanCode;
        if (this.state.loginuserObj.userType === "salesman") {
            scode = scode;
        } else {
            scode = scode;
        }

        const params = {
            salesmanCode: scode,
            companyCode: this.state.companyCode,
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/report/getSalesmanList", { params })
            .then(response => response.data)
            .then((data) => {
                console.log(data.stateList)
                this.setState({
                    loading: false
                })
                this.setState({
                    salesmanList: data.salesmansList,
                    vdFStateList: data.stateList,
                }, () => {

                    if (data.salesmansList.length === 1) {
                        var temparry = []
                        temparry.push({
                            label: data.salesmansList[0].label,
                            value: data.salesmansList[0].value
                        });

                        this.setState({
                            selectedsalesmanList: temparry,
                        })
                    }

                })
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                })
                alert(err);
            })
    }

    loadSalesmanList() {
        var dList = this.state.salesmanList;
        var sman = this.state.vdSalesmanCode;
        dList.map(function (sales, i) {

            if (sales.salesmanCode === sman) {
                //this.setState({ salesmanList: dList[i] })
            } else {
                dList.splice(i, 1)
            }

        });
        this.setState({ salesmanList: dList })
    }

    reportGo = () => {
        var dFlag = true;
        let stateVal = '';
        if (this.state.startDate !== '' && this.state.endDate !== '') {
            if (this.state.startDate > this.state.endDate) {
                alert("From Date Cannot Be Greater Than End Date");
                dFlag = false;
            }
        } else {
            alert("Please select From and To Date...");
            dFlag = false;
        }

        if (this.state.vdFState === '' || this.state.vdFState === undefined) {
            stateVal = "All";
        } else {
            stateVal = this.state.vdFState;
        }
        if (dFlag) {
            let salesmnallist = "";
            // if(this.state.selectedsalesmanList.length===0){
            //     if(this.state.userType === "salesman"){
            //         salesmnallist = "'"+this.state.salesmanCode+"',"
            //         salesmnallist = salesmnallist.substring(0, salesmnallist.length - 1);
            //     } else {
            //     salesmnallist="";
            //     }
            // } else{
            //     this.state.selectedsalesmanList.map((sal) => (
            //         salesmnallist +="'"+sal.value+"',"
            //     ));
            //     salesmnallist = salesmnallist.substring(0, salesmnallist.length - 1);
            // }
            // console.log("salesmnallist-->"+salesmnallist)
            salesmnallist += "'" + this.state.vdSalesmanCode + "'"
            let tempSalesmanCode = this.state.vdSalesmanCode;
            if (tempSalesmanCode === "" || tempSalesmanCode === null || tempSalesmanCode === undefined) {
                tempSalesmanCode = "All";
            } else {
                tempSalesmanCode = this.state.vdSalesmanCode;
            }
            const params = {
                salesmanCode: tempSalesmanCode,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                loginSalesmanCode: this.state.loginSalesmanCode,
                companyCode: this.state.companyCode,
                state: stateVal,
            }
            this.setState({
                loading: true
            })
            axios.get(this.state.httpURL + "/report/getBAUsageDetailSummaryReport", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.setState({
                            loading: false
                        })
                        this.setState({
                            reportList: data.reportList,
                            orgreportList: data.reportList,
                        })
                        ReactTooltip.rebuild();
                    } else {
                        alert(data.responseMsg);
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    })
                    alert(err);
                })
        }
    }

    reportChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSalesmanChange = (event) => {
        // this.setState({
        //     salesmanCode: event.value
        // });
        this.setState({
            selectedsalesmanList: event,
            vdSalesmanCode: event.value
        });
    }

    filterList = (event) => {
        let filtered = this.state.orgreportList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            reportList: filtered
        })
    }

    visitDetailExcel = () => {
        var dFlag = true;
        if (this.state.reportList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.reportList;
            var fileName = "BA Usage Detail Summary Report_" + this.state.todaydate
            var wscols = [
                { wch: 35 },
                { wch: 10 },
                { wch: 45 },
                { wch: 20 },
                { wch: 45 },
                { wch: 20 },

            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "State": dList[i].state,
                    "Order Date": dList[i].orderDate,
                    "No. of Sales Person": dList[i].salesmancount,
                    "NO. of Shops Visited": dList[i].noofshops,
                    "NO. of orders": dList[i].noofOrders,
                    "Total Order Value": dList[i].totalOrdervalue,
        
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'UsageDetailSummaryReport': ws }, SheetNames: ['UsageDetailSummaryReport'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    handleVDFStateChange = (event) => {
        this.setState({
            vdFState: event.value,
        }, () => {
            this.reportGo();
        });
    }

    render() {
        const salesmanSearch = [];

        for (const [index, value] of this.state.salesmanList.entries()) {
            salesmanSearch.push({
                label: value.salesmanName,
                value: value.salesmanCode
            });
        }
        return (

            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>BA - USAGE DETAIL SUMMARY</span></div>
                    <div className="O2Table_Body">
                        <div className="O2Table_SubContainer Report_subContainer">
                          
                            <div className="O2Content_row">
                                <div className="contentRow_Label">State</div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.vdFStateList} name="vdFState" value={this.state.vdFStateList.filter(({ value }) => value === this.state.vdFState)} placeholder="Choose State" onChange={this.handleVDFStateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date From </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="startDate" value={this.state.startDate} onChange={this.reportChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date To </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="endDate" value={this.state.endDate} onChange={this.reportChange} />
                                </div>
                            </div>

                            <div className="O2Table_Go" onClick={this.reportGo}><button>Go</button></div>
                        </div>
                        <div className="Table_wrapper visitDetailTable_Wrapper">
                            <table className="customTable UsageDetailReportTable">
                                <thead>
                                    <tr>
                                    <th className="usage_width_first">State
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="state" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="usage_width_first">Order Date
                                            <div className="searchWrapper">
                                    
                                            </div>
                                        </th>
                                        <th className="usage_width_first_align">NO. Of Sales Person
                                            <div className="searchWrapper">
                                    
                                            </div>
                                        </th>
                                        <th className="usage_width_first_align">NO. of Shops Visited
                                            <div className="searchWrapper">
                                                {/* <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/> */}
                                                {/* <input type="text" className="tableMultiFilterSearch_input maingrid" name="date" onChange={this.filterList} /> */}
                                            </div>
                                        </th>
                                        <th className="usage_width_first_align">NO. of orders
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="noofOrders" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="usage_width_first_align">Total Order Value
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="totalOrdervalue" onChange={this.filterList} />
                                            </div>
                                        </th>


                                        {/* <th className="status_Width">Latitude
                                        <div className="searchWrapper">
                                             <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="timeOut" onChange={this.filterList} /> 
                                        </div>
                                    </th> */}


                                        {/* <th className="VDDescript_width">Order Type
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderType" onChange={this.filterList} />
                                        </div>
                                    </th> */}
                                        <th className="scroller"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.reportList || this.state.reportList === null || this.state.reportList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="6">No Records</td>
                                            </tr> : this.state.reportList.map((report) => (
                                                <tr>
                                                    <td className="usage_width_first" data-tip={report.state}>{report.state}</td>
                                                    <td className="usage_width_first" data-tip={report.orderDate}>{report.orderDate}</td>
                                                    <td className="usage_width_first_align" style={{textAlign:'center'}} data-tip={report.salesmancount}>{report.salesmancount}</td>
                                                    <td className="usage_width_first_align" style={{textAlign:'center'}} data-tip={report.noofshops}>{report.noofshops}</td>
                                                    <td className="usage_width_first_align" style={{textAlign:'center'}} data-tip={report.noofOrders}>{report.noofOrders}</td>
                                                    <td className="usage_width_first_align" style={{textAlign: 'right'}} data-tip={report.totalOrdervalue}>{report.totalOrdervalue}</td>
                            
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.reportList ? this.state.reportList.length : 0}</span>entries</span></div>
                            <div className="O2Table_ExpExcel" onClick={() => this.visitDetailExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                        </div>
                    </div>
                </div>
                {<FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />}
            </div>
        )
    }
}

// export default VisitDetailModule

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}

export default connect(mapStateToProps)(BAUsageDetailSummaryReport)

