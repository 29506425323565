import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

class Inventory extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0,10);
        
         let orghttpURL = "http://139.59.8.226:8080/o2webng"

        this.state = {
             inventoryList:[],
             salesmanList:[],
             salesmanCode:'All',
             distributorList:[],
             distributorCode:'All',
             inventoryObj:'',
             inventoryDetailsList:[],
             refNum:"",
             salesmanName:"",
             distributorName:"",
             inventoryDTM:"",
             currentDate:todaydate,
             httpURL:orghttpURL
        }
    }

    Tableedit_popCloseHandler = () => {
        document.querySelector('.inventoryTableEdit_pop').style.display = 'none';
    }
    Tableedit_openHandler = (inventory) => {
        const params={
            inventoryId:inventory.id,
        }
        axios.get(this.state.httpURL+"/distributor/getInventoryInfo",{params})
        .then(response=>response.data)
        .then((data) =>{
        //    console.log("data-->"+data.forOrderDetailsList)
            this.setState({
                inventoryDetailsList:data.inventoryDetailsList,
                inventoryObj:inventory,
                refNum:inventory.refNum,
                salesmanName:inventory.displayName,
                distributorName:inventory.distributorName,
                inventoryDate:inventory.salesReturnDTM,
            },()=>{  
                document.querySelector('.inventoryTableEdit_pop').style.display = 'flex';
            })
        }).catch(err=>{
            console.log(err)
        })
    }

    componentDidMount(){
        this.getAllInventory();
    }

    getAllInventory(){ 
        const params={
            companyCode:localStorage.getItem("usercompanyCode"),
            distributorCode:this.state.distributorCode,
            salesmanCode:this.state.salesmanCode,
        }

        axios.get(this.state.httpURL+"/distributor/getInventoryList",{params})
        .then(response=>response.data)
        .then((data) =>{
            this.setState({
                inventoryList:data.inventoryDataList,
                salesmanList:data.salesmanList,
                distributorList:data.distributorList
            })
        }).catch(err=>{
            console.log(err)
        })
    }

    handleSalesmanChange = (event)=>{
        this.setState({
            salesmanCode:event.target.value
        },()=>{
          this.getAllInventory();
        });
    }

    handleDistributorChange  = (event)=>{
        this.setState({
            distributorCode:event.target.value
        },()=>{
          this.getAllInventory();
        });
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>INVENTORY</span></div>
                    <div className="O2Table_Body">
                    <div className="O2Table_SubContainer">
                        <div className="O2Table_SelectSales">
                        <div className="O2Table_Label">Salesman</div>
                            <div className="O2Table_Value">
                                <select value={this.state.salesmanCode} onChange={this.handleSalesmanChange}>
                                        <option value="All" key="All">All</option>
                                    {
                                        this.state.salesmanList.map( (salesman)=>(
                                            <option value={salesman.salesmanCode} key={salesman.salesmanCode}>{salesman.salesmanName}</option>
                                            
                                        ))
                                    }
                                </select>
                             </div>
                        </div>
                        <div className="O2Table_SelectDist">
                            <div className="O2Table_Label">Distributor</div>
                            <div className="O2Table_Value">
                                <select value={this.state.distributorCode} onChange={this.handleDistributorChange}>
                                        <option value="All" key="All">All</option>
                                    {
                                        this.state.distributorList.map( (dist)=>(
                                            <option value={dist.distributorCode} key={dist.distributorCode}>{dist.distributorName}</option>
                                            
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="O2Table_Search">
                            <div className="O2Table_Label"> Search : </div>
                            <div className="O2Table_Value"><input type="text"/></div>
                        </div>
                    </div>
                    {/* <div className="O2Table_SubContainer">
                        <div className="O2Table_Records">
                            <div className="O2Table_Value"><select><option>5</option></select></div>
                            <div className="O2Table_Label">Records per pages</div>
                        </div> 
                        <div className="O2Table_Search">
                            <div className="O2Table_Label"> Search : </div>
                            <div className="O2Table_Value"><input type="text"/></div>
                        </div>
                    </div> */}
                    <div className="Table_wrapper">
                        <table className="customTable">
                            <thead>
                                <tr>
                                    <th className="checkBox_Container"></th>
                                    <th>Inventory REF Num</th>
                                    <th>Distributor</th>
                                    <th>Salesman</th>
                                    <th>Date Time</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !this.state.inventoryList ||  this.state.inventoryList === null || this.state.inventoryList.length ===0  ?
                                    <tr align="center">
                                                <td colSpan="6">No Inventory Available</td>
                                    </tr> :
                                    this.state.inventoryList.map( (inventory) =>(
                                        <tr key={inventory.id}>
                                            <td className="checkBox_Container">
                                                    <input type="checkbox"/>
                                            </td>
                                            <td  onClick={ ()=> this.Tableedit_openHandler(inventory)}>{inventory.refNum}</td>
                                            <td>{inventory.distributorName}</td>
                                            <td>{inventory.displayName}</td>
                                            <td>{inventory.salesReturnDTM}</td>
                                            <td>{inventory.isExported}</td>
                                        </tr>

                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.inventoryList ? this.state.inventoryList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{padding:'0px 8px'}}/></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{padding:'0px 8px'}}/> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{padding:'0px 8px'}}/> Export</div>
                    </div>
                </div>
            </div>
            <div className="O2TableEdit_wrapper inventoryTableEdit_pop">
                <div className="O2TableEditBoxWrap">
                    <div className="O2TableEditBox">
                        <div className="O2TableEditTitle">Order Ref No(#): {this.state.refNum}</div>
                        <div className="O2TableEditBody">
                            <div className="row">
                                <div className="FieldComponent">
                                    <div className="FieldLabel">Inventory Ref Num:</div>
                                    <div className="FieldValue"><span>{this.state.refNum}</span></div>
                                </div>
                                <div className="FieldComponent">
                                    <div className="FieldLabel">Salesman:</div>
                                    <div className="FieldValue"><span>{this.state.salesmanName}</span></div>
                                </div>
                               
                            </div>
                            <div className="row">
                                <div className="FieldComponent">
                                    <div className="FieldLabel">Distributor:</div>
                                    <div className="FieldValue"><span>{this.state.distributorName}</span></div>
                                </div>
                                <div className="FieldComponent">
                                    <div className="FieldLabel">Date:</div>
                                        <div className="FieldValue"><span>{this.state.inventoryDate}</span></div>
                                </div>

                            </div>
                    
                            <div className="Table_wrapper">
                                <table className="customTable">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>VOM</th>        
                                            <th className="Quant_inventory">Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.inventoryDetailsList.length ===0 ?
                                            <tr align="center">
                                                        <td colSpan="6">{this.state.inventoryDetailsList.length} Inventory Details Available</td>
                                            </tr> :
                                            this.state.inventoryDetailsList.map( (inv) =>(
                                            <tr key={inv.id}>
                                                <td>{inv.productName}</td>
                                                <td>{inv.productName}</td>
                                                <td className="Quant_inventory">{inv.quantity}</td>
                                            </tr>
                                                ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="O2TableEditFooter">
                            <div className="row O2TableBtnWrap">
                               
                                <button onClick={this.Tableedit_popCloseHandler}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
            </div>
        )
    }
}

export default Inventory
