import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

class ClientModule extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0,10);
    
        this.state = {
            Distributorbtn: "Popup_tabButtons",
            DistServbtn: "Popup_tabButtons",
            cmClientList: [],
            cmOrgClientList: [],
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            currentDate:todaydate,        
        }
    
    }
    
    componentDidMount() {
        this.getCMClientList();       
    }
    getCMClientList() {
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/webmaster/getClientList")
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        cmClientList : data.clientList,
                        cmOrgClientList : data.clientList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    filterList = (event) => {
        let filtered =this.state.cmOrgClientList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
            }
        }
        this.setState({
            cmClientList: filtered
        })
    }


    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>CLIENT</span></div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="client_Table">
                            <thead>
                                <tr>
                                    <th>Client No
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="ad_client_id" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="name" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Description
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="description" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th className="Status_Route">Active
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="isactive" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>                                             
                                    {/* <td>2589</td>
                                    <td>Y</td>
                                    <td className="Status_Route">Y</td> */}
                                    {
                                   !this.state.cmClientList || this.state.cmClientList === null || this.state.cmClientList.length === 0 ?
                                   <tr align="center">
                                       <td colSpan="5">No Client Available</td>
                                   </tr> :
                                   this.state.cmClientList.map((cl,i) => (
                                       <tr key={cl.id}>                                                                                              
                                           <td data-tip={cl.ad_client_id}>{cl.ad_client_id}</td>
                                           <td data-tip={cl.name}>{cl.name}</td>
                                           <td data-tip={cl.description}>{cl.description}</td>
                                           <td className="Status_Route" data-tip={cl.isactive}>{cl.isactive}</td>                                                     
                                       </tr>
                                   ))
                               }
                                </tr> 
                               
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.cmClientList ? this.state.cmClientList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    {/* <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /></div>
                    </div> */}
                    {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{padding:'0px 8px'}}/><ReactHTMLTableToExcel className="download-table-xls-button" table="client_Table" filename={"Client_"+this.state.currentDate}
                        sheet="tablexls" buttonText="Excel Export"/></div> */}
                    {/* <div className="O2Table_Footer">
                        <button>Close</button>
                    </div> */}
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />   
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL        
    }
}
export default connect(mapStateToProps)(ClientModule)
// export default ClientModule

