import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faPlus, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
import './ViewPhotoModule.css';
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;


export class ViewPhotoModule extends Component {

    constructor(props) {

        var curr = new Date();
        curr.setDate(curr.getDate());
        var currentdate = curr.toISOString().substr(0, 10);

        super(props)
        this.state = {
            loading: false,            
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            vpUserList: [],
            vpUserOrgList: [],
            userList:[],
            retailerList:[],  
            todayDate:currentdate,
            vpCommands:'',
            vpApproval:'',
            vpImage:'',
            vpEditFlag:'',
            vpDate:'' ,
            vpUserId:'',                  
        }
    }

    componentDidMount() {
        this.getVPUserList();        
    }

    getVPUserList() {
        const params={
            companyCode:this.state.loginuserObj.companyCode,
            userId:this.state.loginuserObj.salesmanCode
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/viewphoto/getBaUserList",{params})
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({                        
                        userList: data.userList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getBaUserImageList() {
        const params={           
            userRoleId:this.state.vpUserRole           
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/viewphoto/getBaUserImageList",{params})
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({
                        vpUserList: data.baUserImageList,
                        vpUserOrgList: data.baUserImageList                                                                     
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    vpSave = () => {
        let params = {   
            "userId": this.state.vpUserId,                      
            "commands": this.state.vpCommands,
            "supervisorApproval": this.state.vpApproval,
            "lastUpdatedBy":this.state.loginuserObj.userName            
        }
        //console.log("<-1->"+JSON.stringify(params));
        this.setState({
            loading: true
        })
        axios.post(this.state.httpURL + "/viewphoto/saveBaUserImageData",params)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    alert("Saved Successfully.");
                    document.querySelector('.viewPhotoTableEdit_pop').style.display = 'none';
                    this.getBaUserImageList();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    handleVPRoleChange = (event) => {        
        this.setState({                    
            vpUserRole:event.value,
            vpUserRoleName:event.label
        },()=>{   
            if(this.state.vpUserRole !== "" && this.state.vpUserRole !== null && this.state.vpUserRole !== undefined){
                this.getBaUserImageList();
            } else {
                this.setState({
                    vpUserList: [],
                    vpUserOrgList: [],                                                                    
                });
            }                                 
        })
    }

    vp_cancelfunction = (e) => {
        if(this.state.vpUserRole !== "" && this.state.vpUserRole !== null && this.state.vpUserRole !== undefined){
            this.getBaUserImageList();
        }
    }

    Collection_popup_openHandler = (user) => {
        this.clearVPM();
        document.querySelector('.viewPhotoTableEdit_pop').style.display = 'flex';
        var image = "http://139.59.8.226/"+user.image;  
        var editFlag = true;    
        if(user.expenseApprover === 'Y'){
            editFlag = false;
        } 
        this.setState({
            vpCommands: user.commands,
            vpApproval: user.supervisorApproval,
            vpImage: image,
            vpEditFlag: editFlag,
            vpDate: user.date,
            vpUserId: user.id
        })
    }

    clearVPM(){
        this.setState({
            vpCommands: '',
            vpApproval: '',
            vpImage: '',
        })
    }

    vpEditChange = (e) => {
        if (e.target.name === 'vpCommands') {
            this.setState({
                vpCommands: e.target.value                
            })
        } else if (e.target.name === 'vpApproval'){
            this.setState({                
                vpApproval: e.target.value
            })
        }        
    }

    Collection_popCloseHandler = () => {
        document.querySelector('.viewPhotoTableEdit_pop').style.display = 'none';
    }

    filterList = (event) => {
        let filtered =this.state.vpUserOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
                
            }
        }
        this.setState({
            vpUserList: filtered
        })
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>View Photo</span></div>
                    <div className="O2Table_SubContainer Report_subContainer">
                                <div className="O2Content_row">
                                    <div className="contentRow_Label">User Name</div>
                                    <div className="contentRow_Value">
                                        <Select options={this.state.userList} name="vpUser" value={this.state.userList.filter(({ value }) => value === this.state.vpUserRole)} placeholder="Choose User" onChange={this.handleVPRoleChange}/> 
                                    </div>
                                </div>
                                <div className="O2Content_row">
                                    <div className="contentRow_Label"></div>
                                    <div className="contentRow_Value">
                                    
                                    </div>
                                </div> 
                                <div className="O2Content_row">
                                    <div className="contentRow_Label"></div>
                                    <div className="contentRow_Value">
                                                                        
                                    </div>
                                </div> 
                            </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="metaData_Table">
                            <thead>
                                <tr>
                                    <th>Date </th>                                    
                                    <th>Commands
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="commands" onChange={this.filterList} />
                                        </div>
                                    </th>                                    
                                    <th>Supervisor View 
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="supervisorViewStr" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th>Supervisor Approval
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="supervisorApprovalStr" onChange={this.filterList} />
                                        </div>
                                    </th> 
                                    <th className="o2Del_container"> </th>                                  
                                    <th className="scroller"></th>                                                                        
                                </tr>
                            </thead>
                            <tbody>                                                      
                                {
                                    !this.state.vpUserList || this.state.vpUserList === null || this.state.vpUserList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No BA Details Available</td>
                                        </tr> :
                                        this.state.vpUserList.map((user,i) => (                                                                                         
                                            <tr key={i}>                                                                                                
                                    <td>
                                        <input type="date" value={user.effectiveStrDate} readOnly={true} onChange={ (e)=> this.handleDateChange(i,e)}/>
                                    </td>
                                    <td>
                                        <input type="text" data-tip={user.commands} value={user.commands}/>
                                    </td>
                                    <td>
                                        <input type="text" data-tip={user.supervisorViewStr} value={user.supervisorViewStr}/>
                                    </td>
                                    <td>
                                        <input type="text" data-tip={user.supervisorApprovalStr} value={user.supervisorApprovalStr}/>
                                    </td>
                                    <td className="o2Del_container" onClick={() => this.Collection_popup_openHandler(user)}>
                                       <a>View</a>
                                    </td>
                                            </tr>
                                            
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.vpUserList ? this.state.vpUserList.length : 0}</span>entries</span></div>                        
                    </div>                    
                    <div className="O2Table_Footer">                                                                        
                        <button onClick={this.vp_cancelfunction}>Cancel</button>
                    </div>
                </div>

                <div className="O2TableEdit_wrapper viewPhotoTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">User Name : <span>{this.state.vpUserRoleName}</span>                                
                            </div>
                            <div className="O2TableEditBody">
                            <div className="row">
                            <div className="FieldComponent">
                                <div className="imgPos">                                    
                                    <img src={this.state.vpImage}></img>
                                </div>
                            </div>
                                </div>
                                
                                <div className="row">
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Commands : </div>
                                        <div className="FieldValue VPValue"><textarea name="vpCommands" value={this.state.vpCommands} readOnly={this.state.vpEditFlag} onChange={this.vpEditChange} /></div>
                                    </div>
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Approval : </div>
                                        <div className="FieldValue">
                                            <select name="vpApproval" value={this.state.vpApproval} disabled={this.state.vpEditFlag} onChange={this.vpEditChange}>
                                                <option value="Y" >Yes</option>
                                                <option value="N" >No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>                                                                
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                <button onClick={this.vpSave}>Save</button>                                   
                                    <button onClick={this.Collection_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>                
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(ViewPhotoModule)
//export default ViewPhotoModule
