import React, { Component } from 'react'
import './StateCityModule.css';
import Select from 'react-select';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faEdit, faTrashAlt,faSearch } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import * as XLSX from 'xlsx';
import fileSaver from 'file-saver';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -95px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;

class StateCityModule extends Component {


    constructor(props) {

        var curr = new Date();
        curr.setDate(curr.getDate());
        var currentdate = curr.toISOString().substr(0, 10);

        super(props)
        this.state = {
            loading: false,            
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            scmStateAndCityList: [],
            scmStateAndCityListOrg: [],
            scmStateList: [],              
            todayDate:currentdate,
            scmState:'',
            scmCity:'',
            scmStatus:'Y',
            scmId:0,                              
        }
    }

    componentDidMount() {
        this.getSCMStateAndCity();        
    }

    getSCMStateAndCity() {
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/stateandcity/getStateAndCity")
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({                        
                        scmStateAndCityList: data.stateAndCityList,
                        scmStateAndCityListOrg: data.stateAndCityList,
                        scmStateList:data.stateList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    newStateCity_popCloseHandler = () => {
        document.querySelector('.stateCityTableEdit_pop').style.display = 'none';
    }
    newStateCity_openHandler = () => {
        this.clearSCM();        
    }

    // editStateCity_openHandler = (sc) => {
    //     const params={
    //         id:sc.id
    //     }
    //     this.setState({
    //         loading: true
    //     });
    //     axios.get(this.state.httpURL+"/stateandcity/getStateAndCityDetail",{params})
    //     .then(response=>response.data)
    //     .then((data) =>{        
    //         this.setState({
    //             loading: false
    //         })            
    //         // this.setState({
                               
    //         // },()=>{                  
    //         //     //document.querySelector('.stateCityTableEdit_pop').style.display = 'flex';
    //         //     this.loadStateAndCity(data.stateAndCityDetail);
    //         // })
    //         this.loadStateAndCity(data.stateAndCityDetail);
    //     }).catch(err=>{            
    //         this.setState({
    //             loading: false
    //         })
    //         alert(err);
    //     })        
    // }

    editStateCity_openHandler = (sc) => {
        this.setState({
            scmId : sc.id,
            scmState : sc.stateCode,
            scmCity : sc.city,
            scmStatus : sc.status                
        },()=>{
            document.querySelector('.stateCityTableEdit_pop').style.display = 'flex';
        })      
    }

    loadStateAndCity = (stateAndCityDetail) => {        
        this.setState({
            scmId : stateAndCityDetail[0].id,
            scmState : stateAndCityDetail[0].stateCode,
            scmCity : stateAndCityDetail[0].city,
            scmStatus : stateAndCityDetail[0].status
        },()=>{                  
            document.querySelector('.stateCityTableEdit_pop').style.display = 'flex';            
        })
    }

    handleSCMChange = (event) => {
        this.setState({
            scmState: event.value                
        })
    }

    handleSCMEditChange = (e) => {
        if (e.target.name === 'scmCity') {
            this.setState({
                scmCity: e.target.value                
            })
        } else if (e.target.name === 'scmStatus'){
            this.setState({                
                scmStatus: e.target.value
            })
        }        
    }

    clearSCM() {
        this.setState({
            scmId : 0,
            scmState : '',
            scmCity : '',
            scmStatus : 'Y',
        },()=>{                  
            document.querySelector('.stateCityTableEdit_pop').style.display = 'flex';            
        });        
    }

    scmSave = () => {
        if (this.state.scmState === "" || this.state.scmState === null || this.state.scmState === undefined) {
            alert("Please Select State");
        } else if (this.state.scmCity === "" || this.state.scmCity === null || this.state.scmCity === undefined) {
            alert("Please Enter City");
        } else {
            const newSCM = [{
                id: this.state.scmId,
                stateCode: this.state.scmState,
                city: this.state.scmCity,
                status: this.state.scmStatus,
                modifiedBy: this.state.loginuserObj.userName
            }];

            let params = {
                "stateAndCityDetail": newSCM
            }
            //console.log("<-1->"+JSON.stringify(params));
            this.setState({
                loading: true
            })
            axios.post(this.state.httpURL + "/stateandcity/saveStateAndCity", params)
                .then(response => {
                    if (response.data !== null) {
                        if (response.data.valid) {
                            this.setState({
                                loading: false
                            })
                            if (response.data.existCity) {
                                alert("This State And City Is Already Exist.");
                            } else {
                                alert("State And City Saved Successfully.");
                                this.clearSCM();
                                this.newStateCity_popCloseHandler();
                                this.getSCMStateAndCity();
                            }
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                })
                .catch(error => {
                    //console.log("error" + error)
                    this.setState({
                        loading: false
                    })
                    alert(error);
                })
        }
    }

    filterList = (event) => {
        let filtered =this.state.scmStateAndCityListOrg;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
                
            }
        }
        this.setState({
            scmStateAndCityList: filtered
        })
    }  
    
    scmExcel = () =>{
        var dFlag = true;
        if(this.state.scmStateAndCityList.length === 0){
            alert("No Records");
            dFlag = false;
        } 
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.scmStateAndCityList;
            var fileName = "State And City Report_"+this.state.currentDate;
            var wscols = [                
                {wch:35},
                {wch:35},
                {wch:10}
            ];
            for(var i=0;i<dList.length;i++){
                arr = {};
                arr = {
                    "State": dList[i].stateName, 
                    "City": dList[i].city,                   
                    "Status": dList[i].statusStr                                       
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'Route': ws }, SheetNames: ['Route'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            fileSaver.saveAs(data, fileName + fileExtension);
        }        
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>STATE AND  CITY </span></div>
                    <div className="O2Table_SubContainer">
                        <div className="O2Table_New">
                            <button onClick={this.newStateCity_openHandler}>New</button>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable">
                            <thead>
                                <tr>
                                    <th>State
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="stateName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th>City
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="city" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Status_Route">Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="statusStr" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container"></th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td>TamilNadu </td>
                                    <td>Coimbatore </td>
                                    <td className="Status_Route"></td>
                                    <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.newStateCity_openHandler()} />                                        
                                    </td>
                                </tr> */}
                                {
                                    !this.state.scmStateAndCityList || this.state.scmStateAndCityList === null || this.state.scmStateAndCityList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No State And City Available</td>
                                        </tr> :
                                        this.state.scmStateAndCityList.map((sc,i) => (                                                                                         
                                            <tr key={i}>                                                                                                                                   
                                    <td>
                                        <input type="text" data-tip={sc.stateName} value={sc.stateName}/>
                                    </td>
                                    <td>
                                        <input type="text" data-tip={sc.city} value={sc.city}/>
                                    </td>
                                    <td className="Status_Route">
                                        <input type="text" data-tip={sc.statusStr} value={sc.statusStr}/>
                                    </td>
                                    <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.editStateCity_openHandler(sc)} />                                        
                                    </td>
                                            </tr>
                                            
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.scmStateAndCityList ? this.state.scmStateAndCityList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Export</div> */}
                        {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="routeMaster_Table" filename={"RouteMaster"}
                            sheet="tablexls" buttonText="Excel Export" /></div> */}
                        <div className="O2Table_ExpExcel" onClick={ ()=> this.scmExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }}/>Excel Export</div>
                    </div>
                </div>
                <div className="O2TableEdit_wrapper stateCityTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">State and City</div>
                            <div className="RM_BodyContent">
                               {/* <div className="legentContent_div">
                                    <div className="legendBody_Label">Do You Know Pincode? </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select></select>
                                    </div>
                                </div> 
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Pin Code</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input name='rmRouterName' type="text" />
                                    </div>
                                </div> */}
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">State</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.scmStateList} name="scmState" value={this.state.scmStateList.filter(({ value }) => value === this.state.scmState)} placeholder="Choose State" onChange={this.handleSCMChange}/> 
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">City</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='scmCity' value={this.state.scmCity} onChange={this.handleSCMEditChange} />
                                    </div>
                                </div>                                
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Status </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select name='scmStatus' value={this.state.scmStatus} onChange={this.handleSCMEditChange}>
                                            <option value="Y" >Active</option>
                                            <option value="N" >In Active</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    <button onClick={this.scmSave}>Save</button>
                                    <button onClick={this.newStateCity_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            {/* <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            /> */}
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(StateCityModule)
//export default StateCityModule
