import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit,faSearch,faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux';
import Select from 'react-select';
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class RetailerSchemeProductMaintainModule extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0,10);
    
        this.state = {
            Distributorbtn: "Popup_tabButtons",
            DistServbtn: "Popup_tabButtons",
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            currentDate:todaydate,
            companyCode: this.props.userObj.companyCode,
            rspmSchemeProductList: [],
            rspmSavedRetailerList: [],
            rspmOrgSavedRetailerList: [],
            rspmStateList: [],
            rspmCityList: [],
            rspmDistributorList: [],
            rspmRetailerTypeList: [],
            rspmRetailerList: [],
            rspmSchemeProduct:'',
            rspmFromDate:todaydate,
            rspmToDate:todaydate, 
            rspmRetailerFromDate: todaydate,
            rspmRetailerToDate: todaydate,
            rspmState: '',
            rspmCity: '',
            rspmDistributor:'',
            rspmRetailerType:'',
            rspmRetailer:'',
            rspmNewSchemeProduct:'',
            selectedRSPMCityList: [],
            selectedRSPMistributorList: [],
            rspmSelectedRetailerList: [],     
        }    
    }
    
    componentDidMount() {
        this.getRSPMSchemeProductList();       
    }
    getRSPMSchemeProductList() {
        const params = {
            companyCode: this.state.companyCode            
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/rsp/getRSPMSchemeProductList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        rspmSchemeProductList: data.schemeProductList,
                        rspmStateList: data.stateList,
                        rspmCityList: data.cityList,                                                                      
                    })                    
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    rspmGo = () => { 
        if (this.state.rspmSchemeProduct === "") {
            alert("Please Select Scheme Product");
        } else if(this.state.rspmFromDate === ""){
            alert("Please Select From Date");
        } else if(this.state.rspmToDate === ""){
            alert("Please Select To Date");
        } else if(this.state.rspmFromDate > this.state.rspmToDate){
            alert("From Date Cannot Be Greater Than End Date");
        } else {
            const params = {
                productCode: this.state.rspmSchemeProduct,
                companyCode: this.state.companyCode,
                fromDate: this.state.rspmFromDate,
                toDate: this.state.rspmToDate,            
            }
            this.setState({
                loading: true
            });
            axios.get(this.state.httpURL + "/rsp/getRSPMSavedRetailerList", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {                    
                        this.setState({
                            rspmOrgSavedRetailerList: data.savedRetailerList,
                            rspmSavedRetailerList: data.savedRetailerList,                                                                      
                        });  
                        ReactTooltip.rebuild();                  
                    } else {
                        alert(data.responseMsg);
                    }
                    this.setState({
                        loading: false
                    });
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }        
    }

    getRSPMDistributor() {

        let citylist="";
        this.state.selectedRSPMCityList.map((sal) => (
            citylist +=""+sal.value+","
        ));
        citylist = citylist.substring(0, citylist.length - 1);

        const params = {
            companyCode: this.state.companyCode,
            state: this.state.rspmState,
            city: citylist,
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/rsp/getDistributorList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        rspmDistributorList: data.distributorList,
                        rspmRetailerTypeList: data.retailerTypeList                        
                    })                    
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getRSPMRetailer() {

        let distributorlist="";
        this.state.selectedRSPMistributorList.map((sal) => (
            distributorlist +=""+sal.value+","
        ));
        distributorlist = distributorlist.substring(0, distributorlist.length - 1);

        const params = {
            productCode: this.state.rspmNewSchemeProduct,
            distributorCode: distributorlist,
            retailerType: this.state.rspmRetailerType,
            companyCode: this.state.companyCode            
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/rsp/getRSPMRetailerList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        rspmRetailerList: data.retailerList                                              
                    })                    
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    saveRSPMDetail = () => {        
        
        if (this.state.rspmNewSchemeProduct === "") {
            alert("Please Select Scheme Product");
        } else if (this.state.selectedRSPMistributorList.length === 0) {
            alert("Please Select Distributor");
        } else if(this.state.rspmRetailerType === ""){
            alert("Please Select Retailer Type");
        }  else if(this.state.rspmSelectedRetailerList.length === 0){
            alert("Please Select Retailer");
        } else if(this.state.rspmRetailerFromDate === ""){
            alert("Please Select From Date");
        } else if(this.state.rspmRetailerToDate === ""){
            alert("Please Select To Date");
        } else if(this.state.rspmRetailerFromDate > this.state.rspmRetailerToDate){
            alert("From Date Cannot Be Greater Than End Date");
        } else {

            let distributorlist="";
            this.state.selectedRSPMistributorList.map((sal) => (
                distributorlist +=""+sal.value+","
            ));
            distributorlist = distributorlist.substring(0, distributorlist.length - 1);

            let retailerlist="";
            this.state.rspmSelectedRetailerList.map((sal) => (
                retailerlist +=""+sal.value+","
            ));
            retailerlist = retailerlist.substring(0, retailerlist.length - 1);

            let params = {
                "schemeProduct": this.state.rspmNewSchemeProduct,
                "distributor": distributorlist,
                "retailerType": this.state.rspmRetailerType, 
                "retailer": retailerlist,
                "fromDate": this.state.rspmRetailerFromDate,
                "toDate": this.state.rspmRetailerToDate,
                "user": this.state.loginuserObj.userName,                
                "companyCode": this.state.companyCode,
            }
            // console.log("params-->"+JSON.stringify(params))
            this.setState({
                loading:true
            })
            axios.post(this.state.httpURL + "/rsp/saveRSPMRetailerSchemeList", params)
                .then(response => {
                    this.setState({
                        loading:false
                    })
                    if (response.data !== null) {                        
                        if (response.data.valid) {
                            alert(response.data.responseMsg);
                            document.querySelector('.schemeMaintainTableEdit_pop').style.display = 'none';
                            this.clearRSPMDetails();
                        } else {
                            alert(response.data.responseMsg);
                        }                    
                    }
                })
                .catch(error => {
                   // console.log("error" + error)
                   alert(error);
                   this.setState({
                    loading:false
                })
                })
        }
    }

    rspm_GridDelete = (ind) => {        
        if (window.confirm("Delete the Retailer?")) {            
            const params = {
                "userName": this.state.loginuserObj.userName,
                "retailerId": this.state.rspmSavedRetailerList[ind].id,
            }
            this.setState({
                loading: true
            });
            axios.delete(this.state.httpURL + "/rsp/deleteRSPMRetailer", { params })
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            alert("Retailer Deleted Successfull ...");
                            this.rspmGo();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    newschemeMaintain_popCloseHandler = () => {
        document.querySelector('.schemeMaintainTableEdit_pop').style.display = 'none';
    }
    newschemeMaintain_openHandler = () => {
        document.querySelector('.schemeMaintainTableEdit_pop').style.display = 'flex';
    }
    filterList = (event) => {
        let filtered =this.state.rspmOrgSavedRetailerList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
                
            }
        }
        this.setState({
            rspmSavedRetailerList: filtered
        })
    }

    handleRSPMSchemeProductChange = (event) => {
        this.setState({
            rspmSchemeProduct: event.value
        });
    }

    handleRSPMNewSchemeProduct = (event) => {
        this.setState({
            rspmNewSchemeProduct: event.value
        });
    }

    handleRSPMStateChange = (event) => {
        this.setState({
            rspmState: event.value
        });
    }

    handleRSPMCityChange = (event) => {
        this.setState({
            selectedRSPMCityList: event
        }, () => {           
            this.getRSPMDistributor();
        });
    }

    handleRSPMDistributorChange = (event) => {
        this.setState({
            selectedRSPMistributorList: event,
            rspmRetailerType:'', 
            rspmSelectedRetailerList:[],
        });
    }

    handleRSPMRetailerTypeChange = (event) => {
        this.setState({
            rspmRetailerType: event.value
        }, () => {           
            this.getRSPMRetailer();
        });
    }
    
    handleRSPMRetailerChange = (event) => {
        this.setState({
            rspmSelectedRetailerList: event
        });
    }

    rspmDateChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    clearRSPMDetails(){
        this.setState({
            rspmNewSchemeProduct: '',
            selectedRSPMistributorList:[],
            rspmRetailerType:'', 
            rspmSelectedRetailerList:[],
            rspmState:'',
            selectedRSPMCityList: [],
            rspmRetailerFromDate:this.state.currentDate,
            rspmRetailerToDate:this.state.currentDate,
        }, () => {             
                        
        });    
    }

    retailerSchemeProductExcel = () =>{
        var dFlag = true;
        if(this.state.rspmSavedRetailerList.length === 0){
            alert("No Records");
            dFlag = false;
        } 
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.rspmSavedRetailerList;
            var fileName = "Retailer Scheme Product Report_"+this.state.currentDate;
            var wscols = [
                {wch:16},
                {wch:45},
                {wch:60}
            ];
            for(var i=0;i<dList.length;i++){
                arr = {};
                arr = {
                    "Retailer Code": dList[i].retailerCode,                    
                    "Retailer": dList[i].retailerName,
                    "Location": dList[i].location                                     
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'RetailerSchemeProduct': ws }, SheetNames: ['RetailerSchemeProduct'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            fileSaver.saveAs(data, fileName + fileExtension);
        }        
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>MAINTAIN - RETAILER SCHEME PRODUCTS</span></div>
                    <div className="O2Table_SubContainer schemeSub">
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Scheme Product</div>
                            <div className="contentRow_Value">
                                <Select options={this.state.rspmSchemeProductList} name="rspmSchemeProduct" placeholder="Choose Scheme Product" onChange={this.handleRSPMSchemeProductChange} />
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label">From Date</div>
                            <div className="contentRow_Value">
                                <input type="date" name="rspmFromDate" value={this.state.rspmFromDate} onChange={this.rspmDateChange} />
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label">To Date</div>
                            <div className="contentRow_Value">
                                <input type="date" name="rspmToDate" value={this.state.rspmToDate} onChange={this.rspmDateChange} />
                            </div>
                        </div>
                         <div className="O2Table_New">
                            <button onClick={this.rspmGo}>Go</button>
                        </div> 
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable">
                            <thead>
                                <tr>
                                    <th>Retailer Code
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerCode" onChange={this.filterList} />
                                        </div>
                                    </th>
                                   <th>Retailer
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerName" onChange={this.filterList} />
                                        </div>
                                    </th> 
                                    <th>Location
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="location" onChange={this.filterList} />
                                        </div>
                                    </th> 
                                    <th className="o2Del_container">
                                        <button><FontAwesomeIcon icon={faPlus} style={{ padding: '0px 4px' }}  onClick={this.newschemeMaintain_openHandler}/></button>
                                    </th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>                                                
                                    <td>
                                        5656d5656
                                    </td>
                                    <td>
                                        argjuhkfrjgjj
                                    </td>
                                    <td>
                                        sarahkj, vpojk,c mnkd
                                    </td>
                                    <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}} onClick={this.newschemeMaintain_openHandler} />
                                        <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}}/>
                                    </td>
                                </tr>  */}
                                {
                                    this.state.rspmSavedRetailerList.map((rspm, i) => (
                                        <tr key={rspm.retailerCode}>
                                            <td data-tip={rspm.retailerCode}>{rspm.retailerCode}</td>
                                            <td data-tip={rspm.retailerName}>{rspm.retailerName}</td> 
                                            <td data-tip={rspm.location}>{rspm.location}</td>
                                        <td className="o2Del_container">                                        
                                        <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}} onClick={() => this.rspm_GridDelete(i)}/>
                                        </td>
                                    </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.rspmSavedRetailerList ? this.state.rspmSavedRetailerList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                        <div className="O2Table_ExpExcel" onClick={ ()=> this.retailerSchemeProductExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }}/>Excel Export</div>
                    </div>
                    <div className="O2Table_bottomSubContainer pricemasterSub">
                        {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Export</div> */}
                        {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="priceType_Table" filename={"Product Price Type"}
                            sheet="tablexls" buttonText="Excel Export" /></div>
                        <div class="PricemasterFooter">
                            <button>Save</button>
                            <button>Cancel</button>
                        </div> */}
                    </div>
                    
                </div>
                <div className="O2TableEdit_wrapper schemeMaintainTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Retailer Scheme Products - Maintain</div>
                            <div className="RM_BodyContent">
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Scheme Product</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.rspmSchemeProductList} name="rspmNewSchemeProduct" value={this.state.rspmSchemeProductList.filter(({value}) => value === this.state.rspmNewSchemeProduct)} placeholder="Choose Scheme Product" onChange={this.handleRSPMNewSchemeProduct} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">State</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.rspmStateList} name="rspmState" value={this.state.rspmStateList.filter(({value}) => value === this.state.rspmState)} placeholder="Choose State" onChange={this.handleRSPMStateChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">City</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select isMulti options={this.state.rspmCityList} name="rspmCity" value={this.state.selectedRSPMCityList} placeholder="Choose City" onChange={this.handleRSPMCityChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Distributor </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select isMulti options={this.state.rspmDistributorList} name="rspmDistributor" value={this.state.selectedRSPMistributorList} placeholder="Choose Distributor" onChange={this.handleRSPMDistributorChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Retailer Type </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.rspmRetailerTypeList} name="rspmRetailerType" value={this.state.rspmRetailerTypeList.filter(({value}) => value === this.state.rspmRetailerType)} placeholder="Choose Retailer Type" onChange={this.handleRSPMRetailerTypeChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Retailer Name </div><span>:</span>
                                    <div className="legendBody_Value">                                        
                                        <Select isMulti options={this.state.rspmRetailerList} name="rspmRetailer" value={this.state.rspmSelectedRetailerList} placeholder="Choose Retailer" onChange={this.handleRSPMRetailerChange} />
                                    </div>
                                </div>  
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Date From </div><span>:</span>
                                    <div className="legendBody_Value">                                        
                                        <input type="date" name="rspmRetailerFromDate" value={this.state.rspmRetailerFromDate} onChange={this.rspmDateChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Date To </div><span>:</span>
                                    <div className="legendBody_Value">                                        
                                        <input type="date" name="rspmRetailerToDate" value={this.state.rspmRetailerToDate} onChange={this.rspmDateChange} />
                                    </div>
                                </div>                              
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    <button onClick={this.saveRSPMDetail}>Save</button>                            
                                    <button onClick={this.newschemeMaintain_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL        
    }
}
export default connect(mapStateToProps)(RetailerSchemeProductMaintainModule)
//export default RetailerSchemeProductMaintainModule
