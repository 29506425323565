import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import './RetailerPriceModule.css';
import Select from 'react-select';
import axios from 'axios'
import { connect } from 'react-redux'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class RetailerPriceModule extends Component {

    constructor(props) {
        super(props)
        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            rpmRetailerTypeList: [],
            rpmRetailerTypeOrgList: [],
            rpmRetailerList: [],
            rpmPriceList: [],
            rpmProductTypeList: [],
            rpmStateList: [],
            rpmCityList: [],
            rpmRouteList: [],
            rpmRetailerPriceId: '',
            rpmRetailerName: '',
            // rpmCompanyCode: '',
            rpmPriceType: '',
            rpmProductType: '',
            rpmStatus: 'Y',
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            retailerPriceWriteAccess: this.props.userObj.retailerPriceWriteAccess,
            currentDate: todaydate,
            searchReatailerCode: '',
            searchReatailerName: '',
            rpmStateName: '',
            rpmCityName: '',
            rpmRouteCode: '',
            readOnlyFlag: false,
        }
    }

    componentDidMount() {
        // this.getRetailerPriceList();
        //this.getRPDroupDownList();       
    }

    getRPStateList() {
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/retailerprice/getRPStateList")
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        rpmStateList: data.indianStateList
                    }, () => {
                        document.querySelector('.retailerPriceTableEdit_pop').style.display = 'flex';
                    });
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getRPCityList() {
        const params = {
            state: this.state.rpmStateName
        }

        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/retailerprice/getRPCityList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        rpmCityList: data.indianCitiesList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getRPRouteList() {
        const params = {
            state: this.state.rpmStateName,
            city: this.state.rpmCityName
        }

        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/retailerprice/getRPRouteList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        rpmRouteList: data.routeList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getRPDroupDownList() {
        const params = {
            routeCode: this.state.rpmRouteCode
        }

        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/retailerprice/getRPDroupDownList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        rpmRetailerList: data.retailerList,
                        rpmPriceList: data.priceTypeList,
                        rpmProductTypeList: data.productTypeList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getRetailerPriceList() {

        const params = {
            retailerCode: this.state.searchReatailerCode,
            retailerName: this.state.searchReatailerName
        }

        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/retailerprice/getRetailerList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        rpmRetailerTypeList: data.retailerPriceList,
                        rpmRetailerTypeOrgList: data.retailerPriceList,
                        // rpmRetailerList: data.retailerList,
                        // rpmPriceList: data.priceTypeList,
                        // rpmProductTypeList: data.productTypeList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    newRetailerprice_popCloseHandler = () => {
        document.querySelector('.retailerPriceTableEdit_pop').style.display = 'none';
    }
    newRetailerprice_openHandler = () => {
        this.setState({
            rpmRetailerPriceId: '',
            rpmRetailerName: '',
            // rpmCompanyCode: '',
            rpmPriceType: '',
            rpmProductType: '',
            rpmStatus: 'Y',
            readOnlyFlag: false,

            rpmStateName: '',
            rpmCityName: '',
            rpmRouteCode: '',
            rpmRetailerList: [],
            rpmPriceList: [],
            rpmProductTypeList: [],
            rpmStateList: [],
            rpmCityList: [],
            rpmRouteList: [],
        }, () => {
            this.getRPStateList();
        });
    }

    handleRPMRetailerChange = (event) => {
        this.setState({
            rpmRetailerName: event.value
        });
    }

    handleRPMPriceChange = (event) => {
        this.setState({
            rpmPriceType: event.value
        });
    }

    handleRPMProductChange = (event) => {
        this.setState({
            rpmProductType: event.value
        });
    }

    handleRPMChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });

    }

    rpmStatusChange = (event) => {
        this.setState({
            rpmStatus: event.target.value
        });

    }

    handleRPMStateChange = (event) => {
        this.setState({
            rpmStateName: event.value
        }, () => {
            if (this.state.rpmStateName !== "" && this.state.rpmStateName !== null && this.state.rpmStateName !== undefined) {
                this.getRPCityList();
            }
        });

    }

    handleRPMCityChange = (event) => {
        this.setState({
            rpmCityName: event.value
        }, () => {
            if (this.state.rpmCityName !== "" && this.state.rpmCityName !== null && this.state.rpmCityName !== undefined) {
                this.getRPRouteList();
            }
        });

    }

    handleRPMRouteChange = (event) => {
        this.setState({
            rpmRouteCode: event.value
        }, () => {
            if (this.state.rpmRouteCode !== "" && this.state.rpmRouteCode !== null && this.state.rpmRouteCode !== undefined) {
                this.getRPDroupDownList();
            }
        });
    }

    editRPM_openHandler = (retailer) => {
        // this.setState({
        //     rpmRetailerPriceId: retailer.retailerPriceId,
        //     rpmRetailerName: retailer.retailerCode,
        //    // rpmCompanyCode: retailer.companyCode,
        //     rpmPriceType: retailer.productPriceId,
        //     rpmProductType: retailer.productTypeCode,
        //     rpmStatus: retailer.status
        // });
        // document.querySelector('.retailerPriceTableEdit_pop').style.display = 'flex';
        const params = {
            retailerCode: retailer.retailerCode
        }

        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/retailerprice/getRetailerDetailList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        rpmRetailerPriceId: retailer.retailerPriceId,
                        rpmStateList: data.indianStateList,
                        rpmCityList: data.indianCitiesList,
                        rpmRouteList: data.routeList,
                        rpmPriceList: data.priceTypeList,
                        rpmProductTypeList: data.productTypeList,
                        rpmStateName: data.indianStateList[0].value,
                        rpmCityName: data.indianCitiesList[0].value,
                        rpmRouteCode: data.routeList[0].value,
                        rpmRetailerList: data.retailerList,
                        rpmRetailerName: retailer.retailerCode,
                        rpmPriceType: retailer.productPriceId,
                        rpmProductType: retailer.productTypeCode,
                        rpmStatus: retailer.status,
                        readOnlyFlag: true
                    }, () => {
                        document.querySelector('.retailerPriceTableEdit_pop').style.display = 'flex';
                    });
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    deleteRPM_Handler = (retailer) => {
        if (window.confirm("Delete The Retailer Price Type?")) {
            this.setState({
                loading: true
            });
            axios.delete(this.state.httpURL + "/retailerprice/" + retailer.retailerPriceId)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            alert("Retailer Price Deleted Successfull ...");
                            this.getRetailerPriceList();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    newRetailerprice_popSaveHandler = () => {
        if (this.state.rpmRetailerName === undefined || this.state.rpmRetailerName === "" || this.state.rpmRetailerName === null) {
            alert("Please Enter Retailer Name");
        }
        // else if (this.state.rpmCompanyCode === undefined || this.state.rpmCompanyCode === "" || this.state.rpmCompanyCode === null) {
        //     alert("Please Enter Company Code");
        // } 
        else if (this.state.rpmPriceType === undefined || this.state.rpmPriceType === "" || this.state.rpmPriceType === null) {
            alert("Please Enter Price Type");
        } else if (this.state.rpmProductType === undefined || this.state.rpmProductType === "" || this.state.rpmProductType === null) {
            alert("Please Enter Product Type");
        } else {

            const newRPM = [{
                retailerPriceId: this.state.rpmRetailerPriceId,
                companyCode: this.state.loginuserObj.companyCode,
                retailerCode: this.state.rpmRetailerName,
                productPriceId: this.state.rpmPriceType,
                productTypeCode: this.state.rpmProductType,
                status: this.state.rpmStatus,
                lastUpdatedBy: this.state.loginuserObj.userName
            }];

            let params = {
                "retailerPriceList": newRPM
            }
            // console.log(newRPM);
            this.setState({
                loading: true
            })
            axios.post(this.state.httpURL + "/retailerprice/", params)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            // if (response.data.existRetailerPrice) {
                            //     alert("This Retailer Price Is Already Exist.");
                            // } else {
                            alert(response.data.responseMsg);
                            this.clearRPM();
                            document.querySelector('.retailerPriceTableEdit_pop').style.display = 'none';
                            if ((this.state.searchReatailerCode !== "" && this.state.searchReatailerCode !== null && this.state.searchReatailerCode === undefined) || (this.state.searchReatailerName !== "" && this.state.searchReatailerName !== null && this.state.searchReatailerName !== undefined)) {
                                this.getRetailerPriceList();
                            }
                            // }
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                })
                .catch(error => {
                    //console.log("error" + error)
                    this.setState({
                        loading: false
                    });
                    alert(error);
                })
        }
    }

    clearRPM() {
        this.setState({
            rpmRetailerTypeList: [],
            rpmRetailerPriceId: '',
            rpmRetailerName: '',
            // rpmCompanyCode: '',
            rpmPriceType: '',
            rpmProductType: '',


            rpmStateName: '',
            rpmCityName: '',
            rpmRouteCode: '',
            readOnlyFlag: false,
            rpmRetailerList: [],
            rpmPriceList: [],
            rpmProductTypeList: [],
            rpmStateList: [],
            rpmCityList: [],
            rpmRouteList: [],
        });
    }

    filterList = (event) => {
        let filtered = this.state.rpmRetailerTypeOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));
            }
        }
        this.setState({
            rpmRetailerTypeList: filtered
        })
    }

    retailerPriceExcel = () => {
        var dFlag = true;
        if (this.state.rpmRetailerTypeList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.rpmRetailerTypeList;
            var fileName = "Retailer Price Report_" + this.state.currentDate;
            var wscols = [
                { wch: 45 },
                { wch: 25 },
                { wch: 10 },
                { wch: 10 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Retailer Name": dList[i].name,
                    "Price Type": dList[i].priceTypeName,
                    "Product Type": dList[i].productTypeCode,
                    "Status": dList[i].status
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'RetailerPrice': ws }, SheetNames: ['RetailerPrice'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }


    setSearchDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    searchGoDetails = () => {
        if ((this.state.searchReatailerCode === "" || this.state.searchReatailerCode === null || this.state.searchReatailerCode === undefined) && (this.state.searchReatailerName === "" || this.state.searchReatailerName === null || this.state.searchReatailerName === undefined)) {
            alert("Please Enter Retailer Code or Name");
        } else {
            this.getRetailerPriceList();
        }
    }

    render() {

        const retailerSearch = [];
        for (const [index, value] of this.state.rpmRetailerList.entries()) {
            retailerSearch.push({
                label: value.name,
                value: value.retailerCode
            });
        }

        const priceSearch = [];
        for (const [index, value] of this.state.rpmPriceList.entries()) {
            priceSearch.push({
                label: value.priceTypeName,
                value: value.productPriceId
            });
        }

        const productTypeSearch = [];
        for (const [index, value] of this.state.rpmProductTypeList.entries()) {
            productTypeSearch.push({
                label: value.productTypeCode,
                value: value.productTypeCode
            });
        }


        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>RETAILER PRICE</span></div>
                    <div className="O2Table_SubContainer Report_subContainer">
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Retailer Code </div>
                            <div className="contentRow_Value">
                                <input type="text" name="searchReatailerCode" onChange={this.setSearchDetails}></input>
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Retailer Name </div>
                            <div className="contentRow_Value">
                                <input type="text" name="searchReatailerName" onChange={this.setSearchDetails}></input>
                            </div>
                        </div>
                        <div className="O2Table_Go"><button onClick={this.searchGoDetails}>Go</button></div>
                        {/* <div className="O2Table_Search">
                            <div className="O2Table_Label"> Search : </div>
                            <div className="O2Table_Value"><input type="text" onChange={this.filterList} /></div>
                        </div> */}

                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="retailerPrice_Table">
                            <thead>
                                <tr>
                                    <th className="Ref_Num">Retailer Code
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerCode" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Descript_width">Retailer Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="name" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    {/* <th className="Ref_Num">Company Code
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="companyCode" onChange={this.filterList} />
                                        </div>
                                    </th> */}
                                    <th className="Ref_Num">Price Type
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="priceTypeName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Ref_Num">Product Type
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productTypeCode" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Ref_Num">City
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="city" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Ref_Num">State
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="state" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Status_Route">Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="status" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container">
                                        <button onClick={this.newRetailerprice_openHandler}>New</button>
                                    </th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td className="checkBox_Container">                                                    
                                    </td>                                                
                                    <td>2589</td>
                                    <td>hkis52645</td>
                                    <td>KL5685</td>
                                    <td className="Status_Route">Y</td>
                                    <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}} onClick={ ()=> this.newRetailerprice_openHandler()}/>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}}/>
                                    </td>
                                </tr> */}
                                {
                                    !this.state.rpmRetailerTypeList || this.state.rpmRetailerTypeList === null || this.state.rpmRetailerTypeList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No Retailer Available</td>
                                        </tr> :
                                        this.state.rpmRetailerTypeList.map((retailer) => (
                                            <tr>
                                                <td className="Ref_Num" data-tip={retailer.retailerCode}>{retailer.retailerCode}</td>
                                                <td className="Descript_width" data-tip={retailer.name + '_' + retailer.address_line1 + '_' + retailer.address_line2}>{retailer.name}</td>
                                                {/* <td className="Ref_Num" data-tip={retailer.companyCode}>{retailer.companyCode}</td> */}
                                                <td className="Ref_Num" data-tip={retailer.priceTypeName}>{retailer.priceTypeName}</td>
                                                <td className="Ref_Num" data-tip={retailer.productTypeCode}>{retailer.productTypeCode}</td>
                                                <td className="Ref_Num" data-tip={retailer.city}>{retailer.city}</td>
                                                <td className="Ref_Num" data-tip={retailer.state}>{retailer.state}</td>
                                                <td className="Status_Route" data-tip={retailer.status}>{retailer.status}</td>
                                                <td className="o2Del_container">
                                                    <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.editRPM_openHandler(retailer)} />
                                                    {
                                                        this.state.retailerPriceWriteAccess ?
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 8px' }} onClick={() => this.deleteRPM_Handler(retailer)} />
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.rpmRetailerTypeList ? this.state.rpmRetailerTypeList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="retailerPrice_Table" filename={"Retailer Price"}
                            sheet="tablexls" buttonText="Excel Export" /></div> */}
                        <div className="O2Table_ExpExcel" onClick={() => this.retailerPriceExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                    </div>
                </div>
                <div className="O2TableEdit_wrapper retailerPriceTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Retailer Price</div>
                            <div className="RM_BodyContent">
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">State </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.rpmStateList} name="rpmStateName" value={this.state.rpmStateList.filter(({ value }) => value === this.state.rpmStateName)} isDisabled={this.state.readOnlyFlag} placeholder="Choose State" onChange={this.handleRPMStateChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">City </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.rpmCityList} name="rpmCityName" value={this.state.rpmCityList.filter(({ value }) => value === this.state.rpmCityName)} isDisabled={this.state.readOnlyFlag} placeholder="Choose City" onChange={this.handleRPMCityChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Route </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.rpmRouteList} name="rpmRouteCode" value={this.state.rpmRouteList.filter(({ value }) => value === this.state.rpmRouteCode)} isDisabled={this.state.readOnlyFlag} placeholder="Choose Route" onChange={this.handleRPMRouteChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Retailer Name </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={retailerSearch} name="rpmRetailerName" value={retailerSearch.filter(({ value }) => value === this.state.rpmRetailerName)} isDisabled={this.state.readOnlyFlag} placeholder="Choose Retailer" onChange={this.handleRPMRetailerChange} />
                                    </div>
                                </div>
                                {/* <div className="legentContent_div">
                                    <div className="legendBody_Label">Company Code </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='rpmCompanyCode' value={this.state.rpmCompanyCode} onChange={this.handleRPMChange} />
                                    </div>
                                </div> */}

                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Price Type </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={priceSearch} name="rpmRetailerName" value={priceSearch.filter(({ value }) => value === this.state.rpmPriceType)} placeholder="Choose Price Type" onChange={this.handleRPMPriceChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Product Type </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={productTypeSearch} name="rpmProductType" value={productTypeSearch.filter(({ value }) => value === this.state.rpmProductType)} placeholder="Choose Product Type" onChange={this.handleRPMProductChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Status </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select value={this.state.rpmStatus} onChange={this.rpmStatusChange}>
                                            <option value="Y" >Active</option>
                                            <option value="N" >In Active</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    {
                                        this.state.retailerPriceWriteAccess ?
                                            <button onClick={this.newRetailerprice_popSaveHandler}>Save</button>
                                            : null
                                    }
                                    <button onClick={this.newRetailerprice_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(RetailerPriceModule)
//export default RetailerPriceModule
