import React from 'react';
import './App.css';
import AppBody from './App-Body/AppBody'

import {Provider} from 'react-redux'
import store from './redux/store'

//For Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {faChevronCircleLeft,faChevronCircleRight, faUndo, faQuestionCircle,faFile,faCopy,faTrashAlt,faTrash,faSave,faSyncAlt,faSearch,faPaperclip,faComments,faBars,faCalendarAlt,faChevronLeft,faChevronRight,faStepBackward,faChevronUp,faChevronDown,faStepForward,faFileInvoice,faInbox,faPrint,faFolderOpen, faProjectDiagram, faPaperPlane,faCog,faBarcode,faFileContract,faCaretDown,faFileImport} from '@fortawesome/free-solid-svg-icons'
library.add(faChevronCircleLeft,faChevronCircleRight,faUndo,faQuestionCircle,faFile,faCopy,faTrashAlt,faTrash,faSave,faSyncAlt,faSearch,faPaperclip,faComments,faBars,faCalendarAlt,faChevronLeft,faChevronRight,faStepBackward,faChevronUp,faChevronDown,faStepForward,faFileInvoice,faInbox,faPrint,faFolderOpen,faProjectDiagram,faPaperPlane,faCog,faBarcode,faFileContract,faCaretDown,faFileImport);


function App() {
  

  return (
    <Provider store={store}>
           <div className="App-body-content">
            <AppBody ></AppBody>
         
          </div>
    </Provider>
  );
}


export default App;
