import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import './DayMasterModule.css';
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -95px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class DayMasterModule extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            dmDayList: [],
            dmDayOrgList: [],
            dmDayName: '',
            dmDayId: 0,
            dmStatus: 'Y',
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            dayMasterWriteAccess:this.props.userObj.dayMasterWriteAccess,
            currentDate:todaydate
        }
    }

    componentDidMount() {
        this.getDayList();
    }

    getDayList() {
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/daymaster/getDaysList")
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    })
                    this.setState({
                        dmDayList: data.dayList,
                        dmDayOrgList: data.dayList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    newDM_popCloseHandler = () => {
        document.querySelector('.dayMasterTableEdit_pop').style.display = 'none';
    }
    newDM_openHandler = () => {
        this.setState({
            dmDayName: '',
            dmDayId: 0,
            dmStatus: 'Y',
        });
        document.querySelector('.dayMasterTableEdit_pop').style.display = 'flex';
    }

    editDM_openHandler = (day) => {
        this.setState({
            dmDayId: day.dayId,
            dmDayName: day.dayName,
            dmStatus: day.status,
        });
        document.querySelector('.dayMasterTableEdit_pop').style.display = 'flex';
    }

    newDM_popSaveHandler = () => {
        if (this.state.dmDayName === undefined || this.state.dmDayName === "" || this.state.dmDayName === null) {
            alert("Please Enter Day");
        } else {

            const newDM = [{
                dayId: this.state.dmDayId,
                dayName: this.state.dmDayName,
                status: this.state.dmStatus,
                lastUpdatedBy: this.state.loginuserObj.userName
            }];

            let params = {
                "dayList": newDM
            }
            //console.log(newDM);
            this.setState({
                loading: true
            })
            axios.post(this.state.httpURL + "/daymaster/", params)
                .then(response => {
                    if (response.data !== null) {
                        if (response.data.valid) {
                            this.setState({
                                loading: false
                            });
                            if (response.data.existDay) {
                                alert("This Day Is Already Exist.");
                            } else {
                                alert("Day Saved Successfully.");
                                this.clearDayMaster();
                                document.querySelector('.dayMasterTableEdit_pop').style.display = 'none';
                                this.getDayList();
                            }
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                })
                .catch(error => {
                    //console.log("error" + error)
                    this.setState({
                        loading: false
                    });
                    alert(error);
                })
        }
    }

    deleteDM_Handler = (day) => {
        if (window.confirm("Delete The Day?")) {
            this.setState({
                loading: true
            })
            axios.delete(this.state.httpURL + "/daymaster/" + day.dayId)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            alert("Day Deleted Successfull ...");
                            this.getDayList();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    dmChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    dmStatusChange = (event) => {
        this.setState({
            dmStatus: event.target.value
        });

    }

    clearDayMaster() {
        this.setState({
            dmDayList: [],
            dmDayName: '',
            dmDayId: 0,
            dmStatus: 'Y',
        });
    }

    filterList = (event) => {
        let filtered =this.state.dmDayOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
                
            }
        }
        this.setState({
            dmDayList: filtered
        })
    }

    dayMasterExcel = () =>{
        var dFlag = true;
        if(this.state.dmDayList.length === 0){
            alert("No Records");
            dFlag = false;
        } 
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.dmDayList;
            var fileName = "Day Master Report_"+this.state.currentDate;
            var wscols = [
                {wch:30},
                {wch:10}
            ];
            for(var i=0;i<dList.length;i++){
                arr = {};
                arr = {
                    "Day": dList[i].dayName,                    
                    "Status": dList[i].status                                      
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'dayMaster': ws }, SheetNames: ['dayMaster'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            fileSaver.saveAs(data, fileName + fileExtension);
        }        
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>DAY MASTER</span></div>
                    <div className="O2Table_SubContainer">
                        {/* <div className="O2Table_Search">
                            <div className="O2Table_Label"> Search : </div>
                            <div className="O2Table_Value"><input type="text" onChange={this.filterList} /></div>
                        </div> */}
                        <div className="O2Table_New">
                            <button onClick={this.newDM_openHandler}>New</button>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="dayMaster_Table">
                            <thead>
                                <tr>
                                    <th>Day
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="dayName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Status_Route">Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="status" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container"></th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td className="checkBox_Container">                                                    
                                    </td>                                                
                                    <td>Day 1</td>
                                    <td className="Status_Route">Y</td>
                                    <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}} onClick={ ()=> this.newDM_openHandler()}/>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}}/>
                                    </td>
                                </tr> */}
                                {
                                    !this.state.dmDayList || this.state.dmDayList === null || this.state.dmDayList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No days Available</td>
                                        </tr> :
                                        this.state.dmDayList.map((day) => (
                                            <tr>
                                                <td data-tip={day.dayName}>{day.dayName}
                                                </td>
                                                <td className="Status_Route" data-tip={day.status}>{day.status}</td>
                                                <td className="o2Del_container">
                                                    <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.editDM_openHandler(day)} />
                                                    {
                                                        this.state.dayMasterWriteAccess ?
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 8px' }} onClick={() => this.deleteDM_Handler(day)} />
                                                        : null
                                                    }                                                    
                                                </td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.dmDayList ? this.state.dmDayList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Export</div> */}
                        {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="dayMaster_Table" filename={"Day Master"}
                            sheet="tablexls" buttonText="Excel Export" /></div> */}
                        <div className="O2Table_ExpExcel" onClick={ ()=> this.dayMasterExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }}/>Excel Export</div>    
                    </div>
                </div>
                <div className="O2TableEdit_wrapper dayMasterTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Day Master</div>
                            <div className="RM_BodyContent">
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Day </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name="dmDayName" value={this.state.dmDayName} onChange={this.dmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Status </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select value={this.state.dmStatus} onChange={this.dmStatusChange}>
                                            <option value="Y">Active</option>
                                            <option value="N">In Active</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    {
                                        this.state.dayMasterWriteAccess ?
                                            <button onClick={this.newDM_popSaveHandler}>Save</button>
                                        : null
                                    }                                    
                                    <button onClick={this.newDM_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(DayMasterModule)

//export default DayMasterModule




