import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import './ProductModule.css';
import Select from 'react-select';
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from 'react-tooltip';
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -265px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class ProductModule extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            pmProductList: [],
            pmProductOrgList: [],
            pmUOMList: [],
            pmProductId: '',
            pmProductCode: '',
            pmProductName: '',
            // pmCompanyCode: '',
            pmBrandCode: '',
            pmDescription: '',
            pmTaxCode: '',
            pmUOMType: '',
            pmStatus: 'Y',
            pmOrderQty: '',
            pmWeight: '',
            pmImageURL: '',
            productGroupList: [],
            productTypeList: [],
            umUserTeamList: [],
            umUserTeam: '',
            umUserTeamLabel: '',
            productGroupid: 0,
            productTypeCode: '',
            productShades: '',
            productKey: '',
            pmPiecesPerPack: '',
            pmPacksPerBox: '',
            pmBoxesPerCase: '',
            pmProductShortName: '',
            pmSchemeProduct: 'N',
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            productWriteAccess: this.props.userObj.productWriteAccess,
            currentDate: todaydate,
            eUserTeamList: [],
            smrTeamList: [],
            smrTeamOrgList: [],
            smrtempTeamList: [],
            productClassification: '',
            productSubCategory: '',
            productBrand: '',
            productSize: '',
            productSpecification: '',
            productShape: '',
            shadeNumber: '',
            eanNo: '',
            hsnNo: '',
            oldProductCode: ''
        }
    }

    componentDidMount() {
        this.getProductList();
    }

    getProductList() {
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/product/getAllProducts")
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                });
                if (data.valid) {
                    this.setState({
                        pmProductList: data.productList,
                        pmProductOrgList: data.productList,
                        pmUOMList: data.uomMasterList,
                        productGroupList: data.productGroupList,
                        productTypeList: data.productTypeList,
                        umUserTeamList: data.umUserTeamList,
                        smrTeamOrgList: data.umUserTeamList,
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    handlePMUOMChange = (event) => {
        this.setState({
            pmUOMType: event.value
        });

    }

    handleUmUserTeamChange = (event) => {
        this.setState({
            umUserTeam: event.label,
            umUserTeamLabel: event.label
        });
    }

    editPM_openHandler = (product) => {

        // console.log("--product--"+JSON.stringify(product))

        this.setState({
            pmProductId: product.id,
            pmProductCode: product.productCode,
            pmProductName: product.productName,
            // pmCompanyCode: product.companyCode,
            // pmBrandCode: product.brandCode,
            pmBrandCode: "Eyetex",
            pmDescription: product.description,
            pmTaxCode: product.taxCode,
            pmUOMType: product.uomCode,
            pmStatus: product.status,
            umUserTeam: product.umUserTeam,
            pmOrderQty: product.productOrderQty,
            pmWeight: product.productWeight,
            pmImageURL: product.productImage,
            productGroupid: product.productGroupId.toString(),
            productTypeCode: product.productTypeCode,
            productShades: product.productShades,
            productKey: product.productKey,
            pmPiecesPerPack: product.piecesPerPack,
            pmPacksPerBox: product.packsPerBox,
            pmBoxesPerCase: product.boxesPerCase,
            pmProductShortName: product.productShortName,
            pmSchemeProduct: product.schemeProduct,
            productClassification: product.productClassification,
            productSubCategory: product.productSubCategory,
            productBrand: product.productBrand,
            productSize: product.productSize,
            productSpecification: product.productSpecification,
            productShape: product.productShape,
            shadeNumber: product.shadeNumber,
            eanNo: product.eanNo,
            hsnNo: product.hsnNo,
            oldProductCode: product.oldProductCode
        }, () => {
            document.querySelector('.ProductTableEdit_pop').style.display = 'flex';
        });
        this.getUMUserTeamTagList(product.id);
    }

    getUMUserTeamTagList(productId) {
        const params = {
            companyCode: this.state.loginuserObj.companyCode,
            productId: productId,
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/product/getUMUserTeamTagList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        eUserTeamList: data.umUserTeamList,
                    });
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    newProduct_popCloseHandler = () => {
        document.querySelector('.ProductTableEdit_pop').style.display = 'none';
    }
    newProduct_openHandler = () => {
        this.setState({
            pmProductId: '',
            pmProductCode: '',
            pmProductName: '',
            // pmCompanyCode: '',
            pmBrandCode: '',
            pmDescription: '',
            pmTaxCode: '',
            pmUOMType: '',
            pmStatus: 'Y',
            umUserTeam: '',
            pmOrderQty: '',
            pmWeight: '',
            pmImageURL: '',
            productGroupid: 0,
            productTypeCode: '',
            productShades: '',
            productKey: '',
            pmProductShortName: '',
            pmPiecesPerPack: 0,
            pmPacksPerBox: 0,
            pmBoxesPerCase: 0,
            pmSchemeProduct: 'N',
            productClassification: '',
            productSubCategory: '',
            productBrand: '',
            productSize: '',
            productSpecification: '',
            productShape: '',
            shadeNumber: '',
            eanNo: '',
            hsnNo: '',
            oldProductCode: ''
        });
        document.querySelector('.ProductTableEdit_pop').style.display = 'flex';
    }
    newProduct_popSaveHandler = () => {
        if (this.state.productKey === undefined || this.state.productKey === "" || this.state.productKey === null) {
            alert("Please Enter Product Code");
        } else if (this.state.pmProductName === undefined || this.state.pmProductName === "" || this.state.pmProductName === null) {
            alert("Please Enter Product Name");
        } else if (this.state.pmProductShortName === undefined || this.state.pmProductShortName === "" || this.state.pmProductShortName === null) {
            alert("Please Enter Product Short Name");
        }
        // else if (this.state.pmCompanyCode === undefined || this.state.pmCompanyCode === "" || this.state.pmCompanyCode === null) {
        //     alert("Please Enter Company Code");
        // }
        // else if (this.state.pmBrandCode === undefined || this.state.pmBrandCode === "" || this.state.pmBrandCode === null) {
        //     alert("Please Enter Brand Code");
        // }
        else if (this.state.pmDescription === undefined || this.state.pmDescription === "" || this.state.pmDescription === null) {
            alert("Please Enter Description");
        } else if (this.state.pmUOMType === undefined || this.state.pmUOMType === "" || this.state.pmDescripmUOMTypeption === null) {
            alert("Please Enter UOM");
        } 
        // else if (this.state.pmWeight === undefined || this.state.pmWeight === "" || this.state.pmWeight === null) {
        //     alert("Please Enter Product Weight");
        // }
        // else if (this.state.pmImageURL === undefined || this.state.pmImageURL === "" || this.state.pmImageURL === null) {
        //     alert("Please Enter Image URL");
        // } 
        else if (this.state.productGroupid === undefined || this.state.productGroupid === "" || this.state.productGroupid === 0) {
            alert("Please Select Group Name");
        } else if (this.state.productTypeCode === undefined || this.state.productTypeCode === "" || this.state.productTypeCode === null) {
            alert("Please select Type Name");
        } else if (this.state.pmSchemeProduct === undefined || this.state.pmSchemeProduct === "" || this.state.pmSchemeProduct === null) {
            alert("Please Enter Scheme Product");
        } else if (this.state.productClassification === undefined || this.state.productClassification === "" || this.state.productClassification === null) {
            alert("Please Enter Product Category");
        } else if (this.state.productSubCategory === undefined || this.state.productSubCategory === "" || this.state.productSubCategory === null) {
            alert("Please Enter Product Sub-Category");
        } else if (this.state.productBrand === undefined || this.state.productBrand === "" || this.state.productBrand === null) {
            alert("Please Enter Product Brand");
        } 
        // else if (this.state.productSize === undefined || this.state.productSize === "" || this.state.productSize === null) {
        //     alert("Please Enter Product Size");
        // } else if (this.state.productSpecification === undefined || this.state.productSpecification === "" || this.state.productSpecification === null) {
        //     alert("Please Enter Product Finish");
        // }
        else {

            const newPM = [{
                id: this.state.pmProductId,
                productCode: this.state.pmProductCode,
                productName: this.state.pmProductName,
                companyCode: this.state.loginuserObj.companyCode,
                // brandCode: this.state.pmBrandCode,
                brandCode: "Eyetex",
                description: this.state.pmDescription,
                taxCode: this.state.pmTaxCode,
                uomCode: this.state.pmUOMType,
                status: this.state.pmStatus,
                teamTagList: this.state.eUserTeamList,
                productOrderQty: this.state.pmOrderQty,
                productWeight: this.state.pmWeight,
                productImage: this.state.pmImageURL,
                productGroupId: this.state.productGroupid,
                productTypeCode: this.state.productTypeCode,
                productShades: this.state.productShades,
                productKey: this.state.productKey,
                productShortName: this.state.pmProductShortName,
                piecesPerPack: this.state.pmPiecesPerPack,
                packsPerBox: this.state.pmPacksPerBox,
                boxesPerCase: this.state.pmBoxesPerCase,
                schemeProduct: this.state.pmSchemeProduct,
                lastUpdatedBy: this.state.loginuserObj.userName,
                productClassification: this.state.productClassification,
                productSubCategory: this.state.productSubCategory,
                schemeProproductBrandduct: this.state.productBrand,
                productSize: this.state.productSize,
                productSpecification: this.state.productSpecification,
                productShape: this.state.productShape,
                shadeNumber: this.state.shadeNumber,
                eanNo: this.state.eanNo,
                hsnNo: this.state.hsnNo,
                oldProductCode: this.state.oldProductCode,
                productBrand: this.state.productBrand,
            }];



            let params = {
                "productList": newPM
            }
            // console.log(newPM);
            this.setState({
                loading: true
            });
            axios.post(this.state.httpURL + "/product/", params)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            if (response.data.existProductName) {
                                alert("This Product Name Is Already Exist.");
                            } else if (response.data.existProductCode) {
                                alert("This Product Code Is Already Exist.");
                            } else {
                                alert("Product Saved Successfully.");
                                this.clearPTM();
                                document.querySelector('.ProductTableEdit_pop').style.display = 'none';
                                this.getProductList();
                            }
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                })
                .catch(error => {
                    //console.log("error" + error)
                    this.setState({
                        loading: false
                    });
                    alert(error);
                })
        }
    }

    pmChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    pmQtyChange = (e) => {
        const re = /^\d{1,}(\.\d{0,2})?$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }
    pmStatusChange = (event) => {
        this.setState({
            pmStatus: event.target.value
        });

    }
    pmSchemeChange = (event) => {
        this.setState({
            pmSchemeProduct: event.target.value
        });

    }

    deletePM_Handler = (price) => {
        if (window.confirm("Delete The Product?")) {
            this.setState({
                loading: true
            });
            axios.delete(this.state.httpURL + "/product/" + price.id)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            alert("Product Deleted Successfully ...");
                            this.getProductList();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    clearPTM() {
        this.setState({
            pmProductList: [],
            pmProductId: '',
            pmProductCode: '',
            pmProductName: '',
            // pmCompanyCode: '',
            pmBrandCode: '',
            pmDescription: '',
            pmTaxCode: '',
            pmUOMType: '',
            pmStatus: 'Y',
            pmOrderQty: '',
            pmWeight: '',
            pmImageURL: '',
            productGroupid: 0,
            productTypeCode: '',
            productShades: '',
            productKey: '',
            productClassification: '',
            productSubCategory: '',
            productBrand: '',
            productSize: '',
            productSpecification: '',
            productShape: '',
            shadeNumber: '',
            eanNo: '',
            hsnNo: '',
            oldProductCode: ''
        });
    }

    filterList = (event) => {
        let filtered = this.state.pmProductOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            pmProductList: filtered
        })
    }

    handleproductGroupChange = (event) => {
        // console.log("event.value==>"+event.value)
        this.setState({ productGroupid: event.value }, () => {
            // console.log(this.state.retailerdistributorList);
        });
    }

    handleproductTypeChange = (event) => {
        // console.log("event.value==>"+event.value)
        this.setState({ productTypeCode: event.value }, () => {
            // console.log(this.state.retailerdistributorList);
        });
    }

    handleMultiTeamChange = (event) => {
        const { smrTeamList, smrtempTeamList } = this.state;
        this.setState({
            smrtempTeamList: event
        }, () => {
            this.state.eUserTeamList = this.state.smrtempTeamList
            this.setState({
                smrTeamList: smrTeamList,
                smrTeamOrgList: smrTeamList
            }, () => {
            })
        });
    }

    productExcel = () => {
        var dFlag = true;
        if (this.state.pmProductList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.pmProductList;
            var fileName = "Product Report_" + this.state.currentDate;
            var wscols = [
                { wch: 16 },
                { wch: 50 },
                { wch: 16 },
                { wch: 16 },
                { wch: 16 },
                { wch: 16 },
                { wch: 16 },
                { wch: 16 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Product Code": dList[i].productCode,
                    "Product Name": dList[i].productName,
                    "Product Shades": dList[i].productShades,
                    "Product Type": dList[i].productTypeName,
                    "Product Group": dList[i].productGroupName,
                    "Pieces Per Pack": dList[i].piecesPerPack,
                    "Packs Per Box": dList[i].packsPerBox,
                    "Boxes Per Case": dList[i].boxesPerCase,
                    // "Classification": dList[i].productClassification,
                    "Category": dList[i].productClassification,
                    "Sub-Category": dList[i].productSubCategory,
                    "Brand": dList[i].productBrand,
                    "Size": dList[i].productSize,
                    // "Specification": dList[i].productSpecification,
                    "Finish": dList[i].productSpecification,
                    "Shape": dList[i].productShape,
                    "Status": dList[i].status
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'Product': ws }, SheetNames: ['Product'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    render() {

        const uomSearch = [];
        for (const [index, value] of this.state.pmUOMList.entries()) {
            uomSearch.push({
                label: value.uomDescription,
                value: value.uomCode
            });
        }

        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>PRODUCT</span></div>
                    <div className="O2Table_SubContainer">
                        {/* <div className="O2Table_Search">
                            <div className="O2Table_Label"> Search : </div>
                            <div className="O2Table_Value"><input type="text" onChange={this.filterList} /></div>
                        </div> */}
                        <div className="O2Table_New">
                            <button onClick={this.newProduct_openHandler}>New</button>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="product_Table">
                            <thead>
                                <tr>
                                    <th className="Ref_Num productMinWidth">Product Code
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productCode" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Descript_width prodNamewid">Product Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Ref_Num">Product Shades
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productShades" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="salesMan productMinWidth">Product Type
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productTypeName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="salesMan productMinWidth">Product Group
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productGroupName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    {/* <th>Company Code
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="companyCode" onChange={this.filterList} />
                                        </div>
                                    </th> */}
                                    {/* <th className="Ref_Num">Brand Code
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="brandCode" onChange={this.filterList} />
                                        </div>
                                    </th> */}
                                    {/* <th className="Descript_width prodNamewid">Description
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="description" onChange={this.filterList} />
                                        </div>
                                    </th> */}
                                    <th className="salesMan productMinWidth">Pieces Per Pack
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="piecesPerPack" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="salesMan productMinWidth">Packs Per Box
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="packsPerBox" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="salesMan productMinWidth">Boxes per Case
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="boxesPerCase" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Status_Route">Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="status" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container"></th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {/* <td className="checkBox_Container">                                                    
                                    </td>                                                
                                    <td>2589</td>
                                    <td>hkis52645</td>
                                    <td>KL5685</td>
                                    <td>ArunIcecream</td>
                                    <td>IceCream</td>
                                    <td className="Status_Route">Y</td>
                                    <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}} onClick={ ()=> this.newProduct_openHandler()}/>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}}/>
                                    </td> */}
                                    {
                                        !this.state.pmProductList || this.state.pmProductList === null || this.state.pmProductList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="5">No Product Available</td>
                                            </tr> :
                                            this.state.pmProductList.map((product) => (
                                                <tr>
                                                    <td className="Ref_Num productMinWidth" data-tip={product.productKey}>{product.productKey}</td>
                                                    <td className="Descript_width prodNamewid" data-tip={product.productName}>{product.productName}</td>
                                                    <td className="Ref_Num" data-tip={product.productShades}>{product.productShades}</td>
                                                    <td className="salesMan productMinWidth" data-tip={product.productTypeName}>{product.productTypeName}</td>
                                                    <td className="salesMan productMinWidth" data-tip={product.productGroupName}>{product.productGroupName}</td>
                                                    <td className="salesMan productMinWidth" data-tip={product.piecesPerPack}>{product.piecesPerPack}</td>
                                                    <td className="salesMan productMinWidth" data-tip={product.packsPerBox}>{product.packsPerBox}</td>
                                                    <td className="salesMan productMinWidth" data-tip={product.boxesPerCase}>{product.boxesPerCase}</td>
                                                    {/* <td>{product.companyCode}</td> */}
                                                    {/* <td className="Ref_Num" data-tip={product.brandCode}>{product.brandCode}</td> */}
                                                    {/* <td className="Descript_width prodNamewid" data-tip={product.description}>{product.description}</td> */}
                                                    <td className="Status_Route" data-tip={product.status}>{product.status}</td>
                                                    <td className="o2Del_container">
                                                        <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.editPM_openHandler(product)} />
                                                        {
                                                            this.state.productWriteAccess ?
                                                                <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 8px' }} onClick={() => this.deletePM_Handler(product)} />
                                                                : null
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                    }
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.pmProductList ? this.state.pmProductList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Export</div> */}
                        {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="product_Table" filename={"Product"}
                            sheet="tablexls" buttonText="Excel Export" /></div> */}
                        <div className="O2Table_ExpExcel" onClick={() => this.productExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                    </div>
                </div>
                <div className="O2TableEdit_wrapper ProductTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Product</div>
                            <div className="RM_BodyContent">
                                <div className="legentContent_div">
                                    <div className="legendBody_Label mandatoryClass">Product Code <span>*</span></div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='productKey' value={this.state.productKey} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label mandatoryClass">Product Name <span>*</span></div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pmProductName' value={this.state.pmProductName} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label mandatoryClass">Product Short Name <span>*</span></div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pmProductShortName' value={this.state.pmProductShortName} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Product Shade  </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='productShades' value={this.state.productShades} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label mandatoryClass">Group Name <span>*</span></div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.productGroupList} name="productGroup" value={this.state.productGroupList.filter(({ value }) => value === this.state.productGroupid)} placeholder="Choose Product Group" onChange={(e) => this.handleproductGroupChange(e)} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label mandatoryClass">Type Name <span>*</span></div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={this.state.productTypeList} name="productType" value={this.state.productTypeList.filter(({ value }) => value === this.state.productTypeCode)} placeholder="Choose Product Type" onChange={(e) => this.handleproductTypeChange(e)} />
                                    </div>
                                </div>
                                {/* <div className="legentContent_div">
                                    <div className="legendBody_Label">Company Code  </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pmCompanyCode' value={this.state.pmCompanyCode} onChange={this.pmChange} />
                                    </div>
                                </div> */}
                                {/* <div className="legentContent_div">
                                    <div className="legendBody_Label">Brand Code  </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pmBrandCode' value={this.state.pmBrandCode} onChange={this.pmChange} />
                                    </div>
                                </div> */}
                                <div className="legentContent_div">
                                    <div className="legendBody_Label mandatoryClass">Description <span>*</span></div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pmDescription' value={this.state.pmDescription} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Tax Code </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pmTaxCode' value={this.state.pmTaxCode} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label mandatoryClass">UOM type <span>*</span></div><span>:</span>
                                    <div className="legendBody_Value">
                                        <Select options={uomSearch} name="pmUOMType" value={uomSearch.filter(({ value }) => value === this.state.pmUOMType)} placeholder="Choose UOM Type" onChange={this.handlePMUOMChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Product Weight</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pmWeight' value={this.state.pmWeight} onChange={this.pmQtyChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Product Image </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pmImageURL' value={this.state.pmImageURL} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">ProductOrder Qty </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pmOrderQty' value={this.state.pmOrderQty} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Pieces Per Pack</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pmPiecesPerPack' value={this.state.pmPiecesPerPack} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Packs Per Box</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pmPacksPerBox' value={this.state.pmPacksPerBox} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Boxes Per Case</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pmBoxesPerCase' value={this.state.pmBoxesPerCase} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label mandatoryClass">Scheme Product <span>*</span></div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select value={this.state.pmSchemeProduct} onChange={this.pmSchemeChange}>
                                            <option value="N" >No</option>
                                            <option value="Y" >Yes</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    {/* <div className="legendBody_Label">Classification </div><span>:</span> */}
                                    <div className="legendBody_Label mandatoryClass">Category <span>*</span></div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='productClassification' value={this.state.productClassification} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label mandatoryClass">Sub-Category <span>*</span></div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='productSubCategory' value={this.state.productSubCategory} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label mandatoryClass">Brand <span>*</span></div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='productBrand' value={this.state.productBrand} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Size</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='productSize' value={this.state.productSize} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    {/* <div className="legendBody_Label">Specification </div><span>:</span> */}
                                    <div className="legendBody_Label">Finish</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='productSpecification' value={this.state.productSpecification} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Shape</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='productShape' value={this.state.productShape} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Shade Number </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='shadeNumber' value={this.state.shadeNumber} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">EAN </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='eanNo' value={this.state.eanNo} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">HSN </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='hsnNo' value={this.state.hsnNo} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Old ProductCode </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='oldProductCode' value={this.state.oldProductCode} onChange={this.pmChange} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label mandatoryClass">Team Tag  <span>*</span></div><span>:</span>
                                    <div className="legendBody_Value">
                                        {/* <Select options={this.state.umUserTeamList} name="umUserTeam" value={this.state.umUserTeamList.filter(({ label }) => label === this.state.umUserTeam)} placeholder="Please Choose Team Tag" onChange={(e) => this.handleUmUserTeamChange(e)} /> */}
                                        <Select isMulti options={this.state.umUserTeamList} name="umUserTeam" value={this.state.eUserTeamList} placeholder="Choose Roles" onChange={(e) => this.handleMultiTeamChange(e)} />
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Status </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select value={this.state.pmStatus} onChange={this.pmStatusChange}>
                                            <option value="Y" >Active</option>
                                            <option value="N" >In Active</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    {
                                        this.state.productWriteAccess ?
                                            <button onClick={this.newProduct_popSaveHandler}>Save</button>
                                            : null
                                    }
                                    <button onClick={this.newProduct_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(ProductModule)
//export default ProductModule
