
const initialState={
    userObj:'',
    //  httpURL:"http://localhost:8080/o2webng",
    // httpURL:"http://localhost:8081/o2webng",
    // httpURL:"http://localhost:8080/o2webng_preprod",
  //  httpURL:"http://139.59.8.226:8080/o2webng_preprod",
//    httpURL:"http://139.59.8.226:8080/o2webng",
   httpURL:"http://139.59.72.69:8888/o2webng",
   httpPostalURL:"https://api.postalpincode.in/pincode/",
   isUserLogged:false,
   appPrefrenece:'',
}

const userReducer= (state=initialState,action)=>{
  //  console.log("action.type---->"+action.type)
    switch(action.type){
        case "USER_CREDENTIALS":
            return {
                ...state,
                userObj:action.userObj,
                appPrefrenece:action.appPrefrenece,
                isUserLogged:true
            }
        case "userLoggedOut":
            return {
                ...state,
                userObj:"",
                appPrefrenece:"",
                isUserLogged:false
            }
        default: return state
    }
}

export default userReducer;