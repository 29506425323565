import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

class ProductModuleView extends Component {   

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0,10);
    
        this.state = {
            Distributorbtn: "Popup_tabButtons",
            DistServbtn: "Popup_tabButtons",
            pmProductList: [],
            pmOrgProductList: [],
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,  
            currentDate:todaydate,      
        }
    
    }
    
    componentDidMount() {
        this.getUMUserList();       
    }
    getUMUserList() {
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/webmaster/getProductList")
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        pmProductList : data.productList,
                        pmOrgProductList : data.productList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    filterList = (event) => {
        let filtered =this.state.pmOrgProductList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
            }
        }
        this.setState({
            pmProductList: filtered
        })
    }

     render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>PRODUCT</span></div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="product_Table">
                            <thead>
                                <tr>
                                <th>Product No
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="m_product_id" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Product Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="name" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Description
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="description" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Active
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="isactive" onChange={this.filterList}/>
                                        </div>
                                    </th>                                    
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>                                             
                                    {/* <td>2589</td>
                                    <td>Y</td>
                                    <td>2589</td>
                                    <td>Y</td>
                                    <td>Y</td>
                                    <td>2589</td>
                                    <td>Y</td>
                                    <td>2589</td>
                                    <td>Y</td>
                                    <td>Y</td> */}
                                    {
                                   !this.state.pmProductList || this.state.pmProductList === null || this.state.pmProductList.length === 0 ?
                                   <tr align="center">
                                       <td colSpan="5">No Product Available</td>
                                   </tr> :
                                   this.state.pmProductList.map((pl,i) => (
                                       <tr key={pl.id}>                                                                                              
                                           <td data-tip={pl.m_product_id}>{pl.m_product_id}</td>
                                           <td data-tip={pl.name}>{pl.name}</td>
                                           <td data-tip={pl.description}>{pl.description}</td>                                                                                                                                                                            
                                           <td data-tip={pl.isactive}>{pl.isactive}</td>                                                     
                                       </tr>
                                   ))
                               }
                                </tr> 
                               
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.pmProductList ? this.state.pmProductList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    {/* <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /></div>
                    </div> */}
                    <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{padding:'0px 8px'}}/><ReactHTMLTableToExcel className="download-table-xls-button" table="product_Table" filename={"Product_"+this.state.currentDate}
                        sheet="tablexls" buttonText="Excel Export"/></div>
                    {/* <div className="O2Table_Footer">
                        <button>Close</button>
                    </div> */}
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />   
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL        
    }
}
export default connect(mapStateToProps)(ProductModuleView)
// export default ProductModuleView



