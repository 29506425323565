import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import './RoleAccessModule.css'
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class RoleAccessModule extends Component {

   

    newRoleAccess_popCloseHandler = () => {
        document.querySelector('.RoleAccessTableEdit_pop').style.display = 'none';
    }
    newRoleAccess_openHandler = () => {
        document.querySelector('.RoleAccessTableEdit_pop').style.display = 'flex';
    }
  
    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>ROLE ACCESS</span></div>
                    <div className="O2Table_SubContainer roleAccessSub">
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Role</div>
                            <div className="contentRow_Value">
                                <select></select>
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label"></div>
                            <div className="contentRow_Value">
                               
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label"></div>
                            <div className="contentRow_Value">
                                
                            </div>
                        </div>
                        <div className="O2Table_New">
                            <button onClick={this.newRoleAccess_openHandler}>New Role</button>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable">
                            <thead>
                                <tr>
                                    <th className="checkBox_Container">
                                        <input type="checkbox" />
                                    </th> 
                                    <th>Menu Name</th>
                                    <th>SubMenu Name</th>
                                    {/* <th className="o2Del_container"></th> */}
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr> 
                                    <td className="checkBox_Container">
                                        <input type="checkbox" />
                                    </td>                                              
                                    <td>Order</td>
                                    <td>Sub Order</td>
                                    {/* <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}} onClick={ ()=> this.newRoleAccess_openHandler()}/>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}}/>
                                    </td> */}
                                </tr> 
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> 0</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="productGroup_Table" filename={"Product Group"}
                            sheet="tablexls" buttonText="Excel Export" /></div> */}
                    </div>
                    <div class="O2Table_Footer">
                        <button>Save</button>
                        <button>Cancel</button>
                    </div>
                </div>
                <div className="O2TableEdit_wrapper RoleAccessTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">New Role</div>
                            <div className="RM_BodyContent">
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Role Name </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='' />
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    <button>Save</button>
                                    <button onClick={this.newRoleAccess_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            {/* <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            /> */}
                        </div>
                    </div>
                </div>
                {/* <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                /> */}
            </div>
        )
    }
}
export default RoleAccessModule

