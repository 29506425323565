import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from 'react-select';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';



const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

const override1 = css`
  display: block;
  top: -400px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class RetailerReport extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            retailersList: [],
            orgretailersList: [],
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            userType: this.props.userObj.userType,
            loginSalesmanCode: this.props.userObj.salesmanCode,
            ncFStateList: [],
            ncFCityList: [],
            ncFState: '',
            ncFCity: '',
            searchReatailerCode:'',
            searchReatailerName:'',
            currentDate: todaydate,
        }
    }

    componentDidMount() {
       //this.getAllRetailers();
       this.getStateList();
    }

    
    getStateList() {

        const params = {
            companyCode: this.props.userObj.companyCode,
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/getStateList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        ncFStateList: data.rStateList,
                        retailerTypeList: data.retailerTypeList,
                        erpList: data.erpCodeList,
                        erpOrgList: data.erpCodeList,
                        // routeList: data.routeList,
                        ncStateList: data.indianStateList,
                        popupDistributorList: data.popupDistributorList,
                        productTypeList: data.productTypeList,
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getCityList() {

        const params = {
            companyCode: this.props.userObj.companyCode,
            state: this.state.ncFState
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/getCityList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        ncFCityList: data.rCityList,
                       
                    }, () => {
                        // if(this.state.ncFCity !== "" && this.state.ncFCity !== null && this.state.ncFCity !== undefined){               
                        //     this.getRouteList();                            
                        // }
                        this.setState({
                            ncFCity: data.rCityList[0].value,
                           
                        })
                    });
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getAllRetailers() {

     

        const params = {
            companyCode: this.state.companyCode,
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/getAllRetailers", { params })
            .then(response => response.data)
            .then((data) => {
                // console.log(data)
                this.setState({
                    loading: false
                })
                this.setState({
                    retailersList: data.retailerList,
                    orgretailersList:data.retailerList,
                }, () => {

                })
            }).catch(err => {
                this.setState({
                    loading: false
                })
                alert(err);
            })
    }

    handleNCFStateChange = (event) => {
        this.setState({
            ncFState: event.value,
            ncFCity: '',
            ncFCityList: [],
        }, () => {
            if (this.state.ncFState !== "" && this.state.ncFState !== null && this.state.ncFState !== undefined) {
                this.getCityList();
             
            }
                                           
        })
    }

    handleNCFCityChange = (event) => {
        this.setState({
            ncFCity: event.value,
        }, () => {
                                            
        })
    }

    getNCFRetailer = () => {
        // console.log("city =>"+this.state.ncFCity)
       //  console.log("ncFRoute =>"+this.state.ncFRoute)
         if (this.state.ncFState === "" || this.state.ncFState === null || this.state.ncFState === undefined) {
             alert("Please Select State");
         }else if (this.state.ncFCity === "" || this.state.ncFCity === null || this.state.ncFCity === undefined ) {
             alert("Please Select City");
         }else {
             this.getRetailerList();
         }
     }


     getRetailerList() {

        const params = {
            companyCode: this.state.companyCode,
            state: this.state.ncFState,
            city: this.state.ncFCity,
            retailerCode: this.state.searchReatailerCode,
            retailerName: this.state.searchReatailerName
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/getNewRetailerReportList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        retailerList: data.retailerList,
                        orgretailersList:data.retailerList,
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    
    setSearchDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    filterList = (event) => {
      
        let filtered = this.state.orgretailersList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }

        this.setState({
            retailerList: filtered
        })

    }

    dsrExcel = () => {
        var dFlag = true;
        if (this.state.orgretailersList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.orgretailersList;
            var fileName = "Retailer Report_" + this.state.currentDate;
            var wscols = [
                { wch: 16 },
                { wch: 35 },
                { wch: 10 },
                { wch: 35 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 35 },
                { wch: 20 },
                { wch: 20 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Retailer Code": dList[i].retailerCode,
                    "Retailer": dList[i].name,
                    "State": dList[i].state,
                    "City": dList[i].city,
                    "Product Type": dList[i].productType,
                    "Distributor Category": dList[i].distributorCategory,
                    "Distributor Code": dList[i].distributorCode,
                    "Distributor Name": dList[i].distributorName,
                    
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'RetailerReport': ws }, SheetNames: ['RetailerReport'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

  render() {
    return (
      <div className="O2Container_Wrapper">
            <ReactTooltip />
            <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>Retailer Report</span></div>
                    <div className="O2Table_Body">
                        <div className="O2Table_SubContainer Report_subContainer">
                            <div className="O2Content_row">
                                <div className="contentRow_Label mandatoryClass">State <span>*</span></div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.ncFStateList} name="ncFState" value={this.state.ncFStateList.filter(({ value }) => value === this.state.ncFState)} placeholder="Choose State" onChange={this.handleNCFStateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label mandatoryClass">City <span>*</span></div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.ncFCityList} name="ncFCity" value={this.state.ncFCityList.filter(({ value }) => value === this.state.ncFCity)} placeholder="Choose City" onChange={this.handleNCFCityChange} />
                                </div>
                            </div>
                          


                        </div>
                        <div className="O2Table_SubContainer Report_subContainer">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Retailer Code </div>
                                <div className="contentRow_Value">
                                    <input type="text" name="searchReatailerCode" onChange={this.setSearchDetails}></input>
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Retailer Name </div>
                                <div className="contentRow_Value">
                                    <input type="text" name="searchReatailerName" onChange={this.setSearchDetails}></input>
                                </div>
                            </div>
                            <div className="O2Table_Go"><button onClick={this.getNCFRetailer}>Go</button></div>
                        </div>

                        <div className="Table_wrapper">
                            <table className="customTable" id="salesmanDSRReport_Table">
                                <thead>
                                    <tr>
                                        <th className="EmpId_width">Retailer Code
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerCode" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Salesman_width">Name
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="name" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="activity">State
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="state" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Time_width">City
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="city" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Time_width">Product Type
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="productType" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Time_width">Distributor Category
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="distributorCategory" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Time_width">Distributor Code
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="distributorCode" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="comments_width">Distributor Name
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="distributorName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.retailerList || this.state.retailerList === null || this.state.retailerList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="6">No Records</td>
                                            </tr> :
                                            this.state.retailerList.map((report) => (
                                                <tr>
                                                    <td className="EmpId_width" data-tip={report.retailerCode}>{report.retailerCode}</td>
                                                    <td className="Salesman_width" data-tip={report.name}>{report.name}</td>
                                                    <td className="activity" data-tip={report.state}>{report.state}</td>
                                                    <td className="Time_width" data-tip={report.city}>{report.city}</td>
                                                    <td className="Time_width" data-tip={report.productType}>{report.productType}</td>
                                                    <td className="Time_width" data-tip={report.distributorCategory}>{report.distributorCategory}</td>
                                                    <td className="Time_width" data-tip={report.distributorCode}>{report.distributorCode}</td>
                                                    <td className="comments_width" data-tip={report.distributorName}>{report.distributorName}</td>
                                                </tr>
                                            ))
                                    }

                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.retailerList ? this.state.retailerList.length : 0}</span>entries</span></div>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_ExpExcel" onClick={() => this.dsrExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
      </div>
    )
  }
}




const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}

export default connect(mapStateToProps)(RetailerReport)

