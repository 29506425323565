import React, { Component, useState } from 'react'
import Select from 'react-select';
import './SalesmanRoute.css';
import axios from 'axios'
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
class SalesmanRoute extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            smrSalesmanList: [],
            smrSelectedSalesmanList: [],
            smrCustomerList: [],
            smrRouteList: [],
            smrSalesManRouteList: [],
            smrDaysList: [],
            smrDaysOrgList: [],
            smrtempRouteList: [],
            smrStateList: [],
            smrCityList: [],
            smrCustomerValue: '',
            smrSalesmanValue: '',
            smrDistributorValue: '',
            smrCustomerCode: '',
            smrCustomerCity: '',
            smrCustomerState: '',
            smrCustomerCountry: '',
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            salesmanRouteWriteAccess:this.props.userObj.salesmanRouteWriteAccess
        }

    }

    componentDidMount() {
        //  this.getSalesManList();

        this.getSalesmanRouteDetails()
    }

    getSalesmanRouteDetails() {
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/salesmanroute/getSalesmanRouteDetail")
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        smrSalesmanList: data.salesmanList1,
                        smrRouteList: data.routeList1,
                        //smrDaysList: data.routeDaysList,
                        smrDaysOrgList: data.routeDaysList,
                         smrStateList: data.stateList,
                         smrCityList: data.cityList
                    })                    
                    var dList = data.routeDaysList;
                    dList.map(function (nc, i) {                        
                        nc.stateList = data.stateList
                        nc.cityList = data.cityList
                        nc.routeList = data.routeList1
                    });
                    this.setState({smrDaysList:dList},()=>{
                    }); 
                   // console.log(this.state.smrDaysList);                   
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                // console.log(err)
                alert(err);
                this.setState({
                    loading: false
                })
            })
    }

    getSalesManDataList() {
        if(this.state.smrSelectedSalesmanList.value !== undefined){
            let params = {
                salesmanCode: this.state.smrSelectedSalesmanList.value
            }
            this.setState({
                loading:true
            })
            axios.get(this.state.httpURL + "/salesmanroute/getSalesmanDetail", { params })
                .then(response => response.data)
                .then((data) => {
                    this.setState({
                        loading:false
                    })
                    if (data.valid) {
                        // this.setState({
                        // })    
                        this.setState({
                         //   smrDaysList: data.routeDaysList,
                            smrDaysOrgList: data.routeDaysList                       
                        })
                        var dList = data.routeDaysList;
                        var state = this.state.smrStateList;
                        var city = this.state.smrCityList;
                        var route = this.state.smrRouteList;
                        dList.map(function (nc, i) {                        
                            nc.stateList = state
                            nc.cityList = city
                            nc.routeList = route
                            if(nc.salesmanRouteList.length !== 0){
                                nc.isSelectCityDisabled = false
                                nc.isSelecRoutetDisabled = false
                            }                        
                            nc.routeList = nc.cityRouteList;
                            nc.cityList = nc.stateCityList;
                        });
                        this.setState({smrDaysList:dList},()=>{
                        }); 
                       // console.log(this.state.smrDaysList);
                    } else {
                        alert(data.responseMsg);
                    }                
                }).catch(err => {
                    //console.log(err)
                    alert(err);
                    this.setState({
                        loading:false
                    })
                })
        }        
    }


    getSRRoute(ind,event,sc) {
        var stateList = [];
        var cityList = [];
        var routeList1 = [];
        let params = {stateList,cityList,routeList1};

        if(sc === "state"){
            params = {
                stateList: event
            }
        } else if(sc === "city"){
            params = {
                cityList: event
            }
        } 
        // else if(sc === "route"){
        //     params = {
        //         routeList1: event
        //     }
        // }
 
        if(event.length !== 0){
            this.setState({
                loading:true
            })
            axios.post(this.state.httpURL + "/salesmanroute/getSRRoute", params)
                .then(response => response.data)
                .then((data) => {
                    this.setState({
                        loading:false
                    })
                    if (data.valid) {
                        if(sc === "state"){
                            var dList = this.state.smrDaysList                        
                            var city = data.cityList;
                            var route = data.routeList1;
                            dList.map(function (nc, i) {
                                if(i === ind){
                                    nc.cityList = city
                                    nc.routeList = route
                                    nc.isSelectCityDisabled = false
                                }                                                                            
                            });
                            this.setState({smrDaysList:dList},()=>{

                            });
                        } else if(sc === "city"){
                            var dList = this.state.smrDaysList;
                            var state = data.stateList;
                            var route = data.routeList1;
                            dList.map(function (nc, i) {  
                                if(i === ind){
                                    //nc.estateList = state
                                    nc.routeList = route
                                    nc.isSelecRoutetDisabled = false
                                }                                                                          
                            });
                            this.setState({smrDaysList:dList},()=>{
                            });
                        }
                        //  else if(sc === "route"){
                        //     var dList = this.state.smrDaysList;
                        //     var state = data.stateList;
                        //     var city = data.cityList;
                        //     dList.map(function (nc, i) {  
                        //         if(i === ind){
                        //            // nc.estateList = state
                        //            // nc.ecityList = city
                        //         }                                                                          
                        //     });
                        //     this.setState({smrDaysList:dList},()=>{
                        //     });
                        // }  
                         
                    } else {
                        alert(data.responseMsg);
                    }                
                }).catch(err => {
                    //console.log(err)
                    alert(err);
                    this.setState({
                        loading:false
                    })
                })
        } else {           
            if(sc === "state"){
                var dList = this.state.smrDaysList;
                dList.map(function (nc, i) {  
                    if(i === ind){
                        nc.ecityList = [] 
                        nc.salesmanRouteList = []
                        nc.isSelectCityDisabled = true
                        nc.isSelecRoutetDisabled = true                      
                    }                                                                          
                });
                this.setState({smrDaysList:dList},()=>{
                });
            } else if(sc === "city"){                
                var dList = this.state.smrDaysList;
                dList.map(function (nc, i) {  
                    if(i === ind){
                        //nc.estateList = []
                        nc.salesmanRouteList = []
                        nc.isSelecRoutetDisabled = true                         
                    }                                                                          
                });
                this.setState({smrDaysList:dList},()=>{
                });
            }
        }              
    }

    handleSMRSalesManChange = (event) => {
        this.setState({
            smrSelectedSalesmanList: event
        }, () => {
            // console.log('--'+JSON.stringify(this.state.smrSelectedSalesmanList.value))
            this.getSalesManDataList()
        });
    }




    handleRouteChange = (ind, event) => {

        const { smrDaysList, smrtempRouteList } = this.state;
        this.setState({
            smrtempRouteList: event
        }, () => {
            smrDaysList[ind].salesmanRouteList = this.state.smrtempRouteList
            //  console.log('before smrDaysList-->'+JSON.stringify(smrDaysList))
            this.setState({
                smrDaysList: smrDaysList,
                smrDaysOrgList: smrDaysList
            }, () => {
                // console.log('after smrDaysList-->'+JSON.stringify(this.state.smrDaysList))
                //console.log('after smrDaysOrgList-->'+JSON.stringify(this.state.smrDaysOrgList))
                // var sc = "route";
                // this.getSRRoute(ind,event,sc);
            })
        });

    }

    handleStateChange = (ind, event) => {

        const { smrDaysList, smrtempStateList } = this.state;
        this.setState({
            smrtempStateList: event
        }, () => {
            smrDaysList[ind].estateList = this.state.smrtempStateList            
            this.setState({
                smrDaysList: smrDaysList,
                smrDaysOrgList: smrDaysList
            }, () => {
                var sc = "state";
                this.getSRRoute(ind,event,sc);
            })
        });

    }

    handleCityChange = (ind, event) => {

        const { smrDaysList, smrtempCityList } = this.state;
        this.setState({
            smrtempCityList: event
        }, () => {
            smrDaysList[ind].ecityList = this.state.smrtempCityList            
            this.setState({
                smrDaysList: smrDaysList,
                smrDaysOrgList: smrDaysList
            }, () => {
                var sc = "city";
                this.getSRRoute(ind,event,sc);
            })
        });

    }


    saveSMRDetail = (e) => {

        // console.log("--<"+this.state.smrSelectedSalesmanList)

        if (this.state.smrSelectedSalesmanList.length == 0) {
            alert("Select SalesMan");
            return false;
        }

        let params = {
            "routeDaysList": this.state.smrDaysList,
            "salesmanCode": this.state.smrSelectedSalesmanList.value,
            "updatedBy": this.state.loginuserObj.userName
        }
        // console.log("params-->"+JSON.stringify(params))
        this.setState({
            loading:true
        })
        axios.post(this.state.httpURL + "/salesmanroute/", params)
            .then(response => {
                if (response.data !== null) {
                    this.setState({
                        loading:false
                    })
                    if (response.data.valid) {
                        alert("Salesman Route Saved Successfully ...");
                        // this.cancelSMRDetail();
                    } else {
                        alert(response.data.responseMsg);
                    }                    
                }
            })
            .catch(error => {
               // console.log("error" + error)
               alert(error);
               this.setState({
                loading:false
            })
            })

    }


    filterList = (event) => {
        // alert(event.target.value);
        var tlist = this.state.smrDaysOrgList;
        // console.log('tlist---'+JSON.stringify(tlist))
        var searchString = event.target.value;
        const filtered = tlist.filter(od => (
            od.dayName.toLowerCase().search(searchString.toLowerCase()) !== -1
        ));
        // console.log('filtered---'+JSON.stringify(filtered))
        this.setState({
            smrDaysList: filtered
        })

    }


    render() {


        return (

            <div className="O2Container_Wrapper">
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>ROUTE COVERAGE</span></div>
                    <div className="O2Container_legendDiv">
                        <div className="legendTitle">Route Coverage Creation</div>
                        <div className="legendBodyContent">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Sales Person</div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.smrSalesmanList} name="smrSalesmanValue" value={this.state.smrSelectedSalesmanList} placeholder="Choose Sales Person" onChange={this.handleSMRSalesManChange} />
                                </div>
                            </div>
                            {/* <div className="O2Content_row">
                                <div className="contentRow_Label">State</div>
                                <div className="contentRow_Value">
                                    <Select placeholder="Choose State" />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">City</div>
                                <div className="contentRow_Value">
                                    <Select placeholder="Choose City" />
                                </div>
                            </div> */}
                            <div className="O2Content_row">
                                <div className="contentRow_Label"> </div>
                                <div className="contentRow_Value">
                                    {/* <input type="text" onChange={this.filterList} /> */}
                                </div>
                            </div> 

                            {/* <div className="O2Table_SelectSales">
                                    <div className="O2Table_Label">SalesmanCode</div>
                                    <div className="O2Table_Value">
                                        <Select options={this.state.smrSalesmanList} name="smrSalesmanValue" value={this.state.smrSelectedSalesmanList} placeholder="Choose Salesman Code" onChange={this.handleSMRSalesManChange}/>                                    
                                    </div>
                                </div>
                               
                                <div className="O2Table_Search">
                                    <div className="O2Table_Label"> Search  </div>
                                    <div className="O2Table_Value"><input type="text" onChange={this.filterList} /></div>
                                </div> */}


                        </div>
                        <div className="O2Content_Container">
                            {/* <div className="O2Content_leftContainer">
                                <div className="O2Container_legendDiv">
                                    <div className="legendTitle">Customer :
                                        <div className="O2Table_Value">
                                            <Select options={customerSearch} name="smrCustomerValue"  value={customerSearch.filter(({value}) => value === this.state.smrCustomerValue)} placeholder="Choose Customer Code" onChange={this.handleSMRCustomerChange}/>                                            
                                        </div>
                                    </div>
                                    <div className="O2legend_datacontainer">
                                        <div className="legentContent_div">
                                            <div className="legendBody_Label">Code </div><span>:</span>
                                            <div className="legendBody_Value">
                                                {this.state.smrCustomerCode}
                                            </div>
                                        </div>
                                        <div className="legentContent_div">
                                            <div className="legendBody_Label">City </div><span>:</span>
                                            <div className="legendBody_Value">
                                                {this.state.smrCustomerCity}
                                            </div>
                                        </div>
                                        <div className="legentContent_div">
                                            <div className="legendBody_Label">State </div><span>:</span>
                                            <div className="legendBody_Value">
                                                {this.state.smrCustomerState}
                                            </div>
                                        </div>
                                        <div className="legentContent_div">
                                            <div className="legendBody_Label">Country </div><span>:</span>
                                            <div className="legendBody_Value">
                                                {this.state.smrCustomerCountry}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="O2Content_rightContainer">
                                <div className="Table_wrapper">
                                    <table className="customTable salesmanRoute_table" >
                                        <thead>
                                            <tr>
                                                <th className="DaysWid">Days</th>
                                                <th className="StateWid">State</th>
                                                <th className="CityWid">City</th>
                                                <th className="RouteNamWid">Route Name</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.smrDaysList.map((smd, i) => (
                                                    <tr key={smd.dayId}>
                                                        <td className="DaysWid">{smd.dayName}</td>
                                                        <td className="StateWid">
                                                            {/* <Select placeholder="Choose State" /> */}
                                                            <Select isMulti options={smd.stateList} name="smrMondayValue" value={smd.estateList} placeholder="Choose State" onChange={(e) => this.handleStateChange(i, e)} />
                                                        </td>
                                                        <td className="CityWid">
                                                            {/* <Select placeholder="Choose City" /> */}
                                                            <Select isMulti options={smd.cityList} name="smrMondayValue" value={smd.ecityList} placeholder="Choose City" isDisabled={smd.isSelectCityDisabled} onChange={(e) => this.handleCityChange(i, e)} />
                                                        </td>
                                                        <td className="RouteNamWid">
                                                            <Select isMulti options={smd.routeList} name="smrMondayValue" value={smd.salesmanRouteList} placeholder="Choose Route" isDisabled={smd.isSelecRoutetDisabled} onChange={(e) => this.handleRouteChange(i, e)} />
                                                        </td>
                                                       
                                                    </tr>
                                                ))
                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="O2Table_Footer">
                        {
                            this.state.salesmanRouteWriteAccess ?
                                <button onClick={this.saveSMRDetail}>Save</button>
                            : null
                        }                        
                        {/* <button onClick={this.cancelSMRDetail}>Cancel</button> */}
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}


export default connect(mapStateToProps)(SalesmanRoute)


//export default SalesmanRoute

