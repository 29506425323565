import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from 'react-select';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

class ErpUploadedOrder extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0,10);
    
        this.state = {
            Distributorbtn: "Popup_tabButtons",
            DistServbtn: "Popup_tabButtons",
            euoOrderList: [],
            euoOrgOrderList: [],
            euoSalesmanList: [],
            euoSelectedSalesmanList: [],
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            currentDate:todaydate,
            salesmanCode:this.props.userObj.salesmanCode,
            userType:this.props.userObj.userType,
            euoSalesmanCode: this.props.userObj.salesmanCode,   
            euoSalesmanCodeList: [],     
            loginSalesmanCode:this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
        }
    
    }
    
    componentDidMount() {
        this.getEUOOrderList();       
    }
    getEUOOrderList() {
        const params={                    
            salesmanCode:this.state.salesmanCode,
            companyCode:this.state.companyCode,
            loginSalesmanCode:this.state.loginSalesmanCode,
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/report/getEUOOrderList",{params})
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        euoOrderList : data.erpUploadedList,
                        euoOrgOrderList : data.erpUploadedList,
                        euoSalesmanList: data.salesmansList
                    },()=>{

                        // if(data.salesmansList !== null){
                        //     var temparry=[]
                        //     temparry.push({
                        //         label: data.salesmansList[0].label,
                        //         value: data.salesmansList[0].value});

                        //         this.setState({
                        //             euoSelectedSalesmanList:temparry
                        //         })
                        // }

                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    filterList = (event) => {
        let filtered =this.state.euoOrgOrderList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
                
            }
        }
        this.setState({
            euoOrderList: filtered
        })
    }

    handleEUOSalesmanChange = (event) => {        
        this.setState({
                    euoSelectedSalesmanList: event,
                    euoSalesmanCode:event.value
                },()=>{                    
                    this.getEUOSalesmanwiseOrderList(event);            
                });
    }

    getEUOSalesmanwiseOrderList(event) {
       // console.log("<--1-->"+JSON.stringify(event));
        
        // var salesmansList = [];
        // salesmansList.push({
        //             label: event.label,
        //             value: event.value});

        var salesmanCode = "";
        if(this.state.euoSalesmanCode !== "" && this.state.euoSalesmanCode !== undefined && this.state.euoSalesmanCode !== null){
            salesmanCode = this.state.euoSalesmanCode;            
        } else {
            salesmanCode = this.state.salesmanCode;            
        }

        let params = {salesmanCode:salesmanCode,loginSalesmanCode:this.state.loginSalesmanCode,companyCode:this.state.companyCode};

        // params = {
        //     salesmansList: event
        // }

        this.setState({
            loading: true
        });
        axios.post(this.state.httpURL + "/report/getEUOSalesmanwiseOrderList", params)       
        .then(response => response.data)
        .then((data) => {
            if (data.valid) {                    
                this.setState({
                    euoOrderList : data.erpUploadedList,
                    euoOrgOrderList : data.erpUploadedList                    
                },()=>{

                

                })
                ReactTooltip.rebuild();
            } else {
                alert(data.responseMsg);
            }
            this.setState({
                loading: false
            });
        }).catch(err => {
            //console.log(err)
            this.setState({
                loading: false
            });
            alert(err);
        })

    }

    erpUploadedExcel = () =>{
        var dFlag = true;
        if(this.state.euoOrderList.length === 0){
            alert("No Records");
            dFlag = false;
        } 
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.euoOrderList;
            var fileName = "Erp Uploaded_"+this.state.currentDate;
            var wscols = [
                {wch:10},
                {wch:35},
                {wch:60},
                {wch:10},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
            ];
            for(var i=0;i<dList.length;i++){
                arr = {};
                arr = {
                    "Order No": dList[i].refNum,                    
                    "Retailer Name": dList[i].retailerName,
                    "Product": dList[i].productName,
                    "Date": dList[i].date, 
                    "ERP Document No": dList[i].documentNo,
                    "Qty": dList[i].qty.toFixed(2),
                    "Rate": dList[i].rate.toFixed(2),
                    "Value": dList[i].value.toFixed(2),
                    "Order Type": dList[i].orderType,                                          
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'ERP': ws }, SheetNames: ['ERP'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            fileSaver.saveAs(data, fileName + fileExtension);
        }        
    }

     render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>ERP UPLOADED ORDER</span></div>
                    {
                        this.state.userType !== "salesman" ? 
                                <div className="O2Table_SubContainer Report_subContainer">
                                <div className="O2Content_row">
                                    <div className="contentRow_Label">Sales Person</div>
                                    <div className="contentRow_Value">
                                    <Select options={this.state.euoSalesmanList} name="euoSalesmanCode" placeholder="Choose Sales Person" value={this.state.euoSalesmanList.filter(({ value }) => value === this.state.euoSalesmanCode)} onChange={this.handleEUOSalesmanChange}/> 
                                    </div>
                                </div>
                                <div className="O2Content_row">
                                    {/* <div className="contentRow_Label">Order Type</div>
                                    <div className="contentRow_Value">
                                        <select></select>
                                    </div> */}
                                </div> 
                                
                                {/* <div className="O2Table_Go"><button>Go</button></div> */}
                            </div>
                        : null
                    }                    
                    <div className="Table_wrapper">
                        <table className="customTable" id="erpUploadedOrder_Table">
                            <thead>
                                <tr>
                                     <th>Order No
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="refNum" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Retailer Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerName" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Product
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productName" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Date
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="date" onChange={this.filterList}/>
                                        </div>
                                    </th> 
                                    <th>ERP Document No
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="erpOrderId" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    {/* <th>ERP Order No
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="documentNo" onChange={this.filterList}/>
                                        </div>
                                    </th>   */}
                                    {/* <th>Item Description
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="description" onChange={this.filterList}/>
                                        </div>
                                    </th> */}
                                    <th class="rate_width">Qty
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="qty" onChange={this.filterList}/>
                                        </div>
                                    </th>                                
                                    <th class="rate_width">Rate
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="rate" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th class="rate_width">Value
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="value" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    {/* <th>Order Type
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderType" onChange={this.filterList}/>
                                        </div>
                                    </th>                                     */}
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>                                                                                              
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>                                                                                       
                                </tr> */}
                                {
                                            !this.state.euoOrderList || this.state.euoOrderList === null || this.state.euoOrderList.length === 0 ?
                                                <tr align="center">
                                                    <td colSpan="6">No Records</td>
                                                </tr> :
                                                this.state.euoOrderList.map((report) => (
                                                    <tr>
                                                        <td data-tip={report.refNum}>{report.refNum}</td>
                                                        <td data-tip={report.retailerName}>{report.retailerName}</td>
                                                        <td data-tip={report.productName}>{report.productName}</td>
                                                        <td data-tip={report.date}>{report.date}</td>
                                                        {/* <td data-tip={report.erpOrderId}>{report.erpOrderId}</td> */}
                                                        <td data-tip={report.documentNo}>{report.documentNo}</td>
                                                        {/* <td data-tip={report.description}>{report.description}</td> */}
                                                        <td class="rate_width" data-tip={report.qty.toFixed(2)}>{report.qty.toFixed(2)}</td>
                                                        <td class="rate_width" data-tip={report.rate.toFixed(2)}>{report.rate.toFixed(2)}</td>
                                                        <td class="rate_width" data-tip={report.value.toFixed(2)}>{report.value.toFixed(2)}</td>
                                                        {/* <td data-tip={report.orderType}>{report.orderType}</td> */}
                                                    </tr>
                                                ))
                                        }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.euoOrderList ? this.state.euoOrderList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    {/* <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /></div>
                    </div> */}
                    {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{padding:'0px 8px'}}/><ReactHTMLTableToExcel className="download-table-xls-button" table="erpUploadedOrder_Table" filename={"ErpUploaded_"+this.state.currentDate}
                        sheet="erpOrders" buttonText="Excel Export"/></div> */}
                    <div className="O2Table_ExpExcel" onClick={ ()=> this.erpUploadedExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }}/>Excel Export</div>    
                    {/* <div className="O2Table_Footer">
                        <button>Close</button>
                    </div>  */}
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />   
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL        
    }
}
export default connect(mapStateToProps)(ErpUploadedOrder)
//export default ErpUploadedOrder







