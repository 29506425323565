import React, { Component, useState } from 'react'
import Select from 'react-select';
import './GenerateRetailerSchemeProductModule.css';
import axios from 'axios'
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
class GenerateRetailerSchemeProductModule extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0,10);
    
        this.state = {
            Distributorbtn: "Popup_tabButtons",
            DistServbtn: "Popup_tabButtons",
            grspSchemeProductList: [],
            grspStateList: [],
            grspCityList: [],
            grspDistributorList: [],
            grspRetailerTypeList: [],
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            currentDate:todaydate,
            companyCode: this.props.userObj.companyCode,
            grspSchemeProduct: '',
            grspState: '',
            grspCity: '',
            grspDistributor: '',
            grspRetailerType: '',
            grspFromDate: todaydate,
            grspToDate: todaydate,
            selectedGRSPCityList: [],
            selectedGRSPDistributorList: [],
        }
    
    }
    
    componentDidMount() {
        this.getGRSPList();       
    }
    getGRSPList() {
        const params = {
            companyCode: this.state.companyCode            
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/rsp/getRetailerSchemeProduct", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        grspSchemeProductList: data.schemeProductList,
                        grspStateList: data.stateList,
                        grspCityList: data.cityList,                                                
                    })                    
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getGRSPDistributor() {

        let citylist="";
        this.state.selectedGRSPCityList.map((sal) => (
            citylist +=""+sal.value+","
        ));
        citylist = citylist.substring(0, citylist.length - 1);        

        const params = {
            companyCode: this.state.companyCode,
            state: this.state.grspState,
            city: citylist,
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/rsp/getDistributorList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        grspDistributorList: data.distributorList,
                        grspRetailerTypeList: data.retailerTypeList                        
                    })                    
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    handleGRSPSchemeProductChange = (event) => {
        this.setState({
            grspSchemeProduct: event.value
        });
    }

    handleGRSPStateChange = (event) => {
        this.setState({
            grspState: event.value
        });
    }

    handleGRSPCityChange = (event) => {
        this.setState({
            //grspCity: event.value,
            selectedGRSPCityList:event
        }, () => {             
            if(this.state.selectedGRSPCityList.length > 0){
                this.getGRSPDistributor();                
            }  
            //console.log("<--1-->"+JSON.stringify(this.state.selectedGRSPCityList));              
        });
    }

    handleGRSPDistributorChange = (event) => {
        this.setState({
            //grspDistributor: event.value
            selectedGRSPDistributorList: event
        });
    }

    handleGRSPRetailerTypeChange = (event) => {
        this.setState({
            grspRetailerType: event.value
        });
    }

    saveGRDetail = () => {        
        
        if (this.state.grspSchemeProduct === "") {
            alert("Please Select Scheme Product");
        } else if (this.state.selectedGRSPDistributorList.length === 0) {
            alert("Please Select Distributor");
        } else if(this.state.grspRetailerType === ""){
            alert("Please Select Retailer Type");
        } else if(this.state.grspFromDate === ""){
            alert("Please Select From Date");
        } else if(this.state.grspToDate === ""){
            alert("Please Select To Date");
        } else if(this.state.grspFromDate > this.state.grspToDate){
            alert("From Date Cannot Be Greater Than End Date");
        } else {


            let distributorlist="";
            this.state.selectedGRSPDistributorList.map((sal) => (
                distributorlist +=""+sal.value+","
            ));
            distributorlist = distributorlist.substring(0, distributorlist.length - 1);


            let params = {
                "schemeProduct": this.state.grspSchemeProduct,
                "distributor": distributorlist,
                "retailerType": this.state.grspRetailerType,
                "fromDate": this.state.grspFromDate,
                "toDate": this.state.grspToDate,
                "user": this.state.loginuserObj.userName,
                "companyCode": this.state.companyCode,
            }
            // console.log("params-->"+JSON.stringify(params))
            this.setState({
                loading:true
            })
            axios.post(this.state.httpURL + "/rsp/saveRetailerSchemeList", params)
                .then(response => {
                    this.setState({
                        loading:false
                    })
                    if (response.data !== null) {                        
                        if (response.data.valid) {
                            alert(response.data.responseMsg);
                            this.clearGRSPDetails();
                        } else {
                            alert(response.data.responseMsg);
                        }                    
                    }
                })
                .catch(error => {
                   // console.log("error" + error)
                   alert(error);
                   this.setState({
                    loading:false
                })
                })
        }
    }

    grspDateChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    clearGRSP  = () => { 
        this.clearGRSPDetails();
    }

    clearGRSPDetails(){
        this.setState({
            grspSchemeProduct: '',
            grspState: '',
            selectedGRSPCityList: [],
            selectedGRSPDistributorList: [],
            grspRetailerType: '',
            grspFromDate: this.state.currentDate,
            grspToDate: this.state.currentDate,
        }, () => {             
                        
        });    
    }

    render() {


        return (

            <div className="O2Container_Wrapper">
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>GENERATE RETAILER SCHEME PRODUCTS</span></div>
                    <div className="O2Container_legendDiv Generate_Container">
                        <div className="legendTitle">Generate Retailer Scheme </div>
                        <div className="legendBodyContent">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Scheme Product</div>
                                <div className="contentRow_Value">                                    
                                    <Select options={this.state.grspSchemeProductList} name="grspSchemeProduct" value={this.state.grspSchemeProductList.filter(({value}) => value === this.state.grspSchemeProduct)} placeholder="Choose Scheme Product" onChange={this.handleGRSPSchemeProductChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label"></div>
                                <div className="contentRow_Value">
                                    
                                </div>
                            </div>
                         
                        </div>
                        <div className="legendBodyContent">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">State</div>
                                <div className="contentRow_Value">                                    
                                    <Select options={this.state.grspStateList} name="grspState" value={this.state.grspStateList.filter(({value}) => value === this.state.grspState)} placeholder="Choose State" onChange={this.handleGRSPStateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label"></div>
                                <div className="contentRow_Value">
                                   
                                </div>
                            </div>
             
                        </div>
                        <div className="legendBodyContent">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">City</div>
                                <div className="contentRow_Value">
                                    <Select isMulti options={this.state.grspCityList} name="grspCity" value={this.state.selectedGRSPCityList} placeholder="Choose City" onChange={this.handleGRSPCityChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label"></div>
                                <div className="contentRow_Value">
                                    
                                </div>
                            </div>
                           
                        </div>
                        <div className="legendBodyContent">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Distributor</div>
                                <div className="contentRow_Value">                                    
                                    <Select isMulti options={this.state.grspDistributorList} name="grspDistributor" value={this.state.selectedGRSPDistributorList} placeholder="Choose Distributor" onChange={this.handleGRSPDistributorChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label"></div>
                                <div className="contentRow_Value">
                                    
                                </div>
                            </div>
                             
                        </div>
                        <div className="legendBodyContent">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Retailer Type</div>
                                <div className="contentRow_Value">                                    
                                    <Select options={this.state.grspRetailerTypeList} name="grspRetailerType" value={this.state.grspRetailerTypeList.filter(({value}) => value === this.state.grspRetailerType)} placeholder="Choose Retailer Type" onChange={this.handleGRSPRetailerTypeChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label"></div>
                                <div className="contentRow_Value">
                                    
                                </div>
                            </div>
                             
                        </div>
                        <div className="legendBodyContent">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date From</div>
                                <div className="contentRow_Value">                                    
                                    <input type="date" name="grspFromDate" value={this.state.grspFromDate} onChange={this.grspDateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label"></div>
                                <div className="contentRow_Value">
                                    
                                </div>
                            </div>
                             
                        </div>
                        <div className="legendBodyContent">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date To</div>
                                <div className="contentRow_Value">
                                    <input type="date" name="grspToDate" value={this.state.grspToDate} onChange={this.grspDateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label"></div>
                                <div className="contentRow_Value">
                                    
                                </div>
                            </div>
                             
                        </div>
                    </div>
                    <div className="O2Table_Footer">
                            <button onClick={this.saveGRDetail}>Generate Retailer List</button>            
                            <button onClick={this.clearGRSP}>Clear</button> 
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL        
    }
}
export default connect(mapStateToProps)(GenerateRetailerSchemeProductModule)
//export default GenerateRetailerSchemeProductModule

