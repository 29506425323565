import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faPlus, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class BARetailerOutLetModule extends Component {

    constructor(props) {

        var curr = new Date();
        curr.setDate(curr.getDate());
        var currentdate = curr.toISOString().substr(0, 10);

        super(props)
        this.state = {
            loading: false,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            baUserList: [],
            baUserOrgList: [],
            userList: [],
            retailerList: [],
            todayDate: currentdate,
            orFState: '',
            orFStateList: [],
        }
    }

    componentDidMount() {
        this.getStateList();
    }

    getStateList() {
        const params = {
            companyCode: this.state.loginuserObj.companyCode
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/baretailer/getBaOutLetList", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({
                        orFStateList: data.stateList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getBaUserList() {
        const params = {
            companyCode: this.state.loginuserObj.companyCode,
            state:this.state.orFState
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/baretailer/getBaOutLetList", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({
                        // baUserList: data.baUserList,
                        // baUserOrgList: data.baUserList,
                        // userList: data.userList,
                        // retailerList: data.retailerList,
                        userList: data.outLetList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getBaRetailerList() {
        let stateVal = '';
        if (this.state.orFState === '' || this.state.orFState === undefined) {
            stateVal = "All";
        } else {
            stateVal = this.state.orFState;
        }

        const params = {
            companyCode: this.state.loginuserObj.companyCode,
            userRoleId: this.state.urUserRole,
            state: stateVal,
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/baretailer/getBaRetailerOutLetList", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({
                        baUserList: data.baOutLetList,
                        baUserOrgList: data.baOutLetList,
                        // retailerList: data.retailerList,
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    ba_savefunction = (e) => {
        // console.log("<-1->" + JSON.stringify(this.state.loginuserObj));
        if (this.state.baUserList === undefined || this.state.baUserList === null || this.state.baUserList.length === 0) {
            alert("No Records To Save");
            return false;
        } else if (this.getBAEmpty()) {
            alert("Please Enter All Values");
            return false;
        } else if (this.getBAUnique()) {
            alert("This Retailer Already Exists!!");
            return false;
        } else if (this.validateSecondary()) {
            alert("Please Select the From and To Date");
            return false;
        } else {
            var updateFlag = false;
            if (this.state.baUserList.some(item => item.changeFlag === 'A')) {
                updateFlag = true;
            }
            let stateVal = '';
            if (this.state.orFState === '' || this.state.orFState === undefined) {
                stateVal = "All";
            } else {
                stateVal = this.state.orFState;
            }

            let params = {
                "userRoleId": this.state.urUserRole,
                "companyCode": this.state.loginuserObj.companyCode,
                "baUserList": this.state.baUserList,
                "lastUpdatedBy": this.state.loginuserObj.userName,
                "updateFlag": updateFlag,
                "salesmanCode": this.state.loginuserObj.salesmanCode,
                "state": stateVal,
            }
            this.setState({
                loading: true
            });
            axios.post(this.state.httpURL + "/baretailer/saveRetailerData", params)
                .then(response => {
                    this.setState({
                        loading: false
                    });
                    if (response.data.valid) {
                        alert("BA Retailer Saved Successfully.");
                        this.setState({
                            baUserList: response.data.baUserList,
                            baUserOrgList: response.data.baUserList,
                        })
                        ReactTooltip.rebuild();
                    } else {
                        alert(response.data.responseMsg);
                    }
                })
                .catch(error => {
                    //console.log("error" + error)
                    this.setState({
                        loading: false
                    });
                    alert(error);
                })
        }
    }

    validateSecondary() {
        var isUnique = false;
        let srlist = this.state.baUserList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].reatilerCategory === "Secondary" && (srlist[i].fromStrDate === "" || srlist[i].toStrDate === "")) {
                isUnique = true;
            }

        }
        return isUnique;
    }
    ba_cancelfunction = (e) => {
        if (this.state.urUserRole !== "" && this.state.urUserRole !== null && this.state.urUserRole !== undefined) {
            this.getBaRetailerList();
        }
    }

    getBAEmpty() {
        var isUnique = false;
        let srlist = this.state.baUserList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].retailerId === "" || srlist[i].retailerId === null || srlist[i].retailerId === undefined) {
                isUnique = true;
            }
            if (srlist[i].effectiveStrDate === "" || srlist[i].effectiveStrDate === null || srlist[i].effectiveStrDate === undefined) {
                isUnique = true;
            }
        }
        return isUnique;
    }

    getBAUnique() {
        console.log('baUserList > ' + JSON.stringify(this.state.baUserList));
        var isUnique = false;
        let srlist = this.state.baUserList;
        for (var i = 0; i < srlist.length; i++) {
            const retailerId = srlist[i].retailerId;
            const retailerDate = srlist[i].effectiveStrDate;
            const reatilerCategory = srlist[i].reatilerCategory;
            for (var j = i + 1; j < srlist.length; j++) {
                const retailerId1 = srlist[j].retailerId;
                const retailerDate1 = srlist[j].effectiveStrDate;
                const reatilerCategory1 = srlist[j].reatilerCategory;
                // if (retailerId === retailerId1 && retailerDate === retailerDate1 && reatilerCategory === reatilerCategory1) {
                if (retailerId === retailerId1 && reatilerCategory === reatilerCategory1) {
                    isUnique = true;
                }
            }
        }
        return isUnique;
    }

    ba_newRowHandler = () => {

        if (this.state.urUserRole === "" || this.state.urUserRole === undefined) {
            alert("Please Choose A User");

        } else {
            // console.log("-->"+JSON.stringify(this.state.baUserList))   

            if (!this.state.baUserList.some(item => item.changeFlag === 'A')) {
                var newind = this.state.baUserList.length + 1;
                var emptyobj = {
                    tempId: newind,
                    companyCode: this.state.loginuserObj.companyCode,
                    userId: this.state.urUserRole,
                    retailerId: '',
                    reatilerCategory: 'Primary',
                    effectiveStrDate: this.state.todayDate,
                    fromStrDate: "",
                    toStrDate: "",
                    activeFlag: 'Y',
                    readOnly: false,
                    changeFlag: 'A',
                    deletedFlag: 'N',
                    lastUpdatedBy: this.state.loginuserObj.userName
                }
                let stockListtemp = this.state.baUserList
                stockListtemp.push(emptyobj)
                this.setState({ baUserList: stockListtemp }, () => {
                    //console.log("ABC-->" + JSON.stringify(this.state.baUserList))
                })
            }
            // else {
            //     alert("Please Choose A User");
            // }                        
        }
    }

    handleRetailerChange = (ind, event) => {
        const { baUserList } = this.state;
        baUserList[ind].retailerId = event.value
        baUserList[ind].changeFlag = 'N'
        this.setState({ baUserList: baUserList }, () => {
            ReactTooltip.rebuild();
        });
    }

    handleDateChange = (ind, event) => {
        const { baUserList } = this.state;
        baUserList[ind].effectiveStrDate = event.target.value
        this.setState({ baUserList: baUserList }, () => {
            ReactTooltip.rebuild();
        });
    }

    handleFromDateChange = (ind, event) => {
        const { baUserList } = this.state;
        baUserList[ind].fromStrDate = event.target.value
        this.setState({ baUserList: baUserList }, () => {
            ReactTooltip.rebuild();
        });
    }

    handleToDateChange = (ind, event) => {
        const { baUserList } = this.state;
        baUserList[ind].toStrDate = event.target.value
        this.setState({ baUserList: baUserList }, () => {
            ReactTooltip.rebuild();
        });
    }

    handleCategoryChange = (ind, event) => {
        const { baUserList } = this.state;
        baUserList[ind].reatilerCategory = event.target.value
        if (event.target.value === "Primary") {
            baUserList[ind].toStrDate = "";
            baUserList[ind].fromStrDate = "";
        }
        baUserList[ind].changeFlag = 'A';
        this.setState({ baUserList: baUserList }, () => {
            ReactTooltip.rebuild();
        });
    }

    handleURRoleChange = (event) => {
        this.setState({
            urUserRole: event.value
        }, () => {
            if (this.state.urUserRole !== "" && this.state.urUserRole !== null && this.state.urUserRole !== undefined) {
                this.getBaRetailerList();
            } else {
                this.setState({
                    baUserList: [],
                    baUserOrgList: [],
                });
            }
        })
    }

    handleURStateChange = (event) => {
        this.setState({
            orFState: event.value,
            urUserRole: '',
            userList: [],
            baUserList: [],
            baUserOrgList: [],
        }, () => {
            if (this.state.orFState !== "" && this.state.orFState !== null && this.state.orFState !== undefined) {
                this.getBaUserList();
            }
        })
    }

    ba_deleteHandler = (ind) => {
        // var baUserList = [...this.state.baUserList];
        // baUserList.splice(ind, 1)
        // this.setState({ baUserList: baUserList })
        const { baUserList } = this.state;
        //  console.log("baUserList-->"+JSON.stringify(baUserList[ind].id))
        if (window.confirm("Do you want to Delete Retailer?")) {
            if (baUserList[ind].id != undefined) {
                baUserList[ind].changeFlag = 'D';
                this.setState({ baUserList: baUserList })
            } else {
                baUserList.splice(ind, 1);
                this.setState({ baUserList: baUserList })
            }
        }
    }

    orderExcel = () => {
        var dFlag = true;
        if (this.state.baUserList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.baUserList;
            var fileName = "BA RETAILER OUTLET_"+this.state.urUserRole;
            var wscols = [
                { wch: 13 },
                { wch: 35 },
                { wch: 45 },
                { wch: 35 },
                { wch: 20 },
                { wch: 20 },
                //{wch:20},
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "User ID": dList[i].userLoginId,
                    "User Name": dList[i].userName,
                    "Date": dList[i].effectiveStrDate,
                    "State": this.state.orFState                  
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'order': ws }, SheetNames: ['order'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    filterList = (event) => {
        let filtered = this.state.baUserOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            baUserList: filtered
        })
    }
    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>BA RETAILER OUTLET</span></div>
                    <div className="O2Table_SubContainer Report_subContainer">
                        <div className="O2Content_row">
                            <div className="contentRow_Label">State</div>
                            <div className="contentRow_Value">
                                {/* <Select name="urUserRole" placeholder="Choose State"/>  */}
                                <Select options={this.state.orFStateList} name="orFState" value={this.state.orFStateList.filter(({ value }) => value === this.state.orFState)} placeholder="Choose State" onChange={this.handleURStateChange} />
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Outlet</div>
                            <div className="contentRow_Value">
                                {/* <Select name="urUserRole" placeholder="Choose User Role"/>  */}
                                <Select options={this.state.userList} name="baUser" value={this.state.userList.filter(({ value }) => value === this.state.urUserRole)} placeholder="Choose User" onChange={this.handleURRoleChange} />
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label"></div>
                            <div className="contentRow_Value">

                            </div>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="metaData_Table">
                            <thead>
                                <tr>
                                    <th style={{ width: '20%' }}>User Id
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="userLoginId" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th style={{ width: '20%' }}>User Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="userName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th>Date </th>
                                    <th>Category </th>
                                    <th>From Date </th>
                                    <th>To Date </th>
                                    {/* <th>Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="status" onChange={this.filterList} />
                                        </div>
                                    </th> */}
                                    {/* <th className="o2Del_container">
                                        <button><FontAwesomeIcon icon={faPlus} style={{ padding: '0px 4px' }} onClick={() => this.ba_newRowHandler()} /></button>
                                    </th> */}
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>

                                {/* <tr>
                                    <td>
                                        <input type="text"/>
                                    </td>
                                    <td>
                                        <select></select>
                                    </td>
                                    <td>
                                        <input type="date"/>
                                    </td>
                                    <td className="Status_Route">
                                        <select>
                                            <option value="N" >Active</option>
                                            <option value="Y" >In Active</option>
                                        </select>
                                    </td>
                                    <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }}  />
                                       
                                    </td>
                                </tr> */}
                                {
                                    !this.state.baUserList || this.state.baUserList === null || this.state.baUserList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="4">No BA Outlet Available</td>
                                        </tr> :
                                        this.state.baUserList.map((user, i) => (

                                            user.changeFlag !== 'D'
                                                ?
                                                <tr key={i}>
                                                    {/* <td style={{ width: '20%' }}><Select options={this.state.retailerList} value={this.state.retailerList.filter(({ value }) => value === user.retailerId)} placeholder="Choose BA Retailer" isDisabled={user.readOnly} onChange={(e) => this.handleRetailerChange(i, e)} /></td> */}
                                                    <td style={{ width: '20%' }} className="User ID" data-tip={user.userLoginId}>{user.userLoginId}</td>
                                                    <td style={{ width: '20%' }} className="Name" data-tip={user.userName}>{user.userName}</td>
                                                    <td>
                                                        <input disabled={true} type="date" value={user.effectiveStrDate} readOnly={user.readOnly} onChange={(e) => this.handleDateChange(i, e)} />
                                                    </td>
                                                    <td>
                                                        <select disabled={true} value={user.reatilerCategory} onChange={(e) => this.handleCategoryChange(i, e)} >
                                                            <option value="Primary" >Primary</option>
                                                            <option value="Secondary" >Secondary</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="date" value={user.fromStrDate} disabled={user.reatilerCategory === "Primary" || user.reatilerCategory === "" ? true : false} onChange={(e) => this.handleFromDateChange(i, e)} />
                                                    </td>
                                                    <td>
                                                        <input type="date" value={user.toStrDate} disabled={user.reatilerCategory === "Primary" || user.reatilerCategory === "" ? true : false} onChange={(e) => this.handleToDateChange(i, e)} />
                                                    </td>
                                                    {/* <td>
                                                        <select disabled={true} value={user.activeFlag}>
                                                            <option value="Y" >Active</option>
                                                            <option value="N" >In Active</option>
                                                        </select>
                                                    </td> */}
                                                    {/* <td className="o2Del_container">
                                                        <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} onClick={() => this.ba_deleteHandler(i)} />
                                                    </td> */}
                                                </tr>
                                                : null
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.baUserList ? this.state.baUserList.length : 0}</span>entries</span></div>
                    </div>
                    <div className="O2Table_bottomSubContainer">                        
                        <div className="O2Table_ExpCollExcel" onClick={() => this.orderExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Excel Export</div>
                    </div>
                    {/* <div className="O2Table_Footer">
                        <button onClick={this.ba_savefunction}>Save</button>
                        <button onClick={this.ba_cancelfunction}>Cancel</button>
                    </div> */}
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(BARetailerOutLetModule)
//export default BARetailerOutLetModule
