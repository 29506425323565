const USER_CREDENTIALS='USER_CREDENTIALS'
const LOGOUT='LOGOUT'

export const addUserObj = (userObj,appPrefrenece) => ({
    type: USER_CREDENTIALS,
    userObj:userObj,
    appPrefrenece:appPrefrenece
});


export const userLoggedOut = () => ({
    type: LOGOUT,
    
});
