import React, { Component } from 'react'
import './TopModule.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faPowerOff ,faEye} from "@fortawesome/free-solid-svg-icons";

import axios from 'axios';

import {connect} from 'react-redux'

class TopModule extends Component {

    constructor(props) {
        super(props)

        this.state = {
            active:false,
            userName:"",
            userobj:this.props.userObj,
            httpURL: this.props.httpURL,            
            companyCode: this.props.userObj.companyCode,
            resetFlag:false,
            passwordShown:false,
            RepasswordShown:false,
            ConfirmpasswordShown:false
        }
        
    }

toggleClass = () =>{
    const currentState = this.state.active;
    this.setState({
        active:!currentState
    },()=>{
      //  console.log("-currentState---"+this.state.active);
        this.props.parentCallback(this.state.active);
    });
   
};

togglePasswordVisiblity = () => {
    const flag=this.state.passwordShown;

        this.setState({
            passwordShown: !flag,
        });

  };

  toggleRePasswordVisiblity = () => {
    const flag=this.state.RepasswordShown;

        this.setState({
            RepasswordShown: !flag,
        });

  };
  toggleConfirmPasswordVisiblity = () => {
    const flag=this.state.ConfirmpasswordShown;

        this.setState({
            ConfirmpasswordShown: !flag,
        });

  };

ResetPswd_OpenHandler = () => {
    document.querySelector('.Changepswd_wrapper').style.display = 'flex';
}
ResetPswd_CloseHandler = () => {
    document.querySelector('.Changepswd_wrapper').style.display = 'none';
}

logOut = () =>{
   // console.log("Logout called")
  this.props.logoutflag();
  
};


  componentDidMount(){  
    //this.getTMPasswordValidation();
    // console.log("appPrefrenece-->"+JSON.stringify(this.props.appPrefrenece))
    }

    getTMPasswordValidation(){
        const params = {
            id: this.state.userobj.userId,
            companyCode: this.state.companyCode            
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/user/getTMPasswordValidation", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    if(data.oldPasswordFlag){
                        document.querySelector('.Changepswd_wrapper').style.display = 'flex';
                        this.setState({
                            resetFlag:true
                        })
                    }                   
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    };

    tmChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    ResetTMPswd_SaveHandler = () =>{


        //const { value } = this.state.tmNewPassword;
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        
        if (this.state.tmOldPassword === "" || this.state.tmOldPassword === undefined || this.state.tmOldPassword === null) {
            alert("Please Old Enter Password");
        } else if (this.state.tmNewPassword === "" || this.state.tmNewPassword === undefined || this.state.tmNewPassword === null) {
            alert("Please New Enter Password");
        } else if (this.state.tmConfirmPassword === "" || this.state.tmConfirmPassword === undefined || this.state.tmConfirmPassword === null) {
            alert("Please Enter Confirm Password");
        } else if (this.state.tmNewPassword !== this.state.tmConfirmPassword) {
            alert("Incorrect Confirm Password");
        }  else if (this.state.tmNewPassword === this.state.tmOldPassword) {
            alert("Old And New Password Cannaot Be Same");
        } else if (!strongRegex.test(this.state.tmNewPassword) && this.props.appPrefrenece.patternCheck === 'Y'){
            alert("Week Password : Password should contain one upperCase, one lowerCase, one special character and minimum length of 8 characters");
        } 
        else {
        let params = {   
                "id": this.state.userobj.userId,
                "changeFlag" : true,                           
                "companyCode": this.state.companyCode,
                "loginUser": this.state.userobj.userName,
                "oldPassword": this.state.tmOldPassword,
                "resetPassword": this.state.tmNewPassword                              
                }                 
                this.setState({
                    loading:true
                })
                axios.post(this.state.httpURL + "/user/resetPassword", params)
                    .then(response => {
                        this.setState({
                            loading:false
                        })
                        if (response.data.valid) {
                            if(response.data.oldPasswordFlag){
                                alert("Incorrect Old Password");
                            } else {
                                alert("Password Changed Successfully.");
                                this.ResetPswd_CloseHandler();
                                this.cleanTMResetPassword();
                                this.setState({
                                    resetFlag:false
                                })                               
                            }                            
                        } else {
                            alert(response.data.responseMsg);
                        }
                    })
                    .catch(error => {
                       // console.log("error" + error)
                       alert(error);
                       this.setState({
                        loading:false
                    })
                    }) 
        }
    }

    cleanTMResetPassword(){
        this.setState({
            tmOldPassword:'',
            tmNewPassword:'',
            tmConfirmPassword:''
        })
    }

    render() {
       
    var date = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year
    var cdate = date + "/" + month + "/" + year ;
        

    setTimeout(() => {
        this.setState({
            userName:localStorage.getItem("userLoginname")
        },()=>{
        });
      }, 3000);

        return (
            <div className="Breadcum_Container">
               <div className="MenuCollapser">
                   <span><FontAwesomeIcon icon={faBars} onClick={this.toggleClass}/></span>
                </div>
                <div className="LoginDate">
                    <span>{cdate}</span>
                </div>
                <div className="UName_Container">
                    <span>{this.state.userobj.userName}</span> 
                </div>
                <div className="FPass_Container">
                    <span onClick={() =>this.ResetPswd_OpenHandler()}>Change Password</span> 
                </div>
               <div className="Logout_btn" onClick={this.logOut}>
               <span><FontAwesomeIcon icon={faPowerOff} /></span>
               </div>

               <div className="Changepswd_wrapper">
                <div className="ChangePswrdBoxWrap">
                    <div className="ChangePswrdBox">
                        <div className="ChangePswrdTitle">Change Password</div>
                        <div className="ChangePswrdBody">
                            <div className="passwordChange_row">
                                <div className="passwordChange_label">Old Password</div>
                                <div className="passwordChange_value passIconWrapper">
                                    <input type={this.state.passwordShown ? 'text' : 'password'} name='tmOldPassword' value={this.state.tmOldPassword} onChange={this.tmChange}/>
                                    <FontAwesomeIcon onClick={this.togglePasswordVisiblity} className="resetPasswordIcon" icon={faEye} style={{padding:'0px 4px'}} />
                                </div>
                            </div>
                            <div className="passwordChange_row">
                                <div className="passwordChange_label">New Password</div>
                                <div className="passwordChange_value passIconWrapper">
                                    <input type={this.state.RepasswordShown ? 'text' : 'password'} name='tmNewPassword' value={this.state.tmNewPassword} onChange={this.tmChange}/>
                                    <FontAwesomeIcon onClick={this.toggleRePasswordVisiblity} className="resetPasswordIcon" icon={faEye} style={{padding:'0px 4px'}} />
                                </div>
                            </div>
                            <div className="passwordChange_row">
                                <div className="passwordChange_label">Confirm Password</div>
                                <div className="passwordChange_value passIconWrapper">
                                    <input type={this.state.ConfirmpasswordShown ? 'text' : 'password'} name='tmConfirmPassword' value={this.state.tmConfirmPassword} onChange={this.tmChange}/>
                                    <FontAwesomeIcon onClick={this.toggleConfirmPasswordVisiblity} className="resetPasswordIcon" icon={faEye} style={{padding:'0px 4px'}} />
                                </div>
                            </div>
                        </div>
                        <div className="ChangePswrdFooter">
                            <button onClick={() =>this.ResetTMPswd_SaveHandler()}>Save</button>
                            <button onClick={() =>this.ResetPswd_CloseHandler()}>Cancel</button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps=state=>{
    return {
        userObj:state.userObj,
        httpURL:state.httpURL,
        appPrefrenece:state.appPrefrenece
    }
}



export default connect(mapStateToProps)(TopModule)
