import React, { Component } from 'react'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faSearch, faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import './RetailerTeamAllocation.css';
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux'
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

const override1 = css`
  display: block;
  top: -400px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class RetailerTeamAllocation extends Component {


    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            retailerList: [],
            teamList: [],
            orgretailerList: [],
            salesmanList: [],
            salesmanCode: 'All',
            distributorList: [],
            orgdistributorList: [],
            distributorCode: 'All',
            retailerDetailList: [],
            routeList: [],
            ncDistributorList: [],
            ncSubDistributorList: [],
            erpList: [],
            erpOrgList: [],
            ncId: '',
            ncName: '',
            ncCompanyName: '',
            ncDistributorCode: '',
            ncRetailerCode: '',
            ncRefNum: '',
            ncAddressLine1: '',
            ncAddressLine2: '',
            ncAddressLine3: '',
            ncPinCode: '',
            ncLandMark: '',
            ncLatitude: '',
            ncLongtitude: '',
            ncContactName: '',
            ncContactPhone: '',
            ncContactEmail: '',
            ncPendingAmount: '',
            ncCreditLimit: '',
            ncCreditDays: '',
            ncPINNumber: '',
            ncRouteValue: '',
            stateName: '',
            cityName: '',
            ncCustomerChecked: false,
            ncVendorChecked: false,
            ncGstinnum: '',
            ncPanNum: '',
            ncAddressLine4: '',
            ncCity: '',
            ncState: '',
            ncCountry: '',
            // ncSource: 'Market',
            ncConfirmation: 'Y',
            ncStatus: 'Y',
            cCode: '',
            erpCode: '',
            ncFState: '',
            ncFCity: '',
            ncFRoute: '',
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            allChecked: false,
            retailerbtn: "Popup_tabButtons",
            distpopbtn: "Popup_tabButtons",
            popupDistributorList: [],
            productGroupList: [],
            productTypeList: [],
            retailerdistributorList: [],
            httpPostalURL: this.props.httpPostalURL,
            retailerWriteAccess: this.props.userObj.retailerWriteAccess,
            retailerTypeList: [],
            ncFStateList: [],
            ncFCityList: [],
            ncFRouteList: [],
            retailerTypeCode: '',
            orgretailerTypeCode: '',
            currentDate: todaydate,
            searchReatailerCode: '',
            searchReatailerName: '',
            ncStateList: [],
            ncCityList: [],
            ncOldRetailerCode: '',
        }

    }

    componentDidMount() {

        // this.getRetailerList();
        //this.loadRet();
        this.getStateList();
    }
    loadRet() {
        let cCode = this.state.companyCode;
        this.setState({
            cCode: cCode
        }, () => {
            //  console.log(this.state.topic)
            this.getRetailerList();
        });
    }


    poptabRetailerHandler = () => {
        this.setState({
            retailerbtn: "Popup_tabButtonsActive",
            distpopbtn: "Popup_tabButtons",
        })
        document.querySelector('.retailerTab').style.display = 'block';
        document.querySelector('.distributorTab').style.display = 'none';

    }
    poptabDistributorHandler = () => {
        if (this.state.retailerTypeCode !== 3) {
            this.setState({
                retailerbtn: "Popup_tabButtons ",
                distpopbtn: "Popup_tabButtonsActive",
            })
            document.querySelector('.distributorTab').style.display = 'block';
            document.querySelector('.retailerTab').style.display = 'none';
        }
    }

    getStateList() {

        const params = {
            companyCode: this.props.userObj.companyCode,
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/getStateList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        ncFStateList: data.rStateList,
                        retailerTypeList: data.retailerTypeList,
                        erpList: data.erpCodeList,
                        erpOrgList: data.erpCodeList,
                        // routeList: data.routeList,
                        ncStateList: data.indianStateList,
                        popupDistributorList: data.popupDistributorList,
                        productTypeList: data.productTypeList,
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getCityList() {

        const params = {
            companyCode: this.props.userObj.companyCode,
            state: this.state.ncFState
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/getCityList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        ncFCityList: data.rCityList,
                        ncFRouteList: data.rRouteList
                    }, () => {
                        // if(this.state.ncFCity !== "" && this.state.ncFCity !== null && this.state.ncFCity !== undefined){               
                        //     this.getRouteList();                            
                        // }
                        this.setState({
                            ncFCity: data.rCityList[0].value,
                            ncFRoute: data.rRouteList[0].value
                        })
                    });
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getRouteList() {

        const params = {
            companyCode: this.props.userObj.companyCode,
            state: this.state.ncFState,
            city: this.state.ncFCity,
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/getRouteList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        ncFRouteList: data.rRouteList
                    }, () => {
                        if (this.state.ncFCity === "All") {
                            this.setState({
                                ncFRoute: data.rRouteList[0].value
                            })
                        }
                    });
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getRetailerList() {

        const params = {
            companyCode: this.state.cCode,
            distributorCode: "",
            salesmanCode: "",
            status: "Y",
            retailerCode: "All",
            state: this.state.ncFState,
            city: this.state.ncFCity,
            route: this.state.ncFRoute  
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/retailerTeamAllocationList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        orgretailerList: data.retailerList,
                        retailerList: data.retailerList,
                        teamList:data.userTeamList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    newCust_newpopup_openHandler = () => {

        var emptyobj = {
            retailerCode: 0,
            distributorcode: 0,
            productGroupid: 0,
            distributorCategory: "Primary"
        }

        if (!this.state.retailerdistributorList || this.state.retailerdistributorList.length === 0 || this.state.retailerdistributorList.length >= 0) {
            let emptylist = []
            emptylist.push(emptyobj)
            this.setState({
                retailerdistributorList: emptylist
            })
        }
        this.setState({
            retailerbtn: "Popup_tabButtonsActive",
            distpopbtn: "Popup_tabButtons",
        })
        document.querySelector('.retailerTab').style.display = 'block';
        document.querySelector('.distributorTab').style.display = 'none';

        document.querySelector('.newCustEdit_wrapper').style.display = 'flex';
        this.clearNCDetail();
        var dList = this.state.distributorList;
        dList.map(function (nc, i) {
            nc.isChecked = false;
        });
    }

    loadRetailerDetail = () => {

        this.state.retailerDetailList.map(function (cr, i) {
            if (cr.customer === 'Y') {
                this.state.ncCustomerChecked = true;
            } else {
                this.state.ncCustomerChecked = false;
            }
            if (cr.vendor === 'Y') {
                this.state.ncVendorChecked = true;
            } else {
                this.state.ncVendorChecked = false;
            }
          //  console.log("state Name->" + cr.stateName)
          //  console.log("Retailer List > " + JSON.stringify(cr))
            this.setState({
                ncId: cr.id,
                ncName: cr.name,
                ncCompanyName: cr.companyCode,
                ncDistributorCode: cr.distributorCode,
                ncRetailerCode: cr.retailerCode,
                ncRefNum: cr.refNum,
                ncAddressLine1: cr.address1,
                ncAddressLine2: cr.address2,
                ncAddressLine3: cr.address3,
                ncPinCode: cr.pincode,
                ncLandMark: cr.landmark,
                ncLatitude: cr.latitude,
                ncLongtitude: cr.longitude,
                ncContactName: cr.contactName,
                ncContactPhone: cr.contactPhone,
                ncContactEmail: cr.contactEmail,
                ncPendingAmount: cr.pendingAmt,
                ncCreditLimit: cr.creditLimit,
                ncCreditDays: cr.creditDays,
                ncPINNumber: cr.tinNumber,
                ncRouteValue: cr.routeCode,
                ncGstinnum: cr.gstinNumber,
                ncPanNum: cr.panNumber,
                ncAddressLine4: cr.address4,
                ncCity: cr.city,
                ncState: cr.state,
                ncCountry: cr.country,
                ncConfirmation: cr.confirmationFlag,
                ncStatus: cr.status,
                stateName: cr.stateName,
                cityName: cr.cityName,
                erpCode: cr.erpCode,
                retailerTypeCode: cr.retailerTypeId,
                orgretailerTypeCode: cr.orgretailerTypeId,
                ncOldRetailerCode: cr.oldRetailerCode,
            }, () => {
                var dcode = this.state.ncDistributorCode;
                var dList = this.state.distributorList;
                dList.map(function (nc, index) {
                    if (nc.distributorCode === dcode) {
                        // dList.splice(index, 1);
                        nc.showValue = false;
                    } else {
                        nc.showValue = true;
                    }
                });
                this.getncCity();
                this.getncState();
            });
        }.bind(this));
        var dList = this.state.distributorList;
        var sdList = this.state.ncSubDistributorList;
        dList.map(function (nc, i) {
            nc.isChecked = false;
            sdList.map(function (ncsd, i) {
                if (ncsd.distributorcode === nc.distributorCode) {
                    nc.isChecked = true;
                }
            });
        });
    }
    customerChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        });
        if (e.target.name === 'ncName') {
            // console.log("<--1-->");
            var tlist = this.state.erpOrgList;
            var searchString = e.target.value;
            const filtered = tlist.filter(c => (
                c.name.toLowerCase().search(searchString.toLowerCase()) !== -1
            ));
            this.setState({
                erpList: filtered
            })
        }
    }
    customerPinCodeChange = (e) => {
        if (e.target.value.length <= 6) {
            this.setState({
                [e.target.name]: e.target.value
            });
        }

        /*    if(e.target.value.length === 6){
                this.setState({
                    loading: true
                });
                axios.get(this.state.httpPostalURL+e.target.value)
                .then(response => response.data)            
                .then((data) => {     
                    if(data[0].Status === "Success"){
                        this.setState({
                            ncCity: data[0].PostOffice[0].Block,
                            ncState: data[0].PostOffice[0].State,
                            ncCountry: data[0].PostOffice[0].Country,
                        })
                    }                          
                    this.setState({
                        loading: false
                    });
                }).catch(err => {                               
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
            }  */

    }
    customerldRetailerCodeChange = (e) => {
        this.setState({
            ncOldRetailerCode: e.target.value
        });
    }
    newCust_Savefns = (e) => {
        //alert("save fns");
        var ncCustomerFalg = 'N';
        var ncVendorFalg = 'N';

        var uniqobj = this.getUnique(this.state.retailerdistributorList, 'distributorcode');
        // console.log("uniqobj-->"+uniqobj)

        // console.log("primay count-->"+this.checkisPrimaySelected())
        if (this.state.ncCustomerChecked) {
            ncCustomerFalg = 'Y';
        }
        if (this.state.ncVendorChecked) {
            ncVendorFalg = 'Y'
        }
      //  console.log('ncRouteValue > ' + this.state.ncRouteValue);

        if (this.state.ncName === "" || this.state.ncName === null || this.state.ncName === undefined) {
            alert("Please Enter Name");
        } else if (this.state.retailerTypeCode === "" || this.state.retailerTypeCode === null || this.state.retailerTypeCode === undefined) {
            alert("Please Select Retailer Type");
        } else if (this.state.ncRouteValue === "" || this.state.ncRouteValue === null || this.state.ncRouteValue === undefined) {
            alert("Please Select Route Code");
        }
        //  else if (this.state.ncCompanyName === "") {
        //     alert("Please Enter Company Name");
        // }
        //  else if (this.state.ncDistributorCode === "") {
        //     alert("Please Select Distributor");
        // }
        else if (this.state.ncAddressLine1 === "" || this.state.ncAddressLine1 === null || this.state.ncAddressLine1 === undefined) {
            alert("Please Enter Address1");
        } else if (this.state.ncAddressLine2 === "" || this.state.ncAddressLine2 === null || this.state.ncAddressLine2 === undefined) {
            alert("Please Enter Address2");
        } else if (this.state.ncPinCode === "" || this.state.ncPinCode === null || this.state.ncPinCode === undefined) {
            alert("Please Enter PinCode");
        } else if (this.state.ncPinCode.length < 6) {
            alert("Please Enter Valid Pincode")
            return false;
        } else if (this.state.ncCity === "" || this.state.ncCity === null || this.state.ncCity === undefined || this.state.ncCity === "NA") {
            alert("Please Enter City");
        } else if (this.state.ncState === "" || this.state.ncState === null || this.state.ncState === undefined || this.state.ncState === "NA") {
            alert("Please Enter State");
        } else if (this.state.ncCountry === "" || this.state.ncCountry === null || this.state.ncCountry === undefined || this.state.ncCountry === "NA") {
            alert("Please Enter Country");
        } else if (this.state.ncContactName === "" || this.state.ncContactName === null || this.state.ncContactName === undefined) {
            alert("Please Enter ContactName");
        } else if (this.state.ncContactPhone === "" || this.state.ncContactPhone === null || this.state.ncContactPhone === undefined) {
            alert("Please Enter ContactPhone");
        } else if (this.state.ncContactEmail === "" || this.state.ncContactEmail === null || this.state.ncContactEmail === undefined) {
            alert("Please Enter ContactEmail");
        } else if (this.checkDataEnterinFirstRow() && this.state.retailerTypeCode !== 3) {
            alert("Select All Distribuor Values");
            return false;
        } else if (!uniqobj && this.state.retailerTypeCode !== 3) {
            alert("Remove Duplicate Distributor and Proudct Group");
            return false;
        } else if (this.checkisPrimaySelected() == 0 && this.state.retailerTypeCode !== 3) {
            alert("Select Anyone Primay Catogery");
            return false;
        }

        // else if (this.checkUniquePrimaySelected() > 1) {
        //     alert("Only One Primary Category Allowed")
        //     return false;
        // }
        //  else if (this.state.retailerdistributorList.length !== uniqobj.length) {
        //     alert("Remove Duplicate Distributor");
        //     //console.log("Duplicate product");
        //     return false;
        // } 

        else if (this.state.ncPendingAmount === "" || this.state.ncPendingAmount === null || this.state.ncPendingAmount === undefined) {
            alert("Please Enter Pending Amount")
            return false;
        } else if (this.state.ncCreditLimit === "" || this.state.ncCreditLimit === null || this.state.ncCreditLimit === undefined) {
            alert("Please Enter Credit Limit")
            return false;
        } else if (this.state.ncPinCode === "" || this.state.ncPinCode === null || this.state.ncPinCode === undefined) {
            alert("Please Enter Valid Pincode")
            return false;
        } else {
            e.preventDefault();
            let uCode = this.state.loginuserObj.userName;
            const newcust = [{
                id: this.state.ncId,
                name: this.state.ncName,
                companyCode: this.state.loginuserObj.companyCode,
                distributorCode: this.state.ncDistributorCode,
                retailerCode: this.state.ncRetailerCode,
                refNum: this.state.ncRefNum,
                address1: this.state.ncAddressLine1,
                address2: this.state.ncAddressLine2,
                address3: this.state.ncAddressLine3,
                pincode: this.state.ncPinCode,
                landmark: this.state.ncLandMark,
                latitude: this.state.ncLatitude,
                longitude: this.state.ncLongtitude,
                contactName: this.state.ncContactName,
                contactPhone: this.state.ncContactPhone,
                contactEmail: this.state.ncContactEmail,
                pendingAmt: this.state.ncPendingAmount,
                creditLimit: this.state.ncCreditLimit,
                creditDays: this.state.ncCreditDays,
                tinNumber: this.state.ncPINNumber,
                routeCode: this.state.ncRouteValue,
                address4: this.state.ncAddressLine4,
                city: this.state.ncCity,
                state: this.state.ncState,
                country: this.state.ncCountry,
                gstinNumber: this.state.ncGstinnum,
                panNumber: this.state.ncPanNum,
                customer: ncCustomerFalg,
                vendor: ncVendorFalg,
                confirmationFlag: this.state.ncConfirmation,
                erpCode: this.state.erpCode,
                status: this.state.ncStatus,
                lastUpdtedBy: uCode,
                retailerTypeId: this.state.retailerTypeCode,
                orgretailerTypeId: this.state.orgretailerTypeCode,
                oldRetailerCode: this.state.ncOldRetailerCode,
            }];

            let params = {
                "retailerList": newcust,
                "ncDistributorList": this.state.distributorList,
                "retailerDistributorList": this.state.retailerdistributorList,
                "companyCode": this.state.loginuserObj.companyCode
            }
         //   console.log("<-1->"+JSON.stringify(params));
            this.setState({
                loading: true
            })
            axios.post(this.state.httpURL + "/newcustomer/", params)
                .then(response => {
                    if (response.data !== null) {
                        if (response.data.valid) {
                            this.setState({
                                loading: false
                            })
                            if (response.data.existRetailer) {
                                alert("This Retailer Name has Already Exist for the Same Route...!!!");
                            } else {
                                alert("Retailer Saved Successfully.");
                                this.clearNCDetail();
                                this.newCust_popCloseHandler();
                                this.setState({
                                    retailerList: [],
                                    orgretailerList: [],
                                });
                                if ((this.state.ncFState !== "" && this.state.ncFState !== null && this.state.ncFState !== undefined) || (this.state.ncFCity !== "" && this.state.ncFCity !== null && this.state.ncFCity !== undefined)) {
                                    this.getRetailerList();
                                }
                            }
                        } else {
                            this.setState({
                                loading: false
                            });
                            alert(response.data.responseMsg);
                        }
                    }
                })
                .catch(error => {
                    //console.log("error" + error)                    
                    this.setState({
                        loading: false
                    })
                    alert(error);
                })

                
        }
    }

    checkisPrimaySelected = () => {

        //console.log("calling")
        var isPrimarySelected = false;
        var primaryCount = 0;
        let srlist = this.state.retailerdistributorList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].distributorCategory === "Primary") {
                isPrimarySelected = true;
                primaryCount = primaryCount + 1
            }
        }
        return primaryCount;
    }

    checkUniquePrimaySelected = () => {

        //console.log("calling")
        var isPrimaryCount = 0;
        let srlist = this.state.retailerdistributorList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].distributorCategory === "Primary") {
                isPrimaryCount = isPrimaryCount + 1;
            }
        }
        return isPrimaryCount;
    }

    getUnique = (arr, index) => {
        // const unique = arr
        //     .map(e => e[index])
        //     // store the keys of the unique objects
        //     .map((e, i, final) => final.indexOf(e) === i && i)
        //     // eliminate the dead keys & store unique objects
        //     .filter(e => arr[e]).map(e => arr[e]);
        // return unique;
        var isUnique = true;
        let srlist = this.state.retailerdistributorList;
        for (var i = 0; i < srlist.length; i++) {

            for (var j = i + 1; j < srlist.length; j++) {
                if (srlist[i].distributorcode === srlist[j].distributorcode && srlist[i].productTypeCode === srlist[j].productTypeCode) {
                    // console.log(' i d->'+srlist[i].distributorcode+ ' --j di ->'+srlist[i].distributorcode)
                    // console.log(' i pg->'+srlist[i].productGroupid+ ' --j pg ->'+srlist[i].productGroupid)
                    isUnique = false;
                }
            }

        }
        return isUnique;
    }

    checkDataEnterinFirstRow() {
        var notEntered = false;
        let srlist = this.state.retailerdistributorList;
        for (var i = 0; i < srlist.length; i++) {
            if (srlist[i].distributorcode === 0 || srlist[i].productTypeCode === "" || srlist[i].distributorcode === "0" || srlist[i].productTypeCode === "0") {
                notEntered = true;
            }
        }
        return notEntered;

    }
    newCust_popCloseHandler = () => {
        document.querySelector('.newCustEdit_wrapper').style.display = 'none';
    }

    newCust_popup_openHandler = (retailer) => {
        //  document.querySelector('.newCustEdit_wrapper').style.display = 'flex';
        this.clearNCDetail();
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/" + retailer.id)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({
                        retailerDetailList: data.retailerList,
                        ncSubDistributorList: data.ncSubDistributorList,
                        retailerdistributorList: data.retailerDistributorList
                    }, () => {  // callback functions
                        // console.log("retailerdistributorList-->"+JSON.stringify(data.retailerDistributorList))
                        ReactTooltip.rebuild();
                        this.poptabRetailerHandler();
                        this.loadRetailerDetail();
                        document.querySelector('.newCustEdit_wrapper').style.display = 'flex';

                    })
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    newCust_delete_funs = (retailer) => {
        var answer = window.confirm("Are Sure To Delete The Retailer?")
        if (answer) {
            this.setState({
                loading: true
            })
            axios.delete(this.state.httpURL + "/newcustomer/" + retailer.id)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        })
                        alert("Retailer Deleted Successfully.");
                        this.getRetailerList();
                    }
                }).catch(err => {
                    // console.log(err)                    
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    filterList = (event) => {
        //alert(event.target.value);
        var tlist = this.state.orgdistributorList;
        var searchString = event.target.value;
        const filtered = tlist.filter(c => (
            c.distributorName.toLowerCase().search(searchString.toLowerCase()) !== -1

        ));

        this.setState({
            distributorList: filtered
        })

    }

    handleAllChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        // console.log("else -->"+itemName)
        this.setState(prevState => {
            let { retailerList, allChecked, orgretailerList } = prevState;
            if (itemName === "checkAll") {
                // console.log(checked)
                if (checked) {
                    allChecked = checked;
                    retailerList = retailerList.map(item => ({ ...item, isChecked: checked }));
                    orgretailerList = orgretailerList.map(item => ({ ...item, isChecked: checked }));
                } else {
                    allChecked = false;
                    retailerList = retailerList.map(item => ({ ...item, isChecked: false }));
                    orgretailerList = orgretailerList.map(item => ({ ...item, isChecked: false }));
                }

            }
            return { retailerList, allChecked, orgretailerList };
        });
    };

    handleChange = (e, ind) => {
        //console.log(ind)
        let checked = e.target.checked;
        // console.log(checked)
        const { retailerList, allChecked, orgretailerList } = this.state;
        retailerList[ind].isChecked = checked
        orgretailerList[ind].isChecked = checked

        this.setState({ orgretailerList: orgretailerList, retailerList: retailerList, allChecked: false }, () => {
            //console.log("0 index-->"+JSON.stringify(retailerList[ind]))
            ReactTooltip.rebuild();
        });

        // let itemName = e.target.name;
        // let checked = e.target.checked;
        // console.log("else -->"+itemName)
        // this.setState(prevState => {
        //     let { retailerList, allChecked, orgretailerList } = prevState;
        //     if (itemName === "checkAll") {
        //         console.log(checked)
        //         if(checked){
        //             allChecked = checked;
        //             retailerList = retailerList.map(item => ({ ...item, isChecked: checked }));
        //             orgretailerList = orgretailerList.map(item => ({ ...item, isChecked: checked }));
        //         }else{
        //             allChecked = false;
        //             retailerList = retailerList.map(item => ({ ...item, isChecked: false }));
        //             orgretailerList = orgretailerList.map(item => ({ ...item, isChecked: false }));
        //         }

        //     } else {
        //         allChecked = false;
        //         //console.log("retailerList--"+JSON.stringify(retailerList))

        //         // retailerList = retailerList.map(item =>
        //         //      item.id === itemName ? { ...item, isChecked: false } : item
        //         //  );
        //        // console.log("itemName==>"+itemName)
        //          retailerList = retailerList.map(item =>
        //             //item.id === itemName ? console.log("is checked") : console.log("is unched")
        //             //console.log("--item-->"+item.id+"----itemname-->"+itemName)
        //             item.id === itemName ? {  isChecked: true } : item
        //         );

        //         orgretailerList = orgretailerList.map(item =>
        //              item.id === itemName ? { ...item, isChecked: checked } : item
        //          );
        //             console.log("retailerList--"+JSON.stringify(retailerList))
        //        //allChecked = retailerList.every(item => item.isChecked);
        //     }
        //     return { retailerList, allChecked, orgretailerList };
        // });
    };

    handleNCErpCodeChange = (event) => {
        this.setState({
            erpCode: event.value
        });
    }

    handleNCRetailerTypeChange = (event) => {
        this.setState({
            retailerTypeCode: event.value
        });
    }

    handleNCRouteChange = (event) => {
        this.setState({
            ncRouteValue: event.value,
            stateName: event.stName,
            cityName: event.cName,
        });
    }

    // handleNCDistributorChange = (event) => {
    //     this.setState({
    //         distributorCode: event.target.value
    //     });
    // }

    ncCheckBoxChange = (e) => {
        if (e.target.name === 'ncCustomer') {
            if (e.target.checked) {
                this.setState({
                    ncCustomerChecked: true
                });
            } else {
                this.setState({
                    ncCustomerChecked: false
                });
            }
        }
        if (e.target.name === 'ncVendor') {
            if (e.target.checked) {
                this.setState({
                    ncVendorChecked: true
                });
            } else {
                this.setState({
                    ncVendorChecked: false
                });
            }
        }
    }

    handleNCDistributorChange = (event) => {
        this.setState({
            ncDistributorCode: event.value
        });

        // this.state.distributorList.splice(event.index);
        var dList = this.state.distributorList;
        dList.map(function (nc, index) {
            if (nc.distributorCode === event.value) {
                //dList.splice(index, 1);
                nc.showValue = false;
            } else {
                nc.showValue = true;
            }
        });
    }

    clearNCDetail() {
        this.setState({
            ncId: '',
            ncName: '',
            ncCompanyName: '',
            ncDistributorCode: "",
            ncRetailerCode: '',
            ncRefNum: '',
            ncAddressLine1: "",
            ncAddressLine2: '',
            ncAddressLine3: '',
            ncPinCode: '',
            ncLandMark: '',
            ncLatitude: '',
            ncLongtitude: '',
            ncContactName: '',
            ncContactPhone: '',
            ncContactEmail: '',
            ncPendingAmount: '',
            ncCreditLimit: '',
            ncCreditDays: '',
            ncPINNumber: '',
            ncRouteValue: '',
            stateName: '',
            cityName: '',
            ncCustomerChecked: false,
            ncVendorChecked: false,
            ncGstinnum: '',
            ncPanNum: '',
            ncAddressLine4: '',
            ncCity: '',
            ncState: '',
            ncCountry: '',
            ncConfirmation: 'Y',
            ncStatus: 'Y',
            erpCode: '',
            retailerTypeCode: '',
            routeCode: [],
            ncRouteValue: '',
        })
    }

    addNewRetailerDistributor_popup_openHandler = () => {
        var emptyobj = {
            retailerCode: 0,
            distributorcode: 0,
            productGroupid: 0,
            distributorCategory: "Primary"
        }
        let emptylist = this.state.retailerdistributorList
        emptylist.push(emptyobj)
        this.setState({
            retailerdistributorList: emptylist
        })
    }

    retailerDistributor_GridDelete = (ind) => {

        var retailerdistributorList = [...this.state.retailerdistributorList];
        if (retailerdistributorList.length === 1) {
            alert("Can't Delete All Rows");
        } else {
            retailerdistributorList.splice(ind, 1)
            this.setState({ retailerdistributorList: retailerdistributorList }, () => {
                // console.log(this.state.retailerdistributorList);
            });
        }
    }

    handleretailerDistChange = (ind, event) => {
        // console.log("ind-->"+ind)
        // console.log("event-->"+event.value)
        const { retailerdistributorList } = this.state;
        retailerdistributorList[ind].distributorcode = event.value
        retailerdistributorList[ind].distributorName = event.label
        this.setState({ retailerdistributorList: retailerdistributorList }, () => {
            // console.log(this.state.retailerdistributorList);
            ReactTooltip.rebuild();
        });

        if (event.value !== undefined) {
            this.setState({ retailerdistributorList: retailerdistributorList }, () => {
                ReactTooltip.rebuild();
                this.getProductTypeByDistributor(ind, event);
            });
        }
    }

    getProductTypeByDistributor = (ind, event) => {
        const params = {
            companyCode: this.state.loginuserObj.companyCode,
            distributorCode: event.value,
            salesmanCode: this.state.loginuserObj.salesmanCode,
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/newcustomer/getProductTypeList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    });
                    const { retailerdistributorList } = this.state;
                    if (data.productTypeListDistributorWise.length > 0) {
                        // retailerdistributorList[ind].productTypeList = data.productTypeListDistributorWise;  
                        retailerdistributorList[ind].productTypeCode = data.productTypeListDistributorWise[0].value;
                        //  retailerdistributorList[ind].isPTYpeSelectDisabled = true ;
                    }

                    this.setState({ retailerdistributorList: retailerdistributorList });
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }


    handleproductGroupChange = (ind, event) => {
        // console.log("ind-->"+ind)
        // console.log("event-->"+event.value)
        const { retailerdistributorList } = this.state;
        retailerdistributorList[ind].productGroupid = event.value
        retailerdistributorList[ind].productGroupName = event.label
        this.setState({ retailerdistributorList: retailerdistributorList }, () => {
            // console.log(this.state.retailerdistributorList);
            ReactTooltip.rebuild();
        });
    }

    handleproductTypeChange = (ind, event) => {
        // console.log("ind-->"+ind)
      //  console.log("event-->" + event.value)
      //  console.log("event-->" + event.label)
        const { retailerdistributorList } = this.state;
        retailerdistributorList[ind].productTypeCode = event.value;
        retailerdistributorList[ind].productTypeName = event.label;
        this.setState({ retailerdistributorList: retailerdistributorList }, () => {
            // console.log(this.state.retailerdistributorList);
            ReactTooltip.rebuild();
        });
    }

    handledistributorCategoryChange = (ind, event) => {
        // console.log("ind-->"+ind)
        const { retailerdistributorList } = this.state;
        retailerdistributorList[ind].distributorCategory = event.target.value
        this.setState({ retailerdistributorList: retailerdistributorList }, () => {
            // console.log(this.state.retailerdistributorList);
        });
    }

    gridFilterList = (event) => {
        let filtered = this.state.orgretailerList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            retailerList: filtered
        })
    }

    handleNCFStateChange = (event) => {
        this.setState({
            ncFState: event.value,
            ncFCity: '',
            ncFRoute: '',
            ncFCityList: [],
            ncFRouteList: [],
            retailerList: [],
            orgretailerList: [],
        }, () => {
            if (this.state.ncFState !== "" && this.state.ncFState !== null && this.state.ncFState !== undefined) {
                this.getCityList();
                // this.state.ncFCity = '';
                // this.state.ncFRoute = '';
                // this.state.ncFCityList = [];
                // this.state.ncFRouteList = []; 
            }
            // else if(this.state.ncFState === "" || this.state.ncFState === null || this.state.ncFState === undefined){
            //     this.state.ncFCity = '';
            //     this.state.ncFRoute = '';
            //     this.state.ncFCityList = [];
            //     this.state.ncFRouteList = [];
            // }                                
        })
    }

    handleNCFCityChange = (event) => {
        this.setState({
            ncFCity: event.value,
            ncFRoute: '',
            ncFRouteList: [],
            retailerList: [],
            orgretailerList: [],
        }, () => {
            if (this.state.ncFCity !== "" && this.state.ncFCity !== null && this.state.ncFCity !== undefined) {
                this.getRouteList();
                // this.state.ncFRoute = '';                
                // this.state.ncFRouteList = [];
            }
            // else if(this.state.ncFCity === "" || this.state.ncFCity === null || this.state.ncFCity === undefined){                
            //     this.state.ncFRoute = '';                
            //     this.state.ncFRouteList = [];
            // }                                
        })
    }

    handleNCFRouteChange = (event) => {
        if (event.value !== undefined) {
            this.setState({
                ncFRoute: event.value,
                retailerList: [],
                orgretailerList: [],
            })
        } else {
            this.setState({
                ncFRoute: ''
            })
        }

    }

    getNCFRetailer = () => {
       // console.log("city =>"+this.state.ncFCity)
      //  console.log("ncFRoute =>"+this.state.ncFRoute)
        if (this.state.ncFState === "" || this.state.ncFState === null || this.state.ncFState === undefined) {
            alert("Please Select State");
        }
        // else if(this.state.ncFCity === "" || this.state.ncFCity === null || this.state.ncFCity === undefined){
        //     alert("Please Select City");
        // } 
        // else if( (this.state.searchReatailerCode === "" || this.state.searchReatailerCode === null || this.state.searchReatailerCode === undefined ) && (this.state.searchReatailerName === "" || this.state.searchReatailerName === null || this.state.searchReatailerName === undefined)){
        //     alert("Please Enter Retailer Code or Name");
        // } 
        else if (this.state.ncFCity === "" || this.state.ncFCity === null || this.state.ncFCity === undefined ) {
            alert("Please Select City");
        } else if (this.state.ncFRoute === "" || this.state.ncFRoute === null || this.state.ncFRoute === undefined ) {
            alert("Please Select Route");
        }else {
            this.loadRet();
        }
    }

    // retailerExcel = () =>{
    //     var dFlag = true;
    //     if(this.state.retailerList.length === 0){
    //         alert("No Records");
    //         dFlag = false;
    //     } 
    //     if (dFlag) {
    //         var arr = {};
    //         var arrayList = [];
    //         var dList = this.state.retailerList;
    //         var fileName = "Retailer Report_"+this.state.currentDate;
    //         var wscols = [
    //             {wch:16},
    //             {wch:35},
    //             {wch:12},
    //             {wch:12},
    //             {wch:12},
    //             {wch:10}
    //         ];
    //         for(var i=0;i<dList.length;i++){
    //             arr = {};
    //             arr = {
    //                 "Retailer Number": dList[i].retailerCode,                    
    //                 "Retailer": dList[i].name,
    //                 "Route": dList[i].routeName,
    //                 "City": dList[i].city,
    //                 "Retailer Type": dList[i].retailerType,
    //                 "Date Time": dList[i].status                                       
    //             }
    //             arrayList.push(arr);
    //         }
    //         const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //         const fileExtension = '.xlsx';
    //         const ws = XLSX.utils.json_to_sheet(arrayList);
    //         ws['!cols'] = wscols;
    //         const wb = { Sheets: { 'Retailer': ws }, SheetNames: ['Retailer'] };
    //         const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //         const data = new Blob([excelBuffer], {type: fileType});
    //         fileSaver.saveAs(data, fileName + fileExtension);
    //     }        
    // }

    retailerExcel = () => {
        const params = {
            companyCode: this.state.loginuserObj.companyCode,
            state: this.state.ncFState,
            city: this.state.ncFCity,
            route: this.state.ncFRoute,
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/getRetailerReportList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.genarateXL(data.retailerReportList);
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    genarateXL = (retailerReportList) => {
        var dFlag = true;
        if (retailerReportList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = retailerReportList;
            var fileName = "Retailer Report_" + this.state.currentDate;
            var wscols = [
                { wch: 16 },
                { wch: 35 },
                { wch: 10 },
                { wch: 35 },
                { wch: 10 },
                { wch: 10 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 35 },
                { wch: 20 },
                { wch: 20 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Retailer Number": dList[i].retailerCode,
                    "Retailer": dList[i].name,
                    "Retailer Type": dList[i].retailerType,
                    "Distributor Name": dList[i].distributorName,
                    "Distributor Category": dList[i].distributorCategory,
                    "Product Type": dList[i].productType,
                    "Route": dList[i].routeName,
                    "City": dList[i].city,
                    "State": dList[i].state,
                    "Contact Phone": dList[i].contactPhone,
                    "Contact Email": dList[i].contactEmail,
                    "Serviced Date": dList[i].lastServicedDate,
                    "Updated Date": dList[i].lastUpdatedDate
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'Retailer': ws }, SheetNames: ['Retailer'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    routeExcel = () => {
        if (this.state.ncFState === "" || this.state.ncFState === null || this.state.ncFState === undefined) {
            alert("Please Select State");
        }
        // else if(this.state.ncFCity === "" || this.state.ncFCity === null || this.state.ncFCity === undefined){
        //     alert("Please Select City");
        // } 
        else {
            const params = {
                companyCode: this.state.loginuserObj.companyCode,
                state: this.state.ncFState,
                city: this.state.ncFCity
            }

            this.setState({
                loading: true
            })
            axios.get(this.state.httpURL + "/newcustomer/getRetailerRouteList", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.genarateRouteXL(data.retailerRouteList);
                    } else {
                        alert(data.responseMsg);
                    }
                    this.setState({
                        loading: false
                    })
                }).catch(err => {
                    //console.log(err)                
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    genarateRouteXL = (retailerRouteList) => {
        var dFlag = true;
        if (retailerRouteList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = retailerRouteList;
            var fileName = "Retailer Route Report_" + this.state.currentDate;
            var wscols = [
                { wch: 16 },
                { wch: 35 },
                { wch: 10 },
                { wch: 35 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 35 },
                { wch: 20 },
                { wch: 20 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Retailer Number": dList[i].retailerCode,
                    "Retailer": dList[i].name,
                    "Retailer Type": dList[i].retailerType,
                    "Route Name": dList[i].routeName,
                    "State": dList[i].state,
                    "City": dList[i].city,
                    "Contact Phone": dList[i].contactPhone,
                    "Contact Email": dList[i].contactEmail,
                    "Serviced Date": dList[i].lastServicedDate,
                    "Updated Date": dList[i].lastUpdatedDate
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'RetailerRoute': ws }, SheetNames: ['RetailerRoute'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    distributorExcel = () => {
        if (this.state.ncFState === "" || this.state.ncFState === null || this.state.ncFState === undefined) {
            alert("Please Select State");
        }
        // else if(this.state.ncFCity === "" || this.state.ncFCity === null || this.state.ncFCity === undefined){
        //     alert("Please Select City");
        // } 
        else {
            const params = {
                companyCode: this.state.loginuserObj.companyCode,
                state: this.state.ncFState,
                city: this.state.ncFCity
            }

            this.setState({
                loading: true
            })
            axios.get(this.state.httpURL + "/newcustomer/getRetailerDistributorList", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.genarateDistributorXL(data.retailerDistributorReportList);
                    } else {
                        alert(data.responseMsg);
                    }
                    this.setState({
                        loading: false
                    })
                }).catch(err => {
                    //console.log(err)                
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    genarateDistributorXL = (retailerDistributorReportList) => {
        var dFlag = true;
        if (retailerDistributorReportList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = retailerDistributorReportList;
            var fileName = "Retailer Distributor Report_" + this.state.currentDate;
            var wscols = [
                { wch: 16 },
                { wch: 35 },
                { wch: 10 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 35 },
                { wch: 20 },
                { wch: 20 },
                { wch: 35 },
                { wch: 20 },
                { wch: 20 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Retailer Number": dList[i].retailerCode,
                    "Retailer": dList[i].name,
                    "Retailer Type": dList[i].retailerType,
                    "State": dList[i].state,
                    "City": dList[i].city,
                    "Contact Phone": dList[i].contactPhone,
                    "Contact Email": dList[i].contactEmail,
                    "Product Type": dList[i].productType,
                    "Distributor Category": dList[i].distributorCategory,
                    "Distributor Name": dList[i].distributorName,
                    "Serviced Date": dList[i].lastServicedDate,
                    "Updated Date": dList[i].lastUpdatedDate
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'RetailerDistributor': ws }, SheetNames: ['RetailerDistributor'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    priceExcel = () => {
        if (this.state.ncFState === "" || this.state.ncFState === null || this.state.ncFState === undefined) {
            alert("Please Select State");
        }
        // else if(this.state.ncFCity === "" || this.state.ncFCity === null || this.state.ncFCity === undefined){
        //     alert("Please Select City");
        // } 
        else {
            const params = {
                companyCode: this.state.loginuserObj.companyCode,
                state: this.state.ncFState,
                city: this.state.ncFCity
            }

            this.setState({
                loading: true
            })
            axios.get(this.state.httpURL + "/newcustomer/getRetailerPriceList", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.genaratePirceXL(data.retailerPriceList);
                    } else {
                        alert(data.responseMsg);
                    }
                    this.setState({
                        loading: false
                    })
                }).catch(err => {
                    //console.log(err)                
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    genaratePirceXL = (retailerPriceList) => {
        var dFlag = true;
        if (retailerPriceList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = retailerPriceList;
            var fileName = "Retailer Price Report_" + this.state.currentDate;
            var wscols = [
                { wch: 16 },
                { wch: 35 },
                { wch: 10 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 35 },
                { wch: 20 },
                { wch: 35 },
                { wch: 20 },
                { wch: 20 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Retailer Number": dList[i].retailerCode,
                    "Retailer": dList[i].name,
                    "Retailer Type": dList[i].retailerType,
                    "State": dList[i].state,
                    "City": dList[i].city,
                    "Contact Phone": dList[i].contactPhone,
                    "Contact Email": dList[i].contactEmail,
                    "Product Type": dList[i].productType,
                    "Price Category": dList[i].priceType,
                    "Serviced Date": dList[i].lastServicedDate,
                    "Updated Date": dList[i].lastUpdatedDate
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'RetailerPrice': ws }, SheetNames: ['RetailerPrice'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }


    setSearchDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleNCStateChange = (event) => {
        this.setState({
            ncState: event.value,
            // ncCity : '',
            routeList: [],
            ncRouteValue: '',
            ncFRoute: '',
            stateName: '',
            cityName: '',
        }, () => {
            if (this.state.ncState !== "" && this.state.ncState !== null && this.state.ncState !== undefined) {
                this.getncCity();
            }
            // else if(this.state.ncFState === "" || this.state.ncFState === null || this.state.ncFState === undefined){
            //     this.state.ncFCity = '';
            //     this.state.ncFRoute = '';
            //     this.state.ncFCityList = [];
            //     this.state.ncFRouteList = [];
            // }                                
        })
    }
    getncCity() {

        const params = {
            state: this.state.ncState
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/getIndianCityList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        ncCityList: data.indianCitiesList
                    })
                    this.getncState();
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })

    }
    handleNCityChange = (event) => {
        this.setState({
            ncCity: event.value,
            // ncCity : '',
            ncRouteValue: '',
            stateName: '',
            cityName: '',
        }, () => {
            if (this.state.ncCity !== "" && this.state.ncCity !== null && this.state.ncCity !== undefined) {
                this.getncState();
            }
        })
    }

    getncState() {

        const params = {
            companyCode: this.props.userObj.companyCode,
            state: this.state.ncState,
            city: this.state.ncCity,
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/newcustomer/getCityState_RouteFilter", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        routeList: data.routeList,
                    });
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
            }).catch(err => {
                //console.log(err)                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

 
    handleteamChange = (event,ind) => {
        // console.log("ind-->"+ind)
        const { retailerList } = this.state;
        retailerList[ind].newteamTag = event.target.value
        this.setState({ retailerList: retailerList }, () => {
            // console.log(this.state.retailerdistributorList);
        });

    }

    saveRetailerTeam =()=>{
        let params = {"retailerList": this.state.retailerList,"lastUpdtedBy":this.state.loginuserObj.userName};
      //  console.log("save obj-->"+JSON.stringify(params))
        
        this.setState({
            loading: true
        })
        axios.post(this.state.httpURL + "/newcustomer/saveretailerTeamAllocation", params)
            .then(response => {
                if (response.data !== null) {
                    if (response.data.valid) {
                        this.setState({
                            loading: false
                        })
                        alert("Retailer Team Allocation Saved Successfully.");
                        this.setState({
                            retailerList: [],
                            orgretailerList: [],
                            ncFCity:'',
                            ncFRoute:''

                        });
                    } else {
                        this.setState({
                            loading: false
                        });
                        alert(response.data.responseMsg);
                    }
                }
            })
            .catch(error => {
                //console.log("error" + error)                    
                this.setState({
                    loading: false
                })
                alert(error);
            })

    }


    render() {

        const { ncName, erpCode, ncCompanyName, ncRetailerCode, ncRefNum, ncAddressLine1, ncAddressLine2, ncAddressLine3, ncOldRetailerCode, ncPinCode, ncLandMark, ncLatitude, ncLongtitude, ncContactName, ncContactPhone, ncContactEmail, ncPendingAmount, ncCreditLimit, ncCreditDays, ncPINNumber, ncState, ncAddressLine4, ncCity, ncCountry, ncGstinnum, ncPanNum, retailerTypeCode } = this.state
        const userTeamArr = [];
        const routeCode = [];

        for (const [index, value] of this.state.routeList.entries()) {
            routeCode.push({
                label: value.routeName,
                value: value.routeCode,
                stName: value.state,
                cName: value.city
            });
        }

       

        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>Retailer Team Allocation</span></div>
                    <div className="O2Table_Body">
                        {/* <div className="O2Table_SubContainer">
                          <div className="O2Table_SelectSales">
                                <div className="O2Table_Label"> Salesman</div>
                                <div className="O2Table_Value">
                                    <select value={this.state.salesmanCode} onChange={this.handleSalesmanChange} readOnly>
                                                <option value="All" key="All">All</option>
                                                {
                                                    this.state.salesmanList.map((salesman) => (
                                                <option value={salesman.salesmanCode} key={salesman.salesmanCode}>{salesman.salesmanName}</option>

                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                       */}
                        {/* <div className="O2Table_Search">
                                <div className="O2Table_Label"> Search : </div>
                                <div className="O2Table_Value"><input type="text" onChange={this.gridFilterList} /></div>
                            </div> 
                            <div className="O2Table_New">
                                <button onClick={this.newCust_newpopup_openHandler}>GO</button>
                            </div>
                        </div>*/}
                        <div className="O2Table_SubContainer Report_subContainer">
                            <div className="O2Content_row">
                                <div className="contentRow_Label mandatoryClass">State <span>*</span></div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.ncFStateList} name="ncFState" value={this.state.ncFStateList.filter(({ value }) => value === this.state.ncFState)} placeholder="Choose State" onChange={this.handleNCFStateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label mandatoryClass">City <span>*</span></div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.ncFCityList} name="ncFCity" value={this.state.ncFCityList.filter(({ value }) => value === this.state.ncFCity)} placeholder="Choose City" onChange={this.handleNCFCityChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label mandatoryClass">Route <span>*</span></div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.ncFRouteList} name="ncFRoute" value={this.state.ncFRouteList.filter(({ value }) => value === this.state.ncFRoute)} placeholder="Choose Route" onChange={this.handleNCFRouteChange} />
                                </div>
                            </div>
                            <div className="O2Table_Go"><button onClick={this.getNCFRetailer}>Go</button></div>


                        </div>
            
                        <div className="Table_wrapper">
                            <table className="customTable" id="NewCustomer_table">
                                <thead>
                                    <tr>
                                        {/* <th className="checkBox_Container">
                                            <input type="checkbox" name="checkAll" checked={this.state.allChecked} onChange={this.handleAllChange} />
                                        </th> */}
                                        <th className="Ref_Num1">Retailer Code
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerCode" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="Descript_width1">Retailer
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerName" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="Ref_Num1">Route
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="routeName" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="Ref_Num1">City
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="city" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="Ref_Num1">Retailer Team
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="teamTag" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="Ref_Num1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Action</th>
                                        {/* <th className="retailer_Edit DistAd_width">Retailer Group
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="routeName" onChange={this.gridFilterList} />
                                            </div>
                                        </th> */}
                                        {/* <th>Distributor</th> */}
                                        {/* <th>Salesman</th>
                                        <th>Description</th> */}
                                        {/* <th className="status_Width">Status
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="status" onChange={this.gridFilterList} />
                                            </div>
                                        </th> */}
                                        {/* <th className="o2Del_container">
                                            <button onClick={this.newCust_newpopup_openHandler}>New</button>
                                        </th> */}
                                        <th className="scroller"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.retailerList || this.state.retailerList === null || this.state.retailerList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="5">No Retailer Available</td>
                                            </tr> :
                                            this.state.retailerList.map((retailer, i) => (
                                                <tr key={retailer.id}>
                                                    {/* <td className="checkBox_Container">
                                                        <input type="checkbox" checked={retailer.isChecked} onChange={(e) => this.handleChange(e, i)} />
                                                    </td> */}
                                                    <td className="Ref_Num1" data-tip={retailer.retailerCode}>{retailer.retailerCode}</td>
                                                    <td className="Descript_width1" data-tip={retailer.name}>{retailer.name}
                                                        {/* <button className="editBtn" onClick={() => this.newCust_popup_openHandler(retailer)}><FontAwesomeIcon icon={faPencilAlt} style={{ padding: '0px 8px' }} /></button>
                                                        <button className="deleteBtn" onClick={() => this.newCust_delete_funs(retailer)}><FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 8px' }} /></button> */}
                                                    </td>
                                                    <td className="Ref_Num1" data-tip={retailer.routeName}>{retailer.routeName}
                                                    </td>
                                                    <td className="Ref_Num1" data-tip={retailer.city}>{retailer.city}
                                                    </td>
                                                    {/* <td>{retailer.distributorName}</td> */}
                                                    {/* <td>{retailer.displayName}</td>
                                                    <td>{retailer.description}</td> */}
                                                    <td className="DistAd_width" data-tip={retailer.teamTag}>{retailer.teamTag}</td>
                                                    {/* <td className="DistAd_width" data-tip={retailer.status}>{retailer.status}</td> */}
                                                    {/* <td className="status_Width" data-tip={retailer.status}>{retailer.status}</td> */}
                                                    <td className="Ref_Num1">
                                                         <select value={retailer.newteamTag} onChange={(e) => this.handleteamChange(e, i)}>
                                                            {
                                                                this.state.teamList.map((team, i) => (
                                                                    <option value={team.newteamTag} >{team.teamName} </option>
                                                                ))
                                                            }
                                                            
                                                         </select>
                                                    </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.retailerList ? this.state.retailerList.length : 0}</span>entries</span></div>
                            <div className="O2Table_Go"><button onClick={this.saveRetailerTeam}>Save</button></div>
                            {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                            <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> <ReactHTMLTableToExcel className="download-table-xls-button" table="NewCustomer_table" filename={"New Customer"} */}
                            {/* sheet="tablexls" buttonText="Excel Export"/></div> */}
                            {/* <div className="O2Table_ExpExcel" onClick={ ()=> this.retailerExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div> */}
                            
                        </div>
                    </div>
                </div>
               
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
               
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL,
        httpPostalURL: state.httpPostalURL
    }
}

export default connect(mapStateToProps)(RetailerTeamAllocation)


//export default NewCustomer
