import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faPlus, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
import './ExpensesViewModule.css';
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;


export class ExpensesViewModule extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var currentdate = curr.toISOString().substr(0, 10);
        
        this.state = {
            loading: false,            
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            evExpensesList:[],
            evOrgExpensesList:[],            
            userList:[],
            retailerList:[],  
            todayDate:currentdate,            
            evStartDate: currentdate,
            evEndDate: currentdate,
            evImageList: [],                 
        }
    }

    componentDidMount() {
        this.getEVUserList();        
    }

    getEVUserList() {
        const params={
            companyCode:this.state.loginuserObj.companyCode,
            userId:this.state.loginuserObj.salesmanCode
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/expensesview/getBaUserList",{params})
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({                        
                        userList: data.userList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getExpensesList = () => {
        var dFlag = true;
        //console.log("<-1->"+this.state.loginuserObj.companyCode);
        if (this.state.evStartDate !== '' && this.state.evEndDate !== '') {
            if (this.state.evStartDate > this.state.evEndDate) {
                alert("From Date Cannot Be Greater Than End Date");
                dFlag = false;
            }
        } else {
            alert("Please select Start and End Date...");
            dFlag = false;
        }  
        if(this.state.evUserRole === "" || this.state.evUserRole === null || this.state.evUserRole === undefined){
            alert("Please select User");
            dFlag = false;
        }
        if (dFlag) {
            const params={           
                userRoleId:this.state.evUserRole,
                startDate: this.state.evStartDate,
                endDate: this.state.evEndDate,
            }
            this.setState({
                loading: true
            })
            axios.get(this.state.httpURL + "/expensesview/getExpensesList",{params})
                .then(response => response.data)
                .then((data) => {
                    this.setState({
                        loading: false
                    })
                    if (data.valid) {
                        this.setState({
                            evExpensesList: data.expensesList,
                            evOrgExpensesList: data.expensesList                                                                     
                        })
                        ReactTooltip.rebuild();
                    } else {
                        alert(data.responseMsg);
                    }
                }).catch(err => {                
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }        
    }

    getEVExpensesDetailList = (expenses) => {
        document.querySelector('.expenseViewTableEdit_pop').style.display = 'flex';
        const params={           
            id:expenses.id
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/expensesview/getEVExpensesDetailList",{params})
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({
                        evImageList: data.expensesDetailList                                                                                           
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {                
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }


    handleEVRoleChange = (event) => {        
        this.setState({                    
            evUserRole:event.value,
            evUserRoleName:event.label
        },()=>{   
                                            
        })
    }

    // vp_cancelfunction = (e) => {
    //     if(this.state.vpUserRole !== "" && this.state.vpUserRole !== null && this.state.vpUserRole !== undefined){
    //         this.getBaUserImageList();
    //     }
    // }

    // Collection_popup_openHandler = (user) => {
    //     document.querySelector('.collectionTableEdit_pop').style.display = 'flex';
    //     var image = "http://139.59.8.226/"+user.image;  
    //     var editFlag = true;    
    //     if(user.expenseApprover === 'Y'){
    //         editFlag = false;
    //     } 
    //     this.setState({
    //         vpCommands: user.commands,
    //         vpApproval: user.supervisorApproval,
    //         vpImage: image,
    //         vpEditFlag: editFlag,
    //         vpDate: user.date,
    //         vpUserId: user.id
    //     })
    // }

    // vpEditChange = (e) => {
    //     if (e.target.name === 'vpCommands') {
    //         this.setState({
    //             vpCommands: e.target.value                
    //         })
    //     } else if (e.target.name === 'vpApproval'){
    //         this.setState({                
    //             vpApproval: e.target.value
    //         })
    //     }        
    // }

    evDateChange = (e) => {
        // console.log(e.target.value);
         this.setState({
             [e.target.name]: e.target.value
         });
     }

    expenses_popCloseHandler = () => {
        document.querySelector('.expenseViewTableEdit_pop').style.display = 'none';
    }

    filterList = (event) => {
        let filtered =this.state.evOrgExpensesList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
                
            }
        }
        this.setState({
            evExpensesList: filtered
        })
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>Expenses View</span></div>
                    <div className="O2Table_SubContainer Report_subContainer">
                                <div className="O2Content_row">
                                    <div className="contentRow_Label">User Name</div>
                                    <div className="contentRow_Value">
                                        <Select options={this.state.userList} name="evUser" value={this.state.userList.filter(({ value }) => value === this.state.evUserRole)} placeholder="Choose User" onChange={this.handleEVRoleChange}/> 
                                    </div>
                                </div>
                                <div className="O2Content_row">
                                <div className="contentRow_Label">Date From </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="evStartDate" value={this.state.evStartDate} onChange={this.evDateChange} />                                   
                                </div>
                        </div> 
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Date To </div>
                            <div className="contentRow_Value">
                                <input type="date" name="evEndDate" value={this.state.evEndDate} onChange={this.evDateChange} />                                  
                            </div>
                        </div>
                        <div className="O2Table_Go" onClick={this.getExpensesList}><button>Go</button></div>
                            </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="metaData_Table">
                            <thead>
                                <tr>
                                    <th>Date </th>                                    
                                    <th>Expense Type
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="expenseType" onChange={this.filterList} />
                                        </div>
                                    </th>                                    
                                    <th>Amount
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="amount" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th>User Comments
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="userComments" onChange={this.filterList} />
                                        </div>
                                    </th> 
                                    <th>Approvel Flag
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="approvelStr" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th>Approver Comments
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="approverComments" onChange={this.filterList} />
                                        </div>
                                    </th>      
                                    <th className="o2Del_container"></th>                            
                                    <th className="scroller"></th>                                                                        
                                </tr>
                            </thead>
                            <tbody>                                                      
                                {
                                    !this.state.evExpensesList || this.state.evExpensesList === null || this.state.evExpensesList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No Expenses Available</td>
                                        </tr> :
                                        this.state.evExpensesList.map((expenses,i) => (                                                                                         
                                            <tr key={i}>                                                                                                
                                    <td>
                                        <input type="date" value={expenses.strDate} readOnly={true}/>
                                    </td>
                                    <td>
                                        <input type="text" value={expenses.expenseType}/>
                                    </td>
                                    <td>
                                        <input type="text" value={expenses.amount}/>
                                    </td>
                                    <td>
                                        <input type="text" value={expenses.userComments}/>
                                    </td>
                                    <td>
                                        <input type="text" value={expenses.approvelStr}/>
                                    </td>
                                    <td>
                                        <input type="text" value={expenses.approverComments}/>
                                    </td>
                                    <td className="o2Del_container" onClick={() => this.getEVExpensesDetailList(expenses)}>
                                       <a>View Image</a>
                                    </td>
                                            </tr>
                                            
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.evExpensesList ? this.state.evExpensesList.length : 0}</span>entries</span></div>                        
                    </div>                                        
                </div>

                <div className="O2TableEdit_wrapper expenseViewTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">User Name : <span>{this.state.vpUserRoleName}</span>                                
                            </div>
                            <div className="O2TableEditBody">
                            <div className="tableContainer">
                                <div className="Table_wrapper">
                                    <table className="customTable">
                                        <thead>
                                            <tr>
                                                <th>View Image </th>                                                              
                                                <th className="scroller"></th>                                                                        
                                            </tr>
                                        </thead>
                                        <tbody>                                                      
                                            <tr>                                                                                                
                                                <td>
                                                    Image.jpg
                                                </td>
                                            </tr>
                                                
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Commands : </div>
                                        <div className="FieldValue VPValue"><textarea name="vpCommands" value={this.state.vpCommands} readOnly={this.state.vpEditFlag} onChange={this.vpEditChange} /></div>
                                    </div>
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Approval : </div>
                                        <div className="FieldValue">
                                            <select name="vpApproval" value={this.state.vpApproval} disabled={this.state.vpEditFlag} onChange={this.vpEditChange}>
                                                <option value="Y" >Yes</option>
                                                <option value="N" >No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {
                                    
                                    this.state.evImageList.map((expenses,i) => (
                                        <div className="row">
                                    <div className="FieldComponent">
                                        <div className="imgPos">                                    
                                            <img src={"http://139.59.8.226/"+expenses.imagePath}></img>
                                        </div>
                                        </div>
                                </div>
                                    ))
                                }
                                {/* {
                                    
                                    this.state.evImageList.map((expenses,i) => (
                                        <div className="row">
                                    <div className="FieldComponent">
                                        <div className="imgPos">                                    
                                            <img src={"http://139.59.8.226/"+expenses.imagePath}></img>
                                        </div>
                                        </div>
                                </div>
                                    ))
                                } */}
                                
                           
                                
                                {/* <div className="row">
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Commands : </div>
                                        <div className="FieldValue VPValue"><textarea name="vpCommands" value={this.state.vpCommands} readOnly={this.state.vpEditFlag} onChange={this.vpEditChange} /></div>
                                    </div>
                                    <div className="FieldComponent">
                                        <div className="FieldLabel">Approval : </div>
                                        <div className="FieldValue">
                                            <select name="vpApproval" value={this.state.vpApproval} disabled={this.state.vpEditFlag} onChange={this.vpEditChange}>
                                                <option value="Y" >Yes</option>
                                                <option value="N" >No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>                                                                 */}
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                <button onClick={this.vpSave}>Save</button>                                   
                                    <button onClick={this.expenses_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>                
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(ExpensesViewModule)
//export default ViewPhotoModule
