import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from 'react-select';
import './SalesReportOnDemand.css';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
class ReportsModule extends Component {
    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            salesmanList: [],
            selectedsalesmanList: [],
            reportList: [],
            orgreportList: [],
            startDate: todaydate,
            endDate: todaydate,
            todaydate: todaydate,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            userType: this.props.userObj.userType,
            loginSalesmanCode: this.props.userObj.salesmanCode,
            dsrState: '',
            dsrStateList: [],
            dsrOrderReportList: [],
            dsrBAOrderReportList: [],
            orgdsrOrderReportList: [],
            orgdsrBAOrderReportList: [],
            salesReportFlag: true,
            baSalesReportFlag: false,
        }
    }

    componentDidMount() {
        this.getAllSalesmans();
    }

    getAllSalesmans() {

        let scode = this.state.salesmanCode;
        if (this.state.loginuserObj.userType === "salesman") {
            scode = scode;
        } else {
            scode = scode;
        }

        const params = {
            salesmanCode: scode,
            companyCode: this.state.companyCode,
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/report/getSalesmanList", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                this.setState({
                    salesmanList: data.salesmansList,
                    dsrStateList: data.stateList,
                }, () => {

                    if (data.salesmansList.length === 1) {
                        var temparry = []
                        temparry.push({
                            label: data.salesmansList[0].label,
                            value: data.salesmansList[0].value
                        });

                        this.setState({
                            selectedsalesmanList: temparry
                        })
                    }

                })

            }).catch(err => {
                this.setState({
                    loading: false
                })
                alert(err);
            })
    }

    reportGo = () => {
        var dFlag = true;
        let stateVal = '';
        if (this.state.startDate !== '' && this.state.endDate !== '') {
            if (this.state.startDate > this.state.endDate) {
                alert("From Date Cannot Be Greater Than End Date");
                dFlag = false;
            }
            // const diffInMs = Math.abs(new Date(this.state.endDate) - new Date(this.state.startDate));
            // let dateCount = diffInMs / (1000 * 60 * 60 * 24);
            // if (dateCount > 7) {
            //     alert("");
            //     dFlag = false;
            // }
        } else {
            alert("Please select From and To Date...");
            dFlag = false;
        }

        if (this.state.dsrState === '' || this.state.dsrState === undefined) {
            stateVal = "All";
        } else {
            stateVal = this.state.dsrState;
        }
        if (dFlag) {
            const params = {
                state: stateVal,
                fromDate: this.state.startDate,
                toDate: this.state.endDate,
                salesFlag: this.state.salesReportFlag,
                baSalesFlag: this.state.baSalesReportFlag,
            }

            this.setState({
                loading: true
            })
            axios.get(this.state.httpURL + "/report/getSalesReportOnDemand", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.setState({
                            dsrOrderReportList: data.salesReportList,
                            dsrBAOrderReportList: data.baSalesReportList,
                            orgdsrOrderReportList: data.salesReportList,
                            orgdsrBAOrderReportList: data.baSalesReportList,
                        }, () => {
                            this.setState({
                                loading: false
                            })
                            // this.dsrExcel();
                        })
                        ReactTooltip.rebuild();
                    } else {
                        alert(data.responseMsg);
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    })
                    alert(err);
                })
        }
    }

    reportChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleDSRStateChange = (event) => {
        this.setState({
            dsrState: event.value,
        }, () => {
            this.reportGo();
        });
    }

    salesReportClick = () => {
        this.setState({
            salesReportFlag: true,
            baSalesReportFlag: false,
        }, () => {
            this.reportGo();
        });
    }

    baSalesReportClick = () => {
        this.setState({
            salesReportFlag: false,
            baSalesReportFlag: true,
        }, () => {
            this.reportGo();
        });
    }

    dsrExcel = () => {
        if (this.state.salesReportFlag) {
            var dFlag = true;
            if (this.state.dsrOrderReportList.length === 0 || this.state.dsrOrderReportList == null) {
                alert("No Records Found for Sales Person");
                dFlag = false;
            }
            if (dFlag) {
                var arr = {};
                var arrayList = [];
                var dList = this.state.dsrOrderReportList;
                var fileName = "SALESMANREPORT_" + this.state.todaydate;
                var wscols = [
                    { wch: 30 },
                    { wch: 40 },
                    { wch: 10 },
                    { wch: 10 },
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 15 },
                ];
                for (var i = 0; i < dList.length; i++) {
                    arr = {};
                    arr = {
                        "Date": dList[i].orderdDate,
                        "Salesman Name": dList[i].salesmanName,
                        "Employee Id": dList[i].empId,
                        "Retailer Name": dList[i].retailerName,
                        "Route Name": dList[i].routeName,
                        "City": dList[i].city,
                        "State": dList[i].state,
                        "Order Value": dList[i].orderValue,
                    }
                    arrayList.push(arr);
                }
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(arrayList);
                ws['!cols'] = wscols;
                const wb = { Sheets: { 'Salesman Report': ws }, SheetNames: ['Salesman Report'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                fileSaver.saveAs(data, fileName + fileExtension);
            }
        }
        if (this.state.baSalesReportFlag) {
            var dFlag1 = true;
            if (this.state.dsrBAOrderReportList.length === 0 || this.state.dsrBAOrderReportList == null) {
                alert("No Records Found for BA");
                dFlag1 = false;
            }
            if (dFlag1) {
                var arr = {};
                var arrayList = [];
                var dList = this.state.dsrBAOrderReportList;
                var fileName = "BASALESREPORT_" + this.state.todaydate;
                var wscols = [
                    { wch: 30 },
                    { wch: 40 },
                    { wch: 10 },
                    { wch: 10 },
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 15 },
                ];
                for (var i = 0; i < dList.length; i++) {
                    arr = {};
                    arr = {
                        "Date": dList[i].orderdDate,
                        "State": dList[i].state,
                        "Retailer Name": dList[i].retailerName,
                        "Sales Person": dList[i].salesman,
                        "Employee Id": dList[i].empid,
                        "Total Sales": dList[i].orderValue,
                    }
                    arrayList.push(arr);
                }
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(arrayList);
                ws['!cols'] = wscols;
                const wb = { Sheets: { 'BA Sales Report': ws }, SheetNames: ['BA Sales Report'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                fileSaver.saveAs(data, fileName + fileExtension);
            }
        }
    }

    salesFilterList = (event) => {
        let filtered = this.state.orgdsrOrderReportList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            dsrOrderReportList: filtered
        })
    }

    baSalesFilterList = (event) => {
        let filtered = this.state.orgdsrBAOrderReportList;
        console.log("filtered------------"+JSON.stringify(filtered));
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            dsrBAOrderReportList: filtered
        })
    }

    render() {
        const salesmanSearch = [];

        for (const [index, value] of this.state.salesmanList.entries()) {
            salesmanSearch.push({
                label: value.salesmanName,
                value: value.salesmanCode
            });
        }

        return (

            <div className="O2Container_Wrapper">

                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>Order and BA order sales details</span></div>
                    <div className="O2Table_Body">
                        <div className="O2Table_SubContainer Report_subContainer">

                            <div className="O2Content_row">
                                <div className="contentRow_Label">State :</div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.dsrStateList} name="dsrState" value={this.state.dsrStateList.filter(({ value }) => value === this.state.dsrState)} placeholder="Choose State" onChange={this.handleDSRStateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date From :</div>
                                <div className="contentRow_Value">
                                    <input type="date" name="startDate" value={this.state.startDate} onChange={this.reportChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date To :</div>
                                <div className="contentRow_Value">
                                    <input type="date" name="endDate" value={this.state.endDate} onChange={this.reportChange} />
                                </div>
                            </div>
                            <div className="O2Table_Go" onClick={this.reportGo}><button>Go</button></div>
                        </div>
                        <div className="O2Table_topSubContainer">
                            <div className="O2Table_topButton" onClick={() => this.salesReportClick()}><FontAwesomeIcon icon="fa-solid fa-atom-simple" style={{ padding: '0px 8px' }} />Sales Report</div>
                            <div className="O2Table_topButton" onClick={() => this.baSalesReportClick()}><FontAwesomeIcon icon="fa-solid fa-atom-simple" style={{ padding: '0px 8px' }} />BA Sales Report</div>
                        </div>
                        <div className="Table_wrapper">
                            {this.state.salesReportFlag ? <table className="customTable" id="salesmanDSRReport_Table1">
                                <thead>
                                    <tr>
                                        <th className="OrderDate_width">Order Date
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderdDate" onChange={this.salesFilterList} />
                                            </div>
                                        </th>
                                        <th className="SalesMan_width">Salesman Name
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="salesmanName" onChange={this.salesFilterList} />
                                            </div>
                                        </th>
                                        <th className="OrderDate_width">Employee Id
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="empId" onChange={this.salesFilterList} />
                                            </div>
                                        </th>
                                        <th className="Retailer_Width">Retailer
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerName" onChange={this.salesFilterList} />
                                            </div>
                                        </th>
                                        <th className="OrderDate_width">Route Name
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="routeName" onChange={this.salesFilterList} />
                                            </div>
                                        </th>
                                        <th className="OrderDate_width">City
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="city" onChange={this.salesFilterList} />
                                            </div>
                                        </th>
                                        <th className="OrderDate_width">State
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="state" onChange={this.salesFilterList} />
                                            </div>
                                        </th>
                                        <th className="OrderValue_width">Order Value
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderValue" onChange={this.salesFilterList} />
                                            </div>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        !this.state.dsrOrderReportList || this.state.dsrOrderReportList === null || this.state.dsrOrderReportList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="6">No Records</td>
                                            </tr> :
                                            this.state.dsrOrderReportList.map((report) => (
                                                <tr>
                                                    <td className="OrderDate_width" data-tip={report.orderdDate}>{report.orderdDate}</td>
                                                    <td className="SalesMan_width" data-tip={report.salesmanName}>{report.salesmanName}</td>
                                                    <td className="OrderDate_width" data-tip={report.empId}>{report.empId}</td>
                                                    <td className="Retailer_Width" data-tip={report.retailerName}>{report.retailerName}</td>
                                                    <td className="OrderDate_width" data-tip={report.routeName}>{report.routeName}</td>
                                                    <td className="OrderDate_width" data-tip={report.city}>{report.city}</td>
                                                    <td className="OrderDate_width" data-tip={report.state}>{report.state}</td>
                                                    <td className="OrderValue_width" data-tip={report.orderValue.toFixed(2)}>{report.orderValue.toFixed(2)}</td>
                                                </tr>
                                            ))
                                    }

                                </tbody>
                            </table> : null}
                            {this.state.baSalesReportFlag ? <table className="customTable" id="salesmanDSRReport_Table">
                                <thead>
                                    <tr>
                                        <th className="OrderDate_width">Order Date
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderdDate" onChange={this.baSalesFilterList} />
                                            </div>
                                        </th>
                                        <th className="OrderDate_width">State
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="state" onChange={this.baSalesFilterList} />
                                            </div>
                                        </th>
                                        <th className="Retailer_Width">Retailer
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerName" onChange={this.baSalesFilterList} />
                                            </div>
                                        </th>
                                        <th className="SalesMan_width">Sales Person
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="salesman" onChange={this.baSalesFilterList} />
                                            </div>
                                        </th>
                                        <th className="OrderDate_width">Employee Id
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="empid" onChange={this.baSalesFilterList} />
                                            </div>
                                        </th>
                                        <th className="OrderValue_width">Total Sales
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderValue" onChange={this.baSalesFilterList} />
                                            </div>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        !this.state.dsrBAOrderReportList || this.state.dsrBAOrderReportList === null || this.state.dsrBAOrderReportList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="6">No Records</td>
                                            </tr> :
                                            this.state.dsrBAOrderReportList.map((report) => (
                                                <tr>
                                                    <td className="OrderDate_width" data-tip={report.orderdDate}>{report.orderdDate}</td>
                                                    <td className="OrderDate_width" data-tip={report.state}>{report.state}</td>
                                                    <td className="Retailer_Width" data-tip={report.retailerName}>{report.retailerName}</td>
                                                    <td className="SalesMan_width" data-tip={report.salesman}>{report.salesman}</td>
                                                    <td className="OrderDate_width" data-tip={report.empid}>{report.empid}</td>
                                                    <td className="OrderValue_width" data-tip={report.orderValue.toFixed(2)}>{report.orderValue.toFixed(2)}</td>
                                                </tr>
                                            ))
                                    }

                                </tbody>
                            </table> : null}
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            {this.state.salesReportFlag ? <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.dsrOrderReportList ? this.state.dsrOrderReportList.length : 0}</span>entries</span></div> : null}
                            {this.state.baSalesReportFlag ? <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.dsrBAOrderReportList ? this.state.dsrBAOrderReportList.length : 0}</span>entries</span></div> : null}
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_ExpExcel" onClick={() => this.dsrExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}

export default connect(mapStateToProps)(ReportsModule)