import React, { Component } from 'react'
import './MenuModule.css'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Competitor from '../../../assets/Competitor.png';
import SalesReturn from '../../../assets/SalesReturn.png';
import axios from 'axios';
import { connect } from 'react-redux';
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import { faBars, faShoppingCart, Inr, faRupeeSign, faExclamationTriangle, faFileAlt, faChartLine, faBolt, faAssistiveListeningSystems, faCogs, faRoute, faMapMarkerAlt, faMapMarkedAlt, faUniversalAccess, faChartBar, faCalendarAlt, faFileInvoiceDollar, faCartArrowDown, faFileInvoice, faHandHoldingUsd, faSitemap, faCartPlus, faLayerGroup, faSync, faBoxOpen, faSyncAlt, faUser, faPiggyBank, faBalanceScale, faMoneyBillAlt, faHandsHelping, faPaste, faSearchLocation, faMoneyCheckAlt, faWarehouse, faSortAmountUp, faUserShield, faPeopleCarry, faLuggageCart, faProjectDiagram, faUsers, faDolly, faTasks, faSearchDollar, faLandmark, faDigitalTachograph, faChalkboardTeacher, faDatabase, faUserTag, faRedoAlt, faImage, faPhotoVideo, faCommentsDollar, faFileImport } from "@fortawesome/free-solid-svg-icons";

// import { hashHistory  } from 'react-router';

class MenuModule extends Component {

    constructor(props) {
        super(props)

        this.state = {
            toggleActive: false,
            addClass: false,
            MastersshowMenu: false,
            ReportsshowMenu: false,
            MISReportsshowMenu: false,
            ToolReportsshowMenu: false,
            DistributorsshowMenu: false,
            PriceshowMenu: false,
            viewDatashowMenu: false,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            menuList: [],
            MastersMenuOffset: { ClientX: '', ClientY: '' }
        }
        this.MastersshowMenu = this.MastersshowMenu.bind(this);
        this.ReportsshowMenu = this.ReportsshowMenu.bind(this);
        this.MISReportsshowMenu = this.MISReportsshowMenu.bind(this);
        this.ToolReportsshowMenu = this.ToolReportsshowMenu.bind(this);
        this.DistributorsshowMenu = this.DistributorsshowMenu.bind(this);
        this.PriceshowMenu = this.PriceshowMenu.bind(this);
        this.viewDatashowMenu = this.viewDatashowMenu.bind(this);
        this.toggleDistMenu = this.toggleDistMenu.bind(this);
        this.toggleMasterMenu = this.toggleMasterMenu.bind(this);
        this.toggleReportsMenu = this.toggleReportsMenu.bind(this);
        this.togglePricesMenu = this.togglePricesMenu.bind(this);
        this.toggleViewDataMenu = this.toggleViewDataMenu.bind(this);
        this.toggleMISReportsMenu = this.toggleMISReportsMenu.bind(this);
        this.toggleToolReportsMenu = this.toggleToolReportsMenu.bind(this);
    }

    componentDidMount() {
        this.getMMMenuList();
    }

    getMMMenuList() {
        let utype = this.state.loginuserObj.userType;
        const params = {
            userType: utype,
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/user/getMMMenuList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        menuList: data.menuList
                    })

                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    toggle() {
        this.setState({ addClass: !this.state.addClass });
        //alert("hiii")
    }
    toggleDistMenu = function () {
        this.setState({
            DistributorsshowMenu: !this.state.DistributorsshowMenu
        });
    }
    toggleMasterMenu = function () {
        this.setState({
            MastersshowMenu: !this.state.MastersshowMenu
        });
    }
    toggleReportsMenu = function () {
        this.setState({
            ReportsshowMenu: !this.state.ReportsshowMenu
        });
    }
    toggleMISReportsMenu = function () {
        this.setState({
            MISReportsshowMenu: !this.state.MISReportsshowMenu
        });
    }
    toggleToolReportsMenu = function () {
        this.setState({
            ToolReportsshowMenu: !this.state.ToolReportsshowMenu
        });
    }
    togglePricesMenu = function () {
        this.setState({
            PriceshowMenu: !this.state.PriceshowMenu
        });
    }
    toggleViewDataMenu = function () {
        this.setState({
            viewDatashowMenu: !this.state.viewDatashowMenu
        });
    }
    MastersshowMenu(event) {
        event.preventDefault();
        let clientXval = event.currentTarget.offsetLeft
        let clientYval = event.currentTarget.offsetTop

        this.setState({
            ...this.state,
            MastersshowMenu: !this.state.MastersshowMenu,
            ReportsshowMenu: false,
            DistributorsshowMenu: false,
            PriceshowMenu: false,
            viewDatashowMenu: false,
            MastersMenuOffset: {
                ...this.state.MastersMenuOffset,
                ClientX: clientXval,
                ClientY: clientYval
            }
        });
    }

    ReportsshowMenu(event) {
        event.preventDefault();
        let clientXval = event.currentTarget.offsetLeft
        let clientYval = event.currentTarget.offsetTop
        this.setState({
            ...this.state,
            ReportsshowMenu: !this.state.ReportsshowMenu,
            MISReportsshowMenu: false,
            ToolReportsshowMenu: false,
            MastersshowMenu: false,
            DistributorsshowMenu: false,
            PriceshowMenu: false,
            viewDatashowMenu: false,
            MastersMenuOffset: {
                ...this.state.MastersMenuOffset,
                ClientX: clientXval,
                ClientY: clientYval
            }
        });
    }

    MISReportsshowMenu(event) {
        event.preventDefault();
        let clientXval = event.currentTarget.offsetLeft
        let clientYval = event.currentTarget.offsetTop
        this.setState({
            ...this.state,
            MISReportsshowMenu: !this.state.MISReportsshowMenu,
            ToolReportsshowMenu: false,
            ReportsshowMenu: false,
            MastersshowMenu: false,
            DistributorsshowMenu: false,
            PriceshowMenu: false,
            viewDatashowMenu: false,
            MastersMenuOffset: {
                ...this.state.MastersMenuOffset,
                ClientX: clientXval,
                ClientY: clientYval
            }
        });
    }

    ToolReportsshowMenu(event) {
        event.preventDefault();
        let clientXval = event.currentTarget.offsetLeft
        let clientYval = event.currentTarget.offsetTop
        this.setState({
            ...this.state,
            ToolReportsshowMenu: !this.state.ToolReportsshowMenu,
            MISReportsshowMenu: false,
            ReportsshowMenu: false,
            MastersshowMenu: false,
            DistributorsshowMenu: false,
            PriceshowMenu: false,
            viewDatashowMenu: false,
            MastersMenuOffset: {
                ...this.state.MastersMenuOffset,
                ClientX: clientXval,
                ClientY: clientYval
            }
        });
    }

    DistributorsshowMenu(event) {
        event.preventDefault();
        let clientXval = event.currentTarget.offsetLeft
        let clientYval = event.currentTarget.offsetTop
        this.setState({
            ...this.state,
            DistributorsshowMenu: !this.state.DistributorsshowMenu,
            MastersshowMenu: false,
            ReportsshowMenu: false,
            MISReportsshowMenu: false,
            ToolReportsshowMenu: false,
            PriceshowMenu: false,
            viewDatashowMenu: false,
            MastersMenuOffset: {
                ...this.state.MastersMenuOffset,
                ClientX: clientXval,
                ClientY: clientYval
            }
        });
    }

    hideSubMenu = (event) => {
        event.preventDefault();
        this.setState({
            DistributorsshowMenu: false,
            MastersshowMenu: false,
            ReportsshowMenu: false,
            PriceshowMenu: false,
            viewDatashowMenu: false,
            MISReportsshowMenu: false,
            ToolReportsshowMenu: false,
        });
    }
    //   loadmenu = (menuName) =>{
    //   //  event.preventDefault();
    //     console.log('menuName---'+menuName)
    //     //this.props.history.push("/"+menuName);
    //     hashHistory.push('/'+menuName);
    //     this.setState({
    //         DistributorsshowMenu: false,
    //         MastersshowMenu:false,
    //         ReportsshowMenu:false,
    //         PriceshowMenu:false,
    //         viewDatashowMenu:false
    //     });
    //   }
    PriceshowMenu(event) {
        event.preventDefault();
        let clientXval = event.currentTarget.offsetLeft
        let clientYval = event.currentTarget.offsetTop
        this.setState({
            ...this.state,
            PriceshowMenu: !this.state.PriceshowMenu,
            MastersshowMenu: false,
            ReportsshowMenu: false,
            MISReportsshowMenu: false,
            ToolReportsshowMenu: false,
            DistributorsshowMenu: false,
            viewDatashowMenu: false,
            MastersMenuOffset: {
                ...this.state.MastersMenuOffset,
                ClientX: clientXval,
                ClientY: clientYval
            }
        });
    }

    viewDatashowMenu(event) {
        event.preventDefault();
        let clientXval = event.currentTarget.offsetLeft
        let clientYval = event.currentTarget.offsetTop
        this.setState({
            ...this.state,
            viewDatashowMenu: !this.state.viewDatashowMenu,
            MastersshowMenu: false,
            ReportsshowMenu: false,
            MISReportsshowMenu: false,
            ToolReportsshowMenu: false,
            DistributorsshowMenu: false,
            PriceshowMenu: false,
            MastersMenuOffset: {
                ...this.state.MastersMenuOffset,
                ClientX: clientXval,
                ClientY: clientYval
            }
        });
    }



    render() {

        // console.log("render method->"+this.props.toggleActive)

        var toggleChange = 'MenuContainer';
        if (this.props.toggleActive) (
            toggleChange = 'toggleMenuContainer'
        )

        let boxClass = ["MenuIcon_btn"];
        if (this.state.addClass) {
            boxClass.push('activeone');
        }

        return (
            <nav>
                <div className={toggleChange}>

                    {
                        this.state.menuList.map((menu) => (
                            menu.displayName === 'Order' ?
                                <Link to='/order'>
                                    {
                                        this.props.toggleActive ? <div className="MenuIcon_btn" title="Order"><span><FontAwesomeIcon icon={faShoppingCart} /></span><span className="menuLabel">Order</span> </div> : <div className="MenuIcon_btn" title="Order"><span><FontAwesomeIcon icon={faShoppingCart} /></span><span></span> </div>
                                    }
                                </Link> :

                                menu.displayName === 'Collection' ?
                                    <Link to='/collection' >
                                        <div className="MenuIcon_btn" title="Collection">

                                            <span><FontAwesomeIcon icon={faRupeeSign} /></span>
                                            {
                                                this.props.toggleActive ? <span className="menuLabel">Collection</span> : null
                                            }
                                        </div>
                                    </Link> :
                                    menu.displayName === 'SalesReturn' ?
                                        <Link to='/salesReturn'>
                                            <div className="MenuIcon_btn" title="SalesReturn">
                                                {/* <span><img src={SalesReturn} alt="SalesRetImg" /></span> */}
                                                <span><FontAwesomeIcon icon={faCartArrowDown} /></span>
                                                {
                                                    this.props.toggleActive ? <span className="menuLabel">Sales Return</span> : null
                                                }
                                            </div>
                                        </Link> :
                                        menu.displayName === 'NewRetailer' ?
                                            <Link to="/newCustomer">
                                                <div className="MenuIcon_btn" title="NewRetailer">
                                                    <span><FontAwesomeIcon icon={faFileAlt} /></span>
                                                    {
                                                        this.props.toggleActive ? <span className="menuLabel">New Retailer</span> : null
                                                    }
                                                </div>
                                            </Link> :
                                            menu.displayName === 'View BA Sales' ?
                                                <Link to="/viewBASales">
                                                    <div className="MenuIcon_btn" title="View BA Sales">
                                                        <span><FontAwesomeIcon icon={faFileAlt} /></span>
                                                        {
                                                            this.props.toggleActive ? <span className="menuLabel">View BA Sales</span> : null
                                                        }
                                                    </div>
                                                </Link> :
                                                menu.displayName === 'Competitor' ?
                                                    <Link>
                                                        <div className="MenuIcon_btn" title="Competitor">
                                                            <span><img src={Competitor} alt="CompetitorImg" /></span>
                                                            {/* <span><FontAwesomeIcon icon={ faBolt}/></span> */}
                                                            {
                                                                this.props.toggleActive ? <span className="menuLabel">Competitors</span> : null
                                                            }
                                                        </div>
                                                    </Link> :
                                                    menu.displayName === 'Distributors' ?
                                                        <div className="MenuIcon_btn" title="Distributors" onClick={this.DistributorsshowMenu}>
                                                            <span><FontAwesomeIcon icon={faSitemap} /></span>
                                                            {
                                                                this.props.toggleActive ? <span className="menuLabel">Distributors</span> : null
                                                            }
                                                        </div> :
                                                        menu.displayName === 'Reports' ?
                                                            <div className="MenuIcon_btn" title="Reports" onClick={this.ReportsshowMenu}>
                                                                <span><FontAwesomeIcon icon={faChartBar} /></span>
                                                                {
                                                                    this.props.toggleActive ? <span className="menuLabel">Reports</span> : null
                                                                }
                                                            </div> :
                                                            menu.displayName === 'Masters' ?
                                                                <div className="MenuIcon_btn" title="Masters" onClick={this.MastersshowMenu}>
                                                                    <span><FontAwesomeIcon icon={faLayerGroup} /></span>
                                                                    {
                                                                        this.props.toggleActive ? <span className="menuLabel">Masters</span> : null
                                                                    }
                                                                </div> :
                                                                menu.displayName === 'ViewDataSync' ?
                                                                    <div className="MenuIcon_btn" title="ViewDataSync" onClick={this.viewDatashowMenu}>
                                                                        <span><FontAwesomeIcon icon={faSyncAlt} /></span>
                                                                        {
                                                                            this.props.toggleActive ? <span className="menuLabel">View Sync Data</span> : null
                                                                        }
                                                                    </div> :
                                                                    menu.displayName === 'Price' ?
                                                                        <div className="MenuIcon_btn" title="Price" onClick={this.PriceshowMenu}>
                                                                            <span><FontAwesomeIcon icon={faSearchDollar} /></span>
                                                                            {
                                                                                this.props.toggleActive ? <span className="menuLabel">Price</span> : null
                                                                            }
                                                                        </div> :
                                                                        menu.displayName === 'RoleAccess' ?
                                                                            <Link to='/roleAccess'>
                                                                                <div className="MenuIcon_btn" title="RoleAccess">
                                                                                    <span><FontAwesomeIcon icon={faUserShield} /></span>
                                                                                    {
                                                                                        this.props.toggleActive ? <span className="menuLabel">Role Access</span> : null
                                                                                    }
                                                                                </div>
                                                                            </Link> :
                                                                            menu.displayName === 'Menu Maintenance' ?
                                                                                <Link to='/menuMaintenance'>
                                                                                    <div className="MenuIcon_btn" title="Menu Maintenance">
                                                                                        <span><FontAwesomeIcon icon={faBars} /></span>
                                                                                        {
                                                                                            this.props.toggleActive ? <span className="menuLabel">Menu Maintenance</span> : null
                                                                                        }
                                                                                    </div>
                                                                                </Link> :
                                                                                menu.displayName === 'View Photo' ?
                                                                                    <Link to='/viewPhoto'>
                                                                                        <div className="MenuIcon_btn" title="View Photo">
                                                                                            <span><FontAwesomeIcon icon={faPhotoVideo} /></span>
                                                                                            {
                                                                                                this.props.toggleActive ? <span className="menuLabel">View Photo</span> : null
                                                                                            }
                                                                                        </div>
                                                                                    </Link> :
                                                                                    menu.displayName === 'Expenses View' ?
                                                                                        <Link to='/expensesView'>
                                                                                            <div className="MenuIcon_btn" title="Expenses View">
                                                                                                <span><FontAwesomeIcon icon={faCommentsDollar} /></span>
                                                                                                {
                                                                                                    this.props.toggleActive ? <span className="menuLabel">Expenses View</span> : null
                                                                                                }
                                                                                            </div>
                                                                                        </Link> :
                                                                                        menu.displayName === 'Tool' ?
                                                                                            <Link to="/toolModule">
                                                                                                <div className="MenuIcon_btn" title="Tool" onClick={this.ToolReportsshowMenu}>
                                                                                                    <span><FontAwesomeIcon icon={faFileImport} /></span>
                                                                                                    {
                                                                                                        this.props.toggleActive ? <span className="menuLabel">Tool</span> : null
                                                                                                    }
                                                                                                </div>
                                                                                            </Link> :
                                                                                            menu.displayName === 'MIS Reports' ?
                                                                                                <div className="MenuIcon_btn" title="MIS Reports" onClick={this.MISReportsshowMenu}>
                                                                                                    <span><FontAwesomeIcon icon={faChartBar} /></span>
                                                                                                    {
                                                                                                        this.props.toggleActive ? <span className="menuLabel">MIS Reports</span> : null
                                                                                                    }
                                                                                                </div> : ''
                        ))
                    }





                    {/* <Link to='/competitors'> */}













                    {/* <Link to='/'>
                    <Link to='/inventory'>
                    <div className="MenuIcon_btn" title="Inventory">
                        <span><FontAwesomeIcon icon={ faCogs}/></span>
                        {
                          this.props.toggleActive ?<span className="menuLabel">Inventory</span>:null
                        }
                    </div>
                    </Link> */}






                    {
                        this.state.DistributorsshowMenu
                            ? (
                                <div className="menu" style={{ position: 'absolute', left: '3em', top: this.state.MastersMenuOffset.ClientY }}>
                                    {
                                        this.state.menuList.map((menu) => (
                                            menu.displayName === "Distributor" ?
                                                <Link to='/distributor'>
                                                    <div className="MenuIcon_btn" title="Distributor" onClick={this.toggleDistMenu}>
                                                        <span><FontAwesomeIcon icon={faSitemap} /></span>
                                                        {
                                                            this.props.toggleActive ? <span className="menuLabel">Distributor</span> : null
                                                        }
                                                    </div>
                                                </Link>
                                                :
                                                menu.displayName === "Distributor Stock" ?
                                                    <Link to='/distributorStock'>
                                                        <div className="MenuIcon_btn" title="Distributor Stock" onClick={this.toggleDistMenu}>
                                                            <span><FontAwesomeIcon icon={faCartPlus} /></span>
                                                            {
                                                                this.props.toggleActive ? <span className="menuLabel">Distributor Stock</span> : null
                                                            }
                                                        </div>
                                                    </Link>
                                                    : null
                                        ))
                                    }
                                </div>

                            )
                            : (
                                null
                            )
                    }


                    {
                        this.state.ReportsshowMenu
                            ? (
                                <div className="menu" style={{ position: 'absolute', left: '3em', top: this.state.MastersMenuOffset.ClientY }}>
                                    {
                                        this.state.menuList.map((menu) => (
                                            menu.displayName === "Dsr Reports" ?
                                                <Link to='/reports'>
                                                    <div className="MenuIcon_btn" title="Dsr Reports" onClick={this.toggleReportsMenu}>
                                                        <span><FontAwesomeIcon icon={faChartLine} /></span>
                                                        {
                                                            this.props.toggleActive ? <span className="menuLabel">DSR</span> : null
                                                        }
                                                    </div>
                                                </Link>
                                                :
                                                menu.displayName === "ERP Uploaded Order" ?
                                                    <Link to='/erpUploadedOrder'>
                                                        <div className="MenuIcon_btn" title="ERP Uploaded Order" onClick={this.toggleReportsMenu}>
                                                            <span><FontAwesomeIcon icon={faSortAmountUp} /></span>
                                                            {
                                                                this.props.toggleActive ? <span className="menuLabel">ERP Uploaded Order</span> : null
                                                            }
                                                        </div>
                                                    </Link>
                                                    :
                                                    menu.displayName === "Visit Detail" ?
                                                        <Link to='/visitDetail'>
                                                            <div className="MenuIcon_btn" title="Visit Detail" onClick={this.toggleReportsMenu}>
                                                                <span><FontAwesomeIcon icon={faLandmark} /></span>
                                                                {
                                                                    this.props.toggleActive ? <span className="menuLabel">Visit Detail</span> : null
                                                                }
                                                            </div>
                                                        </Link>
                                                        :
                                                        menu.displayName === "Sale Order Detail" ?
                                                            <Link to='/SaleOrderDetailReport'>
                                                                <div className="MenuIcon_btn" title="Sale Order Detail" onClick={this.toggleReportsMenu}>
                                                                    <span><FontAwesomeIcon icon={faLandmark} /></span>
                                                                    {
                                                                        this.props.toggleActive ? <span className="menuLabel">Sale Order Detail</span> : null
                                                                    }
                                                                </div>
                                                            </Link>
                                                            :
                                                            menu.displayName === "UserAppVersion" ?
                                                                <Link to='/UserAppVersionReport'>
                                                                    <div className="MenuIcon_btn" title="User - App Version" onClick={this.toggleReportsMenu}>
                                                                        <span><FontAwesomeIcon icon={faLandmark} /></span>
                                                                        {
                                                                            this.props.toggleActive ? <span className="menuLabel">User - App Version</span> : null
                                                                        }
                                                                    </div>
                                                                </Link>
                                                                :
                                                                menu.displayName === "SalesReport" ?
                                                                <Link to='/SalesReportOnDemandReport'>
                                                                    <div className="MenuIcon_btn" title="Order and BA order Sales Details" onClick={this.toggleReportsMenu}>
                                                                        <span><FontAwesomeIcon icon={faLandmark} /></span>
                                                                        {
                                                                            this.props.toggleActive ? <span className="menuLabel">Order and BA order Sales Details</span> : null
                                                                        }
                                                                    </div>
                                                                </Link>
                                                                :
                                                                menu.displayName === "Activity Report" ?
                                                                <Link to='/ActivityReports'>
                                                                    <div className="MenuIcon_btn" title="Activity Report" onClick={this.toggleReportsMenu}>
                                                                        <span><FontAwesomeIcon icon={faChartLine} /></span>
                                                                        {
                                                                            this.props.toggleActive ? <span className="menuLabel">Activity Report</span> : null
                                                                        }
                                                                    </div>
                                                                </Link>
                                                                :
                                                                menu.displayName === "Retailer Report" ?
                                                                <Link to='/RetailerReport'>
                                                                    <div className="MenuIcon_btn" title="Retailer Report" onClick={this.toggleReportsMenu}>
                                                                        <span><FontAwesomeIcon icon={faChartLine} /></span>
                                                                        {
                                                                            this.props.toggleActive ? <span className="menuLabel">Retailer Report</span> : null
                                                                        }
                                                                    </div>
                                                                </Link>
                                                                :
                                                                menu.displayName === "Upload Sales Data" ?
                                                                <Link to='/uploadSalesData'>
                                                                    <div className="MenuIcon_btn" title="Upload Sales Data" onClick={this.toggleReportsMenu}>
                                                                        <span><FontAwesomeIcon icon={faChartLine} /></span>
                                                                        {
                                                                            this.props.toggleActive ? <span className="menuLabel">Upload Sales Data</span> : null
                                                                        }
                                                                    </div>
                                                                </Link>
                                                                :
                                                                menu.displayName === "User Attendance" ?
                                                                <Link to='/userAttendance'>
                                                                    <div className="MenuIcon_btn" title="User Attendance" onClick={this.toggleReportsMenu}>
                                                                        <span><FontAwesomeIcon icon={faChartLine} /></span>
                                                                        {
                                                                            this.props.toggleActive ? <span className="menuLabel">User Attendance</span> : null
                                                                        }
                                                                    </div>
                                                                </Link>
                                                                :
                                                                menu.displayName === "BA Attendance" ?
                                                                <Link to='/baAttendance'>
                                                                    <div className="MenuIcon_btn" title="BA Attendance" onClick={this.toggleReportsMenu}>
                                                                        <span><FontAwesomeIcon icon={faChartLine} /></span>
                                                                        {
                                                                            this.props.toggleActive ? <span className="menuLabel">BA Attendance</span> : null
                                                                        }
                                                                    </div>
                                                                </Link>
                                                                :
                                                                null
                                        ))
                                    }
                                </div>
                            )
                            : (
                                null
                            )
                    }

                    {
                        this.state.MISReportsshowMenu
                            ? (
                                <div className="menu" style={{ position: 'absolute', left: '3em', top: this.state.MastersMenuOffset.ClientY }}>
                                    {
                                        this.state.menuList.map((menu) => (
                                            menu.displayName === "SalesmanReport" ?
                                                <Link to='/salesmanReport'>
                                                    <div className="MenuIcon_btn" title="Salesman Report" onClick={this.toggleMISReportsMenu}>
                                                        <span><FontAwesomeIcon icon={faChartLine} /></span>
                                                        {
                                                            this.props.toggleActive ? <span className="menuLabel">Salesman Report</span> : null
                                                        }
                                                    </div>
                                                </Link>
                                                :
                                                menu.displayName === "ProductReport" ?
                                                    <Link to='/productReport'>
                                                        <div className="MenuIcon_btn" title="Product Report" onClick={this.toggleMISReportsMenu}>
                                                            <span><FontAwesomeIcon icon={faChartLine} /></span>
                                                            {
                                                                this.props.toggleActive ? <span className="menuLabel">Product Report</span> : null
                                                            }
                                                        </div>
                                                    </Link>
                                                    :
                                                    menu.displayName === "Usage Detail Report" ?
                                                        <Link to='/UsageDetailReport'>
                                                            <div className="MenuIcon_btn" title="Usage Detail Report" onClick={this.toggleMISReportsMenu}>
                                                                <span><FontAwesomeIcon icon={faChartLine} /></span>
                                                                {
                                                                    this.props.toggleActive ? <span className="menuLabel">Usage Detail Report</span> : null
                                                                }
                                                            </div>
                                                        </Link>
                                                        : menu.displayName === "Usage Detail Summary Report" ?
                                                            <Link to='/UsageDetailSummaryReport'>
                                                                <div className="MenuIcon_btn" title="Usage Detail Summary Report" onClick={this.toggleMISReportsMenu}>
                                                                    <span><FontAwesomeIcon icon={faChartLine} /></span>
                                                                    {
                                                                        this.props.toggleActive ? <span className="menuLabel">Usage Detail Summary Report</span> : null
                                                                    }
                                                                </div>
                                                            </Link>
                                                            : menu.displayName === "BA-USummaryReport" ?
                                                                <Link to='/BA-USummaryReport'>
                                                                    <div className="MenuIcon_btn" title="BA - Usage Detail Summary Report" onClick={this.toggleMISReportsMenu}>
                                                                        <span><FontAwesomeIcon icon={faChartLine} /></span>
                                                                        {
                                                                            this.props.toggleActive ? <span className="menuLabel">BA - Usage Detail Summary Report</span> : null
                                                                        }
                                                                    </div>
                                                                </Link>
                                                                :
                                                                menu.displayName === "BASalesByRetailerReport" ?
                                                                    <Link to='/BASalesByRetailerReport'>
                                                                        <div className="MenuIcon_btn" title="BA - Sales By Retailer Report" onClick={this.toggleMISReportsMenu}>
                                                                            <span><FontAwesomeIcon icon={faChartLine} /></span>
                                                                            {
                                                                                this.props.toggleActive ? <span className="menuLabel">BA - Sales By Retailer Report</span> : null
                                                                            }
                                                                        </div>
                                                                    </Link>
                                                                    :
                                                                    null

                                        ))
                                    }
                                </div>
                            )
                            : (
                                null
                            )
                    }
                    {
                        this.state.ToolReportsshowMenu
                            ? (
                                <div className="menu" style={{ position: 'absolute', left: '3em', top: this.state.MastersMenuOffset.ClientY }}>
                                    {
                                        this.state.menuList.map((menu) => (
                                            menu.displayName === "ToolModule" ?
                                                <Link to="/toolModule">
                                                    <div className="MenuIcon_btn" title="Import" onClick={this.toggleToolReportsMenu}>
                                                        <span><FontAwesomeIcon icon={faFileImport} /></span>
                                                        {
                                                            this.props.toggleActive ? <span className="menuLabel">Import</span> : null
                                                        }
                                                    </div>
                                                </Link>
                                                :
                                                menu.displayName === "RetailerDistributor_Report" ?
                                                    <Link to='/retailerDistributorReport'>
                                                        <div className="MenuIcon_btn" title="Retailer Not Assinged to Distributor" onClick={this.toggleToolReportsMenu}>
                                                            <span><FontAwesomeIcon icon={faFileImport} /></span>
                                                            {
                                                                this.props.toggleActive ? <span className="menuLabel">Retailer Not Assinged to Distributor</span> : null
                                                            }
                                                        </div>
                                                    </Link>
                                                    : menu.displayName === "RetailerRoute_Report" ?
                                                        <Link to='/retailerRouteReport'>
                                                            <div className="MenuIcon_btn" title="Retailer Without Valid Route" onClick={this.toggleToolReportsMenu}>
                                                                <span><FontAwesomeIcon icon={faFileImport} /></span>
                                                                {
                                                                    this.props.toggleActive ? <span className="menuLabel">Retailer Without Valid Route</span> : null
                                                                }
                                                            </div>
                                                        </Link>
                                                        : null
                                        ))
                                    }
                                </div>
                            )
                            : (
                                null
                            )
                    }
                    {
                        this.state.MastersshowMenu
                            ? (
                                <div className="menu" style={{ position: 'absolute', left: '3em', top: this.state.MastersMenuOffset.ClientY ,height: '70vh',overflow: 'scroll'}}>
                                    {
                                        this.state.menuList.map((menu) => (
                                            menu.displayName === "SalesmanRoute" ?
                                                <Link to='/salesmanRoute' >
                                                    <div className="MenuIcon_btn" title="SalesmanRoute" onClick={this.toggleMasterMenu}>
                                                        <span><FontAwesomeIcon icon={faRoute} /></span>
                                                        {
                                                            this.props.toggleActive ? <span className="menuLabel">Salesman Route</span> : null
                                                        }
                                                    </div>
                                                </Link>
                                                :
                                                menu.displayName === "Visit plan" ?
                                                    <Link to='/visitPlan' >
                                                        <div className="MenuIcon_btn" title="VisitPlan" onClick={this.toggleMasterMenu}>
                                                            <span><FontAwesomeIcon icon={faPeopleCarry} /></span>
                                                            {
                                                                this.props.toggleActive ? <span className="menuLabel">Visit Plan</span> : null
                                                            }
                                                        </div>
                                                    </Link>
                                                    :
                                                    menu.displayName === "RouteMaster" ?
                                                        <Link to='/routeMaster'>
                                                            <div className="MenuIcon_btn" title="RouteMaster" onClick={this.toggleMasterMenu}>
                                                                <span><FontAwesomeIcon icon={faMapMarkedAlt} /></span>
                                                                {
                                                                    this.props.toggleActive ? <span className="menuLabel">Route Master</span> : null
                                                                }
                                                            </div>
                                                        </Link>
                                                        :
                                                        menu.displayName === "Salesman" ?
                                                            <Link to='/salesman'>
                                                                <div className="MenuIcon_btn" title="Salesman" onClick={this.toggleMasterMenu}>
                                                                    <span><FontAwesomeIcon icon={faUniversalAccess} /></span>
                                                                    {
                                                                        this.props.toggleActive ? <span className="menuLabel">Salesman</span> : null
                                                                    }
                                                                </div>
                                                            </Link>
                                                            :
                                                            menu.displayName === "DayMaster" ?
                                                                <Link to='/dayMaster'>
                                                                    <div className="MenuIcon_btn" title="DayMaster" onClick={this.toggleMasterMenu}>
                                                                        <span><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                        {
                                                                            this.props.toggleActive ? <span className="menuLabel">Day Master</span> : null
                                                                        }
                                                                    </div>
                                                                </Link>
                                                                :
                                                                menu.displayName === "Product" ?
                                                                    <Link to='/product'>
                                                                        <div className="MenuIcon_btn" title="Product" onClick={this.toggleMasterMenu}>
                                                                            <span><FontAwesomeIcon icon={faCartArrowDown} /></span>
                                                                            {
                                                                                this.props.toggleActive ? <span className="menuLabel">Product</span> : null
                                                                            }
                                                                        </div>
                                                                    </Link>
                                                                    :
                                                                    menu.displayName === "Product Price" ?
                                                                        <Link to='/productPrice'>
                                                                            <div className="MenuIcon_btn" title="Product Price" onClick={this.toggleMasterMenu}>
                                                                                <span><FontAwesomeIcon icon={faFileInvoice} /></span>
                                                                                {
                                                                                    this.props.toggleActive ? <span className="menuLabel">Product Price</span> : null
                                                                                }
                                                                            </div>
                                                                        </Link>
                                                                        :
                                                                        menu.displayName === "Retailer Price" ?
                                                                            <Link to='/retailerPrice'>
                                                                                <div className="MenuIcon_btn" title="Retailer Price" onClick={this.toggleMasterMenu}>
                                                                                    <span><FontAwesomeIcon icon={faHandHoldingUsd} /></span>
                                                                                    {
                                                                                        this.props.toggleActive ? <span className="menuLabel">Retailer Price</span> : null
                                                                                    }
                                                                                </div>
                                                                            </Link>
                                                                            :
                                                                            menu.displayName === "Distributor Price Margin" ?
                                                                            <Link to='/distributorPriceMargin'>
                                                                                <div className="MenuIcon_btn" title="Distributor Price Margin" onClick={this.toggleMasterMenu}>
                                                                                    <span><FontAwesomeIcon icon={faFileInvoice} /></span>
                                                                                    {
                                                                                        this.props.toggleActive ? <span className="menuLabel">Distributor Price Margin</span> : null
                                                                                    }
                                                                                </div>
                                                                            </Link>
                                                                            :
                                                                            menu.displayName === "Product Group" ?
                                                                                <Link to='/productGroup'>
                                                                                    <div className="MenuIcon_btn" title="Product Group" onClick={this.toggleMasterMenu}>
                                                                                        <span><FontAwesomeIcon icon={faSitemap} /></span>
                                                                                        {
                                                                                            this.props.toggleActive ? <span className="menuLabel">Product Group</span> : null
                                                                                        }
                                                                                    </div>
                                                                                </Link>
                                                                                :
                                                                                menu.displayName === "Product Type" ?
                                                                                    <Link to='/productType'>
                                                                                        <div className="MenuIcon_btn" title="Product Type" onClick={this.toggleMasterMenu}>
                                                                                            <span><FontAwesomeIcon icon={faBoxOpen} /></span>
                                                                                            {
                                                                                                this.props.toggleActive ? <span className="menuLabel">Product Type</span> : null
                                                                                            }
                                                                                        </div>
                                                                                    </Link>
                                                                                    :
                                                                                    menu.displayName === "Generate Scheme" ?
                                                                                        <Link to='/schemeGenerate' >
                                                                                            <div className="MenuIcon_btn" title="Generate Scheme" onClick={this.toggleMasterMenu}>
                                                                                                <span><FontAwesomeIcon icon={faChalkboardTeacher} /></span>
                                                                                                {
                                                                                                    this.props.toggleActive ? <span className="menuLabel">Generate Scheme</span> : null
                                                                                                }
                                                                                            </div>
                                                                                        </Link>
                                                                                        :
                                                                                        menu.displayName === "Maintain Scheme" ?
                                                                                            <Link to='/schemeMaintain' >
                                                                                                <div className="MenuIcon_btn" title="Maintain Scheme" onClick={this.toggleMasterMenu}>
                                                                                                    <span><FontAwesomeIcon icon={faProjectDiagram} /></span>
                                                                                                    {
                                                                                                        this.props.toggleActive ? <span className="menuLabel">Maintain Scheme</span> : null
                                                                                                    }
                                                                                                </div>
                                                                                            </Link>
                                                                                            :
                                                                                            menu.displayName === "User" ?
                                                                                                <Link to='/userAccess' >
                                                                                                    <div className="MenuIcon_btn" title="User" onClick={this.toggleMasterMenu}>
                                                                                                        <span><FontAwesomeIcon icon={faUsers} /></span>
                                                                                                        {
                                                                                                            this.props.toggleActive ? <span className="menuLabel">User</span> : null
                                                                                                        }
                                                                                                    </div>
                                                                                                </Link>
                                                                                                :
                                                                                                menu.displayName === "User Role" ?
                                                                                                    <Link to='/userRoleModule' >
                                                                                                        <div className="MenuIcon_btn" title="User Role" onClick={this.toggleMasterMenu}>
                                                                                                            <span><FontAwesomeIcon icon={faUserTag} /></span>
                                                                                                            {
                                                                                                                this.props.toggleActive ? <span className="menuLabel">User Role</span> : null
                                                                                                            }
                                                                                                        </div>
                                                                                                    </Link>
                                                                                                    :
                                                                                                    menu.displayName === "User Team" ?
                                                                                                        <Link to='/userTeamModule' >
                                                                                                            <div className="MenuIcon_btn" title="User Team" onClick={this.toggleMasterMenu}>
                                                                                                                <span><FontAwesomeIcon icon={faUserTag} /></span>
                                                                                                                {
                                                                                                                    this.props.toggleActive ? <span className="menuLabel">User Team</span> : null
                                                                                                                }
                                                                                                            </div>
                                                                                                        </Link>
                                                                                                        :
                                                                                                        menu.displayName === "Meta Data" ?
                                                                                                            <Link to='/metaDataModule' >
                                                                                                                <div className="MenuIcon_btn" title="Meta Data" onClick={this.toggleMasterMenu}>
                                                                                                                    <span><FontAwesomeIcon icon={faDatabase} /></span>
                                                                                                                    {
                                                                                                                        this.props.toggleActive ? <span className="menuLabel">Meta Data</span> : null
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </Link>
                                                                                                            :
                                                                                                            menu.displayName === "BA Retailer" ?
                                                                                                                <Link to='/baRetailer' >
                                                                                                                    <div className="MenuIcon_btn" title="BA Retailer" onClick={this.toggleMasterMenu}>
                                                                                                                        <span><FontAwesomeIcon icon={faFileAlt} /></span>
                                                                                                                        {
                                                                                                                            this.props.toggleActive ? <span className="menuLabel">BA Retailer</span> : null
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </Link>
                                                                                                            :
                                                                                                            menu.displayName === "BA Retailer Outlet" ?
                                                                                                            <Link to='/baRetailerOutLet' >
                                                                                                                <div className="MenuIcon_btn" title="BA Retailer Outlet" onClick={this.toggleMasterMenu}>
                                                                                                                    <span><FontAwesomeIcon icon={faFileAlt} /></span>
                                                                                                                    {
                                                                                                                        this.props.toggleActive ? <span className="menuLabel">BA Retailer Outlet</span> : null
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </Link>
                                                                                                                :
                                                                                                                menu.displayName === "DataSync" ?
                                                                                                                    <Link to='/dataSync'>
                                                                                                                        <div className="MenuIcon_btn" title="DataSync" onClick={this.toggleMasterMenu}>
                                                                                                                            <span><FontAwesomeIcon icon={faSync} /></span>
                                                                                                                            {
                                                                                                                                this.props.toggleActive ? <span className="menuLabel">Data Sync</span> : null
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </Link>
                                                                                                                    :
                                                                                                                    menu.displayName === "State And City" ?
                                                                                                                        <Link to='/cityState'>
                                                                                                                            <div className="MenuIcon_btn" title="State And City" onClick={this.toggleMasterMenu}>
                                                                                                                                <span><FontAwesomeIcon icon={faMapMarkedAlt} /></span>
                                                                                                                                {
                                                                                                                                    this.props.toggleActive ? <span className="menuLabel">State and City</span> : null
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </Link>
                                                                                                                        :
                                                                                                                        menu.displayName === "Retailer Approval" ?
                                                                                                                        <Link to='/retailerApproval'>
                                                                                                                            <div className="MenuIcon_btn" title="Retailer Approval" onClick={this.toggleMasterMenu}>
                                                                                                                                <span><FontAwesomeIcon icon={faMapMarkedAlt} /></span>
                                                                                                                                {
                                                                                                                                    this.props.toggleActive ? <span className="menuLabel">Retailer Approval</span> : null
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </Link>
                                                                                                                        :
                                                                                                                        menu.displayName === "Retailer Team Allocation" ?
                                                                                                                        <Link to='/retailerTeamAllocation'>
                                                                                                                            <div className="MenuIcon_btn" title="Retailer Team Allocation" onClick={this.toggleMasterMenu}>
                                                                                                                                <span><FontAwesomeIcon icon={faMapMarkedAlt} /></span>
                                                                                                                                {
                                                                                                                                    this.props.toggleActive ? <span className="menuLabel">Retailer Team Allocation</span> : null
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </Link>
                                                                                                                        :
                                                                                                                        null
                                        ))
                                    }
                                    {/* <Link to='/employeeTask'>
                            <div className="MenuIcon_btn" title="Employee Task" onClick={this.toggleMasterMenu}>
                                <span><FontAwesomeIcon icon={ faTasks }/></span>
                                {
                                this.props.toggleActive ?<span className="menuLabel">Employee Task</span>:null
                                }
                            </div>
                            </Link> */}
                                    {/* <Link to='/retailerCategory'>
                            <div className="MenuIcon_btn" title="Retailer Category" onClick={this.toggleMasterMenu}>
                                <span><FontAwesomeIcon icon={ faDolly }/></span>
                                {
                                this.props.toggleActive ?<span className="menuLabel">Retailer Category</span>:null
                                }
                            </div>
                            </Link>

                            <Link to='/retailerGroup'>
                            <div className="MenuIcon_btn" title="Retailer Group" onClick={this.toggleMasterMenu}>
                                <span><FontAwesomeIcon icon={ faUsers }/></span>
                                {
                                this.props.toggleActive ?<span className="menuLabel">Retailer Group</span>:null
                                }
                            </div>
                            </Link>
                            
                            <Link to='/distributorCategory'>
                            <div className="MenuIcon_btn" title="Distributor Category" onClick={this.toggleMasterMenu}>
                                <span><FontAwesomeIcon icon={ faPeopleCarry }/></span>
                                {
                                this.props.toggleActive ?<span className="menuLabel">Distributor Category</span>:null
                                }
                            </div>
                            </Link>

                            <Link to='/distributorGroup'>
                            <div className="MenuIcon_btn" title="Distributor Group" onClick={this.toggleMasterMenu}>
                                <span><FontAwesomeIcon icon={ faProjectDiagram }/></span>
                                {
                                this.props.toggleActive ?<span className="menuLabel">Distributor Group</span>:null
                                }
                            </div>
                            </Link> */}

                                </div>
                            )
                            : (
                                null
                            )
                    }

                    {
                        this.state.viewDatashowMenu
                            ? (
                                <div className="menu" style={{ position: 'absolute', left: '3em', top: this.state.MastersMenuOffset.ClientY }}>
                                    {
                                        this.state.menuList.map((menu) => (
                                            menu.displayName === "Client - Data Sync" ?
                                                <Link to='/client'>
                                                    <div className="MenuIcon_btn" title="Client" onClick={this.toggleViewDataMenu}>
                                                        <span><FontAwesomeIcon icon={faCartPlus} /></span>
                                                        {
                                                            this.props.toggleActive ? <span className="menuLabel">Client</span> : null
                                                        }
                                                    </div>
                                                </Link>
                                                :
                                                menu.displayName === "Organization - Data Sync" ?
                                                    <Link to='/organization'>
                                                        <div className="MenuIcon_btn" title="Organization" onClick={this.toggleViewDataMenu}>
                                                            <span><FontAwesomeIcon icon={faExclamationTriangle} /></span>
                                                            {
                                                                this.props.toggleActive ? <span className="menuLabel">Organization</span> : null
                                                            }
                                                        </div>
                                                    </Link>
                                                    :
                                                    menu.displayName === "User - Data Sync" ?
                                                        <Link to='/user'>
                                                            <div className="MenuIcon_btn" title="User" onClick={this.toggleViewDataMenu}>
                                                                <span><FontAwesomeIcon icon={faUser} /></span>
                                                                {
                                                                    this.props.toggleActive ? <span className="menuLabel">User</span> : null
                                                                }
                                                            </div>
                                                        </Link>
                                                        :
                                                        menu.displayName === "Bank - Data Sync" ?
                                                            <Link to='/bank'>
                                                                <div className="MenuIcon_btn" title="Bank" onClick={this.toggleViewDataMenu}>
                                                                    <span><FontAwesomeIcon icon={faPiggyBank} /></span>
                                                                    {
                                                                        this.props.toggleActive ? <span className="menuLabel">Bank</span> : null
                                                                    }
                                                                </div>
                                                            </Link>
                                                            :
                                                            menu.displayName === "Invoice - Data Sync" ?
                                                                <Link to='/invoice'>
                                                                    <div className="MenuIcon_btn" title="Invoice" onClick={this.toggleViewDataMenu}>
                                                                        <span><FontAwesomeIcon icon={faFileInvoice} /></span>
                                                                        {
                                                                            this.props.toggleActive ? <span className="menuLabel">Invoice</span> : null
                                                                        }
                                                                    </div>
                                                                </Link>
                                                                :
                                                                menu.displayName === "UOM - Data Sync" ?
                                                                    <Link to='/uom'>
                                                                        <div className="MenuIcon_btn" title="UOM" onClick={this.toggleViewDataMenu}>
                                                                            <span><FontAwesomeIcon icon={faBalanceScale} /></span>
                                                                            {
                                                                                this.props.toggleActive ? <span className="menuLabel">UOM</span> : null
                                                                            }
                                                                        </div>
                                                                    </Link>
                                                                    :
                                                                    menu.displayName === "Product - Data Sync" ?
                                                                        <Link to='/productView'>
                                                                            <div className="MenuIcon_btn" title="ProductView" onClick={this.toggleViewDataMenu}>
                                                                                <span><FontAwesomeIcon icon={faCartArrowDown} /></span>
                                                                                {
                                                                                    this.props.toggleActive ? <span className="menuLabel">Product View</span> : null
                                                                                }
                                                                            </div>
                                                                        </Link>
                                                                        :
                                                                        menu.displayName === "Business Partner - Data Sync" ?
                                                                            <Link to='/businessPartner'>
                                                                                <div className="MenuIcon_btn" title="Business Partner" onClick={this.toggleViewDataMenu}>
                                                                                    <span><FontAwesomeIcon icon={faHandsHelping} /></span>
                                                                                    {
                                                                                        this.props.toggleActive ? <span className="menuLabel">Business Partner</span> : null
                                                                                    }
                                                                                </div>
                                                                            </Link>
                                                                            :
                                                                            menu.displayName === "Business Partner Location - Data Sync" ?
                                                                                <Link to='/businessPartnerLocation'>
                                                                                    <div className="MenuIcon_btn" title="Business Partner Location" onClick={this.toggleViewDataMenu}>
                                                                                        <span><FontAwesomeIcon icon={faMapMarkedAlt} /></span>
                                                                                        {
                                                                                            this.props.toggleActive ? <span className="menuLabel">Business Partner Location</span> : null
                                                                                        }
                                                                                    </div>
                                                                                </Link>
                                                                                :
                                                                                menu.displayName === "Document Type - Data Sync" ?
                                                                                    <Link to='/documentType'>
                                                                                        <div className="MenuIcon_btn" title="Document Type" onClick={this.toggleViewDataMenu}>
                                                                                            <span><FontAwesomeIcon icon={faPaste} /></span>
                                                                                            {
                                                                                                this.props.toggleActive ? <span className="menuLabel">Document Type</span> : null
                                                                                            }
                                                                                        </div>
                                                                                    </Link>
                                                                                    :
                                                                                    menu.displayName === "Location - Data Sync" ?
                                                                                        <Link to='/location'>
                                                                                            <div className="MenuIcon_btn" title="Location" onClick={this.toggleViewDataMenu}>
                                                                                                <span><FontAwesomeIcon icon={faSearchLocation} /></span>
                                                                                                {
                                                                                                    this.props.toggleActive ? <span className="menuLabel">Location</span> : null
                                                                                                }
                                                                                            </div>
                                                                                        </Link>
                                                                                        :
                                                                                        menu.displayName === "Payment Term - Data Sync" ?
                                                                                            <Link to='/paymentTerm'>
                                                                                                <div className="MenuIcon_btn" title="Payment Term" onClick={this.toggleViewDataMenu}>
                                                                                                    <span><FontAwesomeIcon icon={faMoneyCheckAlt} /></span>
                                                                                                    {
                                                                                                        this.props.toggleActive ? <span className="menuLabel">Payment Term</span> : null
                                                                                                    }
                                                                                                </div>
                                                                                            </Link>
                                                                                            :
                                                                                            menu.displayName === "Price List - Data Sync" ?
                                                                                                <Link to='/priceListView'>
                                                                                                    <div className="MenuIcon_btn" title="PriceListView" onClick={this.toggleViewDataMenu}>
                                                                                                        <span><FontAwesomeIcon icon={faMoneyBillAlt} /></span>
                                                                                                        {
                                                                                                            this.props.toggleActive ? <span className="menuLabel">PriceList View</span> : null
                                                                                                        }
                                                                                                    </div>
                                                                                                </Link>
                                                                                                :
                                                                                                menu.displayName === "Warehouse - Data Sync" ?
                                                                                                    <Link to='/wareHouse'>
                                                                                                        <div className="MenuIcon_btn" title="WareHouse" onClick={this.toggleViewDataMenu}>
                                                                                                            <span><FontAwesomeIcon icon={faWarehouse} /></span>
                                                                                                            {
                                                                                                                this.props.toggleActive ? <span className="menuLabel">WareHouse</span> : null
                                                                                                            }
                                                                                                        </div>
                                                                                                    </Link>
                                                                                                    :
                                                                                                    null
                                        ))
                                    }
                                </div>
                            )
                            : (
                                null
                            )
                    }

                    {
                        this.state.PriceshowMenu
                            ? (
                                <div className="menu" style={{ position: 'absolute', left: '3em', top: this.state.MastersMenuOffset.ClientY }}>
                                    <Link to='/priceType'>
                                        <div className="MenuIcon_btn" title="Price Type" onClick={this.togglePricesMenu}>
                                            <span><FontAwesomeIcon icon={faFileInvoiceDollar} /></span>
                                            {
                                                this.props.toggleActive ? <span className="menuLabel">Price Type</span> : null
                                            }
                                        </div>
                                    </Link>
                                    {/* <Link to='/retailerPricing'> 
                            <div className="MenuIcon_btn" title="Retailer Pricing" onClick={this.togglePricesMenu}>
                                <span><FontAwesomeIcon icon={ faHandHoldingUsd }/></span>
                                {
                                this.props.toggleActive ?<span className="menuLabel">Retailer Pricing</span>:null
                                }
                            </div>
                             </Link> 
                             <Link to='/distributorPricing'> 
                            <div className="MenuIcon_btn" title="Distributor Pricing" onClick={this.togglePricesMenu}>
                                <span><FontAwesomeIcon icon={ faPeopleCarry }/></span>
                                {
                                this.props.toggleActive ?<span className="menuLabel">Distributor Pricing</span>:null
                                }
                            </div>
                             </Link> 
                             <Link to='/retailerProductPrice'> 
                            <div className="MenuIcon_btn" title="Retailer Product Price" onClick={this.togglePricesMenu}>
                                <span><FontAwesomeIcon icon={ faLuggageCart }/></span>
                                {
                                this.props.toggleActive ?<span className="menuLabel">Retailer Product Price</span>:null
                                }
                            </div>
                             </Link> 
                             <Link to='/distributorProductPrice'> 
                                <div className="MenuIcon_btn" title="Distributor Product Price" onClick={this.togglePricesMenu}>
                                    <span><FontAwesomeIcon icon={ faProjectDiagram }/></span>
                                    {
                                    this.props.toggleActive ?<span className="menuLabel">Distributor Product Price</span>:null
                                    }
                                </div>
                             </Link> */}
                                </div>

                            )
                            : (
                                null
                            )
                    }
                </div>
            </nav>

        )
    }
}

//export default MenuModule

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}

export default connect(mapStateToProps)(MenuModule)
