import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faPlus, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class MetaDataModule extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,            
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            mdmMetaTypeList: [],
            mdmMetaDataList: [],
            mdmMetaDataOrgList: [],
            mdmMetaType:'',            
        }
    }

    componentDidMount() {
        this.getMetaTypeList();
    }

    getMetaTypeList() {
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/metadata/getMetaTypeList")
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({
                        mdmMetaTypeList: data.metaTypeList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getMDMMetaDataList() {

        const params = {           
            metaDataType: this.state.mdmMetaType
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/metadata/getMDMMetaDataList", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({
                        mdmMetaDataList: data.metaDataList,
                        mdmMetaDataOrgList: data.metaDataList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    mdmCancelfunction = () => {    
        if(this.state.mdmMetaType !== "" && this.state.mdmMetaType !== null && this.state.mdmMetaType !== undefined){
            this.getMDMMetaDataList();
        } 
    }

    mdmSavefunction = () => {
        //console.log(JSON.stringify(this.state.mdmMetaDataList)); 
        if (this.checkDataEnterMDM()) {
            alert("Please Enter All Value");
            return false;
        } else if (this.getUnique()) {
            alert("Remove Duplicate Record.");
            return false;
        } else if (this.getDSUnique()) {
            alert("Remove Duplicate Display Sequence.");
            return false;
        } else {
            let params = {
                "metaType": this.state.mdmMetaType,
                "lastModifiedBy":this.state.loginuserObj.userName,
                "metaDataList": this.state.mdmMetaDataList
            }
            //console.log("<-1->"+JSON.stringify(params));
            this.setState({
                loading: true
            });
            axios.post(this.state.httpURL + "/metadata/saveMetaData", params)
                .then(response => {
                    this.setState({
                        loading: false
                    });
                    if (response.data.valid) {
                        alert("Meta Data Saved Successfully.");
                        this.setState({
                            mdmMetaDataList: response.data.metaDataList,
                            mdmMetaDataOrgList: response.data.metaDataList
                        })
                        ReactTooltip.rebuild();
                    } else {
                        alert(response.data.responseMsg);
                    }
                })
                .catch(error => {
                    //console.log("error" + error)
                    this.setState({
                        loading: false
                    });
                    alert(error);
                })
        }        
    }

    filterList = (event) => {
        let filtered =this.state.mdmMetaDataOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
                
            }
        }
        this.setState({
            mdmMetaDataList: filtered
        })
    }

    DistributorStock_newRowHandler = () => {                
        if (this.state.mdmMetaType === "") {
            alert("Please Choose Meta Type");
        } else {
            var newind=this.state.mdmMetaDataList.length+1;
            var emptyobj = {
                metaDataId: newind,
                metaDataName: '',
                displayName: '',
                displaySeq: newind,
                isDeleted: 'N',
                displayStatus: 'Active',
                changeFlag: 'A',                
            }
            let stockListtemp = this.state.mdmMetaDataList
            stockListtemp.push(emptyobj)
            this.setState({ mdmMetaDataList: stockListtemp })            
        }
    }

    mdmMetaTypeListChange = (event) => {
        this.setState({
            mdmMetaType: event.value,
            mdmMetaDataList: [],
            mdmMetaDataOrgList: [],            
        }, () => {
            if(this.state.mdmMetaType !== "" && this.state.mdmMetaType !== null && this.state.mdmMetaType !== undefined){
                this.getMDMMetaDataList();
            }            
        });
    }

    editMDM_openHandler = (ind) => {
        const { mdmMetaDataList } = this.state;        
        mdmMetaDataList[ind].readOnly = false
        this.setState({ mdmMetaDataList: mdmMetaDataList })
    }

    deleteMDM_openHandler = (ind) => {
        var mdmMetaDataList = [...this.state.mdmMetaDataList];
        mdmMetaDataList.splice(ind, 1)
            this.setState({ mdmMetaDataList: mdmMetaDataList }, () => {                
                this.setState(prevState => {
                    let {mdmMetaDataList } = prevState;
                    mdmMetaDataList = mdmMetaDataList.map((item,index) => item.changeFlag === "A" ? { ...item, metaDataId : index + 1 ,displaySeq : index + 1 } : item);
                    return { mdmMetaDataList };
                });
            });            
    }

    mdmStatusChange = (event) => {
        this.setState({
            ptStatus: event.target.value
        });
    }

    handleMSMNameChange = (e) => {
        const { mdmMetaDataList } = this.state;
        const { id } = e.target;
        if(e.target.name === "metaDataName"){
            mdmMetaDataList[id].metaDataName = e.target.value;
        } else if(e.target.name === "displayName"){
            mdmMetaDataList[id].displayName = e.target.value;
        } else if(e.target.name === "displaySeq"){
            if(e.target.value !== null && e.target.value !== undefined && e.target.value !== ""){                
                mdmMetaDataList[id].displaySeq = parseInt(e.target.value, 10);
            } else {                
                mdmMetaDataList[id].displaySeq = e.target.value;
            }           
        } else if(e.target.name === "isDeleted"){
            mdmMetaDataList[id].isDeleted = e.target.value;
            if(e.target.value === 'N'){
                mdmMetaDataList[id].displayStatus = "Active";
            } else if(e.target.value === 'Y'){
                mdmMetaDataList[id].displayStatus = "In Active";
            } 
        }
        if(mdmMetaDataList[id].changeFlag === "N"){
            mdmMetaDataList[id].changeFlag = "U";
        }       
        this.setState({ mdmMetaDataList: mdmMetaDataList })        
    }

    checkDataEnterMDM() {
        var notEntered = false;
        let srlist = this.state.mdmMetaDataList;
        if(srlist.length > 0){
            for (var i = 0; i < srlist.length; i++) {                
                if (srlist[i].metaDataName === '' || srlist[i].metaDataName === undefined || srlist[i].metaDataName === null) {
                    notEntered = true;
                } else if (srlist[i].displayName === '' || srlist[i].displayName === undefined || srlist[i].displayName === null){
                    notEntered = true;
                } else if (srlist[i].displaySeq === '' || srlist[i].displaySeq === undefined || srlist[i].displaySeq === null || srlist[i].displaySeq === 0){
                    notEntered = true;
                }
            }
        } else {
            notEntered = true;
        }        
        return notEntered;
    }

    getUnique() {       
        var isUnique = false;
        let srlist = this.state.mdmMetaDataList;
        for (var i = 0; i < srlist.length; i++) {
            const dataName = srlist[i].metaDataName.split(' ').join('').toLowerCase();
            const displayName = srlist[i].displayName.split(' ').join('').toLowerCase();
            for (var j = i + 1; j < srlist.length; j++) {
                const dataName1 = srlist[j].metaDataName.split(' ').join('').toLowerCase();
                const displayName1 = srlist[j].displayName.split(' ').join('').toLowerCase();
                if (dataName === dataName1 || displayName === displayName1) {                   
                    isUnique = true;
                }                           
            }
        }
        return isUnique;
    }

    getDSUnique() {       
        var isUnique = false;
        let srlist = this.state.mdmMetaDataList;
        let mdlist = this.state.mdmMetaDataList;
        for (var i = 0; i < srlist.length; i++) {            
            for (var j = i + 1; j < mdlist.length; j++) {                         
                if (srlist[i].displaySeq === mdlist[j].displaySeq) {                   
                    isUnique = true;                    
                }                           
            }
        }
        return isUnique;       
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>META DATA</span></div>
                    <div className="O2Table_SubContainer distriStockSub">
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Meta Type</div>
                            <div className="contentRow_Value">
                                <Select options={this.state.mdmMetaTypeList} name="mdmMetaType" placeholder="Choose Meta Type" onChange={this.mdmMetaTypeListChange}/>
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label"></div>
                            <div className="contentRow_Value">
                                
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label"></div>
                            <div className="contentRow_Value">
                                
                            </div>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="metaData_Table">
                            <thead>
                                <tr>
                                    <th>Meta Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="metaDataName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th>Display Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="displayName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th>Display Sequence
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="displaySeq" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="Status_Route">Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="displayStatus" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container">
                                        <button><FontAwesomeIcon icon={faPlus} style={{ padding: '0px 4px' }} onClick={() => this.DistributorStock_newRowHandler()} /></button>
                                    </th>
                                    <th className="scroller"></th>                                                                        
                                </tr>
                            </thead>
                            <tbody>                                
                                {
                                    !this.state.mdmMetaDataList || this.state.mdmMetaDataList === null || this.state.mdmMetaDataList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No Meta Data Available</td>
                                        </tr> :
                                        this.state.mdmMetaDataList.map((meta,i) => (
                                            <tr key={i}>
                                                <td data-tip={meta.metaDataName}>
                                                    <input type="text" id={i} name ="metaDataName" value={meta.metaDataName} disabled={meta.readOnly} onChange={this.handleMSMNameChange}/>
                                                </td>
                                                <td data-tip={meta.displayName}>
                                                    <input type="text" id={i} name ="displayName" value={meta.displayName} disabled={meta.readOnly} onChange={this.handleMSMNameChange}/>
                                                </td>
                                                <td data-tip={meta.displaySeq}>
                                                    <input type="text" id={i} name ="displaySeq" value={meta.displaySeq} disabled={meta.readOnly} onChange={this.handleMSMNameChange}/>
                                                </td>
                                                <td className="Status_Route">
                                                    <select id={i} name ="isDeleted" value={meta.isDeleted} disabled={meta.readOnly} onChange={this.handleMSMNameChange}>
                                                        <option value="N" >Active</option>
                                                        <option value="Y" >In Active</option>
                                                    </select>
                                                </td>
                                                <td className="o2Del_container">
                                                    <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.editMDM_openHandler(i)} />
                                                    {
                                                        meta.changeFlag === "A" ? 
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}} onClick={ ()=> this.deleteMDM_openHandler(i)}/>
                                                        : null
                                                    }                                                                                                       
                                                </td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.mdmMetaDataList ? this.state.mdmMetaDataList.length : 0}</span>entries</span></div>                        
                    </div>                    
                    <div className="O2Table_Footer">                         
                        <button onClick={() =>this.mdmSavefunction()}>Save</button>                       
                        <button onClick={() =>this.mdmCancelfunction()}>Cancel</button>
                    </div>
                </div>                
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(MetaDataModule)
//export default PriceTypeModule
