import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from 'react-select';
import './ReportsModule.css';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
class ReportsModule extends Component {
    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            orderList: [],
            salesmanList: [],
            selectedsalesmanList: [],
            reportList: [],
            orgreportList: [],
            reSalesmanCode: '',
            orderObj: '',
            startDate: todaydate,
            endDate: todaydate,
            smanCode: '',
            todaydate: todaydate,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            userType: this.props.userObj.userType,
            loginSalesmanCode: this.props.userObj.salesmanCode,
            rmOrderTypeFilter: '',
            rmOrderTypeList: [],
            dsrState: '',
            dsrStateList: [],
        }
    }

    componentDidMount() {
        this.getAllSalesmans();
    }

    loadReport() {
        let scode = this.state.salesmanCode;
        if (this.state.loginuserObj.userType === "salesman") {
            scode = scode;
        } else {
            scode = 'All';
        }
        this.setState({
            reSalesmanCode: scode,
            smanCode: scode
        }, () => {
            this.getAllSalesmans();
        });

    }

    getAllSalesmans() {

        let scode = this.state.salesmanCode;
        if (this.state.loginuserObj.userType === "salesman") {
            scode = scode;
        } else {
            // scode = 'All';
            scode = scode;
        }

        const params = {
            salesmanCode: scode,
            companyCode: this.state.companyCode,
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/report/getSalesmanList", { params })
            .then(response => response.data)
            .then((data) => {
                // console.log(data)
                this.setState({
                    loading: false
                })
                this.setState({ salesmanList: data.salesmansList,
                    dsrStateList : data.stateList,  
                 }, () => {

                    if (data.salesmansList.length === 1) {
                        var temparry = []
                        temparry.push({
                            label: data.salesmansList[0].label,
                            value: data.salesmansList[0].value
                        });

                        this.setState({
                            selectedsalesmanList: temparry
                        })
                    }

                })
                // this.setState({ 
                //     rmOrderTypeList : data.orderTypeList,
                //     rmOrderTypeFilter:data.orderTypeList[0].label
                // })


            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                })
                alert(err);
            })
    }

    loadSalesmanList() {
        var dList = this.state.salesmanList;
        var sman = this.state.reSalesmanCode;
        dList.map(function (sales, i) {

            if (sales.salesmanCode === sman) {
                //this.setState({ salesmanList: dList[i] })
            } else {
                dList.splice(i, 1)
            }

        });
        this.setState({ salesmanList: dList })
    }

    reportGo = () => {
        var dFlag = true;
        let stateVal = '';
        if (this.state.startDate !== '' && this.state.endDate !== '') {
            if (this.state.startDate > this.state.endDate) {
                alert("From Date Cannot Be Greater Than End Date");
                dFlag = false;
            }
        } else {
            alert("Please select From and To Date...");
            dFlag = false;
        }

        if(this.state.dsrState === '' || this.state.dsrState === undefined){
            stateVal = "All";
        } else {
            stateVal = this.state.dsrState;
        }

        if (dFlag) {

            // console.log("len:"+this.state.selectedsalesmanList.length)

            let salesmnallist = "";

            // if(this.state.selectedsalesmanList.length===0){
            //     if(this.state.userType === "salesman"){
            //         salesmnallist = "'"+this.state.salesmanCode+"',"
            //         salesmnallist = salesmnallist.substring(0, salesmnallist.length - 1);
            //     } else {
            //     salesmnallist="";
            //     }
            // }else{

            //     this.state.selectedsalesmanList.map((sal) => (
            //         salesmnallist +="'"+sal.value+"',"
            //     ));

            //     salesmnallist = salesmnallist.substring(0, salesmnallist.length - 1);
            // }
            // console.log("salesmnallist-->"+salesmnallist)
            salesmnallist += "'" + this.state.reSalesmanCode + "'"
            let tempSalesmanCode = this.state.reSalesmanCode;
            if (tempSalesmanCode === "" || tempSalesmanCode === null || tempSalesmanCode === undefined) {
                tempSalesmanCode = this.state.loginSalesmanCode;
            } else {
                tempSalesmanCode = this.state.reSalesmanCode;
            }
            const params = {
                // salesmanCode: salesmnallist,
                salesmanCode: tempSalesmanCode,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                loginSalesmanCode: this.state.loginSalesmanCode,
                companyCode: this.state.companyCode,
                orderType: this.state.rmOrderTypeFilter,
                state: stateVal,
            }


            this.setState({
                loading: true
            })
            axios.get(this.state.httpURL + "/report/getSalesmanReport", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.setState({
                            loading: false
                        })
                        this.setState({
                            reportList: data.reportList,
                            orgreportList: data.reportList,
                        })
                        ReactTooltip.rebuild();
                    } else {
                        alert(data.responseMsg);
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    })
                    alert(err);
                })


        }
    }

    reportChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSalesmanChange = (event) => {
        // this.setState({
        //     salesmanCode: event.value
        // });
        this.setState({
            selectedsalesmanList: event,
            reSalesmanCode: event.value
        });
    }

    handleRMOrderTypeFilter = (event) => {
        this.setState({
            rmOrderTypeFilter: event.label
        }, () => {

        });
    }

    handleDSRStateChange = (event) => {
        this.setState({
            dsrState: event.value,
        }, () => {
            this.reportGo();
        });
    }

    filterList = (event) => {
        let filtered = this.state.orgreportList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            reportList: filtered
        })
    }


    dsrExcel = () => {
        var dFlag = true;
        if (this.state.reportList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.reportList;
            var fileName = "Salesman DSRReport_" + this.state.todaydate;
            var wscols = [
                { wch: 30 },
                { wch: 40 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 15 },
                { wch: 15 },
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Sales Person": dList[i].salesmanName,
                    "Retailer": dList[i].retailerName,
                    "Date": dList[i].date,
                    "Time In": dList[i].timeIn,
                    "Time Out": dList[i].timeOut,
                    "Order Value": dList[i].orderValue.toFixed(2),
                    ///"Toatal Amount": dList[i].collectionAmt.toFixed(2), 
                    "Order Type": dList[i].orderType
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'DSR': ws }, SheetNames: ['DSR'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    render() {
        const salesmanSearch = [];

        for (const [index, value] of this.state.salesmanList.entries()) {
            salesmanSearch.push({
                label: value.salesmanName,
                value: value.salesmanCode
            });
        }

        return (

            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>DSR REPORTS</span></div>
                    <div className="O2Table_Body">
                        <div className="O2Table_SubContainer Report_subContainer">

                            <div className="O2Content_row">
                                <div className="contentRow_Label">State</div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.dsrStateList} name="dsrState" value={this.state.dsrStateList.filter(({ value }) => value === this.state.dsrState)} placeholder="Choose State" onChange={this.handleDSRStateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Sales Person</div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.salesmanList} name="reSalesmanCode" placeholder="Choose Sales Person" value={this.state.selectedsalesmanList} onChange={this.handleSalesmanChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date From </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="startDate" value={this.state.startDate} onChange={this.reportChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">Date To </div>
                                <div className="contentRow_Value">
                                    <input type="date" name="endDate" value={this.state.endDate} onChange={this.reportChange} />
                                </div>
                            </div>
                            {/* <div className="O2Content_row">
                                <div className="contentRow_Label">Order Type</div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.rmOrderTypeList} value={this.state.rmOrderTypeList.filter(({value}) => value === this.state.rmOrderTypeFilter)} onChange={this.handleRMOrderTypeFilter}></Select>
                                </div>
                            </div>  */}

                            <div className="O2Table_Go" onClick={this.reportGo}><button>Go</button></div>
                        </div>
                        {/* <div className="O2Table_SubContainer">
                    <div className="O2Table_dateFrm">
                        <div className="O2Table_Label">Date From : </div>
                        <div className="O2Table_Value"><input type="date" /></div>
                    </div>
                    <div className="O2Table_dateTo">
                        <div className="O2Table_Label">Date To : </div>
                        <div className="O2Table_Value"><input type="date"/></div>
                    </div>
                    <div class="O2Table_Go"><button>Go</button></div>
                </div> */}
                        <div className="Table_wrapper">
                            <table className="customTable" id="salesmanDSRReport_Table">
                                <thead>
                                    <tr>
                                        <th className="Salemanno_width">Sales Person
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="salesmanName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Descript_width">Retailer
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="status_Width">Date
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="date" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="status_Width">Time In
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="timeIn" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="status_Width">Time Out
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="timeOut" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="Value_width">Order Value
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderValue" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        {/* <th className="Value_width">Total Amt
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="collectionAmt" onChange={this.filterList} />
                                            </div>
                                        </th> */}
                                        {/* <th className="status_Width">Order Type
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderType" onChange={this.filterList} />
                                            </div>
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody>

                                    {/* <td>Gowtham</td>
                            <td>SAI Enterprise</td>
                            <td>06/05/2020</td>
                            <td>11.00 Am</td>
                            <td>12.00 PM</td>
                            <td>Rs.20000</td>
                            <td>Rs.50000</td> */}
                                    {
                                        !this.state.reportList || this.state.reportList === null || this.state.reportList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="6">No Records</td>
                                            </tr> :
                                            this.state.reportList.map((report) => (
                                                <tr>
                                                    <td className="Salemanno_width" data-tip={report.salesmanName}>{report.salesmanName}</td>
                                                    <td className="Descript_width" data-tip={report.retailerName}>{report.retailerName}</td>
                                                    <td className="status_Width" data-tip={report.date}>{report.date}</td>
                                                    <td className="status_Width" data-tip={report.timeIn}>{report.timeIn}</td>
                                                    <td className="status_Width" data-tip={report.timeOut}>{report.timeOut}</td>
                                                    <td className="Value_width" data-tip={report.orderValue.toFixed(2)}>{report.orderValue.toFixed(2)}</td>
                                                    {/* <td className="Value_width" data-tip={report.collectionAmt.toFixed(2)}>{report.collectionAmt.toFixed(2)}</td> */}
                                                    {/* <td className="status_Width" data-tip={report.orderType}>{report.orderType}</td> */}
                                                </tr>
                                            ))
                                    }

                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.reportList ? this.state.reportList.length : 0}</span>entries</span></div>
                            {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                            <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Export</div> */}
                            {/* <div className="O2Table_ExpCollExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="salesmanDSRReport_Table" filename={"SalesmanDSRReport_" + this.state.todaydate}
                                sheet="tablexls" buttonText="Excel Export" /></div> */}
                            <div className="O2Table_ExpExcel" onClick={() => this.dsrExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}

export default connect(mapStateToProps)(ReportsModule)

//export default ReportsModule
