import React, { Component } from 'react'
import Select from 'react-select';
import './Salesman.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faCloudDownloadAlt, faPencilAlt, faTrashAlt, faFileAlt, faLeaf, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -385px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class Salesman extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            smSalesmanList: [],
            smOrgSalesmanList: [],
            smUserList: [],
            smDistributorList: [],
            smSalesManDetailList: [],
            smUserValue: '',
            smDistributorValue: '',
            smSalesManId: '',
            smActive: '',
            smCompanySalesman: '',
            smUser: '',
            smMultipleRoute: '',
            smActiveChecked: false,
            smUserChecked: false,
            smCompanySalesmanChecked: false,
            smMultipleRouteChecked: false,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj.distributorCode,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            salesmanbtn: "Popup_tabButtons",
            productGrpbtn: "Popup_tabButtons",
            productGroupList: [],
            allChecked: false,
            salesmanWriteAccess: this.props.userObj.salesmanWriteAccess,
            currentDate: todaydate
        }

    }

    poptabSalesmanHandler = () => {
        this.setState({
            salesmanbtn: "Popup_tabButtonsActive",
            productGrpbtn: "Popup_tabButtons",
        })
        document.querySelector('.SalesmanTab').style.display = 'block';
        document.querySelector('.ProductgroupTab').style.display = 'none';

    }
    poptabProductGrpHandler = () => {
        this.setState({
            salesmanbtn: "Popup_tabButtons ",
            productGrpbtn: "Popup_tabButtonsActive",
        })
        document.querySelector('.ProductgroupTab').style.display = 'block';
        document.querySelector('.SalesmanTab').style.display = 'none';
    }

    sm_popCloseHandler = () => {
        document.querySelector('.saleManTableEdit_pop').style.display = 'none';
        this.setState({
            allChecked: false
        })
    }
    editSM_openHandler = () => {
        this.getSMDetailList();
        // document.querySelector('.saleManTableEdit_pop').style.display = 'flex';
    }
    newSM_openHandler = () => {
        this.setState({
            salesmanbtn: "Popup_tabButtonsActive",
            productGrpbtn: "Popup_tabButtons",
            allChecked: false
        })
        this.clearSalesmanDetail();
        this.setState(prevState => {
            let { productGroupList } = prevState;
            productGroupList = productGroupList.map(item => ({ ...item, isChecked: false }));
            return { productGroupList };
        });

        document.querySelector('.saleManTableEdit_pop').style.display = 'flex';
    }


    componentDidMount() {
        this.getSalesManList();
    }

    getSalesManList() {
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/salesman")
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        smSalesmanList: data.salesmanList,
                        smOrgSalesmanList: data.salesmanList,
                        smUserList: data.userList,
                        smDistributorList: data.distributorList,
                        productGroupList: data.productGroupList
                    })
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                })
                ReactTooltip.rebuild();
            }).catch(err => {
                //console.log(err)
                alert(err);
                this.setState({
                    loading: false
                })
            })
    }

    handleSMDistributorChange = (event) => {
        this.setState({
            smDistributorValue: event.value
        });

    }

    handleSMUserChange = (event) => {
        this.setState({
            smUserValue: event.value
        });

    }

    getSMDetailList = (smlist) => {
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/salesman/" + smlist.salesmanId)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                this.setState({
                    smSalesManDetailList: data.salesmanList,
                    productGroupList: data.productGroupList
                }, () => {
                    this.loadRetailerDetail();
                    document.querySelector('.saleManTableEdit_pop').style.display = 'flex';
                })
            }).catch(err => {
                //console.log(err)
                alert(err);
                this.setState({
                    loading: false
                })
            })
    }

    loadRetailerDetail = () => {

        this.state.smSalesManDetailList.map(function (sm, i) {
            var smActiveflag = false;
            var smCompanySalesmanflag = false;
            var smUserflag = false;
            var smMultipleRouteflag = false;
            if (sm.isActive == 'Y') {
                smActiveflag = true
            }
            if (sm.isCompanySalesman == 'Y') {
                smCompanySalesmanflag = true
            }
            if (sm.isUser == 'Y') {
                smUserflag = true
            }
            if (sm.multipleRoute == 'Y') {
                smMultipleRouteflag = true
            }
            this.setState({
                smSalesManId: sm.salesmanId,
                smDistributorValue: sm.distributorCode,
                smSalesmanName: sm.salesmanName,
                smBrandCode: sm.brandCode,
                smAddress1: sm.salesmanAddress1,
                smAddress2: sm.salesmanAddress2,
                smAddress3: sm.salesmanAddress3,
                smCityName: sm.city,
                smStateName: sm.state,
                smCountryName: sm.country,
                // smActive: sm.isActive,
                smUserValue: sm.userCode,
                //smCompanySalesman: sm.isCompanySalesman,
                smHierarchyLevel: sm.salesmanHierarchyLevel,
                smHierarchyPath: sm.salesmanHierarchyPath,
                smGeoPath: sm.geoPathMapped,
                //  smUser: sm.isUser,
                //  smMultipleRoute: sm.multipleRoute,
                smTeamNameList: sm.teamNameList,
                smActiveChecked: smActiveflag,
                smCompanySalesmanChecked: smCompanySalesmanflag,
                smUserChecked: smUserflag,
                smMultipleRouteChecked: smMultipleRouteflag
            })
        }.bind(this));
    }

    smChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    smCheckBoxChange = (e) => {
        if (e.target.name == 'smActive') {
            if (e.target.checked) {
                this.setState({
                    smActiveChecked: true,
                    [e.target.name]: e.target.checked
                });
            } else {
                this.setState({
                    smActiveChecked: false,
                    [e.target.name]: e.target.checked
                });
            }
        } else if (e.target.name == 'smCompanySalesman') {
            if (e.target.checked) {
                this.setState({
                    smCompanySalesmanChecked: true,
                    [e.target.name]: e.target.checked
                });
            } else {
                this.setState({
                    smCompanySalesmanChecked: false,
                    [e.target.name]: e.target.checked
                });
            }
        } else if (e.target.name == 'smUser') {
            if (e.target.checked) {
                this.setState({
                    smUserChecked: true,
                    [e.target.name]: e.target.checked
                });
            } else {
                this.setState({
                    smUserChecked: false,
                    [e.target.name]: e.target.checked
                });
            }
        } else if (e.target.name == 'smMultipleRoute') {
            if (e.target.checked) {
                this.setState({
                    smMultipleRouteChecked: true,
                    [e.target.name]: e.target.checked
                });
            } else {
                this.setState({
                    smMultipleRouteChecked: false,
                    [e.target.name]: e.target.checked
                });
            }
        }

        // this.setState({
        //     [e.target.name]:e.target.checked
        // });
    }

    deleteSM_openHandler = (smlist) => {
        if (window.confirm("Delete The SalesMan?")) {
            this.setState({
                loading: true
            })
            axios.delete(this.state.httpURL + "/salesman/" + smlist.salesmanId)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        })
                        if (response.data.valid) {
                            alert("SalesMan Deleted Successfull ...");
                            this.getSalesManList();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    })
                    alert(err);
                })
        }
    }

    saveSMDetail = (e) => {


        if (this.state.smDistributorValue == "All") {
            alert("Please Distributor Code");
        } else if (this.state.smSalesmanName == "") {
            alert("Please Enter Salesman Name");
        } else if (this.state.smAddress1 == "") {
            alert("Please Enter Salesman Address 1");
        } else if (this.state.smAddress2 == "") {
            alert("Please Enter Salesman Address 2");
        } else if (this.state.smAddress3 == "") {
            alert("Please Enter Salesman Address 3");
        } else if (this.state.smCityName == "") {
            alert("Please Enter City");
        } else if (this.state.smStateName == "") {
            alert("Please Enter State");
        } else if (this.state.smCountryName == "") {
            alert("Please Enter Country");
        } else if (this.state.smUserValue == "All") {
            alert("Please Enter User Code");
        } else if (!this.checkproductGroupselected()) {
            alert("Please Select Anyone Product Group");
        }
        /*      else if (this.state.smHierarchyLevel == "") {
                 alert("Please Enter Hierarchy Level");
             } else if (this.state.smHierarchyPath == "") {
                 alert("Please Enter Hierarchy Path");
             } else if (this.state.smGeoPath == "") {
                 alert("Please Enter Geo Path");
             } */
        else if (this.state.smTeamNameList == "") {
            alert("Please Enter Team Name List");
        } else {
            var smActiveflag = 'N';
            var smCompanySalesmanflag = 'N';
            var smUserflag = 'N';
            var smMultipleRouteflag = 'N';

            if (this.state.smActiveChecked) {
                smActiveflag = 'Y';
            }
            if (this.state.smCompanySalesmanChecked) {
                smCompanySalesmanflag = 'Y'
            }
            if (this.state.smUserChecked) {
                smUserflag = 'Y'
            }
            if (this.state.smMultipleRouteChecked) {
                smMultipleRouteflag = 'Y';
            }

            e.preventDefault();
            let uCode = this.state.loginuserObj.userName;

            const smData = [{
                salesmanId: this.state.smSalesManId,
                distributorCode: this.state.smDistributorValue,
                salesmanName: this.state.smSalesmanName,
                brandCode: this.state.smBrandCode,
                salesmanAddress1: this.state.smAddress1,
                salesmanAddress2: this.state.smAddress2,
                salesmanAddress3: this.state.smAddress3,
                country: this.state.smCountryName,
                state: this.state.smStateName,
                city: this.state.smCityName,
                userCode: this.state.smUserValue,
                // salesmanHierarchyLevel: this.state.smHierarchyLevel,
                // salesmanHierarchyPath: this.state.smHierarchyPath,
                //  geoPathMapped: this.state.smGeoPath,
                teamNameList: this.state.smTeamNameList,
                isActive: smActiveflag,
                isCompanySalesman: smCompanySalesmanflag,
                isUser: smUserflag,
                multipleRoute: smMultipleRouteflag,
                modifiedBy: uCode,
                companyCode: this.state.companyCode
            }];

            let params = {
                "salesmanList": smData,
                "productGroupList": this.state.productGroupList
            }
            this.setState({
                loading: true
            })
            axios.post(this.state.httpURL + "/salesman/", params)
                .then(response => {
                    if (response.data !== null) {
                        if (response.data.valid) {
                            this.setState({
                                loading: false
                            })
                            if (response.data.existSalesMan) {
                                alert("This SalesMan Name Is Already Exist.");
                            } else {
                                alert("SalesMan Details Saved Successfully.");
                                document.querySelector('.saleManTableEdit_pop').style.display = 'none';
                                this.clearSalesmanDetail();
                                this.getSalesManList();
                            }
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                })
                .catch(error => {
                    // console.log("error" + error)
                    this.setState({
                        loading: false
                    })
                    alert(error);
                })
        }
    }

    checkproductGroupselected() {
        let selected = false;
        let productGroupList = this.state.productGroupList;
        for (var i = 0; i < productGroupList.length; i++) {
            if (productGroupList[i].isChecked) {
                selected = true
            }
        }
        return selected;
    }
    clearSalesmanDetail() {
        this.setState({
            smSalesManId: '',
            smDistributorValue: 'All',
            smSalesmanName: '',
            smBrandCode: '',
            smAddress1: '',
            smAddress2: '',
            smAddress3: '',
            smCityName: '',
            smStateName: '',
            smCountryName: '',
            smActive: '',
            smUserValue: 'All',
            smCompanySalesman: '',
            smHierarchyLevel: '',
            smHierarchyPath: '',
            smGeoPath: '',
            smUser: '',
            smMultipleRoute: '',
            smTeamNameList: '',
            smActiveChecked: false,
            smUserChecked: false,
            smCompanySalesmanChecked: false,
            smMultipleRouteChecked: false,
        })
    }

    filterList = (event) => {
        let filtered = this.state.smOrgSalesmanList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            smSalesmanList: filtered
        })
    }

    handleproductGroupChange = (e, ind) => {
        //  console.log("index-->"+ind)
        let itemname = e.target.name;
        const { productGroupList } = this.state;
        // console.log("itemname--"+itemname)
        productGroupList[ind].isChecked = !productGroupList[ind].isChecked
        this.setState({ productGroupList: productGroupList })
    }

    handleAllproductGroupChange = (e) => {
        //  console.log("index-->"+ind)

        let checked = e.target.checked;
        this.setState(prevState => {
            let { productGroupList, allChecked } = prevState;
            allChecked = checked;
            productGroupList = productGroupList.map(item => ({ ...item, isChecked: checked }));
            return { productGroupList, allChecked };
        });

    }

    salemanExcel = () => {
        var dFlag = true;
        if (this.state.smSalesmanList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.smSalesmanList;
            var fileName = "Salesman Report_" + this.state.currentDate;
            var wscols = [
                { wch: 30 },
                { wch: 35 },
                { wch: 10 },
                { wch: 20 },
                { wch: 15 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Distributor": dList[i].distributorName,
                    "Sales Person Name": dList[i].salesmanName,
                    "Status": dList[i].isActive,
                    "Company Sales Person": dList[i].isCompanySalesman,
                    "Multiple Route": dList[i].multipleRoute
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'Salesman': ws }, SheetNames: ['Salesman'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    render() {

        const { smSalesmanName, smBrandCode, smAddress1, smAddress2, smAddress3, smCityName, smStateName, smCountryName, smHierarchyLevel, smHierarchyPath, smGeoPath, smUser, smMultipleRoute, smTeamNameList } = this.state

        const distributorCodeSearch = [];
        const userCodeSearch = [];

        for (const [index, value] of this.state.smDistributorList.entries()) {
            distributorCodeSearch.push({
                label: value.distributorName,
                value: value.distributorCode
            });
        }

        for (const [index, value] of this.state.smUserList.entries()) {
            userCodeSearch.push({
                label: value.userName,
                value: value.userName
            });
        }

        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>SALES PERSON</span></div>
                    <div className="O2Table_Body">
                        {/* <div className="O2Table_SubContainer">
                        <div className="O2Table_SelectSales">Salesman<select><option>All</option></select></div>
                        <div className="O2Table_SelectDist">Distributor<select><option>All</option></select></div>
                    </div> */}
                        <div className="O2Table_SubContainer">
                            {/* <div className="O2Table_Records">
                            <div className="O2Table_Value"><select><option>5</option></select></div>
                            <div className="O2Table_Label">Records per pages</div>
                        </div> */}
                            {/* <div className="O2Table_Search">
                                <div className="O2Table_Label"> Search : </div>
                                <div className="O2Table_Value"><input type="text" onChange={this.filterList} /></div>
                            </div> */}
                            {/* <div className="O2Table_New">
                                <button onClick={this.newSM_openHandler}>New</button>
                            </div> */}
                        </div>
                        <div className="Table_wrapper">
                            <table className="customTable" id="salesMan_Table">
                                <thead>
                                    <tr>
                                        {/* <th className="checkBox_Container"></th> */}
                                        <th className="DistributorEdit">Distributor
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="distributorName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="salesMan">Sales Person Name
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="salesmanName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="status_Width">Status
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="isActive" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="status_Width">Company Sales Person
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="isCompanySalesman" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="status_Width">Multiple Route
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="multipleRoute" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="o2Del_container"></th>
                                        <th className="scroller"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.smSalesmanList || this.state.smSalesmanList === null || this.state.smSalesmanList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="5">No Sales Person Available</td>
                                            </tr> :
                                            this.state.smSalesmanList.map((smlist) => (
                                                <tr key={smlist.salesmanId}>
                                                    {/* <td className="checkBox_Container">
                                                        <input type="checkbox" />
                                                    </td> */}
                                                    <td className="DistributorEdit" data-tip={smlist.distributorName}>{smlist.distributorName}
                                                        {/* <button className="editBtn" onClick={() => this.getSMDetailList(smlist)}><FontAwesomeIcon icon={faPencilAlt} style={{ padding: '0px 8px' }} /></button> */}
                                                    </td>
                                                    <td className="salesMan" data-tip={smlist.salesmanName}>{smlist.salesmanName}</td>
                                                    <td className="status_Width" data-tip={smlist.isActive}>{smlist.isActive}</td>
                                                    <td className="status_Width" data-tip={smlist.isCompanySalesman}>{smlist.isCompanySalesman}</td>
                                                    <td className="status_Width" data-tip={smlist.multipleRoute}>{smlist.multipleRoute}</td>
                                                    <td className="o2Del_container">
                                                        <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.getSMDetailList(smlist)} />
                                                        {
                                                            this.state.salesmanWriteAccess ?
                                                                <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} onClick={() => this.deleteSM_openHandler(smlist)} />
                                                                : null
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.smSalesmanList ? this.state.smSalesmanList.length : 0}</span>entries</span></div>
                            {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                            <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> Export</div> */}
                            {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="salesMan_Table" filename={"SalesManTable"}
                                sheet="tablexls" buttonText="Excel Export" /></div> */}
                            <div className="O2Table_ExpExcel" onClick={() => this.salemanExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                        </div>
                    </div>
                </div>
                <div className="O2TableEdit_wrapper saleManTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="Popup_Tabwrapper">
                                <div className={this.state.salesmanbtn} onClick={this.poptabSalesmanHandler}><span>Sales Person</span></div>
                                <div className={this.state.productGrpbtn} onClick={this.poptabProductGrpHandler}><span>Sales Person Product Group</span></div>
                            </div>
                            {/* <div className="O2TableEditTitle">SalesMan</div> */}
                            <div className="Popup_tabContentwrapper">
                                <div className="popTabBody SalesmanTab">
                                    <div className="SM_BodyContent">
                                        <div className="row">
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Distributor Code <span>*</span></div><span>:</span>
                                                <div className="FieldValue">
                                                    <Select options={distributorCodeSearch} name="distributorCode" value={distributorCodeSearch.filter(({ value }) => value === this.state.smDistributorValue)} placeholder="Choose Distributor Code" onChange={this.handleSMDistributorChange} />
                                                </div>
                                            </div>
                                            <div className="FieldComponent Resolhide">
                                                <div className="FieldLabel"></div>
                                                <div className="FieldValue"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Sales Person Name <span>*</span></div><span>:</span>
                                                <div className="FieldValue"><input labeler="SalesmanName" name='smSalesmanName' value={smSalesmanName} type="text" onChange={this.smChange} /></div>
                                            </div>
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Brand Code </div><span>:</span>
                                                <div className="FieldValue"><input labeler="Brand Code" name='smBrandCode' value={smBrandCode} type="text" onChange={this.smChange} /></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Address 1<span>*</span></div><span>:</span>
                                                <div className="FieldValue"><input labeler="Address1" name='smAddress1' value={smAddress1} type="text" onChange={this.smChange} /></div>
                                            </div>
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Address 2<span>*</span></div><span>:</span>
                                                <div className="FieldValue"><input labeler="Address2" name='smAddress2' value={smAddress2} type="text" onChange={this.smChange} /></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Address 3<span>*</span></div><span>:</span>
                                                <div className="FieldValue"><input labeler="Address3" name='smAddress3' value={smAddress3} type="text" onChange={this.smChange} /></div>
                                            </div>
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">City<span>*</span></div><span>:</span>
                                                <div className="FieldValue"><input labeler="City" name='smCityName' value={smCityName} type="text" onChange={this.smChange} /></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">State<span>*</span></div><span>:</span>
                                                <div className="FieldValue"><input labeler="State" name='smStateName' value={smStateName} type="text" onChange={this.smChange} /></div>
                                            </div>
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Country<span>*</span></div><span>:</span>
                                                <div className="FieldValue"><input labeler="Country" name='smCountryName' value={smCountryName} type="text" onChange={this.smChange} /></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Active<span></span></div><span>:</span>
                                                <div className="FieldValue"><input type="checkbox" name='smActive' checked={this.state.smActiveChecked} onChange={this.smCheckBoxChange} /></div>
                                            </div>
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">User Code<span>*</span></div><span>:</span>
                                                <div className="FieldValue">
                                                    <Select options={userCodeSearch} name="smUserValue" value={userCodeSearch.filter(({ value }) => value === this.state.smUserValue)} placeholder="Choose User" onChange={this.handleSMUserChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Company Sales Person<span></span></div><span>:</span>
                                                <div className="FieldValue"><input type="checkbox" name='smCompanySalesman' checked={this.state.smCompanySalesmanChecked} onChange={this.smCheckBoxChange} /></div>
                                            </div>
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Team Name List <span>*</span></div><span>:</span>
                                                <div className="FieldValue"><input labeler="TeamNameList" name='smTeamNameList' value={smTeamNameList} type="text" onChange={this.smChange} /></div>
                                            </div>
                                            {/* <div className="FieldComponent">
                                                <div className="FieldLabel">Hierarchy Level<span>*</span></div><span>:</span>
                                                <div className="FieldValue"><input labeler="Hierarchylevel" name='smHierarchyLevel' value={smHierarchyLevel} type="text" onChange={this.smChange} /></div>
                                            </div> */}
                                        </div>
                                        {/* <div className="row">
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Hierarchy Path<span>*</span></div><span>:</span>
                                                <div className="FieldValue"><input labeler="Hierarchypath" name='smHierarchyPath' value={smHierarchyPath} type="text" onChange={this.smChange} /></div>
                                            </div>
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Geo Path<span>*</span></div><span>:</span>
                                                <div className="FieldValue"><input labeler="Geopath" name='smGeoPath' value={smGeoPath} type="text" onChange={this.smChange} /></div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">User<span></span></div><span>:</span>
                                                <div className="FieldValue"><input type="checkbox" name='smUser' checked={this.state.smUserChecked} onChange={this.smCheckBoxChange} /></div>
                                            </div>
                                            <div className="FieldComponent">
                                                <div className="FieldLabel">Multiple Route<span></span></div><span>:</span>
                                                <div className="FieldValue"><input type="checkbox" name='smMultipleRoute' checked={this.state.smMultipleRouteChecked} onChange={this.smCheckBoxChange} /></div>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            
                                            <div className="FieldComponent">
                                                <div className="FieldLabel"></div>
                                                <div className="FieldValue"></div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="popTabBody ProductgroupTab">
                                    <div className="tabBodyContent">
                                        <div className="Table_wrapper">
                                            <table className="customTable">
                                                <thead>
                                                    <tr>
                                                        <th className="SelectcheckBox_Container">
                                                            <input type="checkbox" name={this.state.allChecked} value={this.state.allChecked} onChange={(e) => this.handleAllproductGroupChange(e)} />
                                                        </th>
                                                        <th>Product Group Name</th>
                                                        <th className="scroller"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.productGroupList.map((pg, i) => (
                                                            <tr key={i}>
                                                                <td className="SelectcheckBox_Container">
                                                                    <input type="checkbox" name={pg.isChecked} id={pg.productGroupId} value={pg.isChecked} checked={pg.isChecked} onChange={(e) => this.handleproductGroupChange(e, i)} />
                                                                </td>
                                                                <td> {pg.productGroupName}</td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="SM_BodyContent">

                                    </div>
                                </div>
                            </div>

                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    {
                                        this.state.salesmanWriteAccess ?
                                            <button onClick={this.saveSMDetail}>Save</button>
                                            : null
                                    }
                                    <button onClick={this.sm_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(Salesman)


//export default Salesman
