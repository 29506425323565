import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import ReactPaginate from 'react-paginate';
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
class DistributorStockModule extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            dsmDistributorList: [],
            dsmDistributorStockList: [],
            orgdsmDistributorStockList: [],
            dsmTempDistributorStockList: [],
            dsmProdctList: [],
            dsmProdctOrgList: [],
            dsmStockId: 0,
            productCode: '',
            dsmDistributor: '',
            dsmDistributorCompanyCode: '',
            uomDescription: '',
            productGroupName: '',
            closingStock: '',
            closingStockActual: '',
            productShades: '',
            productTypeCode: '',
            productTypeName: '',
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0,
            distributorStockWriteAccess: this.props.userObj.distributorStockWriteAccess,
            currentDate: todaydate,
            dsmDistributorStockExcelList: [],
            dsmNewStock: 0,
            dsmUpdateStock: 0,
            dsmDeleteStock: 0,
        }

        this.handlePageClick = this
            .handlePageClick
            .bind(this);

        this.productInput = React.createRef();
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.getPaginationDSMDistributorList()
        });

    };

    componentDidMount() {
        this.getDSMDistributorList();
    }

    getPaginationDSMDistributorList() {
        var rdata = this.state.orgdsmDistributorStockList;
        const slice = rdata.slice(this.state.offset, this.state.offset + this.state.perPage)
        this.setState({
            pageCount: Math.ceil(rdata.length / this.state.perPage),
            dsmDistributorStockList: slice,
        }, () => {
            ReactTooltip.rebuild();
        })
    }

    getDSMDistributorList() {
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/distributorstock/getDistributorList")
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                this.setState({
                    dsmDistributorList: data.distributorList,
                })
            }).catch(err => {
                // console.log(err)
                this.setState({
                    loading: false
                })
                alert(err)
            })
    }

    getDSMStockList() {

        const params = {
            companyCode: this.state.dsmDistributorCompanyCode,
            distributorCode: this.state.dsmDistributor,
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/distributorstock/getDistributorStockList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    })
                    // this.setState({
                    //     dsmDistributorStockList: data.distributorStockList,
                    //     orgdsmDistributorStockList: data.distributorStockList,
                    //     dsmProdctList: data.productList,
                    //     dsmProdctOrgList: data.productList
                    // })

                    var rdata = data.distributorStockList;
                    const slice = rdata.slice(this.state.offset, this.state.offset + this.state.perPage)


                    this.setState({
                        // dsmDistributorStockList: data.distributorStockList,
                        orgdsmDistributorStockList: data.distributorStockList,
                        dsmDistributorStockExcelList: data.distributorStockList,
                        dsmTempDistributorStockList: data.distributorStockList,
                        dsmProdctList: data.productList,
                        dsmProdctOrgList: data.productList,
                        pageCount: Math.ceil(rdata.length / this.state.perPage),
                        dsmDistributorStockList: slice,
                    })

                } else {
                    alert(data.responseMsg);
                }
                ReactTooltip.rebuild();
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                })
                alert(err);
            })
    }

    dsmDistributorChange = (event) => {
        this.setState({
            dsmDistributor: event.value,
            dsmDistributorCompanyCode: event.companyCode
        }, () => {
            this.getDSMStockList();
        });
    }

    handleProductChange = (e) => {

        // if (this.state.dsmDistributorStockList.some(item => item.productCode === e.target.value)) {
        //     alert("This Product Is Product Already Exist");
        // } else {
        //     let ivalue = e.target.value;
        //     let uom = "";
        //     let productGroup = "";
        //     let productGroupId = "";
        //     const { dsmDistributorStockList } = this.state;
        //     const { id } = e.target;
        //     var pList = this.state.dsmProdctList;
        //     pList.map(function (pl, i) {
        //         if (pl.productCode === ivalue) {
        //             productGroup = pl.productGroupName;
        //             productGroupId = pl.productGroupId;
        //             uom = pl.uomDescription;
        //         }
        //     });
        //     if (e.target.name === 'productCode') {
        //         dsmDistributorStockList[id].productCode = ivalue;
        //         dsmDistributorStockList[id].productGroupId = productGroupId;
        //         dsmDistributorStockList[id].productGroupName = productGroup;
        //         dsmDistributorStockList[id].uomDescription = uom;
        //     } else if (e.target.name === 'closingStock') {
        //         dsmDistributorStockList[id].closingStock = ivalue;
        //     }
        //     this.setState({ dsmDistributorStockList: dsmDistributorStockList })
        // }
        const { dsmDistributorStockList, dsmTempDistributorStockList } = this.state;
        const { id } = e.target;
        dsmDistributorStockList[id].closingStockActual = e.target.value;
        var tempIndex = 0;
        tempIndex = this.state.dsmTempDistributorStockList.findIndex(obj => obj.changeFlag === this.state.dsmDistributorStockList[id].changeFlag);
        dsmTempDistributorStockList[tempIndex].closingStockActual = e.target.value;
        this.setState({ dsmDistributorStockList: dsmDistributorStockList, dsmTempDistributorStockList: dsmTempDistributorStockList }, () => {
            //console.log("<-1->"+dsmTempDistributorStockList[tempIndex].closingStockActual);
        })
    }

    handleDSProductChange = (ind, event) => {
        if (this.state.dsmDistributorStockList.some(item => item.productCode === event.value)) {
            alert("This Product Is Product Already Exist");
        } else {
            let uom = "";
            let productGroup = "";
            let productGroupId = "";
            let pShades = "";
            let pTypeCode = "";
            let pTypeName = "";
            var pList = this.state.dsmProdctList;
            var tempIndex = 0;
            pList.map(function (pl, i) {
                if (pl.productCode === event.value) {
                    productGroup = pl.productGroupName;
                    productGroupId = pl.productGroupId;
                    uom = pl.uomDescription;
                    pShades = pl.productShades;
                    pTypeCode = pl.productTypeCode;
                    pTypeName = pl.productTypeName;
                }
            });
            const { dsmDistributorStockList } = this.state;
            const { dsmTempDistributorStockList } = this.state;
            dsmDistributorStockList[ind].productCode = event.value;
            dsmDistributorStockList[ind].productName = event.label;
            dsmDistributorStockList[ind].productGroupId = productGroupId;
            dsmDistributorStockList[ind].productGroupName = productGroup;
            dsmDistributorStockList[ind].uomDescription = uom;
            dsmDistributorStockList[ind].productShades = pShades;
            dsmDistributorStockList[ind].productTypeCode = pTypeCode;
            dsmDistributorStockList[ind].productTypeName = pTypeName;
            tempIndex = this.state.dsmTempDistributorStockList.findIndex(obj => obj.changeFlag === this.state.dsmDistributorStockList[ind].changeFlag);
            dsmTempDistributorStockList[tempIndex].productCode = event.value;
            dsmTempDistributorStockList[tempIndex].productName = event.label;
            dsmTempDistributorStockList[tempIndex].productGroupId = productGroupId;
            dsmTempDistributorStockList[tempIndex].productGroupName = productGroup;
            dsmTempDistributorStockList[tempIndex].uomDescription = uom;
            dsmTempDistributorStockList[tempIndex].productShades = pShades;
            dsmTempDistributorStockList[tempIndex].productTypeCode = pTypeCode;
            dsmTempDistributorStockList[tempIndex].productTypeName = pTypeName;
            this.setState({ dsmDistributorStockList: dsmDistributorStockList, dsmTempDistributorStockList: dsmTempDistributorStockList }, () => {
                // console.log(this.state.dsmDistributorStockList);
                //console.log(this.state.dsmTempDistributorStockList);
                ReactTooltip.rebuild();
            });
        }
    }

    DistributorStock_newRowHandler = () => {
        if (this.state.dsmDistributor === "") {
            alert("Please Choose Distributor");
        } else {
            var newStock = this.state.dsmNewStock + 1;
            var emptyobj = {
                productCode: '',
                productGroupId: '',
                productGroupName: '',
                uomDescription: '',
                closingStock: '',
                closingStockActual: '',
                productShades: '',
                productTypeCode: '',
                productTypeName: '',
                companyCode: this.state.dsmDistributorCompanyCode,
                distributorCode: this.state.dsmDistributor,
                readOnly: false,
                changeFlag: 'A' + newStock
            }
            let stockListtemp = this.state.dsmDistributorStockList
            let stockListtemp1 = this.state.dsmTempDistributorStockList
            stockListtemp.push(emptyobj)
            stockListtemp1.push(emptyobj)
            // this.setState({ dsmDistributorStockList: stockListtemp })
            this.setState({ dsmDistributorStockList: stockListtemp, dsmTempDistributorStockList: stockListtemp1, dsmNewStock: newStock }, () => {
                this.productInput.current.focus();
            })
        }
    }

    product_deleteHandler = (ind) => {
        var stockList = [...this.state.dsmDistributorStockList];
        var id = stockList[ind].id;
        const { dsmTempDistributorStockList } = this.state;
        var pList = this.state.dsmTempDistributorStockList;
        pList.map(function (pl, i) {
            if (pl.id === undefined) {
                dsmTempDistributorStockList.splice(i, 1)
            } else if (pl.id === id) {
                dsmTempDistributorStockList[i].changeFlag = "D";
            }
        });
        stockList.splice(ind, 1)
        this.setState({ dsmDistributorStockList: stockList, dsmTempDistributorStockList: dsmTempDistributorStockList }, () => {
            // console.log("<-1->"+dsmTempDistributorStockList[id].changeFlag);            
        })
    }

    product_EnableEdit = (ind) => {
        var tempIndex = 0;
        var updateStock = this.state.dsmUpdateStock + 1;
        const { dsmDistributorStockList, dsmTempDistributorStockList } = this.state;
        dsmDistributorStockList[ind].readOnly = false
        dsmDistributorStockList[ind].disabled = ""
        dsmDistributorStockList[ind].changeFlag = "U" + updateStock
        tempIndex = dsmTempDistributorStockList.findIndex(obj => obj.productCode === dsmDistributorStockList[ind].productCode);
        dsmTempDistributorStockList[tempIndex].changeFlag = "U" + updateStock
        this.setState({ dsmDistributorStockList: dsmDistributorStockList, dsmTempDistributorStockList: dsmTempDistributorStockList, dsmUpdateStock: updateStock }, () => {
            // console.log("<-1->"+dsmTempDistributorStockList[ind].changeFlag);           
        })

    }

    dsmChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    dsmSavefunction = (e) => {
        var pList = this.state.dsmDistributorStockList;
        var tFlag = true;
        if (pList.length === 0 || pList === null) {
            alert("Please Enter A Product");
        } else {
            pList.map(function (pl, i) {
                if (pl.productCode === undefined || pl.productCode === '') {
                    alert("Please Choose A Product");
                    tFlag = false;
                } else if (pl.closingStockActual === '' || pl.closingStockActual === '0') {
                    alert("Please Enter Closing Stock");
                    tFlag = false;
                }
            });
            if (tFlag) {

                let params = {
                    "userName": this.state.loginuserObj.userName,
                    "distributorStockList": this.state.dsmTempDistributorStockList
                }
                this.setState({
                    loading: true
                })
                axios.post(this.state.httpURL + "/distributorstock/", params)
                    .then(response => {
                        if (response.data !== null) {
                            if (response.data.valid) {
                                alert("Distributor Stock Saved Successfully.");
                                this.getDSMStockList();
                            } else {
                                alert(response.data.responseMsg);
                            }
                            this.setState({
                                loading: false
                            })
                        }
                    })
                    .catch(error => {
                        //console.log("error" + error)
                        this.setState({
                            loading: false
                        })
                        alert(error);
                    })
            }
        }
    }

    dsmCancelfunction = () => {
        this.getDSMStockList();
    }

    filterList = (event) => {
        let filtered = this.state.orgdsmDistributorStockList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        var rdata = filtered;
        const slice = rdata.slice(this.state.offset, this.state.offset + this.state.perPage)
        this.setState({
            pageCount: Math.ceil(rdata.length / this.state.perPage),
            dsmDistributorStockList: slice,
            dsmDistributorStockExcelList: filtered
        }, () => {
            ReactTooltip.rebuild();
        })

        // this.setState({
        //     dsmDistributorStockList: filtered
        // })
    }

    distributorStockExcel = () => {
        var dFlag = true;
        if (this.state.dsmDistributorStockExcelList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.dsmDistributorStockExcelList;
            var fileName = "Distributor Stock Report_" + this.state.currentDate;
            var wscols = [
                { wch: 60 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 }
            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    "Product": dList[i].productName,
                    "Product Shades": dList[i].productShades,
                    "Product Type": dList[i].productTypeName,
                    "UOM": dList[i].uomDescription,
                    "Closing Stock": dList[i].closingStockActual
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'DistributorStock': ws }, SheetNames: ['DistributorStock'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    render() {

        const distributorSearch = [];
        for (const [index, value] of this.state.dsmDistributorList.entries()) {
            distributorSearch.push({
                label: value.distributorName,
                value: value.distributorCode,
                companyCode: value.companyCode
            });
        }

        const productSearch = [];
        for (const [index, value] of this.state.dsmProdctOrgList.entries()) {
            productSearch.push({
                label: value.productName,
                value: value.productCode
            });
        }

        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>DISTRIBUTOR STOCK</span></div>
                    <div className="O2Table_SubContainer distriStockSub">
                        <div className="O2Content_row">
                            <div className="contentRow_Label">Distributor</div>
                            <div className="contentRow_Value">
                                <Select options={distributorSearch} name="dsmDistributor" placeholder="Choose Distributor" onChange={this.dsmDistributorChange} />
                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label"></div>
                            <div className="contentRow_Value">

                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label"></div>
                            <div className="contentRow_Value">

                            </div>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable distributorStockTable">
                            <thead>
                                <tr>
                                    <th className="Descript_width">Product
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="">Product Shades
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productShades" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    {/* <th className="salesMan">Product Group
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productGroupName" onChange={this.filterList} />
                                        </div>
                                    </th> */}
                                    <th className="">Product Type
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productTypeName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="status_Width">UOM
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="uomDescription" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="">Closing Stock
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="closingStock" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container">
                                        <button><FontAwesomeIcon icon={faPlus} style={{ padding: '0px 4px' }} onClick={() => this.DistributorStock_newRowHandler()} /></button>
                                    </th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !this.state.dsmDistributorStockList || this.state.dsmDistributorStockList.length === 0 || this.state.dsmDistributorStockList == null ?
                                        <tr align="center">
                                            <td colSpan="6">No Stock Available</td>
                                        </tr> :
                                        this.state.dsmDistributorStockList.map((stock, i) => (
                                            <tr key={i}>
                                                {/* <td className="Descript_width" data-tip={stock.productName}>
                                                    <select id={i} name="productCode" value={stock.productCode} onChange={this.handleProductChange} disabled={stock.disabled}>
                                                        <option value="" >Choose Product</option>
                                                        {
                                                            this.state.dsmProdctList.map((product) => (
                                                                <option value={product.productCode} >{product.productName}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </td> */}
                                                <td className="Descript_width" data-tip={stock.productName}>
                                                    <Select id={i} options={productSearch} name="productCode" value={productSearch.filter(({ value }) => value === stock.productCode)} isDisabled={stock.readOnly} placeholder="Choose Product" onChange={(e) => this.handleDSProductChange(i, e)} ref={this.productInput} />
                                                </td>
                                                <td className="" data-tip={stock.productShades}>{stock.productShades}</td>
                                                {/* <td className="salesMan" data-tip={stock.productGroupName}>{stock.productGroupName}</td> */}
                                                <td className="" data-tip={stock.productTypeName}>{stock.productTypeName}</td>
                                                <td className="status_Width" data-tip={stock.uomDescription}>{stock.uomDescription}</td>
                                                <td className="" data-tip={stock.closingStockActual}>
                                                    <input type="text" id={i} name="closingStockActual" value={stock.closingStockActual} onChange={this.handleProductChange} readOnly={stock.readOnly} />
                                                </td>
                                                <td className="o2Del_container">
                                                    {
                                                        !stock.changeFlag.includes("A") ?
                                                            <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.product_EnableEdit(i)} />
                                                            : null
                                                    }
                                                    {
                                                        this.state.distributorStockWriteAccess ?
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} onClick={() => this.product_deleteHandler(i)} />
                                                            : null
                                                    }

                                                </td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount">
                            <span className=" distpageCalc_container">Showing<span>{this.state.dsmDistributorStockList ? this.state.dsmDistributorStockList.length : 0}</span>entries</span>
                            <span className="Paginationsub"><ReactPaginate previousLabel={"prev"} nextLabel={"next"} breakLabel={"..."} breakClassName={"break-me"} pageCount={this.state.pageCount} marginPagesDisplayed={2} pageRangeDisplayed={5} onPageChange={this.handlePageClick} containerClassName={"pagination"} subContainerClassName={"pages pagination"} activeClassName={"active"} /></span>
                        </div>
                        <div className="O2Table_PageCount">
                            <div className="O2Table_ExpExcel" onClick={() => this.distributorStockExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                        </div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}

                        {/* <ReactPaginate previousLabel={"prev"} nextLabel={"next"} breakLabel={"..."} breakClassName={"break-me"} pageCount={this.state.pageCount} marginPagesDisplayed={2} pageRangeDisplayed={5} onPageChange={this.handlePageClick} containerClassName={"pagination"} subContainerClassName={"pages pagination"} activeClassName={"active"}/> */}

                    </div>
                    {/* <div className="O2Table_bottomSubContainer">                        
                        <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{padding:'0px 8px'}}/>excel</div>
                    </div> */}
                    <div className="O2Table_Footer">
                        {
                            this.state.distributorStockWriteAccess ?
                                <button onClick={this.dsmSavefunction}>Save</button>
                                : null
                        }
                        <button onClick={this.dsmCancelfunction}>Cancel</button>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(DistributorStockModule)

//export default DistributorStockModule

