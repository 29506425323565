import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faPlus, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class UserTeamModule extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            companyCode: this.props.userObj.companyCode,
            umUserTeamList: [],
            umUserTeamOrgList: [],
        }
    }

    componentDidMount() {
        this.getUserTeamList();
    }

    getUserTeamList() {
        const params = {
            companyCode: this.state.companyCode
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/user/getUserTeamList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        umUserTeamList: data.umUserTeamList,
                        umUserTeamOrgList: data.umUserTeamList,
                    });
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    getMDMMetaDataList() {

        const params = {
            companyCode: this.state.companyCode
        }

        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/metadata/getMDMMetaDataList", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                if (data.valid) {
                    this.setState({
                        mdmMetaDataList: data.metaDataList,
                        mdmMetaDataOrgList: data.metaDataList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    mdmCancelfunction = () => {
        if (this.state.mdmMetaType !== "" && this.state.mdmMetaType !== null && this.state.mdmMetaType !== undefined) {
            this.getMDMMetaDataList();
        }
    }

    mdmSavefunction = () => {
        //console.log(JSON.stringify(this.state.mdmMetaDataList)); 
        if (this.checkDataEnterMDM()) {
            alert("Please Enter All Value");
            return false;
        } else if (this.getUnique()) {
            alert("Remove Duplicate Record.");
            return false;
        } else {
            let params = {
                "companyCode": this.state.loginuserObj.companyCode,
                "lastModifiedBy": this.state.loginuserObj.userName,
                "saveUserTeamList": this.state.umUserTeamList
            }
            //console.log("<-1->"+JSON.stringify(params));
            this.setState({
                loading: true
            });
            axios.post(this.state.httpURL + "/user/saveUserTeamList", params)
                .then(response => {
                    this.setState({
                        loading: false
                    });
                    if (response.data.valid) {
                        alert("User Team Saved Successfully.");
                        this.getUserTeamList();
                        ReactTooltip.rebuild();
                    } else {
                        alert(response.data.responseMsg);
                    }
                })
                .catch(error => {
                    //console.log("error" + error)
                    this.setState({
                        loading: false
                    });
                    alert(error);
                })
        }
    }

    filterList = (event) => {
        let filtered = this.state.umUserTeamOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            umUserTeamList: filtered
        })
    }

    DistributorStock_newRowHandler = () => {
        if (this.state.umUserTeamList === "") {
            alert("Please Choose Meta Type");
        } else {
            var newind = this.state.umUserTeamList.length + 1;
            var emptyobj = {
                teamId: newind,
                teamName: '',
                companyCode: this.state.companyCode,
                prefixCode: '',
                isDeleted: 'N',
                createSalesman: 'Y',
                status: 'Y',
                displayStatus: 'Active',
                changeFlag: 'A',
            }
            let stockListtemp = this.state.umUserTeamList
            stockListtemp.push(emptyobj)
            this.setState({ umUserTeamList: stockListtemp })
        }
    }

    mdmMetaTypeListChange = (event) => {
        this.setState({
            mdmMetaType: event.value,
            mdmMetaDataList: [],
            mdmMetaDataOrgList: [],
        }, () => {
            if (this.state.mdmMetaType !== "" && this.state.mdmMetaType !== null && this.state.mdmMetaType !== undefined) {
                this.getMDMMetaDataList();
            }
        });
    }

    editMDM_openHandler = (ind) => {
        const { umUserTeamList } = this.state;
        umUserTeamList[ind].readOnly = false
        this.setState({ umUserTeamList: umUserTeamList })
    }

    deleteMDM_openHandler = (ind) => {
        var umUserTeamList = [...this.state.umUserTeamList];
        umUserTeamList.splice(ind, 1)
        this.setState({ umUserTeamList: umUserTeamList }, () => {
            this.setState(prevState => {
                let { umUserTeamList } = prevState;
                umUserTeamList = umUserTeamList.map((item, index) => item.changeFlag === "A" ? { ...item, teamId: index + 1 } : item);
                return { umUserTeamList };
            });
        });
    }

    mdmStatusChange = (event) => {
        this.setState({
            ptStatus: event.target.value
        });
    }

    handleMSMNameChange = (e) => {
        const { umUserTeamList } = this.state;
        const { id } = e.target;
        if (e.target.name === "teamName") {
            umUserTeamList[id].teamName = e.target.value;
        } else if (e.target.name === "prefixCode") {
            umUserTeamList[id].prefixCode = e.target.value;
        } else if (e.target.name === "isDeleted") {
            umUserTeamList[id].isDeleted = e.target.value;
            if (e.target.value === 'N') {
                umUserTeamList[id].displayStatus = "Active";
            } else if (e.target.value === 'Y') {
                umUserTeamList[id].displayStatus = "In Active";
            }
        }
        if (umUserTeamList[id].changeFlag === "N") {
            umUserTeamList[id].changeFlag = "U";
        }
        this.setState({ umUserTeamList: umUserTeamList })
    }

    checkDataEnterMDM() {
        var notEntered = false;
        let srlist = this.state.umUserTeamList;
        if (srlist.length > 0) {
            for (var i = 0; i < srlist.length; i++) {
                if (srlist[i].teamName === '' || srlist[i].teamName === undefined || srlist[i].teamName === null) {
                    notEntered = true;
                }
            }
        } else {
            notEntered = true;
        }
        return notEntered;
    }

    getUnique() {
        var isUnique = false;
        let srlist = this.state.umUserTeamList;
        for (var i = 0; i < srlist.length; i++) {
            const dataName = srlist[i].teamName.split(' ').join('').toLowerCase();
            for (var j = i + 1; j < srlist.length; j++) {
                const dataName1 = srlist[j].teamName.split(' ').join('').toLowerCase();
                if (dataName === dataName1) {
                    isUnique = true;
                }
            }
        }
        return isUnique;
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>User Team</span></div>
                    <div className="O2Table_SubContainer distriStockSub">
                        <div className="O2Content_row">
                            <div className="contentRow_Label"></div>
                            <div className="contentRow_Value">

                            </div>
                        </div>
                        <div className="O2Content_row">
                            <div className="contentRow_Label"></div>
                            <div className="contentRow_Value">

                            </div>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="userTeamData_Table">
                            <thead>
                                <tr>
                                    <th>Team Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="teamName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    {/* <th>Prefix Code
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="prefixCode" onChange={this.filterList} />
                                        </div>
                                    </th> */}
                                    <th className="Status_Route">Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="displayStatus" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container">
                                        <button><FontAwesomeIcon icon={faPlus} style={{ padding: '0px 4px' }} onClick={() => this.DistributorStock_newRowHandler()} /></button>
                                    </th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !this.state.umUserTeamList || this.state.umUserTeamList === null || this.state.umUserTeamList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="3">No User Team Details Available</td>
                                        </tr> :
                                        this.state.umUserTeamList.map((teamList, i) => (
                                            <tr key={i}>
                                                <td data-tip={teamList.teamName}>
                                                    <input type="text" id={i} name="teamName" value={teamList.teamName} disabled={teamList.readOnly} onChange={this.handleMSMNameChange} />
                                                </td>
                                                {/* <td data-tip={teamList.prefixCode}>
                                                    <input type="text" id={i} name="prefixCode" value={teamList.prefixCode} disabled={teamList.readOnly} onChange={this.handleMSMNameChange} />
                                                </td> */}
                                                <td className="Status_Route">
                                                    <select id={i} name="isDeleted" value={teamList.isDeleted} disabled={teamList.readOnly} onChange={this.handleMSMNameChange}>
                                                        <option value="N" >Active</option>
                                                        <option value="Y" >In Active</option>
                                                    </select>
                                                </td>
                                                <td className="o2Del_container">
                                                    <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.editMDM_openHandler(i)} />
                                                    {
                                                        teamList.changeFlag === "A" ?
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} onClick={() => this.deleteMDM_openHandler(i)} />
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.mdmMetaDataList ? this.state.mdmMetaDataList.length : 0}</span>entries</span></div>
                    </div>
                    <div className="O2Table_Footer">
                        <button onClick={() => this.mdmSavefunction()}>Save</button>
                        <button onClick={() => this.mdmCancelfunction()}>Cancel</button>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(UserTeamModule)
//export default UserTeamModule
