import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

class DataSync extends Component {


    constructor(props) {
        super(props)

        this.state = {
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            syncTableList: [],
            syncTableOrgList: [],
            loading: false,
            dataSyncWriteAccess: this.props.userObj.dataSyncWriteAccess
        }
    }

    componentDidMount() {
        this.getAllTables();
    }


    getAllTables() {

        axios.get(this.state.httpURL + "/adweb/getAllAdempiereTables")
            .then(response => response.data)
            .then((data) => {

                //console.log("data==>"+data)
                this.setState({

                    syncTableList: data.synctableList,
                    syncTableOrgList: data.synctableList,
                }, () => {  // callback functions
                    ReactTooltip.rebuild();
                })

            }).catch(err => {
                alert(err);
            })

    }

    syncTable = () => {
        this.setState({
            loading: true
        });
        axios.post(this.state.httpURL + "/adweb/syncAdempiereTables", { "synctableList": this.state.syncTableList, "userName": this.state.loginuserObj.userName })
            .then(response => response.data)
            .then((data) => {

                //console.log("data==>"+data)
                // this.setState({


                // }, () => {  // callback functions
                //     ReactTooltip.rebuild();
                // })
                this.getAllTables();
                this.setState({
                    loading: false
                });
                alert(data.responseMsg);
            }).catch(err => {
                alert(err);
            })
    }

    selectTable = (ind) => {
        const { syncTableList } = this.state;
        syncTableList[ind].isSelected = !syncTableList[ind].isSelected

        this.setState({ syncTableList: syncTableList }, () => {
            ReactTooltip.rebuild();
        });

    }

    filterList = (event) => {
        let filtered = this.state.syncTableOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));
            }
        }
        this.setState({
            syncTableList: filtered
        })
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>DATA SYNC</span></div>
                    <div className="Table_wrapper">
                        <table className="customTable">
                            <thead>
                                <tr>
                                    <th className="checkBox_Container"></th>
                                    <th className="Descript_width">Table Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="tablename" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container">
                                        {/* <button><FontAwesomeIcon icon={faPlus} style={{ padding: '0px 4px' }} /></button> */}
                                    </th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    !this.state.syncTableList || this.state.syncTableList == null || this.state.syncTableList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="6">No Tables Available</td>
                                        </tr> :
                                        this.state.syncTableList.map((data, i) => (
                                            <tr key={i}>
                                                <td className="checkBox_Container">
                                                    <input type="checkbox" checked={data.isSelected} onChange={() => this.selectTable(i)} />
                                                </td>
                                                <td className="" data-tip={data.tablename}>
                                                    {data.tablename}
                                                </td>
                                                <td className="o2Del_container">
                                                    {/* <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }}/>
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }}/> */}
                                                </td>
                                            </tr>

                                        ))
                                }


                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.syncTableList ? this.state.syncTableList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    {/* <div className="O2Table_bottomSubContainer">                        
                        <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{padding:'0px 8px'}}/>excel</div>
                    </div> */}
                    <div className="O2Table_Footer">
                        {/* <button>Save</button> */}
                        {
                            this.state.dataSyncWriteAccess ?
                                <button onClick={this.syncTable}>Sync Now</button>
                                : null
                        }
                        {/* <button>Cancel</button> */}

                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(DataSync)


///export default DataSync

