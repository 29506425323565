import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

const override1 = css`
  display: block;
  top: -235px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;


class InvoiceModule extends Component {
    
    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0,10);

        this.state = {
            Distributorbtn: "Popup_tabButtons",
            DistServbtn: "Popup_tabButtons",
            imInvoiceList: [],
            imOrgInvoiceList: [],
            imInvoiceDeteilList: [],
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            imInvoiceNo: '',
            imOrderNo: '',
            imGrandTotal: '',
            imTotalLines: '',
            currentDate:todaydate,
        }

    }

    componentDidMount() {
        this.getIMInvoiceList();       
    }
    getIMInvoiceList() {
        this.setState({
            loading: true
        });
        // const params = {
        //     companyCode: this.state.loginuserObj.companyCode,            
        // }        
        //axios.get(this.state.httpURL + "/distributor/getDistributorList", { params })
        axios.get(this.state.httpURL + "/webmaster/getInvoiceList")
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        imInvoiceList : data.invoiceList,
                        imOrgInvoiceList : data.invoiceList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }


    invoiceDet_popCloseHandler = () => {
        document.querySelector('.InvoiceDetail_pop').style.display = 'none';
    }

    invoiceDet_popOpenHandler = (e,i) => {
        document.querySelector('.InvoiceDetail_pop').style.display = 'flex';
        this.getInvoiceDetail(e,i);
    }

    filterList = (event) => {
        let filtered =this.state.imOrgInvoiceList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
            }
        }
        this.setState({
            imInvoiceList: filtered
        })
    }

    getInvoiceDetail = (e,i) => {       
        this.setState({
            imInvoiceNo: e.c_invoice_id,
            imOrderNo: e.orderNo,
            imTotalLines: e.totalLines
        })
        const params={
            c_invoice_id:e.c_invoice_id                      
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/webmaster/getInvoiceDetailList",{params})
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {                    
                    this.setState({
                        imInvoiceDeteilList:data.invoiceDetailList
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

     render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>INVOICE</span></div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="invoice_Table">
                            <thead>
                                <tr>
                                <th>Invoice No
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="c_invoice_id" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                {/* <th>Client
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="client" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Organization
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="organization" onChange={this.filterList}/>
                                        </div>
                                    </th> */}
                                    <th>Order
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="orderNo" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Document No
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="documentno" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Invoice Date
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="createdDate" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th class="Value_width">Grand Total
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="grandTotal" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Paid
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="ispaid" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Tax
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="istaxincluded" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>                                             
                                    {/* <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler()}>2589</td>
                                    <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler()}>Y</td>
                                    <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler()}>2589</td>
                                    <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler()}>Y</td>
                                    <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler()}>Y</td>
                                    <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler()}>2589</td> */}
                                    {
                                            !this.state.imInvoiceList || this.state.imInvoiceList === null || this.state.imInvoiceList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="5">No Invoice Available</td>
                                            </tr> :
                                            this.state.imInvoiceList.map((il,i) => (
                                                <tr key={il.id}>                                                    
                                                    <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler(il,i)} data-tip={il.c_invoice_id}>{il.c_invoice_id}</td>
                                                    {/* <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler()} data-tip={il.client}>{il.client}</td>
                                                    <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler()} data-tip={il.organization}>{il.organization}</td> */}
                                                    <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler(il,i)} data-tip={il.orderNo}>{il.orderNo}</td>
                                                    <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler(il,i)} data-tip={il.documentno}>{il.documentno}</td>
                                                    <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler(il,i)} data-tip={il.createdDate}>{il.createdDate}</td>
                                                    <td class="Value_width" onDoubleClick={ ()=> this.invoiceDet_popOpenHandler(il,i)} data-tip={il.totalLines}>{il.totalLines}</td>
                                                    <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler(il,i)} data-tip={il.ispaid}>{il.ispaid}</td>
                                                    <td onDoubleClick={ ()=> this.invoiceDet_popOpenHandler(il,i)} data-tip={il.istaxincluded}>{il.istaxincluded}</td>                                                     
                                                </tr>
                                            ))
                                        }
                                </tr> 
                               
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.imInvoiceList ? this.state.imInvoiceList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    {/* <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /></div>
                    </div> */}
                    {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{padding:'0px 8px'}}/><ReactHTMLTableToExcel className="download-table-xls-button" table="invoice_Table" filename={"Invoice_"+this.state.currentDate}
                        sheet="tablexls" buttonText="Excel Export"/></div> */}
                    {/* <div className="O2Table_Footer">
                        <button>Close</button>
                    </div> */}
                </div>
                {/* <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />    */}

            <div className="O2TableEdit_wrapper InvoiceDetail_pop">
                <div className="O2TableEditBoxWrap">
                    <div className="O2TableEditBox">
                        <div className="O2TableEditTitle">Invoice Ref No(#): {this.state.imInvoiceNo} </div>
                        <div className="O2TableEditBody">
                            <div className="row">
                                <div className="FieldComponent">
                                    <div className="FieldLabel">Order Number : {this.state.imOrderNo}</div>
                                    <div className="FieldValue"><span> </span></div>
                                </div>
                                {/* <div className="FieldComponent">
                                    <div className="FieldLabel">Order Date : </div>
                                    <div className="FieldValue"><span> </span></div>
                                </div> */}
                                <div className="FieldComponent">
                                     <div className="FieldLabel">Grand Total  : {this.state.imTotalLines} </div>
                                    <div className="FieldValue"><span></span></div> 
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="FieldComponent">
                                    <div className="FieldLabel">Business Partner : </div>
                                    <div className="FieldValue"><span> </span></div>
                                </div>
                                <div className="FieldComponent">
                                    <div className="FieldLabel">Partner Location : </div>
                                    <div className="FieldValue"><span> </span></div>
                                </div>
                            </div>     
                            <div className="row">
                                <div className="FieldComponent">
                                    <div className="FieldLabel"> Salesman : </div>
                                    <div className="FieldValue"><span></span></div>
                                </div>
                                <div className="FieldComponent">
                                     <div className="FieldLabel">Grand Total  : {this.state.imGrandTotal} </div>
                                    <div className="FieldValue"><span></span></div> 
                                </div>
                            </div> */}
                            <div className="Table_wrapper orderpop_tablemargin">
                                <table className="customTable invoicepop_Table">
                                    <thead>
                                        <tr>
                                            <th className="invno_width">Order No</th>                                            
                                            <th className="Descript_width">Product Code</th>
                                            <th className="Descript_width">Description</th>
                                            <th className="Value_width">Qty</th>
                                            <th className="Value_width">Price</th>
                                            <th className="TotValue_width">Total Price</th>
                                            <th className="scroller"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {/* <td className="Sno_width"></td>
                                            <td className="Descript_width"></td>
                                            <td className="Descript_width"></td>
                                            <td className="Value_width"></td>
                                            <td className="Value_width"></td>
                                            <td className="Descript_width"> </td> */}
                                            {
                                                !this.state.imInvoiceDeteilList || this.state.imInvoiceDeteilList === null || this.state.imInvoiceDeteilList.length === 0 ?
                                                <tr align="center">
                                                    <td colSpan="5">No Invoice Detail Available</td>
                                                </tr> :
                                                this.state.imInvoiceDeteilList.map((id,i) => (
                                                    <tr key={id.id}>                                                                                              
                                                        <td className="invno_width" data-tip={id.c_invoiceline_id}>{id.c_invoiceline_id}</td>
                                                        <td className="Descript_width" data-tip={id.m_product_id}>{id.m_product_id}</td>
                                                        <td className="Descript_width" data-tip={id.description}>{id.description}</td>
                                                        <td className="Value_width" data-tip={id.qtyentered}>{id.qtyentered}</td>
                                                        <td className="Value_width" data-tip={id.priceentered}>{id.priceentered}</td>
                                                        <td className="TotValue_width" data-tip={id.linenetamt}>{id.linenetamt}</td>                                                     
                                                    </tr>
                                                ))
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>
                        <div className="O2TableEditFooter">
                            <div className="row O2TableBtnWrap">
                                <button onClick={this.invoiceDet_popCloseHandler}>Close</button>
                            </div>
                        </div>
                        <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                        />
                    </div>
                </div>    
            </div>
            <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL        
    }
}
export default connect(mapStateToProps)(InvoiceModule)
//export default InvoiceModule


