import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";

class Competitors extends Component {
    Tableedit_popCloseHandler = () => {
        document.querySelector('.CompetitorTableEdit_pop').style.display = 'none';
    }
    Tableedit_openHandler = () => {
        document.querySelector('.CompetitorTableEdit_pop').style.display = 'flex';
    }
    render() {
        return (
            <div className="O2Container_Wrapper">
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>COMPETITORS</span></div>
                    <div className="O2Table_Body">
                    <div className="O2Table_SubContainer">
                        <div className="O2Table_SelectSales">  
                            <div className="O2Table_Label">Salesman</div>
                            <div className="O2Table_Value"><select><option>All</option></select></div>
                        </div>
                        <div className="O2Table_SelectDist">
                            <div className="O2Table_Label">Distributor</div>
                            <div className="O2Table_Value"><select><option>All</option></select></div>
                        </div>
                        <div className="O2Table_Search">
                            <div className="O2Table_Label"> Search : </div>
                            <div className="O2Table_Value"><input type="text"/></div>
                        </div>
                    </div>
                    {/* <div className="O2Table_SubContainer">
                         <div className="O2Table_Records">
                            <div className="O2Table_Value"><select><option>5</option></select></div>
                            <div className="O2Table_Label">Records per pages</div>
                        </div> 
                        <div className="O2Table_Search">
                            <div className="O2Table_Label"> Search : </div>
                            <div className="O2Table_Value"><input type="text"/></div>
                        </div>
                    </div> */}
                    <div className="Table_wrapper">
                        <table className="customTable">
                            <thead>
                                <tr>
                                    <th className="checkBox_Container"></th>
                                    <th>Competitor</th>
                                    <th>Retailer</th>
                                    <th>Distributor</th>
                                    <th>Competitor Product</th>
                                    <th>Competitor Price</th>        
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="checkBox_Container">
                                        <input type="checkbox"/>
                                </td>
                                <td onDoubleClick={this.Tableedit_openHandler}>Cola</td>
                                <td>Anand</td>
                                <td>SAI Enterprise</td>
                                <td>Coke</td>
                                <td>50 RS</td>
                                <td>7 up</td>
                                <td>55 RS</td>
                                <td>Progress</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>0</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{padding:'0px 8px'}}/></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{padding:'0px 8px'}}/> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{padding:'0px 8px'}}/> Export</div>
                    </div>
                </div>
            </div>
            <div className="O2TableEdit_wrapper CompetitorTableEdit_pop">
                <div className="O2TableEditBoxWrap">
                    <div className="O2TableEditBox">
                        <div className="O2TableEditTitle">Competitor ID(#):</div>
                        <div className="O2TableEditBody">
                            <div className="Table_wrapper">
                                <table className="customTable">
                                    <thead>
                                        <tr>
                                            <th>Competitor</th>
                                            <th>Retailer</th>
                                            <th>Salesman</th>       
                                            <th>Competitor Product</th>
                                            <th>Competitor Price</th>
                                            <th className="competitor_Product">Product</th>
                                            <th>Price</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Anand</td>
                                        <td>ABC Stores</td>
                                        <td>Icecream Quality Wals</td>
                                        <td>
                                            <input type="text"></input>
                                        </td>
                                        <td className="competitor_Product">
                                            <select></select>
                                        </td>
                                        <td>
                                        <input type="text"></input>
                                        </td>
                                        <td>No</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    <div className="O2TableEditFooter">
                        <div className="row O2TableBtnWrap">
                            <button>Save</button>
                            <button onClick={this.Tableedit_popCloseHandler}>Cancel</button>
                        </div>
                    </div>
                </div>    
                </div>
            </div>
            </div>
        )
    }
}

export default Competitors
