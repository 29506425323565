import React, { Component } from 'react'
import './RetailerDistributor_Report.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Select from 'react-select';

import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;
class RetailerDistributor_Report extends Component {
    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            salesmanList: [],
            selectedsalesmanList: [],
            reportList: [],
            orgreportList: [],
            vdSalesmanCode: '',
            orderObj: '',
            startDate: todaydate,
            endDate: todaydate,
            smanCode: '',
            todaydate: todaydate,
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            distributorCode: this.props.userObj,
            salesmanCode: this.props.userObj.salesmanCode,
            companyCode: this.props.userObj.companyCode,
            userType: this.props.userObj.userType,
            loginSalesmanCode: this.props.userObj.salesmanCode,
            vdFState: '',
            vdFCity: '',
            vdFStateList: [],
            vdFCityList: [],
        }
    }

    componentDidMount() {
        this.getRetailerCityStateFilter();
    }

    loadReport() {
        this.getRetailerCityStateFilter();
    }

    getRetailerCityStateFilter() {
        const params = {
            companyCode: this.state.companyCode,
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/report/getRetailerCityStateFilter", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                this.setState({
                    vdFStateList: data.stateList,
                    vdFCityList: data.cityList,
                }, () => { })
            }).catch(err => {
                this.setState({
                    loading: false
                })
                alert(err);
            })
    }

    getRetailerStateWiseCityFilter() {
        let stateVal = '';
        if (this.state.vdFState === '' || this.state.vdFState === undefined) {
            stateVal = "All";
        } else {
            stateVal = this.state.vdFState;
        }
        const params = {
            companyCode: this.state.companyCode,
            state: stateVal,
        }
        this.setState({
            loading: true
        })
        axios.get(this.state.httpURL + "/report/getRetailerStateWiseCityFilter", { params })
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    loading: false
                })
                this.setState({
                    vdFCityList: data.cityList,
                }, () => { })
            }).catch(err => {
                this.setState({
                    loading: false
                })
                alert(err);
            })
    }

    loadSalesmanList() {
        var dList = this.state.salesmanList;
        var sman = this.state.vdSalesmanCode;
        dList.map(function (sales, i) {

            if (sales.salesmanCode === sman) {
                //this.setState({ salesmanList: dList[i] })
            } else {
                dList.splice(i, 1)
            }

        });
        this.setState({ salesmanList: dList })
    }

    reportGo = () => {
        var dFlag = true;
        let stateVal = '';
        let cityVal = '';
        if (this.state.vdFState === '' || this.state.vdFState === undefined) {
            stateVal = "All";
        } else {
            stateVal = this.state.vdFState;
        }
        if (this.state.vdFCity === '' || this.state.vdFCity === undefined) {
            cityVal = "All";
        } else {
            cityVal = this.state.vdFCity;
        }

        if (this.state.vdFState === '' || this.state.vdFState === undefined) {
            alert("Please select State...");
            dFlag = false;
        }

        if (dFlag) {
            let salesmnallist = "";
            const params = {
                loginSalesmanCode: this.state.loginSalesmanCode,
                companyCode: this.state.companyCode,
                state: stateVal,
                city: cityVal,
            }
            this.setState({
                loading: true
            })
            axios.get(this.state.httpURL + "/report/getRetailerNotAssToDistributor", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        this.setState({
                            loading: false
                        })
                        this.setState({
                            reportList: data.reportList,
                            orgreportList: data.reportList,
                        })
                        ReactTooltip.rebuild();
                    } else {
                        alert(data.responseMsg);
                    }
                }).catch(err => {
                    this.setState({
                        loading: false
                    })
                    alert(err);
                })
        }
    }

    reportChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSalesmanChange = (event) => {
        this.setState({
            selectedsalesmanList: event,
            vdSalesmanCode: event.value
        });
    }

    filterList = (event) => {
        let filtered = this.state.orgreportList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            reportList: filtered
        })
    }

    visitDetailExcel = () => {
        var dFlag = true;
        if (this.state.reportList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.reportList;
            var fileName = "Retailer Not Assinged to Distributor_" + this.state.todaydate
            var wscols = [
                { wch: 35 },
                { wch: 10 },
                { wch: 45 },
                { wch: 20 },
                { wch: 45 },
                { wch: 20 },

            ];
            for (var i = 0; i < dList.length; i++) {
                arr = {};
                arr = {
                    // "Retailer Name": dList[i].retailer,
                    "Retailer Code": dList[i].retailerCode,
                    "Retailer Name": dList[i].retailerName,
                    "Address": dList[i].address,
                    "Comments": dList[i].comments,
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'RetailerDistributor_Report': ws }, SheetNames: ['RetailerDistributor_Report'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    handleVDFStateChange = (event) => {
        this.setState({
            vdFState: event.value,
        }, () => {
            // this.reportGo();
            this.getRetailerStateWiseCityFilter();
        });
    }

    handleVDFCityChange = (event) => {
        this.setState({
            vdFCity: event.value,
        }, () => {
            // this.reportGo();
        });
    }

    render() {
        const salesmanSearch = [];

        for (const [index, value] of this.state.salesmanList.entries()) {
            salesmanSearch.push({
                label: value.salesmanName,
                value: value.salesmanCode
            });
        }
        return (

            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>Retailer Not Assinged to Distributor</span></div>
                    <div className="O2Table_Body">
                        <div className="O2Table_SubContainer Report_subContainer">

                            <div className="O2Content_row">
                                <div className="contentRow_Label mandatoryClass">State <span>*</span></div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.vdFStateList} name="vdFState" value={this.state.vdFStateList.filter(({ value }) => value === this.state.vdFState)} placeholder="Choose State" onChange={this.handleVDFStateChange} />
                                </div>
                            </div>
                            <div className="O2Content_row">
                                <div className="contentRow_Label">City</div>
                                <div className="contentRow_Value">
                                    <Select options={this.state.vdFCityList} name="vdFCity" value={this.state.vdFCityList.filter(({ value }) => value === this.state.vdFCity)} placeholder="Choose City" onChange={this.handleVDFCityChange} />
                                </div>
                            </div>

                            <div className="O2Table_Go" onClick={this.reportGo}><button>Go</button></div>
                        </div>
                        <div className="Table_wrapper visitDetailTable_Wrapper">
                            <table className="customTable UsageDetailReportTable">
                                <thead>
                                    <tr>
                                        <th className="retailerCode">Retailer Code
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerCode" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="retailerName">Retailer Name
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retailerName" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="retailerAddress">Address
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="address" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="comments">Comments
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="comments" onChange={this.filterList} />
                                            </div>
                                        </th>
                                        <th className="scroller"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.reportList || this.state.reportList === null || this.state.reportList.length === 0 ?
                                            <tr align="center">
                                                <td colSpan="2">No Records</td>
                                            </tr> : this.state.reportList.map((report) => (
                                                <tr>
                                                    <td className="retailerCode" data-tip={report.retailer}>{report.retailerCode}</td>
                                                    <td className="retailerName" data-tip={report.retailer}>{report.retailerName}</td>
                                                    <td className="retailerAddress" data-tip={report.address}>{report.address}</td>
                                                    <td className="comments" data-tip={report.comments}>{report.comments}</td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.reportList ? this.state.reportList.length : 0}</span>entries</span></div>
                            <div className="O2Table_ExpExcel" onClick={() => this.visitDetailExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                        </div>
                    </div>
                </div>
                {<FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />}
            </div>
        )
    }
}

// export default RetailerDistributor_Report

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}

export default connect(mapStateToProps)(RetailerDistributor_Report)

