import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import './ProductTypeModule.css';
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class ProductTypeModule extends Component {
    
    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            ptProductTypeList: [],
            ptProductTypeOrgList: [],
            ptProductTypeId: 0,
            ptProductTypeName: '',
            ptProductTypeCode: '',
            ptStatus: 'Y',
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            productTypeWriteAccess:this.props.userObj.productTypeWriteAccess,
            currentDate:todaydate
        }
    }

    componentDidMount() {
        this.getProductTypeList();
    }

    getProductTypeList() {
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/producttype/getProductTypeList")
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        ptProductTypeList: data.productTypeList,
                        ptProductTypeOrgList: data.productTypeList,
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    newproductType_popCloseHandler = () => {
        document.querySelector('.productTypeTableEdit_pop').style.display = 'none';
    }
    newproductType_openHandler = () => {
        this.setState({
            ptProductTypeId: 0,
            ptProductTypeName: '',
            ptProductTypeCode: '',
            ptStatus: 'Y',
        });
        document.querySelector('.productTypeTableEdit_pop').style.display = 'flex';
    }
    editProductType_openHandler = (product) => {
        this.setState({
            ptProductTypeId: product.productTypeId,
            ptProductTypeName: product.productTypeDescription,
            ptProductTypeCode: product.productTypeCode,           
            ptStatus: product.status,
        });
        document.querySelector('.productTypeTableEdit_pop').style.display = 'flex';
    }

    handlePTMChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });

    }

    ptmStatusChange = (event) => {
        this.setState({
            ptStatus: event.target.value
        });

    }

    filterList = (event) => {
        let filtered =this.state.ptProductTypeOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
            }
        }
        this.setState({
            ptProductTypeList: filtered
        })
    }

    deletePTM_Handler = (product) => {
        if (window.confirm("Delete The Product Type?")) {
            this.setState({
                loading: true
            });
            axios.delete(this.state.httpURL + "/producttype/" + product.productTypeId)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            alert("Product Type Deleted Successfull ...");
                            this.getProductTypeList();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }


    newPTM_popSaveHandler = () => {
        if (this.state.ptProductTypeCode === undefined || this.state.ptProductTypeCode === "" || this.state.ptProductTypeCode === null) {
            alert("Please Enter Product Type Code");
        } else if (this.state.ptProductTypeName === undefined || this.state.ptProductTypeName === "" || this.state.ptProductTypeName === null) {
            alert("Please Enter Product Type Name");
        } 
        else {

            const newPTM = [{
                productTypeId: this.state.ptProductTypeId,
                productTypeCode: this.state.ptProductTypeCode,
                productTypeDescription: this.state.ptProductTypeName,
                companyCode: this.state.loginuserObj.companyCode,
                status: this.state.ptStatus,
                lastUpdatedBy: this.state.loginuserObj.userName
            }];

            let params = {
                "productTypeList": newPTM
            }
            //console.log(newDM);
            this.setState({
                loading: true
            });
            axios.post(this.state.httpURL + "/producttype/", params)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            if (response.data.existProductType) {
                                alert("This Product Type Code Is Already Exist.");
                            } else if (response.data.existProductTypeName) {
                                alert("This Product Type Name Is Already Exist.");
                            } else {
                                alert("Product Type Saved Successfully.");
                                this.clearPTM();
                                document.querySelector('.productTypeTableEdit_pop').style.display = 'none';
                                this.getProductTypeList();
                            }
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                })
                .catch(error => {
                    //console.log("error" + error)
                    this.setState({
                        loading: false
                    });
                    alert(error);
                })
        }
    }

    clearPTM() {
        this.setState({
            ptProductTypeList: [],
            ptProductTypeOrgList: [],
            ptProductTypeId: 0,
            ptProductTypeCode: '',
            ptProductTypeName: '',           
            ptStatus: 'Y',
        });
    }

    productTypeExcel = () =>{
        var dFlag = true;
        if(this.state.ptProductTypeList.length === 0){
            alert("No Records");
            dFlag = false;
        } 
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.ptProductTypeList;
            var fileName = "Product Type Report_"+this.state.currentDate;
            var wscols = [
                {wch:12},
                {wch:25},
                {wch:10}
            ];
            for(var i=0;i<dList.length;i++){
                arr = {};
                arr = {
                    "Product Type": dList[i].productTypeCode,                    
                    "Product Type Name": dList[i].productTypeDescription,
                    "Status": dList[i].status                                                       
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'ProductType': ws }, SheetNames: ['ProductType'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            fileSaver.saveAs(data, fileName + fileExtension);
        }        
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>PRODUCT TYPE</span></div>
                    <div className="O2Table_SubContainer">
                        <div className="O2Table_New">
                            <button onClick={this.newproductType_openHandler}>New</button>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="productType_Table">
                            <thead>
                                <tr>
                                    <th>Product Type
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productTypeCode" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th>Product Type Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productTypeDescription" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th className="Status_Route">Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="status" onChange={this.filterList}/>
                                        </div>
                                    </th>
                                    <th className="o2Del_container"></th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>                                             
                                {
                                    !this.state.ptProductTypeList || this.state.ptProductTypeList === null || this.state.ptProductTypeList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No Product Type Available</td>
                                        </tr> :
                                        this.state.ptProductTypeList.map((product) => (
                                            <tr>
                                                <td data-tip={product.productTypeCode}>{product.productTypeCode}</td>
                                                <td data-tip={product.productTypeDescription}>{product.productTypeDescription}</td>                                                
                                                <td className="Status_Route" data-tip={product.status}>{product.status}</td>
                                                <td className="o2Del_container">
                                                    <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}} onClick={ ()=> this.editProductType_openHandler(product)}/>
                                                    {
                                                        this.state.productTypeWriteAccess ?
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}} onClick={ ()=> this.deletePTM_Handler(product)}/>
                                                        : null
                                                    }                                                    
                                                </td>
                                            </tr>
                                        ))
                                }                                    
                                </tr> 
                               
                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.ptProductTypeList ? this.state.ptProductTypeList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="productType_Table" filename={"Product Type"}
                            sheet="tablexls" buttonText="Excel Export" /></div> */}
                        <div className="O2Table_ExpExcel" onClick={ ()=> this.productTypeExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }}/>Excel Export</div>
                    </div>
                </div>
                <div className="O2TableEdit_wrapper productTypeTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Product Type</div>
                            <div className="RM_BodyContent">
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Product Type Code</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='ptProductTypeCode' value={this.state.ptProductTypeCode} onChange={this.handlePTMChange}/>
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Product Type Name</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='ptProductTypeName' value={this.state.ptProductTypeName} onChange={this.handlePTMChange}/>
                                    </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Status </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select value={this.state.ptStatus} onChange={this.ptmStatusChange}>
                                            <option value="Y">Active</option>
                                            <option value="N">In Active</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    {
                                        this.state.productTypeWriteAccess ?
                                            <button onClick={this.newPTM_popSaveHandler}>Save</button>
                                        : null
                                    }                                    
                                    <button onClick={this.newproductType_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(ProductTypeModule)
//export default ProductTypeModule

