import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faEdit,faSearch } from "@fortawesome/free-solid-svg-icons";
import './ProductGroupModule.css';
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

const override1 = css`
  display: block;
  top: -125px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class ProductGroupModule extends Component {

    constructor(props) {
        super(props)

        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            loading: false,
            pgProductGroupList: [],
            pgProductGroupOrgList: [],
            pgProductGroupId: 0,
            pgProductGroupName: '',
           // pgCompanyCode: '',
            pgStatus: 'Y',
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            productGroupWriteAccess:this.props.userObj.productGroupWriteAccess,
            currentDate:todaydate
        }
    }

    componentDidMount() {
        this.getProductGroupList();
    }

    getProductGroupList() {
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/productgroup/getProductGroupList")
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        pgProductGroupList: data.productGroupList,
                        pgProductGroupOrgList: data.productGroupList,
                    })
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    handlePGMChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });

    }

    editPGM_openHandler = (product) => {
        this.setState({
            pgProductGroupId: product.productGroupId,
            pgProductGroupName: product.productGroupName,
           // pgCompanyCode: product.companyCode,
            pgStatus: product.status,
        });
        document.querySelector('.productGroupTableEdit_pop').style.display = 'flex';
    }

    deletePGM_Handler = (product) => {
        if (window.confirm("Delete The Product Group?")) {
            this.setState({
                loading: true
            });
            axios.delete(this.state.httpURL + "/productgroup/" + product.productGroupId)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            alert("Product Group Deleted Successfull ...");
                            this.getProductGroupList();
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    newPGM_popSaveHandler = () => {
        if (this.state.pgProductGroupName === undefined || this.state.pgProductGroupName === "" || this.state.pgProductGroupName === null) {
            alert("Please Enter Product Group Name");
        } 
        // else if (this.state.pgCompanyCode === undefined || this.state.pgCompanyCode === "" || this.state.pgCompanyCode === null) {
        //     alert("Please Enter Company Code");
        // } 
        else {

            const newPGM = [{
                productGroupId: this.state.pgProductGroupId,
                productGroupName: this.state.pgProductGroupName,
                companyCode: this.state.loginuserObj.companyCode,
                status: this.state.pgStatus,
                lastUpdatedBy: this.state.loginuserObj.userName
            }];

            let params = {
                "productGroupList": newPGM
            }
            //console.log(newDM);
            this.setState({
                loading: true
            });
            axios.post(this.state.httpURL + "/productgroup/", params)
                .then(response => {
                    if (response.data !== null) {
                        this.setState({
                            loading: false
                        });
                        if (response.data.valid) {
                            if (response.data.existProductGroup) {
                                alert("This Product Group Is Already Exist.");
                            } else {
                                alert("Product Group Saved Successfully.");
                                this.clearPGM();
                                document.querySelector('.productGroupTableEdit_pop').style.display = 'none';
                                this.getProductGroupList();
                            }
                        } else {
                            alert(response.data.responseMsg);
                        }
                    }
                })
                .catch(error => {
                    //console.log("error" + error)
                    this.setState({
                        loading: false
                    });
                    alert(error);
                })
        }
    }

    newproductGroup_popCloseHandler = () => {
        document.querySelector('.productGroupTableEdit_pop').style.display = 'none';
    }
    newproductGroup_openHandler = () => {
        this.setState({
            pgProductGroupId: 0,
            pgProductGroupName: '',
           // pgCompanyCode: '',
            pgStatus: 'Y',
        });
        document.querySelector('.productGroupTableEdit_pop').style.display = 'flex';
    }

    filterList = (event) => {
        let filtered =this.state.pgProductGroupOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                   od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1 
                ));
            }
        }
        this.setState({
            pgProductGroupList: filtered
        })
    }

    pgmStatusChange = (event) => {
        this.setState({
            pgStatus: event.target.value
        });

    }

    clearPGM() {
        this.setState({
            pgProductGroupList: [],
            pgProductGroupOrgList: [],
            pgProductGroupId: 0,
            pgProductGroupName: '',
           // pgCompanyCode: '',
            pgStatus: 'Y',
        });
    }

    productGroupExcel = () =>{
        var dFlag = true;
        if(this.state.pgProductGroupList.length === 0){
            alert("No Records");
            dFlag = false;
        } 
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.pgProductGroupList;
            var fileName = "Product Group Report_"+this.state.currentDate;
            var wscols = [
                {wch:20},
                {wch:10}
            ];
            for(var i=0;i<dList.length;i++){
                arr = {};
                arr = {
                    "Product Group Name": dList[i].productGroupName,                    
                    "Status": dList[i].status                                      
                }
                arrayList.push(arr);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'ProductGroup': ws }, SheetNames: ['ProductGroup'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            fileSaver.saveAs(data, fileName + fileExtension);
        }        
    }

    render() {
        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip/>
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>PRODUCT GROUP</span></div>
                    <div className="O2Table_SubContainer">
                        {/* <div className="O2Table_Search">
                            <div className="O2Table_Label"> Search : </div>
                            <div className="O2Table_Value"><input type="text" onChange={this.filterList} /></div>
                        </div> */}
                        <div className="O2Table_New">
                            <button onClick={this.newproductGroup_openHandler}>New</button>
                        </div>
                    </div>
                    <div className="Table_wrapper">
                        <table className="customTable" id="productGroup_Table">
                            <thead>
                                <tr>
                                    <th>Product Group Name
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="productGroupName" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    {/* <th>Company Code
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="companyCode" onChange={this.filterList} />
                                        </div>
                                    </th> */}
                                    <th className="Status_Route">Status
                                        <div className="searchWrapper">
                                            <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                            <input type="text" className="tableMultiFilterSearch_input maingrid" name="status" onChange={this.filterList} />
                                        </div>
                                    </th>
                                    <th className="o2Del_container"></th>
                                    <th className="scroller"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td className="checkBox_Container">                                                    
                                    </td>                                                
                                    <td>2589</td>
                                    <td>hkis52645</td>
                                    <td className="Status_Route">Y</td>
                                    <td className="o2Del_container">
                                        <FontAwesomeIcon icon={faEdit} style={{padding:'0px 4px'}} onClick={ ()=> this.newproductGroup_openHandler()}/>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{padding:'0px 4px'}}/>
                                    </td>
                                </tr> */}
                                {
                                    !this.state.pgProductGroupList || this.state.pgProductGroupList === null || this.state.pgProductGroupList.length === 0 ?
                                        <tr align="center">
                                            <td colSpan="5">No Product Group Available</td>
                                        </tr> :
                                        this.state.pgProductGroupList.map((product) => (
                                            <tr>
                                                <td data-tip={product.productGroupName}>{product.productGroupName}</td>
                                                {/* <td>{product.companyCode}</td> */}
                                                <td className="Status_Route" data-tip={product.status}>{product.status}</td>
                                                <td className="o2Del_container">
                                                    <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.editPGM_openHandler(product)} />
                                                    {
                                                        this.state.productGroupWriteAccess ?
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 8px' }} onClick={() => this.deletePGM_Handler(product)} />
                                                        : null
                                                    }                                                    
                                                </td>
                                            </tr>
                                        ))
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span> {this.state.pgProductGroupList ? this.state.pgProductGroupList.length : 0}</span>entries</span></div>
                        {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                        <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                    </div>
                    <div className="O2Table_bottomSubContainer">
                        {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /><ReactHTMLTableToExcel className="download-table-xls-button" table="productGroup_Table" filename={"Product Group"}
                            sheet="tablexls" buttonText="Excel Export" /></div> */}
                        <div className="O2Table_ExpExcel" onClick={ ()=> this.productGroupExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }}/>Excel Export</div>
                    </div>
                </div>
                <div className="O2TableEdit_wrapper productGroupTableEdit_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Product Group</div>
                            <div className="RM_BodyContent">
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Product Group Name </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pgProductGroupName' value={this.state.pgProductGroupName} onChange={this.handlePGMChange} />
                                    </div>
                                </div>
                                {/* <div className="legentContent_div">
                                    <div className="legendBody_Label">Company Code </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name='pgCompanyCode' value={this.state.pgCompanyCode} onChange={this.handlePGMChange} />
                                    </div>
                                </div> */}
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Status </div><span>:</span>
                                    <div className="legendBody_Value">
                                        <select value={this.state.pgStatus} onChange={this.pgmStatusChange}>
                                            <option value="Y">Active</option>
                                            <option value="N">In Active</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">
                                    {
                                        this.state.productGroupWriteAccess ?
                                            <button onClick={this.newPGM_popSaveHandler}>Save</button>
                                        : null
                                    }                                    
                                    <button onClick={this.newproductGroup_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL
    }
}
export default connect(mapStateToProps)(ProductGroupModule)
//export default ProductGroupModule
