import React, { Component } from 'react'
import './AppLogin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAlignCenter, faEye } from "@fortawesome/free-solid-svg-icons";
import {connect} from 'react-redux'
import {addUserObj} from '../redux/actions'


class AppLogin extends Component {

    constructor(props){
        super(props);

        this.state = {
       //   userLoginId:"AravindLabs",
      //  userPassword:"B@app@321",
     //   userPassword:"User@123",
       userLoginId:"",
       userPassword:"",
            history:this.props,
            userobj:"",
            httpURL:this.props.httpURL,
            userId:"",
            companyCode:"",
            userName:"",
            passwordShown:false,
            RepasswordShown:false,
            ConfirmpasswordShown:false,
            appPrefrenece:"",
            teamList:[],
            selectedTeam:'Select',
            selectedTeamDisable:false
        };
        this.userlogin = this.userlogin.bind(this);
        this.userPasswrd = this.userPasswrd.bind(this);

        // localStorage.setItem("userLoginname","");
        // localStorage.setItem("userLoginId","");
        // localStorage.setItem("userType","");
        // localStorage.setItem("userSalesmanCode","");
        // localStorage.setItem("userDistributorCode","");
        // localStorage.setItem("usercompanyCode","");

       // console.log("login:"+JSON.stringify(this.props))
       // this.props.store.dispatch(addUserObj("test"))
        //console.log("after login:"+JSON.stringify(this.props))
    }

    togglePasswordVisiblity = () => {
        const flag=this.state.passwordShown;

            this.setState({
                passwordShown: !flag,
            });

      };

      toggleRePasswordVisiblity = () => {
        const flag=this.state.RepasswordShown;

            this.setState({
                RepasswordShown: !flag,
            });

      };
      toggleConfirmPasswordVisiblity = () => {
        const flag=this.state.ConfirmpasswordShown;

            this.setState({
                ConfirmpasswordShown: !flag,
            });

      };

    userlogin  =(e)=>{
        this.setState({userLoginId: e.target.value});
    };
    userPasswrd  =(e)=>{
        this.setState({userPassword: e.target.value});
    };

    sendData = (val) => {
        this.props.parentCallback(val,this.state.userobj, this.state.appPrefrenece);
    }

    ResetALPswd_CloseHandler = () => {
        document.querySelector('.Changepswd_wrapper').style.display  = 'none';
    }

    Team_CloseHandler = () => {
        document.querySelector('.Changepswd_wrapper1').style.display  = 'none';
    }

    apChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    ResetALPswd_SaveHandler = () =>{


        //const { value } = this.state.tmNewPassword;
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        
        if (this.state.apOldPassword === "" || this.state.apOldPassword === undefined || this.state.apOldPassword === null) {
            alert("Please Old Enter Password");
        } else if (this.state.apNewPassword === "" || this.state.apNewPassword === undefined || this.state.apNewPassword === null) {
            alert("Please New Enter Password");
        } else if (this.state.apConfirmPassword === "" || this.state.apConfirmPassword === undefined || this.state.apConfirmPassword === null) {
            alert("Please Enter Confirm Password");
        } else if (this.state.apNewPassword !== this.state.apConfirmPassword) {
            alert("Incorrect Confirm Password");
        } else if (this.state.apNewPassword === this.state.apOldPassword) {
            alert("Old And New Password Cannaot Be Same");
        } 
        // else if (!strongRegex.test(this.state.apNewPassword) && this.state.appPrefrenece.patternCheck === 'Y'){
            // alert("Week Password : Password should contain one upperCase, one lowerCase, one special character and minimum length of 8 characters");
        // } 
        else {
        let params = {   
                "id": this.state.userId,
                "loginUser": this.state.userName,
                "changeFlag" : true,                           
                "companyCode": this.state.companyCode,                
                "oldPassword": this.state.apOldPassword,
                "resetPassword": this.state.apNewPassword                              
                }                 
                this.setState({
                    loading:true
                })
                axios.post(this.state.httpURL + "/user/resetPassword", params)
                    .then(response => {
                        this.setState({
                            loading:false
                        })
                        if (response.data.valid) {
                            if(response.data.oldPasswordFlag){
                                alert("Incorrect Old Password");
                            } else {
                                alert("Password Changed Successfully.");
                               // console.log("user obj->"+JSON.stringify(this.state.userobj));
                                this.ResetALPswd_CloseHandler();
                              //  this.sendData(true);                                
                                this.cleanAPResetPassword();
                                this.setState({
                                    resetFlag:false
                                },()=>{
                                    this.sendData(true);
                                })                               
                            }                            
                        } else {
                            alert(response.data.responseMsg);
                        }
                    })
                    .catch(error => {
                       // console.log("error" + error)
                       alert(error);
                       this.setState({
                        loading:false
                    })
                    }) 
        }
        window.userName=this.state.userLoginId;
    }

    cleanAPResetPassword(){
        this.setState({
            apOldPassword:'',
            apNewPassword:'',
            apConfirmPassword:''
        })
    }

     loginUser =() => {
        if(this.state.userLoginId === ""){
            alert("Please Enter User Name");
           return false;
        } else if(this.state.userPassword === ""){
            alert("Please Enter Password");
           return false;
        }else{  
           const params={
                userName:this.state.userLoginId,
                userPassword:this.state.userPassword,
                versionId:"",
                loginFrom:"web",
            }
            axios.get(this.state.httpURL+"/user/userLogin",{params})
            .then(response=>response.data)
            .then((data) =>{
                    //console.log('data--'+data);
                    if(data.valid){
                        
                        if(data.oldPasswordFlag && data.appPreferenceList[0].pwdReset === 'Y'){
                            document.querySelector('.Changepswd_wrapper').style.display = 'flex';
                            
                            // this.setState({
                            //     userId: data.usersList[0].userId,
                            //     companyCode: data.usersList[0].companyCode,
                            //     userName: data.usersList[0].userName,
                            //     userobj: data.usersList[0],
                            //     appPrefrenece:data.appPreferenceList[0],
                            // },()=>{
                            //     //console.log("user obj->"+JSON.stringify(this.state.userobj));
                            // });                           
                           
                        } else if(data.usersList[0].teamModified === "N"){
                            document.querySelector('.Changepswd_wrapper1').style.display = 'flex';

                            this.setState({
                                userId: data.usersList[0].userId,
                                companyCode: data.usersList[0].companyCode,
                                userName: data.usersList[0].userName,
                                userobj: data.usersList[0],
                                appPrefrenece:data.appPreferenceList[0],
                                teamList:data.userTeams


                            },()=>{
                                //console.log("user obj->"+JSON.stringify(this.state.userobj));
                                if(data.usersList[0].userType == 'BA'){
                                    this.setState({
                                        selectedTeam: "All",
                                        selectedTeamDisable:true
                                      }, () => {
                                        
                                      });
                                }else{
                                    this.setState({
                                        selectedTeamDisable:false
                                      }, () => {
                                        
                                      });
                                }
                            });                           
                           
                        } else{

                            this.setState({
                                userId: data.usersList[0].userId,
                                companyCode: data.usersList[0].companyCode,
                                userName: data.usersList[0].userName,
                                userobj: data.usersList[0],
                                appPrefrenece:data.appPreferenceList[0],
                            },()=>{
                                //console.log("user obj->"+JSON.stringify(this.state.userobj));
                                this.setState({userobj: data.usersList[0]},()=>{
                                    this.sendData(true);
                                });
                            });   
                        }

                        //document.querySelector('.Changepswd_wrapper').style.display = 'flex';
                        // this.setState({userobj: data.usersList[0]},()=>{
                        //     this.sendData(true);
                        //    // this.props.addUserObj("USER_CREDENTIALS")
                        //    // console.log("after login:"+JSON.stringify(this.props))
                        // });
                       // console.log("user data-->"+data.usersList[0].userId)
                       // localStorage.setItem("userLoginname",data.usersList[0].userName);
                       // localStorage.setItem("userLoginId",this.state.userLoginId);
                      //  localStorage.setItem("userType",data.usersList[0].userType);
                      //  localStorage.setItem("userSalesmanCode",data.usersList[0].salesmanCode);
                      //  localStorage.setItem("userDistributorCode",data.usersList[0].distributorCode);
                       // localStorage.setItem("usercompanyCode",data.usersList[0].companyCode);
                      // console.log("app pre-->"+data.appPreferenceList[0])
                             
                    }else{
                        alert(data.responseMsg)
                    }
            }).catch(err=>{
                console.log(err)
            })

           
        }
        window.userName=this.state.userLoginId;
      


      }


      handleTeamChange = (event) => {
        //  console.log("event-->"+JSON.stringify(event))
          this.setState({
            selectedTeam: event.target.value
          }, () => {
             // this.getAllOrders();
          });
  
      }

      
    Team_SaveHandler = () =>{

      //  console.log("selectedTeam obj->"+JSON.stringify(this.state.selectedTeam));
       // console.log("user userId->"+JSON.stringify(this.state.userId));

        if(this.state.selectedTeam =="Select"){
            alert("Select Team");
        }else{

            let params = {   "loginUserId": this.state.userId,"userTeam": this.state.selectedTeam}                 
            this.setState({
                loading:true
            })
            axios.post(this.state.httpURL + "/user/updateUserTeams", params)
                .then(response => {
                    this.setState({
                        loading:false
                    })
                    if (response.data.valid) {
                            alert("Team Updated Successfully.");
                           // console.log("user obj->"+JSON.stringify(this.state.userobj));
                            this.Team_CloseHandler();
                           // this.sendData(true);
                                                
                    } else {
                        alert(response.data.responseMsg);
                    }
                })
                .catch(error => {
                   // console.log("error" + error)
                   alert(error);
                   this.setState({
                    loading:false
                })
                }) 

        }
        


    }

      closeRefresh=(e)=>{
        //this.props.history.push('/');
        this.setState({
            userLoginId: "",
            userPassword:""
        });
      }

      updateCountry = (value) => this.setState({ country: value });

    render(){
      
      

    return(
        
        <div className="login_wrapper">
           
            <div className="login_box">
                <div className="login_title">Sign In</div>
                <div className="login_body">
                    <div className="row">
                       <div className="FieldComponent">
                            <div className="FieldLabel">Enter User Name</div>
                            <div className="FieldValue"> <input  value={this.state.userLoginId} onChange={this.userlogin} /></div>
                        </div>
                    </div>
                    <div className="row">
                      <div className="FieldComponent">
                            <div className="FieldLabel">Enter Password</div>
                            <div className="FieldValue"> <input type="password" value={this.state.userPassword} onChange={this.userPasswrd} /></div>
                        </div>
                    </div>

                    
             
                </div>
                <div className="login_footer">
                    <div className="loginBtnWrap">
                        <button onClick={this.loginUser}>Login</button>
                        <button onClick={(e) => this.closeRefresh(e)}>Cancel</button>
                    </div>
                    <div className="forgotPwd_container"><span></span></div>

                </div>

                <div className="apkLink_footer" style={{textAlign:'center'}}>
                    <a href="http://avlsfa.com/O2MobileNGv2.apk" target="_blank" style={{color:'red'}}>Download latest mobile application</a>
                </div>
            </div>
            <div className="Changepswd_wrapper">
            <div className="ChangePswrdBoxWrap">
                <div className="ChangePswrdBox">
                    <div className="ChangePswrdTitle">Change Password</div>
                    <div className="ChangePswrdBody">
                        {/* <div className="passwordChange_row">
                            <div className="passwordChange_label">Enter Login ID</div>
                            <div className="passwordChange_value">
                                <input type="text"/>
                            </div>
                        </div> */}
                        <div className="passwordChange_row">
                            <div className="passwordChange_label">Old Password</div>
                            <div className="passwordChange_value passIconWrapper">
                                <input type={this.state.passwordShown ? 'text' : 'password'} name='apOldPassword' value={this.state.apOldPassword} onChange={this.apChange}/>
                                <FontAwesomeIcon onClick={this.togglePasswordVisiblity} className="resetPasswordIcon" icon={faEye} style={{padding:'0px 4px'}} />
                            </div>
                        </div>
                        <div className="passwordChange_row">
                            <div className="passwordChange_label">New Password</div>
                            <div className="passwordChange_value passIconWrapper">
                                <input type={this.state.RepasswordShown ? 'text' : 'password'} name='apNewPassword' value={this.state.apNewPassword} onChange={this.apChange}/>
                                <FontAwesomeIcon onClick={this.toggleRePasswordVisiblity} className="resetPasswordIcon" icon={faEye} style={{padding:'0px 4px'}} />
                            </div>
                        </div>
                        <div className="passwordChange_row">
                            <div className="passwordChange_label">Confirm Password</div>
                            <div className="passwordChange_value passIconWrapper">
                                <input type={this.state.ConfirmpasswordShown ? 'text' : 'password'} name='apConfirmPassword' value={this.state.apConfirmPassword} onChange={this.apChange}/>
                                <FontAwesomeIcon onClick={this.toggleConfirmPasswordVisiblity} className="resetPasswordIcon" icon={faEye} style={{padding:'0px 4px'}} />
                            </div>
                        </div>
                    </div>
                    <div className="ChangePswrdFooter">
                        <button onClick={() =>this.ResetALPswd_SaveHandler()}>Save</button>
                        <button onClick={() =>this.ResetALPswd_CloseHandler()}>Cancel</button>
                    </div>
                </div>
            </div>
            </div>

            <div className="Changepswd_wrapper1">
                <div className="ChangePswrdBoxWrap">
                    <div className="ChangePswrdBox">
                        <div className="ChangePswrdTitle">Change Teams</div>
                        <div className="ChangePswrdBody">
                            {/* <div className="passwordChange_row">
                                <div className="passwordChange_label">Enter Login ID</div>
                                <div className="passwordChange_value">
                                    <input type="text"/>
                                </div>
                            </div> */}
                            <div className="passwordChange_row">
                                <div className="passwordChange_label">Select Team</div>
                                <div className="passwordChange_value passIconWrapper">
                                  
                                           <select value={this.state.selectedTeam} onChange={this.handleTeamChange} disabled={this.state.selectedTeamDisable}>
                                                    <option value="Select" key="Select">Select Team</option>
                                             {
                                            this.state.teamList.map((team) => (
                                                <option value={team.teamName} key={team.teamId}>{team.teamName}</option>

                                            ))
                                        }
                                    </select> 
                                </div>
                            </div>
                            
                        </div>
                        <div className="ChangePswrdFooter">
                            <button onClick={() =>this.Team_SaveHandler()}>Save</button>
                            
                         </div>
                    </div>
                </div>
            </div>
            <div class="confirmAlert_container">
                <div class="confirmAlert_wrapper">
                    <div class="confirmAlertHeader">
                        <span><i class="fa fa-exclamation-triangle"></i></span>
                        <span class="confirmAlertHeader_text">Alert</span>
                        
                        <span class="confirmAlert_container_close confirmAlertClose">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="confirmAlertbody">
                    
                    </div>
                    <div class="confirmAlertfooter">
                        <button class="confirmAlertClose">Confirm</button>
                        <button class="confirmAlertClose">Cancel</button>
                    </div>
                </div>	
            </div>


            <div class="alert_container">
                <div class="alert_wrapper">
                    <div class="alertHeader">
                        <span><i class="fa fa-exclamation-triangle"></i></span>
                        <span class="alertHeader_text">Alert</span>
                        
                        <span class="alert_container_close alertClose">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="alertbody">
                    
                    </div>
                    <div class="alertfooter">
                        <button class="alertClose">Ok</button>
                    </div>
                </div>	
            </div>
        </div>
    );
       
    }
}

const mapStateToProps=state=>{
    return {
        userObj:state.userObj,
        httpURL:state.httpURL,
        appPrefrenece:state.appPrefrenece,
    }
}
const mapDispatchToProps=dispath=>{
    return {
        addUserObj: (userObj) => dispath(addUserObj(userObj))
    }
    
}
 export default connect(mapStateToProps,mapDispatchToProps)(AppLogin)
