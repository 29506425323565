import React, { Component, useState } from 'react'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faPencilAlt, faTrashAlt, faSearch, faPlus, faEdit, faUserLock, faEye } from "@fortawesome/free-solid-svg-icons";
import './UserMainModule.css';
import axios from 'axios'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from 'react-redux'
import ReactTooltip from "react-tooltip";
import fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const override = css`
  display: block;
  top:50%;
  margin: 0 auto;
  border-color: red;
`;

const override1 = css`
  display: block;
  top: -235px;
  height: 0px;
  margin: 0 auto;
  border-color: blue;
`;
class UserMainModule extends Component {


    constructor(props) {
        super(props)
        // console.log(props);
        var curr = new Date();
        curr.setDate(curr.getDate());
        var todaydate = curr.toISOString().substr(0, 10);

        this.state = {
            userbtn: "Popup_tabButtons",
            userprefbtn: "Popup_tabButtons",
            httpURL: this.props.httpURL,
            loginuserObj: this.props.userObj,
            companyCode: this.props.userObj.companyCode,
            umUserList: [],
            umUserOrgList: [],
            umDistributorList: [],
            umReportList: [],
            umUserLanguageList: [],
            umUserTypeList: [],
            umUserType: '',
            umUserTypeLabel: '',
            umUserTeamList: [],
            umUserTeam: '',
            umUserTeamLabel: '',
            umUserRoleList: [],
            eroleList: [],
            smrRoleList: [],
            smrRoleOrgList: [],
            smrtempRoleList: [],
            umUserRole: '',
            umUserRoleLabel: '',
            umReportTo: '',
            umReportToLabel: '',
            umDistributorCode: '',
            umLanguage: '',
            umLanguageLabel: '',
            umDisplayImageInOrder: 'Y',
            umStatus: 'Y',
            umUserStatus: 'Y',
            umMultiUserRole: 'N',
            umBAUserStatus: 'N',
            umPasswordChange: 'Y',
            umUserName: '',
            umUserId: 0,
            umLoginUserId: '',
            currentDate: todaydate,
            passwordShown: false,
            RepasswordShown: false,
            umSalesmanCode: '',
            umEmployeeNumber: '',
            eroleList: [],
            // umappPreferenceList: [],
            orFState: '',
            orFStateList: [],
            umDeleteReason: '',
            umDeleteTempList: [],
            selectedUserType: 'Y',
            selectedUserTypeLen: 0,

        }
        // this.togglePasswordVisiblity = this.togglePasswordVisiblity.bind(this);
    }

    togglePasswordVisiblity = () => {
        const flag = this.state.passwordShown;

        this.setState({
            passwordShown: !flag,
        });

    };

    toggleRePasswordVisiblity = () => {
        const flag = this.state.RepasswordShown;

        this.setState({
            RepasswordShown: !flag,
        });

    };
    componentDidMount() {
        this.getUMuserList();
    }

    getUMuserList() {
        const params = {
            companyCode: this.state.companyCode
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/user/getUMuserList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        umUserList: data.umUsersList,
                        umUserOrgList: data.umUsersList,
                        umDistributorList: data.umDistributorList,
                        umReportList: data.umReportList,
                        umUserLanguageList: data.umUserLanguageList,
                        umUserTypeList: data.umUserTypeList,
                        umUserTeamList: data.umUserTeamList,
                        umUserRoleList: data.umUserRoleList,
                        smrRoleOrgList: data.umUserRoleList,
                        umLanguage: data.umUserLanguageList[0].value,
                        umLanguageLabel: data.umUserLanguageList[0].label,
                        // umappPreferenceList:data.appPreferenceList[0],
                        orFStateList: data.stateList,
                    });
                    ReactTooltip.rebuild();

                    const filterList = this.state.umUserList.filter((ul, i) => (ul.userStatus == this.state.selectedUserType));
                    this.setState({
                        selectedUserTypeLen: filterList.length
                    }, () => {
                        // this.getAllOrders();
                    });
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }



    saveUMDetail() {

        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        let employeeStatus = this.state.umUserStatus;
        if (this.state.umLoginUserId === "" || this.state.umLoginUserId === undefined || this.state.umLoginUserId === null) {
            alert("Please Enter User Name");
        } else if (this.state.umLoginUserId.length !== 10) {
            alert("Please Enter Valid User ID. \n(User ID Should Be In 10 Digit)");
        } else if (this.state.umUserName === "" || this.state.umUserName === undefined || this.state.umUserName === null) {
            alert("Please Enter User Name");
        } else if (this.state.umPassword === "" || this.state.umPassword === undefined || this.state.umPassword === null) {
            alert("Please Enter Password");
        } else if (this.state.umPassword.length < 6 && this.props.appPrefrenece.patternCheck === 'Y') {
            alert("Password Should Be In 6 To 10 Digit");
        } else if (this.state.umPassword.length > 10 && this.props.appPrefrenece.patternCheck === 'Y') {
            alert("Password Should Be In 6 To 10 Digit");
        } else if (this.state.umConfirmPassword === "" || this.state.umConfirmPassword === undefined || this.state.umConfirmPassword === null) {
            alert("Please Enter Confirm Password");
        } else if (this.state.umPassword.length < 6 && this.props.appPrefrenece.patternCheck === 'Y') {
            alert("Confirm Password Should Be In 6 To 10 Digit");
        } else if (this.state.umPassword.length > 10 && this.props.appPrefrenece.patternCheck === 'Y') {
            alert("Confirm Password Should Be In 6 To 10 Digit");
        } else if (this.state.umConfirmPassword !== this.state.umPassword) {
            alert("Incorrect Confirm Password");
        } else if (employeeStatus === 'Y' && (this.state.umUserType === "" || this.state.umUserType === undefined || this.state.umUserType === null)) {
            alert("Please Select User Type");
        }
        // else if (this.state.umEmployeeId === "" || this.state.umEmployeeId === undefined || this.state.umEmployeeId === null) {
        //     alert("Please Enter EmployeeId");
        // }
        else if (employeeStatus === 'Y' && (this.state.umReportTo === "" || this.state.umReportTo === undefined || this.state.umReportTo === null)) {
            alert("Please Select ReportTo");
            // } else if (this.state.umReportTo === this.state.umEmployeeNumber) {
        } else if (this.state.umReportTo === this.state.umEmployeeId) {
            alert("Employee Id And Reporting To Both Are Same. Please Choose Any Other Reporting Person.");
        } else if (this.state.umPhoneNumber === "" || this.state.umPhoneNumber === undefined || this.state.umPhoneNumber === null) {
            alert("Please Enter Phone Number");
        } else if (this.state.umPhoneNumber.length !== 10) {
            alert("Please Enter Valid Phone Number. \n(Phone Number Should Be In 10 Digit)");
        } else if (employeeStatus === 'Y' && (this.state.umEMail === "" || this.state.umEMail === undefined || this.state.umEMail === null)) {
            alert("Please Enter EMail");
        } else if (employeeStatus === 'Y' && (this.state.orFState === "" || this.state.orFState === undefined || this.state.orFState === null)) {
            alert("Please Choose State");
        } else if (this.state.umERPUserId === "" || this.state.umERPUserId === undefined || this.state.umERPUserId === null) {
            alert("Please Enter ERP UserId");
        }
        // else if (this.state.umBrandCode === "") {
        //     alert("Please Enter Brand Code");
        // } else if (this.state.umExpiryDate === "") {
        //     alert("Please Select Expiry Date");
        // } 
        else if (this.state.umLanguage === "" || this.state.umLanguage === undefined || this.state.umLanguage === null) {
            alert("Please Select User Language");
        } else if (!strongRegex.test(this.state.umPassword) && this.props.appPrefrenece.patternCheck === 'Y') {
            alert("Week Password : Password should contain one upperCase, one lowerCase, one special character and minimum length of 8 characters");
        }
        else {
            var tempFlag = false;
            if (this.state.umUserTypeLabel !== 'BA' && this.state.umUserTypeLabel !== 'BA Manager' && this.state.umUserTypeLabel !== 'BA Admin') {
                // if (this.state.umDistributorCode === "" || this.state.umDistributorCode === undefined || this.state.umDistributorCode === null) {
                //     alert("Please Select Distributor Code");
                //     tempFlag = false;
                // } else {
                tempFlag = true;
                // }
            } else {
                tempFlag = true;
            }

            if (tempFlag) {
                let params = {
                    "loginUserId": this.state.umLoginUserId,
                    "userName": this.state.umUserName,
                    "password": this.state.umPassword,
                    "confirmPassword": this.state.umConfirmPassword,
                    "userType": this.state.umUserTypeLabel,
                    "userTeam": this.state.umUserTeamLabel,
                    "umUserAssignedRoleList": this.state.eroleList,
                    "employeeNumber": this.state.umEmployeeNumber,
                    "orFState": this.state.orFState,
                    "erpUserId": this.state.umERPUserId,
                    "employeeId": this.state.umEmployeeId,
                    "reportTo": this.state.umReportTo,
                    "distributorCode": this.state.umDistributorCode,
                    "phoneNumber": this.state.umPhoneNumber,
                    "eMail": this.state.umEMail,
                    "brandCode": this.state.umBrandCode,
                    "deviceId": this.state.umDeviceId,
                    "expiryDate": this.state.umExpiryDate,
                    "passwordChange": this.state.umPasswordChange,
                    "userStatus": this.state.umUserStatus,
                    "multiUserRole": this.state.umMultiUserRole,
                    "baUserStatus": this.state.umBAUserStatus,
                    "language": this.state.umLanguageLabel,
                    "displayImageInOrder": this.state.umDisplayImageInOrder,
                    "status": this.state.umStatus,
                    "companyCode": this.state.companyCode,
                    "loginUser": this.state.loginuserObj.userName,
                    "id": this.state.umUserId
                }
                //console.log("<-1->",JSON.stringify(params));              
                this.setState({
                    loading: true
                })
                axios.post(this.state.httpURL + "/user/saveUserList", params)
                    .then(response => {
                        this.setState({
                            loading: false
                        })
                        if (response.data !== null) {
                            if (response.data.valid) {
                                if (response.data.existUser) {
                                    alert("User Name Already Exist");
                                } else if (response.data.existEmployee) {
                                    alert("Employee Id Already Exist");
                                } else if (response.data.existLoginUserId) {
                                    alert("User Id Already Exist");
                                } else if (response.data.existPhoneNumber) {
                                    alert("Phone Number Already Exist");
                                } else {
                                    alert("User Saved Suceesfully.");
                                    this.getUMuserList();
                                    this.userMain_popCloseHandler();
                                    this.clearUM();
                                }
                            } else {
                                alert(response.data.responseMsg);
                            }
                        }
                    })
                    .catch(error => {
                        // console.log("error" + error)
                        alert(error);
                        this.setState({
                            loading: false
                        })
                    })
            }
        }
    }

    poptabUserHandler = () => {
        this.setState({
            userbtn: "Popup_tabButtonsActive",
            userprefbtn: "Popup_tabButtons",
        })
        document.querySelector('.UserTab').style.display = 'block';
        document.querySelector('.userPrefTab').style.display = 'none';

    }
    poptabUserPreferenceHandler = () => {
        this.setState({
            userbtn: "Popup_tabButtons ",
            userprefbtn: "Popup_tabButtonsActive",
        })
        document.querySelector('.userPrefTab').style.display = 'block';
        document.querySelector('.UserTab').style.display = 'none';
    }



    userMain_newpopup_openHandler = () => {

        this.setState({
            userbtn: "Popup_tabButtonsActive",
            userprefbtn: "Popup_tabButtons",
        })
        document.querySelector('.UserTab').style.display = 'block';
        document.querySelector('.userPrefTab').style.display = 'none';

        document.querySelector('.newCustEdit_wrapper').style.display = 'flex';
        this.clearUM();
    }

    ResetPswd_OpenHandler = (ul) => {
        document.querySelector('.Resetpswd_wrapper').style.display = 'flex';
        this.setState({
            umUserId: ul.id
        });
    }
    ResetPswd_CloseHandler = () => {
        document.querySelector('.Resetpswd_wrapper').style.display = 'none';
        this.cleanUMResetPassword();
    }

    userMain_popCloseHandler = () => {
        document.querySelector('.newCustEdit_wrapper').style.display = 'none';
    }

    userMain_popup_openHandler = (ul) => {
        this.setState({
            userbtn: "Popup_tabButtonsActive",
            userprefbtn: "Popup_tabButtons",

        })
        document.querySelector('.UserTab').style.display = 'block';
        document.querySelector('.userPrefTab').style.display = 'none';

        document.querySelector('.newCustEdit_wrapper').style.display = 'flex';

        this.setState({
            umLoginUserId: '',
            umUserName: '',
            umPassword: '',
            umConfirmPassword: '',
            umUserType: '',
            umUserTeam: '',
            umUserRole: '',
            umEmployeeId: '',
            umERPUserId: '',
            umReportTo: '',
            umDistributorCode: '',
            umPhoneNumber: '',
            umEMail: '',
            umBrandCode: '',
            umDeviceId: '',
            umExpiryDate: '',
            umPasswordChange: 'Y',
            umUserStatus: 'Y',
            umMultiUserRole: 'N',
            umBAUserStatus: 'N',
            umLanguage: '',
            umDisplayImageInOrder: 'Y',
            umStatus: 'Y',
            umUserId: 0,
            umUserTypeLabel: '',
            umUserTeamLabel: '',
            umUserRoleLabel: '',
            umSalesmanCode: '',
            umEmployeeNumber: '',
            eroleList: [],
            orFState: ''
        }, () => {
            // console.log("ul-->" + JSON.stringify(ul))
            // console.log("umEmployeeNumber -->" + ul.employeeNumber)

            this.setState({
                umLoginUserId: ul.loginUserId,
                umUserName: ul.userName,
                umPassword: ul.password,
                umConfirmPassword: ul.password,
                umUserType: ul.userTypeId,

                umEmployeeId: ul.employeeId,
                umERPUserId: ul.erpUserId,
                umReportTo: ul.reportTo,
                umDistributorCode: ul.distributorCode,
                umPhoneNumber: ul.phoneNumber,
                umEMail: ul.email,
                umBrandCode: ul.brandCode,
                umDeviceId: ul.deviceId,
                umExpiryDate: ul.savedExpiryDate,
                umPasswordChange: ul.pwdtobechanged,
                umUserStatus: ul.userStatus,
                umMultiUserRole: ul.multiUserRole,
                umBAUserStatus: ul.baUserStatus,
                umLanguage: ul.languageId,
                umLanguageLabel: ul.language,
                umDisplayImageInOrder: ul.displayImageOrder,
                umStatus: ul.status,
                umUserId: ul.id,
                umUserTypeLabel: ul.userType,
                umUserTeamLabel: ul.userTeam,
                umUserTeam: ul.userTeam,
                umUserRoleLabel: ul.userRole,
                umUserRole: ul.userRole,
                umSalesmanCode: ul.salesmanCode,
                umEmployeeNumber: ul.employeeNumber,
                orFState: ul.orFState
            });
            this.getUMUserAssignedList(ul.id);
        });
    }

    getUMUserAssignedList(userId) {
        const params = {
            companyCode: this.state.companyCode,
            userId: userId,
        }
        this.setState({
            loading: true
        });
        axios.get(this.state.httpURL + "/user/getUMUserAssignedList", { params })
            .then(response => response.data)
            .then((data) => {
                if (data.valid) {
                    this.setState({
                        eroleList: data.umUserAssignedRoleList,
                    });
                    ReactTooltip.rebuild();
                } else {
                    alert(data.responseMsg);
                }
                this.setState({
                    loading: false
                });
            }).catch(err => {
                //console.log(err)
                this.setState({
                    loading: false
                });
                alert(err);
            })
    }

    userMainDelete = (ul) => {
        console.log("------------" + ul.id);
        this.setState({
            umDeleteReason: '',
            umDeleteTempList: ul,
        });
        document.querySelector('.dayMasterTableDelete_pop').style.display = 'flex';
        // var answer = window.confirm("Are Sure To Delete The User?")
        // if (answer) {
        //     const params = {
        //         id: ul.id,
        //         employeeId: ul.employeeId,
        //         loginUser: this.state.loginuserObj.userName,
        //         companyCode: this.state.companyCode
        //     }
        //     this.setState({
        //         loading: true
        //     });
        //     axios.get(this.state.httpURL + "/user/deleteUser", { params })
        //         .then(response => response.data)
        //         .then((data) => {
        //             if (data.valid) {
        //                 alert("Successfully Deleted");
        //                 this.getUMuserList();
        //             } else {
        //                 alert(data.responseMsg);
        //             }
        //             this.setState({
        //                 loading: false
        //             });
        //         }).catch(err => {
        //             //console.log(err)
        //             this.setState({
        //                 loading: false
        //             });
        //             alert(err);
        //         })
        // }
    }

    newUM_popSaveHandler = () => {
        let ul = this.state.umDeleteTempList;
        if (this.state.umDeleteReason === undefined || this.state.umDeleteReason === "" || this.state.umDeleteReason === null) {
            alert("Please Enter Reason for Deletion...");
        } else {
            const params = {
                id: ul.id,
                employeeId: ul.employeeId,
                loginUser: this.state.loginuserObj.userName,
                companyCode: this.state.companyCode,
                deleteReason: this.state.umDeleteReason
            }
            this.setState({
                loading: true
            });
            axios.get(this.state.httpURL + "/user/deleteUser", { params })
                .then(response => response.data)
                .then((data) => {
                    if (data.valid) {
                        alert("Successfully Deleted");
                        document.querySelector('.dayMasterTableDelete_pop').style.display = 'none';
                        this.getUMuserList();
                    } else {
                        alert(data.responseMsg);
                    }
                    this.setState({
                        loading: false
                    });
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        loading: false
                    });
                    alert(err);
                })
        }
    }

    newUM_popCloseHandler = () => {
        document.querySelector('.dayMasterTableDelete_pop').style.display = 'none';
    }

    ResetPswd_SaveHandler = () => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if (this.state.umResetPassword === "") {
            alert("Please Enter Password");
        } else if (this.state.umResetPassword.length < 6 && this.props.appPrefrenece.patternCheck === 'Y') {
            alert("Password Should Be In 6 To 10 Digit");
        } else if (this.state.umResetPassword.length > 10 && this.props.appPrefrenece.patternCheck === 'Y') {
            alert("Password Should Be In 6 To 10 Digit");
        } else if (this.state.umResetConfirmPassword === "") {
            alert("Please Enter Confirm Password");
        } else if (this.state.umResetConfirmPassword.length < 6 && this.props.appPrefrenece.patternCheck === 'Y') {
            alert("Confirm Password Should Be In 6 To 10 Digit");
        } else if (this.state.umResetConfirmPassword.length > 10 && this.props.appPrefrenece.patternCheck === 'Y') {
            alert("Confirm Password Should Be In 6 To 10 Digit");
        } else if (this.state.umResetPassword !== this.state.umResetConfirmPassword) {
            alert("Incorrect Confirm Password");
        } else if (!strongRegex.test(this.state.umResetPassword) && this.props.appPrefrenece.patternCheck === 'Y') {
            alert("Week Password : Password should contain one upperCase, one lowerCase, one special character and minimum length of 8 characters");
        }
        else {
            let params = {
                "id": this.state.umUserId,
                "companyCode": this.state.companyCode,
                "loginUser": this.state.loginuserObj.userName,
                "resetPassword": this.state.umResetPassword
            }
            this.setState({
                loading: true
            })
            axios.post(this.state.httpURL + "/user/resetPassword", params)
                .then(response => {
                    this.setState({
                        loading: false
                    })
                    if (response.data.valid) {
                        alert("Password Reseted Successfully.");
                        this.ResetPswd_CloseHandler();
                        this.cleanUMResetPassword();
                        this.getUMuserList();
                    } else {
                        alert(response.data.responseMsg);
                    }
                })
                .catch(error => {
                    // console.log("error" + error)
                    alert(error);
                    this.setState({
                        loading: false
                    })
                })
        }
    }

    clearUM() {
        var language = this.state.umUserLanguageList[0].value;
        var languageLabel = this.state.umUserLanguageList[0].label;
        this.setState({
            umLoginUserId: '',
            umUserName: '',
            umPassword: '',
            umConfirmPassword: '',
            umUserType: '',
            umUserTeam: '',
            umUserRole: '',
            umEmployeeId: '',
            umERPUserId: '',
            umReportTo: '',
            umDistributorCode: '',
            umPhoneNumber: '',
            umEMail: '',
            umBrandCode: '',
            umDeviceId: '',
            umExpiryDate: '',
            umPasswordChange: 'Y',
            umUserStatus: 'Y',
            umMultiUserRole: 'N',
            umBAUserStatus: 'N',
            umLanguage: language,
            umLanguageLabel: languageLabel,
            umDisplayImageInOrder: 'Y',
            umStatus: 'Y',
            umUserId: 0,
            umUserTypeLabel: '',
            umUserTeamLabel: '',
            umUserRoleLabel: '',
            umSalesmanCode: '',
            umEmployeeNumber: '',
            eroleList: [],
            orFState: ''
        });
    }

    cleanUMResetPassword() {
        this.setState({
            umUserId: 0,
            umResetPassword: '',
            umResetConfirmPassword: ''
        })
    }


    gridFilterList = (event) => {
        let filtered = this.state.umUserOrgList;
        let inputFields = document.getElementsByClassName('maingrid');
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].value !== '') {

                var searchString = inputFields[i].value;
                var fieldName = inputFields[i].name; //list object column name

                filtered = filtered.filter(od => (
                    od[fieldName].toLocaleString().toLowerCase().search(searchString.toLowerCase()) !== -1
                ));

            }
        }
        this.setState({
            umUserList: filtered
        })
    }

    handleUmUserTypeChange = (event) => {
        if (event.value != this.state.umUserType) {
            this.setState({
                eroleList: this.state.eroleList.filter(item => item.userAssignId != 999999999)
            }, () => {
                const isexist = this.state.eroleList.some(item => item.value == event.value)
                if (isexist) {
                    this.setState({
                        umUserType: event.value,
                        umUserTypeLabel: event.label,
                    });
                } else {
                    this.setState({
                        umUserType: event.value,
                        umUserTypeLabel: event.label,
                        eroleList: [...this.state.eroleList, event]
                    });
                }
            })
        }
    }

    handleUmUserTeamChange = (event) => {
        this.setState({
            umUserTeam: event.label,
            umUserTeamLabel: event.label
        });
    }

    handleUmUserRoleChange = (event) => {
        this.setState({
            umUserRole: event.label,
            umUserRoleLabel: event.label
        });
    }

    handleUmReportToChange = (event) => {
        this.setState({
            umReportTo: event.value,
            umReportToLabel: event.label
        });
    }

    handleUmDistributorCodeChange = (event) => {
        this.setState({
            umDistributorCode: event.value
        });
    }

    handleUmLanguageChange = (event) => {
        this.setState({
            umLanguage: event.value,
            umLanguageLabel: event.label
        });
        if (this.state.umDisplayImageInOrder === "" || this.state.umDisplayImageInOrder === undefined || this.state.umDisplayImageInOrder === null) {
            this.setState({
                umDisplayImageInOrder: 'Y'
            });
        } if (this.state.umStatus === "" || this.state.umStatus === undefined || this.state.umStatus === null) {
            this.setState({
                umStatus: 'Y'
            });
        }
    }

    handleMultiRoleChange = (event) => {
        const { smrRoleList, smrtempRoleList } = this.state;
        this.setState({
            smrtempRoleList: event
        }, () => {
            this.state.eroleList = this.state.smrtempRoleList
            this.setState({
                smrRoleList: smrRoleList,
                smrRoleOrgList: smrRoleList
            }, () => {
            })
        });
        // console.log('event > ' + JSON.stringify(this.state.eroleList));
    }

    handleORFStateChange = (event) => {
        // console.log('event > label > ' + event.label);
        this.setState({
            orFState: event.label,
        }, () => {

        });
    }

    umDisplayChange = (event) => {
        this.setState({
            umDisplayImageInOrder: event.target.value
        });
    }

    umStatusChange = (event) => {
        this.setState({
            umStatus: event.target.value
        });
    }

    umPasswordChange = (event) => {
        this.setState({
            umPasswordChange: event.target.value
        });
    }

    umUserStatusChange = (event) => {
        this.setState({
            umUserStatus: event.target.value
        });
    }

    umMultiUserRoleChange = (event) => {
        this.setState({
            umMultiUserRole: event.target.value
        });
    }

    umBAUserStatusChange = (event) => {
        this.setState({
            umBAUserStatus: event.target.value
        });
    }

    umChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    umLanguage_deleteHandler = () => {
        this.setState({
            umLanguage: '',
            umLanguageLabel: '',
            umDisplayImageInOrder: 'Y',
            umStatus: 'Y'
        });
    }

    umReasonDeletChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    userExcel = () => {
        var dFlag = true;
        if (this.state.umUserList.length === 0) {
            alert("No Records");
            dFlag = false;
        }
        if (dFlag) {
            var arr = {};
            var arrayList = [];
            var dList = this.state.umUserList;
            var fileName = "User Report_" + this.state.currentDate;
            var wscols = [
                { wch: 40 },
                { wch: 15 },
                { wch: 15 },
                { wch: 10 },
                { wch: 22 },
                { wch: 10 }
            ];
            for (var i = 0; i < dList.length; i++) {
                if (dList[i].userStatus == this.state.selectedUserType) {
                    arr = {};
                    arr = {
                        "Login User Id": dList[i].loginUserId,
                        "Salesman Code": dList[i].salesmanCode,
                        "User Name": dList[i].userName,
                        "User Type": dList[i].userType,
                        "Employee Id": dList[i].employeeNumber,
                        "User Team": dList[i].userTeam,
                        "City": dList[i].city,
                        "State": dList[i].orFState,
                        // "Expiry Date": dList[i].expiryDate,
                        // "Password To Be Changed": dList[i].pwdtobechanged,
                        // "Status": dList[i].userStatus,
                    }
                    arrayList.push(arr);
                }
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(arrayList);
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'User': ws }, SheetNames: ['User'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            fileSaver.saveAs(data, fileName + fileExtension);
        }
    }

    handleSelectedUserTypeChange = (event) => {
        //  console.log("event-->"+JSON.stringify(event))
        const filterList = this.state.umUserList.filter((ul, i) => (ul.userStatus == event.target.value));
        this.setState({
            selectedUserType: event.target.value,
            selectedUserTypeLen: filterList.length
        }, () => {
            // this.getAllOrders();
        });
    }


    render() {

        return (
            <div className="O2Container_Wrapper">
                <ReactTooltip />
                <div className="O2Container_Table">
                    <div className="O2Table_Header"><span>USER</span></div>
                    <div className="O2Table_Body">
                        <div className="O2Table_SubContainer userType_container">
                            <div className="O2Content_row">
                                <div className="contentRow_Label">User Type</div>
                                <div className="contentRow_Value">
                                    <select value={this.state.selectedUserType} onChange={this.handleSelectedUserTypeChange}>
                                        <option value="Y" >Active</option>
                                        <option value="N" >In Active</option>
                                    </select>
                                </div>
                            </div>
                            <div className="O2Table_New">
                                <button onClick={this.userMain_newpopup_openHandler}>New</button>
                            </div>
                        </div>
                        <div className="Table_wrapper">
                            <table className="customTable">
                                <thead>
                                    <tr>
                                        {/* <th className="checkBox_Container">
                                            <input type="checkbox" name="checkAll" checked={this.state.allChecked} onChange={this.handleAllChange} />
                                        </th>  */}
                                        <th className="">User Name
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="userName" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="">User Type
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="userType" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="">State
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="orFState" onChange={this.gridFilterList} />
                                            </div>
                                        </th>

                                        {/* <th className="">Salesman Code
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retGroup" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="">ERP UserId
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{padding:'0px 4px'}}/>
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="retGroup" onChange={this.gridFilterList} />
                                            </div>
                                        </th> */}
                                        <th className="">Employee ID
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="employeeNumber" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="">User ID
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="loginUserId" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="">Expiry Date
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="expiryDate" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        {/* <th className="">Password to be Changed
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="pwdtobechanged" onChange={this.gridFilterList} />
                                            </div>
                                        </th> */}
                                        <th className="status_Width">Status
                                            <div className="searchWrapper">
                                                <FontAwesomeIcon className="SearchIcon" icon={faSearch} style={{ padding: '0px 4px' }} />
                                                <input type="text" className="tableMultiFilterSearch_input maingrid" name="userStatus" onChange={this.gridFilterList} />
                                            </div>
                                        </th>
                                        <th className="o2Del_container"></th>
                                        <th className="scroller"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <td className="checkBox_Container">
                                            <input type="checkbox"/>
                                        </td>
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className="status_Width"></td>
                                        <td className="o2Del_container">
                                            <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} onClick={() => this.userMain_popup_openHandler()} />
                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 8px' }}  />                                                                                                          
                                        </td>
                                    </tr>   */}
                                    {
                                        this.state.umUserList.map((ul, i) => (

                                            ul.userStatus == this.state.selectedUserType ?

                                                <tr key={ul.id}>
                                                    {/* <td className="checkBox_Container">
                                            <input type="checkbox"/>
                                        </td> */}
                                                    <td className="" data-tip={ul.userName}>{ul.userName}</td>
                                                    <td className="" data-tip={ul.userType}>{ul.userType}</td>
                                                    <td className="" data-tip={ul.orFState}>{ul.orFState}</td>
                                                    <td className="" data-tip={ul.employeeNumber}>{ul.employeeNumber}</td>
                                                    <td className="" data-tip={ul.loginUserId}>{ul.loginUserId}</td>
                                                    <td className="" data-tip={ul.expiryDate}>{ul.expiryDate}</td>
                                                    {/* <td className="" data-tip={ul.pwdtobechanged}>{ul.pwdtobechanged}</td> */}
                                                    <td className="status_Width" data-tip={ul.userStatus}>{ul.userStatus}</td>
                                                    <td className="o2Del_container">
                                                        <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} data-tip={"Edit"} onClick={() => this.userMain_popup_openHandler(ul)} />
                                                        <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 8px' }} data-tip={"Delete"} onClick={() => this.userMainDelete(ul)} />
                                                        <FontAwesomeIcon icon={faUserLock} style={{ padding: '0px 2px' }} data-tip={"Reset Password"} onClick={() => this.ResetPswd_OpenHandler(ul)} />
                                                    </td>
                                                </tr> : ""

                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            <div className="O2Table_PageCount"><span className="pageCalc_container">Showing<span>{this.state.selectedUserTypeLen}</span>entries</span></div>
                            {/* <div className="O2Table_Next"><span className="Nxtprev_btn">Next<FontAwesomeIcon icon={faAngleDoubleRight} style={{ padding: '0px 8px' }} /></span></div>
                            <div className="O2Table_Prev"><span className="Nxtprev_btn"><FontAwesomeIcon icon={faAngleDoubleLeft} style={{ padding: '0px 8px' }} /> Previous</span></div> */}
                        </div>
                        <div className="O2Table_bottomSubContainer">
                            {/* <div className="O2Table_Export"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /> <ReactHTMLTableToExcel className="download-table-xls-button" table="NewCustomer_table" filename={"New Customer"} */}
                            {/* sheet="tablexls" buttonText="Excel Export"/></div> */}
                            {/* <div className="O2Table_ExpExcel"><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} /></div> */}
                            <div className="O2Table_ExpExcel" onClick={() => this.userExcel()}><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ padding: '0px 8px' }} />Excel Export</div>
                        </div>
                    </div>
                </div>
                <div className="newCustEdit_wrapper">
                    <div className="newCustEditBoxWrap">
                        <div className="newCustEditBox">
                            <div className="Popup_Tabwrapper">
                                <div className={this.state.userbtn} onClick={this.poptabUserHandler}><span>User</span></div>
                                <div className={this.state.userprefbtn} onClick={this.poptabUserPreferenceHandler}><span>User Preference</span></div>
                            </div>
                            <div className="Popup_tabContentwrapper UserMainContent">
                                <div className="popTabBody UserTab">
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">User ID <span>*</span></div>
                                            <div className="FieldValue"><input type="text" name='umLoginUserId' value={this.state.umLoginUserId} onChange={this.umChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">User Name <span>*</span></div>
                                            <div className="FieldValue"><input type="text" name='umUserName' value={this.state.umUserName} onChange={this.umChange} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Employee Id </div>
                                            <div className="FieldValue">
                                                <input type="text" name='umEmployeeNumber' value={this.state.umEmployeeNumber} onChange={this.umChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">ERP UserId <span>*</span></div>
                                            <div className="FieldValue">
                                                <input type="text" name='umERPUserId' value={this.state.umERPUserId} onChange={this.umChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Reporting To <span></span></div>
                                            <div className="FieldValue">
                                                <Select options={this.state.umReportList} name="reportTo" value={this.state.umReportList.filter(({ value }) => value === this.state.umReportTo)} placeholder="Please Choose Report To" onChange={(e) => this.handleUmReportToChange(e)} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">User Type <span>*</span></div>
                                            <div className="FieldValue">
                                                <Select options={this.state.umUserTypeList} name="userType" value={this.state.umUserTypeList.filter(({ value }) => value === this.state.umUserType)} placeholder="Please Choose User Type" onChange={(e) => this.handleUmUserTypeChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Password<span>*</span></div>
                                            <div className="FieldValue passIconWrapper"><input type={this.state.passwordShown ? "text" : "password"} name='umPassword' value={this.state.umPassword} onChange={this.umChange} />
                                                <FontAwesomeIcon onClick={this.togglePasswordVisiblity} className="PasswordIcon" icon={faEye} style={{ padding: '0px 4px' }} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Confirm Password<span>*</span></div>
                                            <div className="FieldValue passIconWrapper"><input type={this.state.RepasswordShown ? "text" : "password"} name='umConfirmPassword' value={this.state.umConfirmPassword} onChange={this.umChange} />
                                                <FontAwesomeIcon onClick={this.toggleRePasswordVisiblity} className="PasswordIcon" icon={faEye} style={{ padding: '0px 4px' }} />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Phone Number <span>*</span></div>
                                            <div className="FieldValue">
                                                <input type="text" name='umPhoneNumber' value={this.state.umPhoneNumber} onChange={this.umChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">E-Mail <span>*</span></div>
                                            <div className="FieldValue">
                                                <input type="text" name='umEMail' value={this.state.umEMail} onChange={this.umChange} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Brand Code <span>*</span></div>
                                            <div className="FieldValue">
                                                <input type="text" name='umBrandCode' value={this.state.umBrandCode} onChange={this.umChange}/>
                                            </div>
                                        </div>                                        
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Device Id <span></span></div>
                                            <div className="FieldValue">
                                               <input type="text" name='umDeviceId' value={this.state.umDeviceId} onChange={this.umChange}/>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Expiry Date <span>*</span></div>
                                            <div className="FieldValue">
                                                <input type="date" name="umExpiryDate" value={this.state.umExpiryDate} onChange={this.umChange}/>
                                            </div>
                                        </div>                                        
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Password to be Changed <span>*</span></div>
                                            <div className="FieldValue">
                                            <select value={this.state.umPasswordChange} onChange={this.umPasswordChange}>
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                            </select>
                                            </div>
                                        </div>                                        
                                    </div> */}
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">State <span>*</span></div>
                                            <div className="FieldValue">
                                                <Select options={this.state.orFStateList} name="orFState" value={this.state.orFStateList.filter(({ label }) => label === this.state.orFState)} placeholder="Choose State" onChange={this.handleORFStateChange} />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Status <span></span></div>
                                            <div className="FieldValue">
                                                <select value={this.state.umUserStatus} onChange={this.umUserStatusChange}>
                                                    <option value="Y">Active</option>
                                                    <option value="N">In Active</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Distributor Code <span></span></div>
                                            <div className="FieldValue">
                                                {
                                                    this.state.umUserTypeLabel === 'BA' || this.state.umUserTypeLabel === 'BA Manager' || this.state.umUserTypeLabel === 'BA Admin' ?
                                                        <Select options={this.state.umDistributorList} name="distributorCode" value={this.state.umDistributorList.filter(({ value }) => value === this.state.umDistributorCode)} placeholder="Please Choose Distributor" isDisabled={true} onChange={(e) => this.handleUmDistributorCodeChange(e)} />
                                                        :
                                                        <Select options={this.state.umDistributorList} name="distributorCode" value={this.state.umDistributorList.filter(({ value }) => value === this.state.umDistributorCode)} placeholder="Please Choose Distributor" onChange={(e) => this.handleUmDistributorCodeChange(e)} />
                                                }
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Status <span></span></div>
                                            <div className="FieldValue">
                                                <select value={this.state.umUserStatus} onChange={this.umUserStatusChange}>
                                                    <option value="Y">Active</option>
                                                    <option value="N">In Active</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">User Code</div>
                                            <div className="FieldValue">
                                                <input type="text" value={this.state.umSalesmanCode} disabled />
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">User Team</div>
                                            <div className="FieldValue">
                                                <Select options={this.state.umUserTeamList} name="userTeam" value={this.state.umUserTeamList.filter(({ label }) => label === this.state.umUserTeam)} placeholder="Please Choose User Team" onChange={(e) => this.handleUmUserTeamChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">Multi User</div>
                                            <div className="FieldValue">
                                                <select value={this.state.umMultiUserRole} onChange={this.umMultiUserRoleChange}>
                                                    <option value="Y">Active</option>
                                                    <option value="N">In Active</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="FieldComponent">
                                            <div className="FieldLabel">BA User</div>
                                            <div className="FieldValue">
                                                <select value={this.state.umBAUserStatus} onChange={this.umBAUserStatusChange}>
                                                    <option value="Y">Active</option>
                                                    <option value="N">In Active</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.umMultiUserRole == 'N' ? '' :
                                        <div className="row">
                                            <div className="FieldComponentSelect">
                                                <div className="FieldLabelSelct">Multi User Role</div>
                                                <div className="FieldValueSelect">
                                                    <Select isMulti options={this.state.umUserRoleList} name="smrMondayValue" value={this.state.eroleList} placeholder="Choose Roles" onChange={(e) => this.handleMultiRoleChange(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="popTabBody userPrefTab">
                                    <div className="tabBodyContent">
                                        <div className="Table_wrapper">
                                            <table className="customTable">
                                                <thead>
                                                    <tr>
                                                        <th>Language</th>
                                                        <th>Display Image In Order</th>
                                                        <th className="status_Width">Status</th>
                                                        <th className="o2popDel_container"></th>
                                                        <th className="scroller"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td>
                                                            <Select options={this.state.umUserLanguageList} name="language" value={this.state.umUserLanguageList.filter(({ value }) => value === this.state.umLanguage)} placeholder="Please Choose Language" onChange={(e) => this.handleUmLanguageChange(e)} />
                                                        </td>
                                                        <td>
                                                            <select value={this.state.umDisplayImageInOrder} onChange={this.umDisplayChange}>
                                                                <option value="Y">Yes</option>
                                                                <option value="N">No</option>
                                                            </select>
                                                        </td>
                                                        <td className="status_Width">
                                                            <select value={this.state.umStatus} onChange={this.umStatusChange}>
                                                                <option value="Y">Yes</option>
                                                                <option value="N">No</option>
                                                            </select>
                                                        </td>
                                                        <td className="o2popDel_container">
                                                            {/* <FontAwesomeIcon icon={faEdit} style={{ padding: '0px 4px' }} /> */}
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ padding: '0px 4px' }} onClick={() => this.umLanguage_deleteHandler()} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="newCustEditFooter">
                                <div className="row editCustBtnWrap">
                                    <button onClick={() => this.saveUMDetail()}>Save</button>
                                    <button onClick={() => this.userMain_popCloseHandler()}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <FadeLoader
                    css={override}
                    size={150}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
                <div className="Resetpswd_wrapper">
                    <div className="ResetPswrdBoxWrap">
                        <div className="ResetPswrdBox">
                            <div className="ResetPswrdTitle">Reset Password</div>
                            <div className="ResetPswrdBody">
                                <div className="passwordChange_row">
                                    <div className="passwordChange_label">New Password</div>
                                    <div className="passwordChange_value passIconWrapper">
                                        <input type={this.state.passwordShown ? 'text' : 'password'} name='umResetPassword' value={this.state.umResetPassword} onChange={this.umChange} />
                                        <FontAwesomeIcon onClick={this.togglePasswordVisiblity} className="resetPasswordIcon" icon={faEye} style={{ padding: '0px 4px' }} />
                                    </div>
                                </div>
                                <div className="passwordChange_row">
                                    <div className="passwordChange_label">Confirm Password</div>
                                    <div className="passwordChange_value passIconWrapper">
                                        <input type={this.state.RepasswordShown ? 'text' : 'password'} name='umResetConfirmPassword' value={this.state.umResetConfirmPassword} onChange={this.umChange} />
                                        <FontAwesomeIcon onClick={this.toggleRePasswordVisiblity} className="resetPasswordIcon" icon={faEye} style={{ padding: '0px 4px' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="ResetPswrdFooter">
                                <button onClick={() => this.ResetPswd_SaveHandler()}>Save</button>
                                <button onClick={() => this.ResetPswd_CloseHandler()}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="O2TableEdit_wrapper dayMasterTableDelete_pop">
                    <div className="O2TableEditBoxWrap">
                        <div className="O2TableEditBox">
                            <div className="O2TableEditTitle">Alert</div>
                            <div className="RM_BodyContent">
                                <div className="legentContent_div">
                                    <div className="">Do you Want to Delete ? </div>
                                </div>
                                <div className="legentContent_div">
                                    <div className="legendBody_Label">Reason for deletion</div><span>:</span>
                                    <div className="legendBody_Value">
                                        <input type="text" name="umDeleteReason" value={this.state.umDeleteReason} onChange={this.umReasonDeletChange} />
                                    </div>
                                </div>

                            </div>
                            <div className="O2TableEditFooter">
                                <div className="row O2TableBtnWrap">

                                    <button onClick={this.newUM_popSaveHandler}>Save</button>

                                    <button onClick={this.newUM_popCloseHandler}>Cancel</button>
                                </div>
                            </div>
                            <FadeLoader
                                css={override1}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}



// export default UserMainModule

const mapStateToProps = state => {
    return {
        userObj: state.userObj,
        httpURL: state.httpURL,
        appPrefrenece: state.appPrefrenece
    }
}
export default connect(mapStateToProps)(UserMainModule)
